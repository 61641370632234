//map 검색 바
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useEffect, useState } from 'react';
import { IAlarm } from '../../model/alarm';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import NameSelectRem from 'components/atom/NameSelectRem';
import NameInputRem from 'components/atom/NameInputRem';
import userStore from 'state/user';
import groupStore from 'state/group';
import { any } from 'prop-types';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

type SearchBarProps = {
  size?:number;
  onSearch:(groupId:string, _srType:string)=> void;
};
const SearchBar = ({ size, onSearch }: SearchBarProps) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [width, setWidth] = useState(size);
  const [hide, setHide] = useState(true);
  const [group, setGroup] = useState(null);
  const { t} = useTranslation()

  const [values, setValues] = useState(
    {
      groupId:'',
      alarmStatus:'',
      facNo:'',
    });

  useEffect(() => {
    console.log(`SearchBar getGroupList siteId : ${userStore.siteId}`)
    groupStore.getGroupList(userStore.siteId, "10", "1");
  },[]);

  useEffect(() => {
    console.log(`SearchBar getGroupList group : ${JSON.stringify(group)}`)
  },[group]);

  useEffect(() => {
    console.log(`SearchBar groupList ${JSON.stringify(groupStore.groupList)}`);
    let index = 0;
    const lists = groupStore.groupList.reduce((acc, item) => {
      index++;
      acc.push({label: item.groupName, value: index,id: item.groupId});
      return acc;
    }, [{label: '모든그룹', value: index, id:''}]);
    setGroup(
      lists
    )
  },[groupStore.groupList]);

  useEffect(() => {
    setWidth(size);
  },[size]);
  
  console.log(`SearchBar size : ${size}`)

  const handleHide = (_hide:boolean) => {
  console.log(`SearchBar handleHide : ${_hide}`)
    setHide(_hide);
  };

  const handleSearch = () => {
    const alarm: IAlarm =
    {
      facType: '',
      facNo: '',
      locCode: '',
      alarmStatus: '',
    };
    onSearch(values.groupId, 'total');
  };

  const handleChangeGroup = (name:string, value:string) => {
    console.log(`cyi handleChangeGroup name:${name}, value:${value}`)

		const tmpGroup = group.find((item: any) => item.value == value)
    setValues({
      ...values,
      groupId: tmpGroup.id,
    });
  };

  return (
    <Card>
      <CardContentNoPadding>
        {hide ?
          <IconButton
            onClick={() => handleHide(false)}
            type="submit"
            sx={{ p: '10px' }}
            aria-label="open"
            size="large">
            <SearchIcon />
          </IconButton>
          :
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                pl: 1,
                pb: '12px',
                pt: '12px',
                maxWidth: { width },
              }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                >
                  <Box>
                    <NameSelectRem
                      name={t('global.facGroup')}
                      object={group}
                      defaultValue={'0'}
                      space={'1rem'}
                      nameWidth={'6rem'}
                      valueWidth={'20rem'}
                      onSelect={handleChangeGroup}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => handleSearch()}
                type="submit"
                sx={{ p: '10px' }}
                aria-label="search"
                size="large">
                <SearchIcon />
              </IconButton>
              {!hide &&
                <IconButton
                  onClick={() => handleHide(true)}
                  type="submit"
                  sx={{ p: '10px' }}
                  aria-label="open"
                  size="large">
                  <CloseFullscreenIcon />
                </IconButton>
              }
            </Box>
          </Box>
        }

      </CardContentNoPadding>
    </Card>
  );
};

export default SearchBar;
