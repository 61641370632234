// 장애관련 api

import { observable } from "mobx";
import { api, q } from '../api';
import { ICommentInfo, IMaintenanceErrorInfo } from "model/maintenance";

export interface IMaintenanceStore {
    maintenanceInfoList:any[];
    maintenanceInfo:IMaintenanceErrorInfo;
    commentList:any[];
    commentInfo:ICommentInfo;
    
	getErrorList:(facId:string,siteId:string) => void;
	getError:(mainId:string) => void;
	regError:(facId:string, category:string, description:string, stateCode:string, siteId:string) => void;
	updateError:(facId:string, mainId:string, category:string, description:string, stateCode:string) => void;
	addComment:(mainId:string,subId:string,comment:string) => any;
	delComment:(subId:string) => void;
	getCommentList:(mainId:string) => void;
}

export const maintenanceStore = observable<IMaintenanceStore>({
    maintenanceInfoList:[],
    maintenanceInfo:null,
    commentList:[],
    commentInfo:null,

	//장애 리스트 가져옴
	async getErrorList( facId:string , siteId:string ) {
		console.log(`addLine ::: ${JSON.stringify(this.lineInfo)}`)
		try {
			const ret = await api.post(`/api2/maintenance/getErrorList`, {
                facId,
				siteId
            });
			console.log(`getErrorList ret = ${JSON.stringify(ret)}`)
            this.maintenanceInfoList = ret.data;
			return ret.data;
		} catch (error) {
			console.log(`getErrorList error`)
			return {status:error.status};
		}
	},
	//장애 정보 가져옴(한개)
	async getError( mainId:string ) {
		try {
			const ret = await api.post(`/api2/maintenance/getError`, {
                mainId,
            });
			console.log(`getErrorList ret = ${JSON.stringify(ret)}`)
            this.maintenanceInfo = ret.data;
			return ret.data;
		} catch (error) {
			console.log(`getError error`)
			return {status:error.status};
		}
	},
	//장애 등록
	async regError( facId:string, category:string, description:string, stateCode:string, siteId:string ) {
		// api
		console.log(`regError :::`);
		const ret = await api.post('/api2/maintenance/regError',{
				facId,
                category,
                description,
                stateCode,
				siteId
		});
		console.log(`regError ret ${JSON.stringify(ret)}`)
		return {status:200};
	},
	//장애 상태 업데이트
	async updateError( facId:string, mainId:string ,category:string, description:string, stateCode:string ) {
		// api
		console.log(`updateError :::`);
		const ret = await api.post('/api2/maintenance/updateError',{
				facId,
				mainId,
                category,
                description,
                stateCode,
		});
		console.log(`getLine ret ${JSON.stringify(ret)}`)
		//this.lineInfo = ret.data;
		return {status:200};
	},
	//장애 코멘트 추가
	async addComment( mainId:string,subId:string,comment:string ) {
        console.log(`addComment`);
		try {
			const ret = await api.post(`/api2/maintenance/addComment`,{
				mainId,
				subId,
				comment,
			});
			console.log(`addComment ret = ${JSON.stringify(ret)}`)
			//this.lineList = ret.data;
			return {status:200};
		} catch (error) {
			console.log(`addComment Error`)
			return {status:error.status};

		}
	},
	//코멘트 삭제
	async delComment( subId:string ) {
		try {
			const ret = await api.post(`/api2/maintenance/delComment`, {
                subId,
            });
			console.log(`delComment ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`getErrorList error`)
			return {status:error.status};
		}
	},
	//댓글 리스트 가져옴
	async getCommentList( mainId:string ) {
		console.log(`getCommentList`);
		try {
			const ret = await api.post(`/api2/maintenance/getCommentList`, {
				mainId,
			});
			console.log(`getCommentList ret = ${JSON.stringify(ret)}`)
            this.commentList = ret.data;
			return {status:200};
		} catch (error) {
			console.log(`getCommentList`)
			return {status:error.status};
		}
	},
})

export default maintenanceStore;
