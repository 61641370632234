// 그룹설정 - 라인추가

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
} from '@mui/material';
import NameOverInput from 'components/atom/NameOverInput';
import { ILineSet } from 'model';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import lineStore from 'state/line';
import userStore from 'state/user';

const UserType = [
  { id: 'lineName', name: '라인 이름' },
  { id: 'description', name: '설명' },
];

export interface LineAddPopupProps {
  groupId:string;
  open?: boolean;
  onClose: () => void;
}
const LineAddPopup :React.FunctionComponent<LineAddPopupProps> = ({open, groupId, onClose}: LineAddPopupProps) => {
  const [values, setValues] = useState(
    {
      siteId: '',
      siteName: '',
      groupId: '',
      groupName: '',
      lineId: '',
      lineName: '',
      description: '',
      chunkId: '',
      chunkName: '',
      operationMode: 'N',
      offTime: '',
      onTime: '',
    });

  // useEffect(() => {
  //   if (open) {
  //     setValues({
  //       ...values,
  //     });
  //     console.log("ehl")
  //   }
  // },[open]);

  //라인정보 입력 
  const handleChange = (name:string, value:string) => {
    console.log(`handleChange name:${name}, value"${value}`)
    const found = UserType.find(item => item.name == name);
    console.log(` handleChange find : ${found.id}:${found.name}`)

    setValues({
      ...values,
      [found.id]: value
    });
  };

  //팝업 닫음
  const handleDialogClose = () => {
    console.log(`handleDialog`)
    setValues(null)
    onClose();
  };

  //정보 저장
  const handleApply = async () => {
    console.log(`handleApply`)

    console.log(`handleSave `)
    const line: ILineSet = {
      siteId: userStore.siteId,
      siteName: '',
      groupId: groupId, 
      groupName: '',
      lineId: '',
      lineName: values.lineName,
      description: values.description,
      chunkId: '-1',
      chunkName: '',
      operationMode: 'N',
      onTime: '',
      offTime: '',
    }

    console.log(`LineAddPopup handleApply values ${JSON.stringify(values)}`)
    console.log(`LineAddPopup handleApply line ${JSON.stringify(line)}`)
    const ret: any = await lineStore.addLine(line);
    if (ret.status == 200) {
      setValues(null)
      ToastsStore.success("등록 성공")
      const timer = setInterval(() => {
        clearInterval(timer);
        onClose();
      }, 1000)
    }
    else if (ret.status == 400) {
      setValues(null)
      ToastsStore.success("등록 실패")
    }
  };

  return (
    //라인추가 팝업
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Card>
        <Typography
                  sx={{ 
                    fontSize: 23,
                    fontWeight:600,
                    color:'#0C3149',
                    display:'flex',
                    position:'relative',
                    padding:1.5
                   }}
                >
                  라인 추가
                </Typography>
          <Divider />
          <CardContent>
          <NameOverInput
              name='라인 이름'
              value={values?.lineName}
              onChange={handleChange}
            ></NameOverInput>
            <NameOverInput
              name='설명'
              value={values?.description}
              onChange={handleChange}
            ></NameOverInput>

          <Box sx={{ height: 5 }} />
          </CardContent>
          </Card>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>
          취소
        </Button>
        <Button onClick={handleApply} autoFocus>
          확인
        </Button>
      </DialogActions>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Dialog>
  )
};

export default LineAddPopup;