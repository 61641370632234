//시설운영 - 시설 - 수정 컨테이너 레이아웃
// 사이트관리 - 시설 - 수정 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import RegisterFacilityImpl from 'components/facility/RegisterFacImp';
import ConnectDeviceImpl from 'components/facility/ConnectDeviceImp';
import DisConnectDeviceImpl from 'components/facility/DisConnectDeviceImp';
import { useLocation } from 'react-router';

interface ILocation {
  siteId:string,
  siteName:string,
  facId:string,
  facName:string,
  facType:string,
  facDesc:string,
  devId:string,
}
const ConfigFacility = () => {
  const location = useLocation();
  const state = location.state as ILocation;
  console.log('ConfigFacility')

  return (
    <>
      <Helmet>
        <title>ConfigFacility</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default', // 배경색 조절
          minHeight: '100%', // 높이 조절
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <RegisterFacilityImpl />
          <Box sx={{ mt: 1 }}> </Box>
          { state.devId == '-1' ?
          <ConnectDeviceImpl />
          :
          <DisConnectDeviceImpl />
          }
        </Container>
      </Box>
    </>
  );
};

export default ConfigFacility;
