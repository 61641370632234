// 관리자 - 사이트 등록/수정 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import AdminSiteAdd from '../../../components/admin/system/AdminSiteAdd';

const AdminSiteForm = () => {
  console.log('AdminSiteForm')
  return(
  <>
    <Helmet>
      <title>AdminSiteForm</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <AdminSiteAdd />
      </Container>
    </Box>
  </>
);
    };

export default AdminSiteForm;
