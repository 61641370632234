// 사용자 등록, 사용자 수정

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { IUserDetail, SiteLevel, getSiteLevelStr } from 'model';
import { useStores } from 'state';
import AlertPopup from 'components/popup/AlertPopup';
import NameInput from 'components/atom/NameInput';
import NameInputPswd from 'components/atom/NameInputPswd';
import NameSelect from 'components/atom/NameSelect';
import siteStore from 'state/site';
import styles from 'components/facilityGroup/style.module.css'
import { Eye, EyeOff } from 'react-feather';
import { isMobile } from 'react-device-detect';
import MobileNameInput from 'components/atom/MobileNameInput';
import MobileNameInputPswd from 'components/atom/MobileNameInputPswd';
import MobileNameSelect from 'components/atom/MobileNameSelect';

const UserType = [
  { id: 'userId', name: '아이디' },
  { id: 'userName', name: '사용자명(직책)' },
  { id: 'userPwd', name: '비밀번호' },
  { id: 'telNo', name: '연락처' },
  { id: 'hpNo', name: '핸드폰번호' },
  { id: 'email', name: '이메일' },
  { id: 'userLevel', name: '레벨' },
];

const UserAdd = () => {
  const { userStore } = useStores();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const state = location.state as IUserDetail;
  const navigate = useNavigate();

  const [type, setType] = useState('add');
  const [pwd2, setPwd2] = useState('');
  const [showPswd, setShowPswd] = useState<boolean>(false);
  const [showPswdCheck, setShowPswdCheck] = useState<boolean>(false);
  const [values, setValues] = useState(
    {
      userId: '',
      userPwd: '',
      userName: '',
      userLevel: '8001',
      hpNo: '',
      telNo: '',
      email: '',
    });
  const [disableButton, setDisableButton] = useState(true);
  const [disableButtonPwd, setDisableButtonPwd] = useState(true);
  const [disableButtonPwd2, setDisableButtonPwd2] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');

  // value설정
  useEffect(() => {
    console.log(`UserAdd NameSel useEffect ${JSON.stringify(state)}`)
    if (state != null) {
      console.log(`UserAdd NameSel aa ${state.userPwd}, ${state.userId}`);
      setType('modify');

      setValues({
        ...values,
        userId: state.userId,
        userPwd: state.userPwd,
        userName: state.userName,
        userLevel: state.siteLevel,
        hpNo: state.hpNo,
        telNo: state.telNo,
        email: state.email,
      });
    }
  }, []);

//입력되는 기능
  const onChange = (name: string, value: string) => {
    console.log(`cyi onChange name:${name}, value"${value}`)
    const found = UserType.find(aaa => aaa.name == name);
    console.log(`cyi onChange find : ${found.id}:${found.name}`)

    setValues({
      ...values,
      [found.id]: value
    });

  };
  //핸드폰번호 입력 양식 지정
  const handlePhoneNumberChange = (name: string, value: any) => {
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length <= 3) {
      setPhoneNumber(numericValue);
      onChange(name, numericValue);
    } else if (numericValue.length <= 7) {
      setPhoneNumber(`${numericValue.slice(0, 3)}-${numericValue.slice(3)}`);
      onChange(name, `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`);
    } else {
      const firstPart = numericValue.slice(0, 3);
      const secondPart = numericValue.slice(3, 7);
      const thirdPart = numericValue.slice(7, 11);

      if (thirdPart.length === 4) {
        setPhoneNumber(`${firstPart}-${secondPart}-${thirdPart}`);
        onChange(name, `${firstPart}-${secondPart}-${thirdPart}`);
      } else {
        setPhoneNumber(`${firstPart}-${secondPart}-${thirdPart.slice(0, 4)}`);
        onChange(name, `${firstPart}-${secondPart}-${thirdPart.slice(0, 4)}`);
      }
    }
  };

//연락처 입력 양식 지정
  const handleCallNumberChange = (name: string, value: any) => {
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length <= 3) {
      setPhoneNumber(numericValue);
      onChange(name, numericValue);
    } else if (numericValue.length <= 6) {
      setPhoneNumber(`${numericValue.slice(0, 3)}-${numericValue.slice(3)}`);
      onChange(name, `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`);
    } else {
      const firstPart = numericValue.slice(0, 3);
      const secondPart = numericValue.slice(3, 6);
      const thirdPart = numericValue.slice(6, 10);

      if (thirdPart.length === 4) {
        setPhoneNumber(`${firstPart}-${secondPart}-${thirdPart}`);
        onChange(name, `${firstPart}-${secondPart}-${thirdPart}`);
      } else {
        setPhoneNumber(`${firstPart}-${secondPart}-${thirdPart.slice(0, 4)}`);
        onChange(name, `${firstPart}-${secondPart}-${thirdPart.slice(0, 4)}`);
      }
    }
  };


//비밀번호 확인 값 설정
  const onChangePwd2 = (name: string, value: string) => {
    setPwd2(value)
  };

  //취소
  const cancel = () => {
    navigate(-1);
  };
  //저장
  const handleSave = async () => {
    console.log(`UserAdd handleSave type : ${type}`)
    const user: IUserDetail = {
      userId: values.userId,
      userPwd: values.userPwd,
      userName: values.userName,
      userLevel: values.userLevel,
      siteId: '',
      siteLevel: '',
      siteName: '',
      hpNo: values.hpNo,
      telNo: values.telNo,
      email: values.email,
    }
    if (type == 'add') {
      console.log(`UserAdd addUser ${values.userPwd} : ${pwd2}`)
      if (values.userPwd != pwd2) {
        setOpenDialog(true);
        return;
      }
      const ret: any = await userStore.addUser(user);
      console.log(`UserAdd addUser ${JSON.stringify(ret)}`)
      if (ret.status == 200) {
        ToastsStore.success("등록 성공")

        // site에 등록
        await siteStore.setUser(userStore.siteId, values.userId, values.userLevel);

        const timer = setInterval(() => {
          navigate('/slager/memberlist');
          clearInterval(timer);
        }, 1000)
      }
      else if (ret.status == 400 && ret.resp.reason == 'duplicate') {
        ToastsStore.success("이미 사용중인 ID 입니다.")
      }
      else if (ret.status == 400) {
        ToastsStore.success("등록 실패")
      }
    }
    else {
      user.userPwd = '';
      const ret: any = await userStore.updateUser(user);
      console.log(`UserAdd updateuser ${JSON.stringify(ret)}`)
      if (ret.status == 200) {
        ToastsStore.success("등록 성공")
        const timer = setInterval(() => {
          navigate('/slager/memberlist');
          clearInterval(timer);
        }, 1000)
      }
      else if (ret.status == 400) {
        ToastsStore.error("등록 실패")
      }
      else if (ret.status == 400) {
        ToastsStore.error("등록 실패")
      }
    }
  }
//팝업 닫음
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  //비밀번호 보여주기 숨기기
  const toggleShowPassword = () => {
    setShowPswd(true);
  }
  const toggleHidePassword = () => {
    setShowPswd(false);
  }
  //비밀번호 확인 보여주기 숨기기
  const toggleShowPasswordCheck = () => {
    setShowPswdCheck(true);
  }
  const toggleHidePasswordCheck = () => {
    setShowPswdCheck(false);
  }
  //비밀번호 보여주기 숨기기 diabled
  const disableButtonFuncPwd = () => {
    if (
      values.userPwd == '' // || values?.description == ''
    ) {
      setDisableButtonPwd(true);
    }
    else {
      setDisableButtonPwd(false);
    }
  }
  //비밀번호 확인 보여주기 숨기기 diabled
  const disableButtonFuncPwd2 = () => {
    if (pwd2 === '') {
      setDisableButtonPwd2(true);
    } else {
      setDisableButtonPwd2(false);
    }
  };
  const disableButtonFunc = () => {
    if (
      values.userId == '' ||
      values.userName == '' ||
      values.telNo == '' ||
      values.email == ''
    ) {
      setDisableButton(true);
    }
    else {
      setDisableButton(false);
    }
  }
//위에 함수 실행
  useEffect(() => {
    console.log(`UserAdd NameSelect useEffect(values) ${JSON.stringify(values)}`)
    disableButtonFunc();
    disableButtonFuncPwd();
    disableButtonFuncPwd2();
  }, [values, pwd2]);

  return (
    <Box>
      <Typography
        className={styles.pageDirection}
        gutterBottom>
        {type != 'modify' ? `사이트 관리 ${'>'} 사용자 ${'>'} 사용자 등록` : `사이트 관리 ${'>'} 사용자 ${'>'} 사용자 수정`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2%',
          right: '1%'
        }}
      >
        <Typography //타이틀 글자 조정
          className={styles.pageTitleNoBorder}
          gutterBottom >
          {type != 'modify' ? '사용자 등록' : '사용자 수정'}
        </Typography>
      </Box>

      <form
        autoComplete="off"
        noValidate
      >
        {!isMobile &&
          <Table>
            <TableRow>
              <TableCell className={styles.regTableTop}>
                <NameInput
                  name="아이디"
                  value={values.userId}
                  space={10}
                  nameWidth={100}
                  valueWidth={200}
                  onChange={onChange}
                /></TableCell>
            </TableRow>

            <Box sx={{ height: 5 }} />
            {type != 'modify' &&
              <>

                <TableRow>
                  <TableCell className={styles.regTableBodyPadding1}>
                    <NameInputPswd
                      type={showPswd ? "text" : "password"}
                      name="비밀번호"
                      value={values.userPwd}
                      space={10}
                      nameWidth={100}
                      valueWidth={200}
                      onChange={onChange} />
                    {showPswd === false ? (
                      <Button disabled={disableButtonPwd} onClick={toggleShowPassword}><Eye /></Button>
                    ) : (
                      <Button disabled={disableButtonPwd} onClick={toggleHidePassword}><EyeOff /></Button>
                    )}
                  </TableCell>
                </TableRow>

                <Box sx={{ height: 5 }} />
                <TableRow>
                  <TableCell className={styles.regTableBodyPadding1}>
                    <NameInputPswd
                      type={showPswdCheck ? "text" : "password"}
                      name="비밀번호 확인"
                      value={pwd2}
                      space={10}
                      nameWidth={100}
                      valueWidth={200}
                      onChange={onChangePwd2}
                    />
                    {showPswdCheck === false ? (
                      <Button disabled={disableButtonPwd2} onClick={toggleShowPasswordCheck}><Eye /></Button>
                    ) : (
                      <Button disabled={disableButtonPwd2} onClick={toggleHidePasswordCheck}><EyeOff /></Button>
                    )}
                  </TableCell>
                </TableRow>
              </>
            }

            <TableRow>
              <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
                <NameInput
                  name="사용자명(직책)"
                  value={values.userName}
                  space={10}
                  nameWidth={100}
                  valueWidth={200}
                  onChange={onChange}
                /></TableCell>
            </TableRow>


            <Box sx={{ height: 5 }} />

            <TableRow>
              <TableCell className={styles.regTableBodyPadding1}>
                <NameInput
                  name="연락처"
                  value={values.telNo}
                  space={10}
                  nameWidth={100}
                  valueWidth={200}
                  onChange={handleCallNumberChange}
                /></TableCell>
            </TableRow>


            <Box sx={{ height: 5 }} />
            <TableRow>
              <TableCell className={styles.regTableBodyPadding1}>
                <NameInput
                  name="핸드폰번호"
                  value={values.hpNo}
                  space={10}
                  nameWidth={100}
                  valueWidth={200}
                  onChange={handlePhoneNumberChange}
                /></TableCell>
            </TableRow>

            <Box sx={{ height: 5 }} />
            <TableRow>
              <TableCell className={styles.regTableBodyPadding1}>
                <NameInput
                  name="이메일"
                  value={values.email}
                  space={10}
                  nameWidth={100}
                  valueWidth={200}
                  onChange={onChange}
                /></TableCell>
            </TableRow>

            <Box sx={{ height: 5 }} />
            <TableRow>
              <TableCell className={styles.regTableBottom} sx={{ fontSize: 16 }}>
                <NameSelect
                  name="레벨"
                  object={SiteLevel}
                  defaultValue={values.userLevel}
                  space={10}
                  nameWidth={110}
                  valueWidth={200}
                  onSelect={onChange}
                /></TableCell>
            </TableRow>
          </Table>
        }



        {isMobile &&

          <Table>
            <TableRow>
              <TableCell className={styles.regTableBodyPadding1} sx={{ bordeTop: 2, borderTopColor: '#BFBFBF' }}>
                <MobileNameInput
                  name="아이디"
                  value={values.userId}
                  onChange={onChange}
                /></TableCell>
            </TableRow>

            <Box sx={{ height: 5 }} />
            {type != 'modify' &&
              <>
                <TableRow>
                  <TableCell className={styles.regTableBodyPadding1}>
                    <MobileNameInputPswd
                      type={showPswd ? "text" : "password"}
                      name="비밀번호"
                      value={values.userPwd}
                      onChange={onChange} />
                    {showPswd === false ? (
                      <Button disabled={disableButtonPwd} onClick={toggleShowPassword}><Eye /></Button>
                    ) : (
                      <Button disabled={disableButtonPwd} onClick={toggleHidePassword}><EyeOff /></Button>
                    )}
                  </TableCell>
                </TableRow>

                <Box sx={{ height: 5 }} />
                <TableRow>
                  <TableCell className={styles.regTableBodyPadding1}>
                    <MobileNameInputPswd
                      type={showPswdCheck ? "text" : "password"}
                      name="비밀번호 확인"
                      value={pwd2}
                      onChange={onChangePwd2}
                    />
                    {showPswdCheck === false ? (
                      <Button disabled={disableButtonPwd2} onClick={toggleShowPasswordCheck}><Eye /></Button>
                    ) : (
                      <Button disabled={disableButtonPwd2} onClick={toggleHidePasswordCheck}><EyeOff /></Button>
                    )}
                  </TableCell>
                </TableRow>
              </>
            }

            <TableRow>
              <TableCell className={styles.regTableBodyPadding1}>
                <MobileNameInput
                  name="사용자명(직책)"
                  value={values.userName}
                  onChange={onChange}
                /></TableCell>
            </TableRow>


            <Box sx={{ height: 5 }} />

            <TableRow>
              <TableCell className={styles.regTableBodyPadding1}>
                <MobileNameInput
                  name="연락처"
                  value={values.telNo}
                  onChange={handleCallNumberChange}
                /></TableCell>
            </TableRow>


            <Box sx={{ height: 5 }} />
            <TableRow>
              <TableCell className={styles.regTableBodyPadding1}>
                <MobileNameInput
                  name="핸드폰번호"
                  value={values.hpNo}
                  onChange={handlePhoneNumberChange}
                /></TableCell>
            </TableRow>

            <Box sx={{ height: 5 }} />
            <TableRow>
              <TableCell className={styles.regTableBodyPadding1}>
                <MobileNameInput
                  name="이메일"
                  value={values.email}
                  onChange={onChange}
                /></TableCell>
            </TableRow>

            <Box sx={{ height: 5 }} />
            <TableRow>
              <TableCell className={styles.regTableBottom} sx={{ fontSize: 16 }}>
                <MobileNameSelect
                  name="레벨"
                  object={SiteLevel}
                  defaultValue={values.userLevel}
                  onSelect={onChange}
                /></TableCell>
            </TableRow>
          </Table>
        }


        <Box sx={{ height: 5 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Button
            className={styles.greenButton}
            color="primary"
            variant="contained"
            onClick={cancel}
          >
            취소
          </Button>
          <Button
            className={styles.blueButton}
            color="primary"
            variant="contained"
            disabled={disableButton}
            onClick={handleSave}
          >
            저장
          </Button>
        </Box>
      </form>
      <AlertPopup
        open={openDialog}
        description="비밀번호와 비밀번호 확인 값이 일치하지 않습니다."
        onClose={handleDialogClose}
      >
      </AlertPopup>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Box>
  );
};

export default UserAdd;
