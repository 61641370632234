// 현재 사용하지않음. 
// 사이트 관리에 있던 사용자 관리
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import theme from 'theme';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { ISiteDetail, isSiteManager, IUserDetail, SiteLevel } from 'model';
import { useStores } from 'state';
import userStore from 'state/user';
import { observer } from 'mobx-react';
import NameValueRem from 'components/atom/NameValueRem';
import SiteUser from './SiteUser';
import Space from 'components/atom/Space';
import styles from 'components/facilityGroup/style.module.css'

interface StyledNameValueProps {
  name: string;
  value: string;
}
function StyledNameValue({ name, value }: StyledNameValueProps) {
  return (
    <>
      <NameValueRem
        name={name}
        value={value}
        space={'3rem'}
        nameWidth={'5rem'}
        valueWidth={'20rem'}
      />
    </>
  );
}


interface SiteUserAddProps {
  onClose?: () => void;
}
const SiteUserAdd = observer(({ onClose }: SiteUserAddProps) => {
  const { siteStore } = useStores();

  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const state = location.state as ISiteDetail;
  const navigate = useNavigate();
  const [values, setValues] = useState<any>(
    {
      siteId: '',
      siteName: '',
      siteLevel: '',
    });

  const [inUser, setInUser] = useState<any[]>([]);
  const [outUser, setOutUser] = useState<any[]>([]);

  const [user, setUser] = useState(null);
  const [siteLevel, setSiteLevel] = useState('');
  const [searchId, setSearchId] = useState('');

  useEffect(() => {
    console.log(`SiteUserAdd useEffect ${JSON.stringify(state)}`)
    if (state != null) {
      setValues(
        state
      );
    }
  }, []);

  useEffect(() => {
    console.log(`SiteUserAdd useEffect siteId = ${values.siteId}, usestore site id = ${userStore.siteId}`);
    if (values.siteId != '') {
      console.log(`SiteUserAdd useEffect get siteId = ${values.siteId}, usestore site id = ${userStore.siteId}`);
      siteStore.getSiteUserList(values.siteId);
    }
  }, [values]);

  useEffect(() => {
    console.log(`---inusers--- ${userStore.siteId}`);
    const inusers = siteStore.userList.filter((item: any, index: number) => {
      console.log(`users ---> ${JSON.stringify(item)}`);
      console.log(`users -> ${item.userId}, ${item.siteList.length}`)
      return item.siteList.length >= 1;
    }
    );
    setInUser(inusers);
    console.log(`---outusers---`);
    const outusers = siteStore.userList.filter((item: any, index: number) => {
      console.log(`users ---> ${JSON.stringify(item)}`);
      console.log(`users -> ${item.userId}, ${item.userName}`)
      return item.siteList.length < 1;
    }
    );
    setOutUser(outusers);
  }, [siteStore.userList]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleAddUser = async () => {
    setOpenDialog(false);
    const ret: any = await siteStore.setUser(values.siteId, searchId, siteLevel);
    if (ret.status == 200) {
      ToastsStore.success("추가 되었습니다.")
      siteStore.getSiteUserList(values.siteId);
    }
    else if (ret.status == 400) {
      ToastsStore.success("추가 실패")
    }
  };

  const handleDeleteUser = async () => {
    setOpenDeleteDialog(false);
    const ret: any = await siteStore.deleteUser(values.siteId, user.userId);
    if (ret.status == 200) {
      ToastsStore.success("삭제 되었습니다.")
      siteStore.getSiteUserList(values.siteId);
    }
    else if (ret.status == 400) {
      ToastsStore.success("삭제 실패")
    }
  };

  const onAdd = () => {
    // console.log(`cyi onAdd user name:${JSON.stringify(_user)}`)
    // setUser(_user);
    userStore.userInfo = null;
    setOpenDialog(true);
  };

  const onDelete = (_user: IUserDetail) => {
    console.log(`cyi ondelete user name:${JSON.stringify(_user)}`)
    setUser(_user);
    setOpenDeleteDialog(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`event = ${event}`)
    console.log(`event.name = ${event.target.name}`)
    console.log(`event.value = ${event.target.value}`)
    console.log(`value = ${JSON.stringify(values)}`)
    setSearchId(event.target.value);
  };

  const handleSearch = async () => {
    await userStore.getUserInfo(searchId)
    if (userStore.userInfo == null) {
      ToastsStore.success("없는 사용자 ID 입니다.")
    }
    else {
      ToastsStore.success(`${searchId}가 검색되었습니다.`)
    }
  };

  const handleChangeSiteLevel = (event: SelectChangeEvent) => {
    setSiteLevel(event.target.value as string);
  };
  return (
    <>
      <PerfectScrollbar>
        <Typography
          className={styles.pageDirection}
          gutterBottom>
          사이트 관리 {'>'} 사이트 {'>'} 사이트 사용자 관리
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: 30,
              fontWeight: 600,
              color: '#0C3149',
              display: 'flex',
              position: 'relative',
              left: '1%'
            }}
          >
            사이트 사용자 관리
          </Typography>
        </Box>
        <Box sx={{ mt: 1 }}> </Box>
        <Box sx={{ mt: 2 }}> </Box>
        <Divider />
        <Box sx={{ mt: 2 }}> </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        >
          <StyledNameValue
            name="사이트"
            value={values?.siteName}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: "center",
            }}
          >
            {isSiteManager(values.siteLevel) &&
              <Button
                className={styles.blueButton}
                onClick={() => {
                  console.log(`추가`)
                  onAdd();
                }}
              >
                추가
              </Button>
            }
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}> </Box>
        <Box sx={{ mt: 1 }}> </Box>

        <Grid
          container
          spacing={1}
        >
          {/* <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <SiteUser
                  type ='추가'
                  title="미등록 사용자"
                  userList={outUser}
                  onSelected={onAdd}
                />
              </Grid> */}
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <SiteUser
              type='삭제'
              title="등록 사용자"
              userList={inUser}
              onSelected={onDelete}
            />
          </Grid>
        </Grid>

        <Divider />
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              계정 추가
            </DialogContentText>
            <Space />
            <Space />
            <Space />
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            >
              <TextField
                fullWidth
                label="사용자 ID"
                name="labelNo"
                type="string"
                onChange={handleChange}
                value={searchId}
                variant="outlined"
              />
              <Button onClick={handleSearch}>검색</Button>
            </Box>
            <Space />
            <Typography
              color="textPrimary"
            >
              {userStore.userInfo?.USER_NAME}
            </Typography>
            <Space />
            <Box sx={{}}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">사이트레벨</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-autowidth"
                  value={siteLevel}
                  label="User"
                  onChange={handleChangeSiteLevel}
                >
                  {
                    SiteLevel.map((item: any, index: number) => {
                      return (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button className={styles.greenButton} onClick={handleDialogClose}>취소</Button>
            <Button className={styles.blueButton} onClick={handleAddUser} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              해당 계정을 삭제 하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button className={styles.greenButton} onClick={handleDeleteDialogClose}>취소</Button>
            <Button className={styles.primaryButton} onClick={handleDeleteUser} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </PerfectScrollbar>
    </>
  );

});

export default SiteUserAdd;
