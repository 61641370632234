//중계기 관련 api
import { observable, computed } from 'mobx';
import { api, q } from '../api';
import { IUserDetail } from '../model/user';
import { IRegFacInfo, IUpdateFacInfo } from '../model/gateway';
import { IFacInfo } from '../model';

export interface IGatewayStore {
	test: string;
	gatewayList:any[];
	usedList:any[];
	unUsedList:any[];
	facInfo : IFacInfo;

	addFac:(fac : IRegFacInfo) => any;
	getGatewayList:(perPage:string, page:string) => any;
	getUsedGateway:(used: boolean) => any;
	update:(lat:string, lng:string, facId:string, devId:string, mtCompanyId:string, labelNo:string) => void;
	getDetail:(facId : string) => any;
	turnOnOffGateway:(facId:string, onOff:string) => void;
	testFunc:() => void;
	testFunc1:(user : IUserDetail) => void;

}
export const gatewayStore = observable<IGatewayStore>({
	test: '',
	gatewayList:[],
	usedList:[],
	unUsedList:[],
	facInfo: null,

	async addFac(fac : IRegFacInfo){
		console.log(`addFac ${JSON.stringify(fac)}`)
		try {
			await api.post(`/api/lamp/regFacInfo`, fac);
			return true;
		} catch (error) {
			console.log(`addFac error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async getGatewayList(perPage: string, page: string) {
		 const ret = await api.get(`/api/gateway/tblList${q({
			 perPage,
			 page,
		 })}`);
		console.log(`getGatewayList ${JSON.stringify(ret)}`)
		this.gatewayList = ret.data.contents;
	},
	async getUsedGateway(used: boolean) {
		let cat = 0;
		if (used) {
			cat = 1;
		}
		const ret = await api.get('/api/gateway/usedgateway?searchCategory=' + cat);
		console.log(`getUsedGateway ${JSON.stringify(ret)}`)
		if (used) {
			this.usedList = ret.data.contents;
		}
		else {
			this.unUsedList = ret.data.contents;
		}
	},
	async getDetail(facId: string) {
		const ret = await api.get('/api/gateway/listDetail?facId=' + facId);
		console.log(`getDetail ${JSON.stringify(ret)}`)
		this.facInfo = ret.data.contents;
	},
	async update(lat:string, lng:string, facId:string, devId:string, mtCompanyId:string, labelNo:string) {
		console.log(`update ${(facId)}`)
		try {
			await api.post(`/api/gateway/update`,
				{
					facId: facId,
					devId: devId,
					mtCompanyId: mtCompanyId,
					labelNo: labelNo,
					lat:lat,
					lng:lng,
				});
		} catch (error) {
			console.log(`update error : ${JSON.stringify(error)}`)
		}
	},
	async turnOnOffGateway(facId:string, onOff:string) {
		console.log(`turnOnOffGateway ${(facId)}`)
	},


	async testFunc() {
		// await api.post(`/auth/api/v1/signIn${q({
		// 	id,
		// 	password,
		// })}`, {});
	},
	async testFunc1(user : IUserDetail) {
		console.log(`test 1 ${JSON.stringify(user)}`)
	},
})

export default gatewayStore;
