import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
} from '@mui/material';
import { observer } from 'mobx-react';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import GoogleMapLocation from 'components/test/GoogleMapLocation';

const MapLocation = observer(({ }) => {

  return (
    <>
      <Helmet>
        <title>MapLocation</title>
      </Helmet>
      <Box
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <GoogleMapLocation ></GoogleMapLocation>
      </Box>
    </>
  );
});

export default MapLocation;
