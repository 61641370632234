// 계정관리 - 수정
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
} from '@mui/material';
import NameInput from 'components/atom/NameInput';
import NameOverInput from 'components/atom/NameOverInput';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import userStore from 'state/user';

const UserType = [
  { id: 'userId', name: '아이디' },
  { id: 'userName', name: '이름' },
  { id: 'userPwd', name: '비밀번호' },
  { id: 'telNo', name: '연락처' },
  { id: 'hpNo', name: '핸드폰번호' },
  { id: 'email', name: '이메일' },
  { id: 'userLevel', name: '레벨' },
];

export interface UserUpdatePopupProps {
  userInfo: any;
  open: boolean;
  description: string;
  onClose: (update: boolean) => void;
}
const UserUpdatePopup: React.FunctionComponent<UserUpdatePopupProps> = ({ userInfo, open, description, onClose }: UserUpdatePopupProps) => {
  const [values, setValues] = useState(
    {
      userId: '',
      userName: '',
      hpNo: '',
      telNo: '',
      email: '',
    });

  //value설정
  useEffect(() => {
    if (open) {
      console.log(`cyi UserUpdatePopup useeffect userInfo${JSON.stringify(userInfo)}`)
      setValues({
        ...values,
        userId: userInfo.userId,
        userName: userInfo.userName,
        telNo: userInfo.telNo,
        email: userInfo.email,
      });
    }
  }, [open]);
  
  //수정 정보 입력되는거
  const handleChange = (name: string, value: string) => {
    console.log(`cyi onChange name:${name}, value"${value}`)
    const found = UserType.find(aaa => aaa.name == name);
    console.log(`cyi onChange find : ${found.id}:${found.name}`)

    setValues({
      ...values,
      [found.id]: value
    });
  };
  //팝업 닫기
  const handleDialogClose = () => {
    console.log(`cyi handleDialog`)
    onClose(false);
  };

  //확인버튼
  const handleApply = async () => {
    console.log(`cyi handleApply`)
    const user: any = {
      userId: values.userId,
      userName: values.userName,
      telNo: values.telNo,
      email: values.email,
    }
    const ret: any = await userStore.updateUser(user);
    if (ret.status == 200) {
      ToastsStore.success("등록 성공")
      const timer = setInterval(() => {
        onClose(true);
        clearInterval(timer);
      }, 1000)
    }
  };

  return (
    //수정 팝업
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Card>
          <CardHeader
            subheader=""
            title="사용자 정보 수정"
          >
          </CardHeader>
          <Divider />
          <CardContent>
            <NameOverInput
              name='아이디'
              value={values.userId}
              onChange={handleChange}
            ></NameOverInput>
            <NameOverInput
              name='이름'
              value={values.userName}
              onChange={handleChange}
            ></NameOverInput>
            <NameOverInput
              name='연락처'
              value={values.telNo}
              onChange={handleChange}
            ></NameOverInput>
            <NameOverInput
              name='이메일'
              value={values.email}
              onChange={handleChange}
            ></NameOverInput>

            <Box sx={{ height: 5 }} />
          </CardContent>
        </Card>


      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>
          취소
        </Button>
        <Button onClick={handleApply} autoFocus>
          확인
        </Button>
      </DialogActions>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Dialog>
  )
};

export default UserUpdatePopup;