// 로그인
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useStores } from '../state';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button from 'components/common/Button';
import Space from 'components/atom/Space';
import { useEffect, useState } from 'react';
import siteStore from 'state/site';
import { ISiteDetail, ISiteListInUser } from 'model';
import styles from 'components/facilityGroup/style.module.css'

//로그인 입력 style
const LoginInput = styled(TextField)({
  display: 'flex',
  height: '100%',
  width: '100%'
})

const Login = () => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [openSiteSelectDialog, setOpenSiteSelectDialog] = useState(false);
  const [openSiteSelectByAdminDialog, setOpenSiteSelectByAdminDialog] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [siteId, setSiteId] = useState('');
  const [siteName, setSiteName] = useState('');
  const [siteLevel, setSiteLevel] = useState('');
  const [siteList, setSiteList] = useState([]);

  const handleSaveSiteInfo = async () => {
    userStore.saveSiteInfo(siteId, siteName, siteLevel, String(siteList.length));
    navigate('/slager/dashboard', { replace: true });
  };

  //사이트 선택 dialog닫음
  const handleSiteSelectDialogClose = () => {
    setOpenSiteSelectDialog(false);
  };

  // 관리자 사이트 선택 닫음.
  const handleSiteSelectByAdminDialogClose = () => {
    setOpenSiteSelectByAdminDialog(false);
  };

  //로그인 버튼 disabled 조건
  useEffect(() => {
    console.log('site id : ', siteId);
    if (siteId != '0') {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);
    }
  }, [siteId]);

  useEffect(() => {
    if (siteList.length <= 0) {
      return;
    }
    setSiteId(siteList[0].siteId);
    setSiteName(siteList[0].siteName);
    setSiteLevel(siteList[0].siteLevel);
  }, [siteList]);

  useEffect(() => {
    // const tmpSiteList:ISiteDetail = {siteId: "0", siteName : "선택해 주세요",description : "",
    //   userIdMaintenance : [], userIdManager : [], address : '', zipCode : '',
    //   admCode : '', admCodeType : '', enable : '', userName : '', hpNo : ''}
    setSiteList([...siteStore.siteList])
  }, [siteStore.siteList]);

  useEffect(() => {
    // const tmpSiteList:ISiteDetail = {siteId: "0", siteName : "선택해 주세요",description : "",
    //   userIdMaintenance : [], userIdManager : [], address : '', zipCode : '',
    //   admCode : '', admCodeType : '', enable : '', userName : '', hpNo : ''}
    if (siteStore.userList[0]?.siteList)
      setSiteList([...siteStore.userList[0].siteList])
  }, [siteStore.userList]);

  //사이트 선택
  const handleChangeSiteId = (event: SelectChangeEvent) => {
    const ret: ISiteListInUser = siteList?.find((item: any, index: number) => {
      return item.siteId == event.target.value;
    });
    setSiteId(event.target.value);
    setSiteName(ret.siteName);
    setSiteLevel(ret.siteLevel);
  };
  //관리자 사이트 선택
  const handleChangeSiteIdByAdmin = (event: SelectChangeEvent) => {
    const ret: ISiteDetail = siteList?.find((item: any, index: number) => {
      return item.siteId == event.target.value;
    });
    setSiteId(event.target.value);
    setSiteName(ret.siteName);
    setSiteLevel('9200');
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '90vh',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              id: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              id: Yup.string().max(255).required('Id is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values) => {
              const ret = await userStore.loginUser(values.id, values.password);
              if (ret) {

                if (userStore.isAdmin()) { // super user
                  await siteStore.getSiteList(userStore.getId(), "10", "1");

                  if (siteStore.siteList.length > 1) {
                    setOpenSiteSelectByAdminDialog(true);
                  }
                  else if (siteStore.siteList.length == 1) {
                    userStore.saveSiteInfo(
                      siteStore.userList[0].siteList[0].siteId,
                      siteStore.userList[0].siteList[0].siteName,
                      siteStore.userList[0].siteList[0].siteLevel,
                      String(siteStore.siteList.length)
                    );
                    navigate('/slager/dashboard', { replace: true });
                  }
                  else { // super and maybe user who don't have site
                    navigate('/slager/dashboard', { replace: true });
                  }
                }
                else {
                  await siteStore.getSiteListByUser(userStore.getId());
                  if (siteStore.userList[0].siteList.length > 1) {
                    setOpenSiteSelectDialog(true);
                  }
                  else if (siteStore.userList[0].siteList.length == 1) {
                    userStore.saveSiteInfo(
                      siteStore.userList[0].siteList[0].siteId,
                      siteStore.userList[0].siteList[0].siteName,
                      siteStore.userList[0].siteList[0].siteLevel,
                      String(siteStore.userList[0].siteList.length)
                    );
                    navigate('/slager/dashboard', { replace: true });
                  }
                  else { // super and maybe user who don't have site
                    console.log(`login fail ${JSON.stringify(ret)}`)
                    ToastsStore.error("등록된 사이트가 없습니다. 관리자에게 연락하세요")
                  }
                }
              }
              else {
                console.log(`login fail ${JSON.stringify(ret)}`)
                ToastsStore.error("아이디 또는 비밀번호가 잘못되었습니다.")
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {t('signin.title')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {t('signin.description')}
                  </Typography>
                </Box>
                <LoginInput
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('signin.id')}
                  margin="normal"
                  name="id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="id"
                  value={values.id}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('signin.pw')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('signin.signin')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      {/* 기본 사이트 선택 dialog */}
      <Dialog
        open={openSiteSelectDialog}
        onClose={handleSiteSelectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ padding: '25px 29px', pb: '10px' }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>• 사이트 선택</Typography>
          <Space></Space>
          <Box sx={{}}>
            <FormControl sx={{ minWidth: '240px' }}>
              <Select
                displayEmpty
                native
                value={siteId}
                onChange={handleChangeSiteId}
                sx={{
                  fontSize: 17,
                  "&:hover":
                  {
                    "&& fieldset": { border: "2px solid #0C3149" }
                  }
                }}
              >
                {
                  siteList.map((item: any, index: number) => {
                    return (
                      <option style={{ padding: 0 }} value={item.siteId}>{item.siteName}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: '13px' }}>
          <Button className={styles.primaryButtonLogin} size="small" variant="outlined" color="error" onClick={handleSiteSelectDialogClose}>취소</Button>
          <Button className={styles.primaryButtonLogin} size="small" variant="outlined" color="primary" disabled={disableButton} onClick={handleSaveSiteInfo} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      {/* 관리자 사이트 선택 dialog */}
      <Dialog
        open={openSiteSelectByAdminDialog}
        onClose={handleSiteSelectByAdminDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ padding: '25px 29px', pb: '10px' }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>• 사이트 선택</Typography>
          <Space></Space>
          <Box sx={{}}>
            <FormControl sx={{ minWidth: '240px' }}>
              <Select
                displayEmpty
                native
                value={siteId}
                onChange={handleChangeSiteIdByAdmin}
                sx={{
                  fontSize: 17,
                  "&:hover":
                  {
                    "&& fieldset": { border: "2px solid #0C3149" }
                  }
                }}
              >{
                  siteList.map((item: any, index: number) => {
                    return (
                      <option style={{ padding: 0 }} value={item?.siteId}>{item?.siteName}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: '13px' }}>
          <Button className={styles.primaryButton} size="small" variant="outlined" color="error" onClick={handleSiteSelectByAdminDialogClose}>취소</Button>
          <Button className={styles.primaryButton} size="small" variant="outlined" color="primary" disabled={disableButton} onClick={handleSaveSiteInfo} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </>
  );
};

export default Login;
