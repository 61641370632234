//시설운영 - 시설 상단 borderBox

import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import styles from 'components/facilityGroup/style.module.css'

type ManageLampProps = {
  lampCount: string;
  onCommand?: (command: string, name?: string, value?: string) => void;
};
const ManageLampToolbar = observer(({ lampCount, onCommand }: ManageLampProps) => {
  const theme = useTheme();

  const { t } = useTranslation()

  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };

  return (
    <Box>
      <Typography
        className={styles.pageDirection}>
        시설 운영 {'>'} 시설
      </Typography>

      <Typography //타이틀 글자 조정
        className={styles.pageTitle}>
        시설
      </Typography>

      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
        sx={{
          borderRadius: 3,
          borderColor: '#1C75BC',
          marginBottom: '2%',
          margin: 'auto'
        }}
        {...defaultProps}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1,
            textAlign: 'left'
          }}
        >

          {/* <Box sx={{m:1}}>

            <Typography
              color="textSecondary"
              fontSize={16}
            >
               통합 검색
            </Typography>
          </Box>
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          }}
        >
          <Box sx={{
            ml: 1,
            display: 'flex',
          }}
          >
            <Typography
              color="textSecondary"
              variant="h4"
            >
              가로등 목록({lampCount})
        </Typography> */}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: "center",
              }}
            >
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default ManageLampToolbar;
