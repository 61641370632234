

/* zDMS : gps value, ex) 37.229239, 126.489372 */
export const convDMS2DegEx = (zDMS: string) => {
	// console.log(`convDMS2DegEx: ${zDMS}`)

	if (zDMS == null) {
		return 0;
	}
	
	let dDeg = 0.000000;
	let dDMS;
	if (zDMS.includes('.')) {
		dDMS = Number(zDMS) * 1000000;
	}
	else {
		dDMS = Number(zDMS);
	}
	// console.log(`convDMS2DegEx dDMS: ${dDMS}`)

	dDeg = Math.floor((dDMS / 1000000)) + ((dDMS % 1000000) / 10000 / 60);
	return dDeg;
}

const convDeg2DMSEx = (zDeg: string) => {
	const dDms = 0.000000;
	const dd = Math.floor(Number(zDeg));
	const tmp = (Number(zDeg) % 1).toFixed(6);
	const mm = Math.floor(+tmp * 60);
	const ss1 = (+tmp * 60 % 1);
	const ss2 = (+tmp * 60 % 1) * 60;
	const ss3 = (((+tmp * 60 % 1) * 60) * 100);
	const ss4 = (((+tmp * 60 % 1) * 60) / 100);
	const ss = Math.floor(((+tmp * 60 % 1) * 60) * 100);
	// if (ss > 999) {
	// 	console.log(`conv 9: ${dd}.${mm}${ss}`)
	// }
	// else {
	// 	console.log(`conv 9-1: ${dd}.${mm}0${ss}`)
	// }
}

export const getLocation = () => {
	return new Promise((resolve, reject) => {
		if (navigator.geolocation) {
			const now = new Date();
			navigator.geolocation.getCurrentPosition(
				(position) => {
					console.info(
						`re:${position.coords.latitude} ${position.coords.longitude}`,
					);
					convDeg2DMSEx(position.coords.latitude.toString());
					convDeg2DMSEx(position.coords.longitude.toString());
					resolve({
						err: 0,
						time: now.toLocaleTimeString(),
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					});
				},
				(err) => {
					resolve({
						err: -1,
						latitude: -1,
						longitude: -1,
					});
				},
				{ enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 }
			);
		}
		else {
			reject({ error: -2, latitude: -1, longitude: -1 });
		}
	});
}
