//사이트 관리 - 중계기(연동시설) - 수정 컨테이너 레이아웃
// 시설운영 - 중계기 - 수정 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import RegisterFacilityGatewayImp from 'components/facility/RegisterFacGatewayImp';
import { useLocation } from 'react-router';
import ConnectDeviceGatewayImp from 'components/facility/ConnectDeviceGatewayImp';
import DisConnectDeviceGatewayImp from 'components/facility/DisConnectDeviceGatewayImp';

interface ILocation {
  siteId:string,
  siteName:string,
  facId:string,
  facName:string,
  facType:string,
  facDesc:string,
  devId:string,
  gwId:string,
}
const ConfigFacilityGateway = () => {
  const location = useLocation();
  const state = location.state as ILocation;
  console.log('ConfigFacilityGateway')

  return (
    <>
      <Helmet>
        <title>ConfigFacilityGateway</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <RegisterFacilityGatewayImp />
          <Box sx={{ mt: 1 }}> </Box>
          { state.gwId == '-1' ?
          <ConnectDeviceGatewayImp />
          :
          <DisConnectDeviceGatewayImp />
          }
        </Container>
      </Box>
    </>
  );
};

export default ConfigFacilityGateway;
