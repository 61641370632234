// 장애 등록과 장애설정에서 사용하는 select
import * as React from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { useEffect } from 'react';


type  StatusSelectProps = {
    name: string;
    defaultValue?: string;
    object: any[];
    space? : number;
    nameWidth? : number;
    valueWidth? : number;
    onSelect: (name: string, value: string) => void;
    disabled?:boolean;
};
const StatusSelect = (
    { name, object,defaultValue = '100',nameWidth = 60 , valueWidth = 300, space = 10, onSelect,disabled }
        :  StatusSelectProps) => {
    console.log(`StatusSelect ${name}:${defaultValue}:${JSON.stringify(object)}`)
    const [select, setSelect] = React.useState<string>('');

    // select 값 설정
    useEffect(() => {
        if (defaultValue) {
            console.log(` StatusSelect values ${name}:${defaultValue}`)
            setSelect(defaultValue);
        }
        else {
            console.log(`NameSelectRem values else ${name}:${defaultValue}`)
        }
    }, [defaultValue]);

    //select 동작
    const handleSelect = (event: SelectChangeEvent) => {
        console.log(`cyi NameSelectRem handleSelect ${event.target.value}`)
        setSelect(event.target.value as string);
        onSelect(name, event.target.value as string);
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                 <Typography
                    sx={{
                        width: nameWidth,
                        m:'1rem',
                        p:'0.4rem'
                    }}
                    color="textPrimary"
                    variant="h5"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space }} />
                <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                    value={select}
                    sx={{
                        m:'0.4rem',
                        p:'0.3rem',
                        borderColor:'#BFBFBF',
                        backgroundColor:"#FFFFFF",
                        width: '15rem',
                        height: '3rem'
                    }}
                    onChange={handleSelect}
                    disabled={disabled}
                >
                    <option disabled={true} value="100" >
                        -- 선택해주세요. --
                    </option>
                    {object.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
};

export default StatusSelect;
