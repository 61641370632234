// 현재 사용하지않음.
// 과거 시설운영 - 보안등
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { observer } from 'mobx-react';
import ManageLampToolbar from 'components/facility/ManageLampToolbar';
import ManageLampListResults from 'components/facility/ManageLampListResults';
import { useStores } from 'state';
import { useEffect, useState } from 'react';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import { FacType } from 'model/facility';

const SecurityLampList = observer(() => {
  const { clientStore, iotStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);
  console.log('SecurityLampList')


  useEffect(() => {
    facilityStore.getFacilityInfoList(userStore.siteId, null, null, FacType.SecurityLamp);
  },[]);
  
  useEffect(() => {
    console.log(`managelamp clientlist ${JSON.stringify(clientStore.clientList)}`);
  },[clientStore.clientList]);

  const onCommand = (command: string) => {
    console.log(`onCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }

  return (
    <>
      <Helmet>
        <title>SecurityLampList</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ManageLampToolbar lampCount={facilityStore.inFacilityList?.length.toString()} onCommand={onCommand} />
          <Box sx={{ mt: 1 }}></Box>
          <Box sx={{ minWidth: 1500 }}>
            <ManageLampListResults lampCount={facilityStore.inFacilityList?.length.toString()} facs={facilityStore.inFacilityList} doit={doit} facId={''}/>
          </Box>
        </Container>
      </Box>
    </>
  );
});

export default SecurityLampList;
