// 계정관리 - 암호변경

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
} from '@mui/material';
import NameOverInput from 'components/atom/NameOverInput';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import userStore from 'state/user';

export interface UserUpdatePwdPopupProps {
  userInfo: any;
  open: boolean;
  description:string;
  onClose: (update:boolean) => void;
}
const UserUpdatePwdPopup :React.FunctionComponent<UserUpdatePwdPopupProps> = ({userInfo, open, description, onClose}: UserUpdatePwdPopupProps) => {
  const [values, setValues] = useState(
    {
    userId: '',
    userPwd: '',
    curPwd: '',
    newPwd: '',
    newPwd2: '',
  });

  useEffect(() => {
    if (open) {
      console.log(`cyi UserUpdatePwdPopup useeffect userInfo${JSON.stringify(userInfo)}`)
      setValues({
        ...values,
        userId: userInfo.userId,
        userPwd: userInfo.userPwd,
        curPwd: '',
        newPwd: '',
        newPwd2: '',
      });
    }
  },[open]);

  //암호 입력되는거
  const handleChange = (name:string, value:string) => {
    console.log(`cyi onChange name:${name}, value"${value}`)

    if (name == '현재암호') {
      setValues({
        ...values,
        curPwd: value
      });

    }
    else if (name == '새로운 암호') {
      setValues({
        ...values,
        newPwd: value
      });
    }
    else {
      setValues({
        ...values,
        newPwd2: value
      });
    }
  };

  const handleDialogClose = () => {
    console.log(`cyi handleDialog`)
    onClose(false);
  };

  //확인버튼
  const handleApply = async () => {
    console.log(`cyi handleApply`)
    if (values.newPwd != values.newPwd2) {
      ToastsStore.success("새로운 암호가 서로 다릅니다. 확인해주세요")
      return;
    }
    if (values.curPwd == values.newPwd) {
      ToastsStore.success("현재 암호와 새로운 암호가 서로 같습니다. 확인해주세요")
      return;
    }
    const ret: any = await userStore.isMatch(values.userId, values.curPwd);
    if (ret) {
      const user: any = {
        userId: values.userId,
        password: values.newPwd,
      }
      const ret1: any = await userStore.updateUserPwd(user);
      if (ret1.status == 200) {
        ToastsStore.success("등록 성공")
        const timer = setInterval(() => {
          onClose(true);
          clearInterval(timer);
        }, 1000)
      }
    }
    else {
      ToastsStore.success("현재 암호가 다릅니다. 확인해주세요")
      return;
    }
  };

  return (
    //암호변경 dialog
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Card>
          <CardHeader
            subheader=""
            title="암호변경"
            >
            </CardHeader>
          <Divider />
          <CardContent>
          <NameOverInput
              name='현재암호'
              value={values.curPwd}
              onChange={handleChange}
              type='password'
            ></NameOverInput>
          <NameOverInput
              name='새로운 암호'
              value={values.newPwd}
              type='password'
              onChange={handleChange}
            ></NameOverInput>
          <NameOverInput
              name='새로운 암호 확인'
              value={values.newPwd2}
              type='password'
              onChange={handleChange}
            ></NameOverInput>

          <Box sx={{ height: 5 }} />
          </CardContent>
          </Card>


      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>
          취소
        </Button>
        <Button onClick={handleApply} autoFocus>
          확인
        </Button>
      </DialogActions>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Dialog>
  )
};

export default UserUpdatePwdPopup;