export default {
	translation: {
		global: {
			modify: "modify",
			delete: "delete",
			add: "add",
			addFacility: "add facility",
			addDevice: "add device",
			select:"select",
			status:"status",
			facnumber:"facility name",
			factype:"facility type",
			state:"state",
			facname:"facility name",
			facState:"state",
			facInfo:"Info",
			upperFacInfo:"upper Info",
			facGroup:"group",
			curState:"Current State",

			curState:"Current State",
			errorNumber:"Error Number",
			errorType:"Error Type",
			errorDetail:"Error Detail",

			errorHistory:"Error History",
			upperFacility:"upper Facility",
			mtCompany:"Company",
			latitude:"Latitude",
			longtude:"Longtude",
			atatude:"Atatude",
			address:"address",
			device:"device",
			gwMac:"G/W Mac",
			macAddress:"Mac Addr",
			installDay:"Install Day",
			receiveTime:"Receive Time",
			inputVolt:"input Volt",

			failList:"Fail List",
			searchList:"Search List",
		},
		signin: {
			title: "Sign-In",
			description: "Lamp monitoring System",
			id: "ID",
			pw: "Password",
			signin: "Sign in now",
		},
		navbar: {
			welcome: "Welcome",
		},
		sidemenu: {
			dashboard: "dashboard",

			map: "map",
			mapstatus: "facility group",
			maperror: "error",

			fail: "fail",
			failurelist: "failures",
			requirelist: "requires",
			repairlist: "repairs",
			examinelist: "examines",
			failurehistory: "failure history",
            errorlist : "error list",
			
			facility: "facility",
			gatewaylist: "gateway",
			streetlamplist: "street lamp",
			securitylamplist: "security lamp",

			facilitygroup: "facility group",
			facilityline: "facility line",

			system: "system",
			memberlist: "user",
			departmentlist: "department",
			managingcompanylist: "managing company",
			gatewayform: "gateway registration",
			registration: "register facility",
			departmentform: "departmentform",
		},
	}
}