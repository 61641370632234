// 관리자 - 사이트 - 사이트 사용자 관리 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import AdminSiteUserAdd from '../../../components/admin/system/AdminSiteUserAdd';

const AdminSiteUserForm = () => {
  console.log('AdminSiteUserForm')
  return(
  <>
    <Helmet>
      <title>AdminSiteUserForm</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <AdminSiteUserAdd />
      </Container>
    </Box>
  </>
);
    };

export default AdminSiteUserForm;
