// 중계기 상세 (super계정에서만 보임)
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Drawer, Grid } from "@mui/material";
import { TitleinTab } from "./DefineStyled";
import { useStores } from 'state';
import NameValue from 'components/atom/NameValue';
import { IFacInfo } from "model";
import facilityStore from "state/facility";
import { useEffect } from "react";
import { observer } from "mobx-react";

interface StyledNameValueProps {
	name: string;
	value :string;
  }
  function StyledNameValue({name, value}:StyledNameValueProps) {
	return (
	  <>
		<Grid
		  item
		  lg={6}
		  sm={12}
		  xl={6}
		  xs={12}
		>
		<NameValue
		  name={name}
		  value={value}
		  space={3}
		  nameWidth={150}
		  valueWidth={400}
		/>
		</Grid>
	  </>
	);
  }
type FacGatewayInfoDrawerProps = {
	show: boolean;
	facId:string;
	facInfo?:IFacInfo;
	uplinkInfo?:any;
	onShow?: (show:boolean) => void;
    onClose?: () => void;
};
const FacGatewayInfoDrawer = observer(({ show, facId, facInfo, uplinkInfo, onShow,onClose }:FacGatewayInfoDrawerProps) => {
	useEffect (() => {
		if (show)
			facilityStore.getFacilityGatewayInfo(facId)
	},[show]);
    const handleDialogClose = () => {
        console.log(`handleDialog`)
        onClose();
      };
	return (
		<Dialog
        open={show}
        onClose={onClose}
        PaperProps={{
            sx: {
                maxWidth:1000,
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                border: 1,
                borderRadius: 4
                // ml: {isMobile?1:35},
            }
			}}
		>
            <DialogContent>
			<Card>
				<CardContent>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Box
							sx={{
								borderBottom: 1,
								borderColor: 'divider',
							}}
						>
							<TitleinTab
								color="textPrimary"
								variant="h4"
							>
								중계기 정보
							</TitleinTab>
						</Box>
							<Box
								sx={{
									flex: 1,
								}}>
							</Box>
					</Box>
					<Box sx={{ pt: 3 }}>
						<Grid
							container
							spacing={1}
						>
							<StyledNameValue
								name="이름"
								value={facilityStore.facilityGatewayInfo?.facName}
							/>
							<StyledNameValue
								name="설명"
								value={facilityStore.facilityGatewayInfo?.facDesc}
							/>
							<StyledNameValue
								name="좌표(위도/경도)"
								value={facilityStore.facilityGatewayInfo?.latitude ? facilityStore.facilityGatewayInfo.latitude?.substr(0, 2) + '.' + facilityStore.facilityGatewayInfo.latitude?.substr(2) + '/' +
									facilityStore.facilityGatewayInfo.longitude?.substr(0, 3) + '.' + facilityStore.facilityGatewayInfo.longitude?.substr(3) : ''}
							/>
							<StyledNameValue
								name="최종 업데이트"
								value={facilityStore.facilityGatewayInfo?.devTimeUpdate}
							/>
							<StyledNameValue
								name="맥 어드레스"
								value={facilityStore.facilityGatewayInfo?.devSerialNo}
							/>
							<StyledNameValue
								name="설치주소"
								value={facilityStore.facilityGatewayInfo?.address}
							/>
						</Grid>
					</Box>
				</CardContent>
			</Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} autoFocus>
                    닫기
                </Button>
            </DialogActions>
		</Dialog>
	)
});
		
export default FacGatewayInfoDrawer;