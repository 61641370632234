// 관리자 - 사용자 등록/수정 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import AdminUserAdd from '../../../components/admin/system/AdminUserAdd';

const AdminMemberForm = () => {
  console.log('AdminMemberForm')
  return(
  <>
    <Helmet>
      <title>AdminMemberForm</title>
    </Helmet>
    
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <AdminUserAdd />
      </Container>
    </Box>

  </>
);
    };

export default AdminMemberForm;
