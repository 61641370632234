// 사이트 입력 처리상태 등 상태 select Rem
import * as React from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { useEffect } from 'react';


type NameSelectRemProps = {
    name: string;
    defaultValue?: string;
    object: any[];
    space?: string;
    nameWidth?: string;
    valueWidth?: string;
    onSelect: (name: string, value: string) => void;
};
const NameSelectRem = (
    { name, object, defaultValue = '100', nameWidth = '15rem', valueWidth = '20rem', space = '2rem', onSelect }
        : NameSelectRemProps) => {
    console.log(`NameSelectRem ${name}:${defaultValue}:${JSON.stringify(object)}`)
    const [select, setSelect] = React.useState<string>('');

    useEffect(() => {
        if (defaultValue) {
            console.log(`NameSelectRem values ${name}:${defaultValue}`)
            setSelect(defaultValue);
        }
        else {
            console.log(`NameSelectRem values else ${name}:${defaultValue}`)
        }
    }, [defaultValue]);

    const handleSelect = (event: SelectChangeEvent) => {
        console.log(`cyi NameSelectRem handleSelect ${event.target.value}`)
        setSelect(event.target.value as string);
        onSelect(name, event.target.value as string);
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Typography
                    sx={{
                        width: nameWidth,
                    }}
                    color="textPrimary"
                    variant="h5"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space }} />
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select}
                    sx={{
                        height: '2rem',
                        width: valueWidth,
                        textAlign: 'center',
                        borderRadius: "0.5rem",
                    }}
                    onChange={handleSelect}
                >
                    <option disabled={true} value="100" >
                        -- 지역을 선택 해주세요 --
                    </option>
                    {object.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
};

export default NameSelectRem;
