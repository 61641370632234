// 사이트 관리 - 시설
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  useRadioGroup
} from '@mui/material';
import { useStores } from '../../state';
import { useLocation, useNavigate } from 'react-router';
import { IGatewayFacInfo, IRegFacInfo } from '../../model/gateway';
import { IFacilityInfo, ISetFacilityInfo } from 'model/facility';
import facilityStore from 'state/facility';
import UnRegisteredFacListResults from './UnRegisteredFacListResults';
import RegisteredFacListResults from './RegisteredFacListResults';

type RegistrationImpProps = {
  regFacility : IFacilityInfo[];
  unRegFacility : IFacilityInfo[];
  doit:string;
};
const RegistrationImp = ({regFacility, unRegFacility, doit}:RegistrationImpProps) => {
  const navigate = useNavigate();
  const [doCommand, setDoCommand] = useState('');

  useEffect(() => {
    if (doit.includes("registerFacility")) {
      console.log(`registerFacility`)
      navigate('/slager/registerfacility');
    }
    else if (doit.includes("registerDevice")) {
      console.log(`registerDevice`)
      navigate('/slager/registerdevice');
    }
  },[doit]);

  return (
    //미연동 시설과 연동시설을 보여줌.
    <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <UnRegisteredFacListResults title="미연동 시설" facInfos={unRegFacility} doit={doCommand} />
              </Grid>
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <RegisteredFacListResults title="연동 시설" facInfos={regFacility} doit={doCommand} />
              </Grid>
            </Grid>
          </Box>

  );
};

export default RegistrationImp;
