// 사이트 관리 - 중계기  컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';
import RegisterFacGatewayToolbar from 'components/facility/RegisterFacGatewayToolbar';
import { useEffect, useState } from 'react';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import RegisterationGatewayImp from 'components/facility/RegisterationGatewayImp';

const RegistrationGateway = observer(({}) => {
  const { clientStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

  const [regFacilityGateway, setRegFacilityGateway] = useState<any[]>([]);
  const [unRegFacilityGateway, setUnRegFacilityGateway] = useState<any[]>([]);

  // 중계기 정보 받아옴
  useEffect(() => {
    facilityStore.getFacilityGatewayInfoList(userStore.siteId, null);
  },[]);

  useEffect(() => {
    if (facilityStore.facilityGatewayList == null) {
      return;
    }

    const regFac= facilityStore.facilityGatewayList.filter((item: any, index: number) => {
      console.log(`facilityGateway ---> ${JSON.stringify(item)}`);
      console.log(`facilityGateway -> ${item.facId}, ${item.gwId}`)
      return item.gwId != -1;
    }
    );
    regFac.map((item:any, index:number) => {
      console.log(`regFacilityGateway ---> ${JSON.stringify(item)}`);
    }
    );
    setRegFacilityGateway(regFac);
    const unRegFac= facilityStore.facilityGatewayList.filter((item: any, index: number) => {
      console.log(`facilityGateway ---> ${JSON.stringify(item)}`);
      console.log(`facilityGateway -> ${item.facId}, ${item.gwId}`)
      return item.gwId == -1;
    }
    );
    unRegFac.map((item:any, index:number) => {
      console.log(`unRegFacilityGateway ---> ${JSON.stringify(item)}`);
    }
    );
    setUnRegFacilityGateway(unRegFac);
  },[facilityStore.facilityGatewayList]);

  const handleCommand = (command:string) => {
    console.log(`handleCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }

  return (
    <>
      <Helmet>
        <title>RegistrationGateway</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <RegisterFacGatewayToolbar handleCommand={handleCommand}/>
          <RegisterationGatewayImp regFacilityGateway={regFacilityGateway} unRegFacilityGateway={unRegFacilityGateway} doit={doit}></RegisterationGatewayImp>
        </Container>
      </Box>
    </>
  );
});

export default RegistrationGateway;
