//계정관리
import {
  Box,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../components/common/Button'
import { ButtonBlue , ButtonGreen } from 'components/admin/system/Styled';

type MyAccountToolbarProps = {
  onCommand?:(command:string)=> void;
};
const MyAccountToolbar = ({ onCommand }: MyAccountToolbarProps) => {
  const { t } = useTranslation()


    return (
        <Box>
      <Typography 
         sx={{
          position:'relative',
          height:1,
          textAlign:'right',
          right:'1%'
         }}
         gutterBottom>
          시스템 관리 {'>'} 계정 관리
        </Typography>
        <Box
          sx={{
            display: 'flex',
            position:'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2%',
            right:'1%'
          }}
        >
            <Typography //타이틀 글자 조정
              sx={{ 
                fontSize: 30,
                fontWeight:600,
                color:'#0C3149',
                display:'flex',
                position:'relative',
                left: '2.2%',
                marginBottom:'1%'
               }}    
                gutterBottom > 
                계정 관리
                </Typography>

        <Box sx={{ mt: 1 }}> </Box>

            <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
          <ButtonBlue
          sx= {{
            "&:hover": {backgroundColor: "#10548A"}
          }}
            onClick={() => {
              console.log(`modify`)
              onCommand('modify');
            }}
          >
            {t('global.modify')}
          </ButtonBlue>
          <ButtonBlue
          sx= {{
            "&:hover": {backgroundColor: "#10548A"}
          }}
            onClick={() => {
              console.log(`change passwd`)
              onCommand('changePW');
            }}
          >
            암호 변경
          </ButtonBlue>
          <ButtonGreen
          sx= {{
            "&:hover": {backgroundColor: "#0E3B25"}
          }}
            onClick={() => {
              console.log(`leave`)
              onCommand('leave');
            }}
          >
            탈퇴
          </ButtonGreen>
        </Box>
      </Box>
    </Box>

  );
};

export default MyAccountToolbar;
