//모바일버전 nameInput 컴포넌트 
import * as React from 'react';
import {
  Box,
  Typography,
  Input,
} from '@mui/material';

type MobileNameInputProps = {
  name : string;
  value : string;
  disabled? : boolean;
  readOnly? : boolean;
  space? : string;
  nameWidth? : string;
  valueWidth? : string;
  backgroundColor? : string;
  onChange:(name:string, value:string)=>void;
};
const MobileNameInput = (
    { name, value, disabled = false, readOnly = false, nameWidth = '15rem', valueWidth = '20rem', space = '2rem', backgroundColor = '#FFFFFF', onChange 
    }: MobileNameInputProps) => {
    // const [value, setValue] = useState(defaultValue);
        console.log(`NameInput ${name}:${value}`)
        console.log(`${name} : ${nameWidth}, ${valueWidth}`)

//   React.useEffect(() => {
//     console.log(`NameInput useEffect(value) ${value}`)
//   },[value]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`event = ${event}`)
        console.log(`event.name = ${event.target.name}`)
        console.log(`event.value = ${event.target.value}`)
        // setValue(event.target.value);
        onChange(event.target.name, event.target.value);
    };

return (
    <>
        <Box
            sx={{
                  //marginTop:'26px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',

            }}
        >
            <Typography
                sx={{
                    m:'0.6rem',
                    p:'0.4rem',
                    width:'80px'
                }}
                color="textPrimary"
                variant="h6"
            >
                {name}
            </Typography>
            <Input
                sx={{
                    m:'0.4rem',
                    p:'0.7rem',
                    border:1,
                    borderRadius : '5px',
                    borderColor:'#BFBFBF',
                    backgroundColor:{backgroundColor},
                    //width: '5rem',
                    height: '2.7rem',
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",    }           
                }}
                disabled={disabled}
                readOnly={readOnly}
                disableUnderline
                name={name}
                onChange={handleChange}
                required
                value={value}
                margin="none"
            />
        </Box>
    </>
)
};

export default MobileNameInput;