// 시설상세/중계기 상세 정보칸에서 사용하는 value칸 - > rem으로 조정한것
import * as React from 'react';
import {
  Box,
  Typography
} from '@mui/material';

type NameValueRemProps = {
  name : string;
  value : string;
  space? : string;
  nameWidth? : string;
  valueWidth? : string;
};
const NameValueRem = ({ name, value, nameWidth = '7rem', valueWidth = '15rem', space = '1rem' }:NameValueRemProps) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Typography
                    sx={{
                        width:nameWidth,
                    }}
                    color="textPrimary"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space,
                        backgroundColor:"#00ff00"
                 }} />
                <Typography
                    sx={{
                        width:valueWidth,
                        height: '2rem',
                        borderRadius: "0.5rem",
                        border: "1px solid #bdbdbd",
                        pl: 1,
                    }}
                    color="textPrimary"
                >
                    {value}
                </Typography>
            </Box>
        </>
    )
};

export default NameValueRem;
