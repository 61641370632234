import React from 'react';
import clientStore, { IClientStore } from './client';
import companyStore, { ICompanyStore } from './company';
import dashboardStore, { IDashboardStore } from './dashboard';
import gatewayStore, { IGatewayStore } from './gateway';
import alarmStore, { IAlarmStore } from './alarm';
import iotStore, { IIotStore } from './iot';

import userStore, { IUserStore } from './user';
import groupStore, { IGroupStore } from './group';
import lineStore, { ILineStore } from './line';
import facilityStore, { IFacilityStore } from './facility';
import siteStore, { ISiteStore } from './site';

interface IStores {
  userStore: IUserStore;
  companyStore: ICompanyStore;
  clientStore: IClientStore;
  gatewayStore: IGatewayStore;
  alarmStore: IAlarmStore;
  iotStore: IIotStore;
  dashboardStore: IDashboardStore;
  groupStore: IGroupStore;
  lineStore: ILineStore;
  facilityStore: IFacilityStore;
  siteStore: ISiteStore;
}

export const useStores = (): IStores => {
  return {
    userStore,
    companyStore,
    clientStore,
    gatewayStore,
    alarmStore,
    iotStore,
    dashboardStore,
    groupStore,
    lineStore,
    facilityStore,
    siteStore,
  };
};