//사이트관리  - 시설 타이틀 및 시설등록 / 기기등록
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Paper,
  InputBase,
  Divider,
  Typography,
  Pagination
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonBlue } from 'components/admin/system/Styled';

type RegisterFacProps = {
  handleCommand?: (command: string) => void;
};
const RegisterFacToolbar = ({ handleCommand }: RegisterFacProps) => {
  const { t } = useTranslation()

  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };
  return (
    <Box>
      <Typography
        sx={{
          position: 'relative',
          height: 1,
          textAlign: 'right',
          right: '1%'
        }}
        gutterBottom>
        사이트 관리 {'>'} 시설
      </Typography>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2%',
          right: '1%'
        }}>

        <Typography //타이틀 글자 조정
          sx={{
            fontSize: 30,
            fontWeight: 600,
            color: '#0C3149',
            display: 'flex',
            position: 'relative',
            left: '2.2%',
            marginBottom: '1%'
          }}
          gutterBottom>
          시설
        </Typography>
        <Box sx={{ mt: 1 }}> </Box>

        <Box sx={{ mt: 1 }}> </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {/* 시설등록 */}
          <ButtonBlue
            sx={{
              "&:hover": { backgroundColor: "#10548A" }
            }}
            onClick={() => {
              handleCommand('registerFacility');
            }}
          >
            {t('global.addFacility')}
          </ButtonBlue>

          {/* 기기등록 */}
          <ButtonBlue
            sx={{
              "&:hover": { backgroundColor: "#10548A" }
            }}
            onClick={() => {
              handleCommand('registerDevice');
            }}
          >
            {t('global.addDevice')}
          </ButtonBlue>
        </Box>
      </Box>
      <Divider sx={{ border: 1, borderColor: '#bfbfbf' }} />
    </Box>
  );
};

export default RegisterFacToolbar;
