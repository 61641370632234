//일반 팝업 틀 - AdminUserAdd와 UserAdd에서 사용중
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import NameInput from 'components/atom/NameInputRem';
import React from 'react';

export interface AlertPopupProps {
  open: boolean;
  description:string;
  onClose: () => void;
}
const AlertPopup :React.FunctionComponent<AlertPopupProps> = ({open, description, onClose}: AlertPopupProps) => {

  //닫기
  const handleDialogClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        {description}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AlertPopup;