// 사이트 모델
export enum SiteLevelType {
	Manager = '9200',
	User = '9300',
	Maintenance = '9400',
}

export const SiteLevel = [
  { value: SiteLevelType.Manager, label: '사이트관리자' },
  { value: SiteLevelType.User, label: '사이트사용자' },
  { value: SiteLevelType.Maintenance, label: '설치/유지보수' },
];



export const getSiteLevelStr = (value: string) => {
  const ret: any = SiteLevel.find(site => site.value == value);
  return ret?.label;
}

export const isSiteManager = (siteLevel:string) => {
  return siteLevel == SiteLevelType.Manager;
}

export interface IUserIdManager {
    userName: string;
	userId:string;
	siteLevel:string;
}

export interface ISiteDetail {
	siteId : string;
	siteName : string;
	description : string;
	userIdManager : IUserIdManager[];
	userIdMaintenance : IUserIdManager[];
	address : string;
	zipCode : string;
	admCode : string;
	admCodeType : string;
	enable : string;
	userName : string;
	hpNo : string;

};

export interface ISiteSet {
	siteId : string;
	siteName : string;
	description : string;
	address : string;
	zipCode : string;
	admCode : string;
	admCodeType : string;

	smtpEnable : string;
    smtpSslEnable : string;
    smtpServer : string;
    smtpPort : string;
    smtpId : string;
    smtpPw : string;
    smtpEncryptPw : string;   // SMTP_ENCRYPT_PW
    smtpFromName : string;

	userIdManager : IUserIdManager[];
	userIdMaintenance : IUserIdManager[];
	enable : string;
};

export interface ISiteListInUser {
    siteId: string;
	siteName:string;
	siteLevel:string;
};
export interface ISiteUser {
	userName: string;
	userId: string;
	siteList : ISiteListInUser[];
};