// 관리자 - 사용자
import {
  Box,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button'
import { borderColor, borderRadius, color, textAlign } from '@mui/system';
import { ButtonGreen, ButtonBlue } from './Styled'

type UserProps = {
  userCount:string;
  onCommand?:(command:string)=> void;
};
//상단 borderBox
const defaultProps = {
  bgcolor: 'background.paper',
  m: 1,
  border: 1,
  style: { width: '100%', height: '6rem'},
};

const AdminUserToolbar = ({ userCount, onCommand }: UserProps) => {
  const { t } = useTranslation()
  return (
    <Box >

         <Typography 
         sx={{
          position:'relative',
          height:1,
          textAlign:'right',
          right:'1%'
         }}
         gutterBottom>
          관리자 {'>'} 사용자 관리
        </Typography>

            <Typography //타이틀 글자 조정
              sx={{ 
                fontSize: 30,
                fontWeight:600,
                color:'#0C3149',
                display:'flex',
                position:'relative',
                left: '1%',
                marginBottom:'1%'
             }}   
              gutterBottom > 
              사용자 관리
              </Typography>


      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
      sx={{ borderRadius:3,
            borderColor: '#1C75BC',
            marginBottom:'2%',
            margin:'auto'
             }}       
          {...defaultProps}
           >
      <Box
        sx={{
          display: 'flex',
          position:'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m:1,
          textAlign:'left',
          float:'right',
          top:15
        }}
      >
        <Box sx={{m:1}}>
</Box>
<Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin:'auto',
            right:'10px'
          }}
        >
          <ButtonBlue
              sx={{
              "&:hover": {backgroundColor: "#10548A"}}}
            onClick={() => {
              onCommand('add');
            }}
          >
            {t('global.add')}
          </ButtonBlue>
          <ButtonBlue // 수정,삭제,등록 버튼 조정
          sx={{
            "&:hover": {backgroundColor: "#10548A"}
          }} //마우스 올린 뒤 색상
          
            onClick={() => {
              onCommand('modify');
            }}
          >
            {t('global.modify')}
          </ButtonBlue>

          <ButtonGreen
          sx={{
            "&:hover": {backgroundColor: "#0E3B25"}
            }}
            onClick={() => {
              onCommand('delete');
            }}
          >
            {t('global.delete')}
          </ButtonGreen>
         
        </Box>
      </Box>
    </Box>
    </Box>
  );
};

export default AdminUserToolbar;
