import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const TitleinTab = styled(Typography)(
  ({theme}) => ({
    color: theme.palette.primary.main,
  })
);