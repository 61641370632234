//사용자 타이틀 및 borderBox

import {
  Box,
  Card,
  CardContent,
  Typography,
  Popover,
  IconButton,
  Input
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import userStore from 'state/user';
import theme from 'theme';
import Button from '../../../components/common/Button'
import { ButtonBlue } from 'components/admin/system/Styled';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { HelpOutline } from '@mui/icons-material';
import styles from 'components/facilityGroup/style.module.css'
type UserProps = {
  userCount: string;
  onCommand?: (command: string) => void;
};
const UserToolbar = ({ userCount, onCommand }: UserProps) => {
  const { t } = useTranslation()
  
//borderBox설정
  const defaultProps = {
    //bgcolor: '#E8F1FF',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };
  return (
    <Box>
      <Typography
        className={styles.pageDirection}
        gutterBottom>
        사이트 관리 {'>'} 사용자
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '-0.5%'
        }}>

        <Typography //글자 조정
          className={styles.pageTitle}
          gutterBottom >
          사용자
        </Typography>
      </Box>

      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
        sx={{
          borderRadius: 3,
          borderColor: '#1C75BC',
          marginBottom: '2%',
          margin: 'auto'
        }}
        {...defaultProps}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1,
            textAlign: 'left',
            float: 'right',
            top: 15
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 'auto',
              position: 'relative',
              right: '10px'
            }}
          >
            {userStore.isSiteManager() &&
              <>
                <Button
                  className={styles.blueButton}
                  onClick={() => {
                    console.log(`add`)
                    onCommand('add');
                  }}
                >
                  {t('global.add')}
                </Button>
              </>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserToolbar;
