// 그룹설정 - 정보수정 팝업

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import NameInput from 'components/atom/NameInput';
import NameSelect from 'components/atom/NameSelect';
import { IFacilityInfo, ILineSet } from 'model';
import { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import lineStore from 'state/line';
import { useStores } from 'state';
import { useLocation } from 'react-router';
import sunsetStore from 'state/sunset';
import SunsetCitySelectDialog from 'components/atom/SunsetCitySelectDialog';
import styles from 'components/facilityGroup/style.module.css'
import { observer } from 'mobx-react';
import GoScheduleModePopup from './GoScheduleModePopup';
import SunsetSchedleUpdatePopup from './SunsetScheduleUpdatePopup';
import TimeSelect from 'components/atom/TimeSelect';
import { isMobile } from 'react-device-detect';
import MobileNameInput from 'components/atom/MobileNameInput';
import MobileNameSelect from 'components/atom/MobileNameSelect';

interface StyledNameInputProps {
  name?: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  backgroundColor?: string;
  onChange?: (name: string, value: string) => void;
}
function StyledNameInput({ name, value, disabled, readOnly, backgroundColor, onChange }: StyledNameInputProps) {
  return (
    <>
      <NameInput
        name={name}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        backgroundColor={backgroundColor}
        space={10}
        nameWidth={110}
        valueWidth={200}
        onChange={onChange}
      />
    </>
  );
}
const OnOffType = [
  { value: '0', mode: 'N', label: '선택' },
  { value: '1', mode: 'S', label: '연간스케줄' },
  { value: '2', mode: 'O', label: '고정 시간' },
  { value: '3', mode: 'M', label: '사용안함' },
];

interface ICombineArr {
  chunkId: string,
  chunkSubIdx: string,
  startDate: string,
  endDate: string,
  date: string,
  ctSunriseTime: string,
  ctSunsetTime: string,
  civile: string,
  civilm: string,
  onRatioTime: any,
  offRatioTime: any,
  onTime: any,
  offTime: any,
  description: string,
  ratio: any
}

interface LineDetailUpdatePopupProps {
  lineInfo?: any,
  lId?: string,
  onClose?: () => void;
  open?: boolean;
  timeSet?: boolean;
}

const LineDetailUpdatePopup = observer(({ lId, lineInfo, onClose, open, timeSet }: LineDetailUpdatePopupProps) => {
  const { facilityStore, userStore } = useStores();
  const location = useLocation();
  const [onHour, setOnHour] = useState<string>("");

  const [onMin, setOnMin] = useState<string>("");
  const [offHour, setOffHour] = useState<string>("");
  const [offMin, setOffMin] = useState<string>("");
  const [values, setValues] = useState<ILineSet>(
    {
      siteId: '',
      siteName: '',
      groupId: '',
      groupName: '',
      lineId: '',
      lineName: '',
      description: '',
      chunkId: '-1',
      chunkName: '',
      operationMode: 'N',
      offTime: '',
      onTime: '',
    });
  const [openSunsetDialog, setOpenSunsetDialog] = useState(false);

  const [showSunsetCitySelectDialog, setShowSunsetCitySelectDialog] = useState(false);
  const [combineHeader, setCombineHeader] = useState<any>();
  const [combineArr, setCombineArr] = useState<ICombineArr[]>(null);

  const [areaId, setAreaId] = useState('1');
  const [cityId, setCityId] = useState('');
  const [cityName, setCityName] = useState('');
  const [onTimeDisabled, setOnTimeDisabled] = useState(true)//수동
  const [offTimeDisabled, setOffTimeDisabled] = useState(true)//수동
  const [outFacility, setOutFacility] = useState<IFacilityInfo[]>([]);
  const [showGoSchedulePopup, setshowGoSchedulePopup] = useState(false);
  const [onOffEnable, setOnOffEnable] = useState(true);
  const [scheduleChunkId, setScheduleChunkId] = useState('');

  useEffect(() => {
    if (openSunsetDialog) {
      setOffTimeDisabled(true);
      setOnTimeDisabled(true);
    }
  }, [openSunsetDialog]);

  useEffect(() => {
    if (open) { // lineInfo의 일부만 불러오는건 안됨. 불러올때는 lineInfo 다 불러오자. 
      console.log("---- useEffect LineInfo1 ----", JSON.stringify(lineInfo)) //lineInfo요소를 확인할 때(콘솔을 확인해서 나오는 애들 다 넣어주면된다.)
      setValues({
        ...values,
        siteId: lineInfo.siteId,
        siteName: lineInfo.siteName,
        groupId: lineInfo.groupId,
        groupName: lineInfo.groupName,
        lineId: lineInfo.lineId,
        lineName: lineInfo.lineName,
        description: lineInfo.description,
        chunkId: lineInfo.chunkId,
        chunkName: lineInfo.chunkName,
        operationMode: timeSet ? "O" : lineInfo.operationMode,
        offTime: lineInfo.offTime,
        onTime: lineInfo.onTime,
      });
      const onArray = lineInfo.onTime?.split(':');
      const tmpOnHour = onArray?.[0];
      console.log("tmp tmpOnHour value =====", tmpOnHour)
      const tmpOnMin = onArray?.[1];
      console.log("tmp tmpOnMin value =====", tmpOnMin)
      setOnMin(tmpOnMin)
      setOnHour(tmpOnHour)

      const offArray = lineInfo.offTime?.split(':');
      const tmpOffHour = offArray?.[0];
      console.log("tmp OffHour value ===== ", tmpOffHour)
      const tmpOffMin = offArray?.[1];
      console.log("tmp OffMin value ===== ", tmpOffMin)
      setOffMin(tmpOffMin)
      setOffHour(tmpOffHour)
    }
  }, [open]);

  const handleDialogClose = () => {
    console.log(`handleDialog`)
    onClose();
  };

  //점멸방식 저장
  const getType = (mode: string): string => {
    console.log(`getType ${mode} `)
    console.log(`getType values ${JSON.stringify(values)}`);

    if (mode == null) {
      return '0';
    }

    const type = OnOffType.find((item: any) => item.mode == mode)
    return type.value;
  }

  const handleOnOffType = (name: string, value: string) => {
    console.log(`cyi handleOnOffType name:${name}, value"${value}`)
    const type = OnOffType.find((item: any) => item.value == value)

    setValues({
      ...values,
      operationMode: type.mode,
    });
  };

  //라인추가 정보입력
  const onChange = (name: string, value: string) => { // 바뀌는 데이터 한자한자 입력되는거(실질적 저장x)
    console.log(`cyi onChange name:${name}, value:${value}`)
    if (name == '라인이름') {
      setValues({
        ...values,
        lineName: value
      });
    }
    else if (name == '설명') {
      setValues({
        ...values,
        description: value
      });
    }
  };
  //연간스케줄 닫음
  const handleSunsetDialogClose = () => {
    setOpenSunsetDialog(false)
    setCombineHeader(null)
    setCombineArr(null)
  };

  //연간스케줄 열림
  const onSunsetCity = () => {
    setOpenSunsetDialog(true);
  };

  const handleSave = async () => {// 실질적 저장기능( 일출일몰, 그룹명, 설명 전부 다 )
    console.log(`handleSave `)
    const line: ILineSet = {
      siteId: values.siteId,
      siteName: values.siteName,
      groupId: values.groupId,
      groupName: values.groupName,
      lineId: values.lineId,
      lineName: values.lineName,
      description: values.description,
      chunkId: scheduleChunkId ? scheduleChunkId : values.chunkId || '-1',
      chunkName: values.chunkName,
      operationMode: values.operationMode == 'N' ? 'M' : values.operationMode,
      onTime: values.operationMode == 'O' ? `${onHour}:${onMin}` : '',
      offTime: values.operationMode == 'O' ? `${offHour}:${offMin}` : '',
    }

    console.log(`LineDetailUpdatePopup handleSave values !!!!!!!!!!!${JSON.stringify(values)}`)
    console.log(`LineDetailUpdatePopup handleSave line !!!!!!!!!!! ${JSON.stringify(line)}`)
    //const ret:any = await lineStore.addLine(line);

    if (values.operationMode === "S" && (scheduleChunkId || values.chunkId) === "-1") {
      ToastsStore.warning(
        "점멸방식을 연간스케줄로 할 시 반드시 도시설정을 해야합니다. \n 연간스케줄 설정을 통해 도시를 설정해주세요. \n( 다른 점멸방식을 사용하시려면 점멸방식을 변경해주세요. )"
      );
      return;
    } else if (values.operationMode === 'O' && (!onHour || !onMin || !offHour || !offMin)) {
      ToastsStore.warning(
        "시간 형식이 올바르지않습니다. 점등시간과 소등시간을 확인해주세요."
      );
      return;
    }
    else {
      console.log(`ddff1 ${JSON.stringify(scheduleChunkId)}`);
      console.log(`LineDetailUpdatePopup handleSave values !!!!!!!!!!!${JSON.stringify(values)}`);
      console.log(`LineDetailUpdatePopup handleSave line !!!!!!!!!!! ${JSON.stringify(line)}`);

      const ret: any = await lineStore.addLine(line);

      if (ret.status === 200) {
        if (values.operationMode === 'O' && (onHour && onMin && offHour && offMin)) {
          setshowGoSchedulePopup(true)
          ToastsStore.success("라인정보 업데이트 성공");
        }
        else if (values.operationMode === "S" && scheduleChunkId !== "-1") {
          setshowGoSchedulePopup(true)
          ToastsStore.success("라인정보 업데이트 성공");
        }
        setTimeout(() => {
          if (values.operationMode === 'M' || values.operationMode === 'N') {
            onClose();
          }
        }, 1000);
      } else if (ret.status === 400) {
        ToastsStore.error("등록 실패");
      }
    }
  }

  const getFacilityAllOutofLine = async () => {
    await facilityStore.getFacilityInfoListOutLine(userStore.siteId, null, null, null);
    {
      console.log(`facilityLineFormImp getFacility facilityStore.outFacilityList ${JSON.stringify(facilityStore.outFacilityList)}`);
      setOutFacility(facilityStore.outFacilityList?.filter(facility => facility.lineId != lineInfo?.lineId));
    }
  }

  useEffect(() => {
    if (lineInfo?.lineId) {
      getFacilityAllOutofLine();
    }
  }, [onClose]);

  //스케줄모드 이동
  const handleGoScheduleClose = async () => {
    console.log(`handleLineDrawerClose in facilitylinelist`)
    setshowGoSchedulePopup(false);
    onClose();
  };
  //스케줄모드 팝업 하나만 닫음.
  const handleGoScheduleCloseOne = async () => {
    setshowGoSchedulePopup(false);
  };

  // 고정시간 점등,소등 시간 설정
  const onChangeHour = (name: string, value: string) => {
    console.log(`cyi onChangeHour name:${name}, value:${value}`)
    if (name == '점등 시간') {
      setOnHour(value);
    }
    else {
      setOffHour(value);
    }
  };
 // 고정시간 점등,소등 시간 설정
  const onChangeMin = (name: string, value: string) => {
    console.log(`cyi onChangeMin name:${name}, value:${value}`)
    if (name == '점등 시간') {
      setOnMin(value);
    }
    else {
      setOffMin(value);
    }
  };

  // chunkId저장
  const handleScheduleChunkId = async (chunkId: any) => {
    setScheduleChunkId(chunkId);
  };
  return (
    // 정보수정 dialog
    <><Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Card>
          <Typography
            sx={{
              fontSize: 23,
              fontWeight: 600,
              color: '#0C3149',
              display: 'flex',
              position: 'relative',
              padding: 1.5
            }}>
            { timeSet ? "ON/OFF 시간 설정" : "정보 수정" }
          </Typography>
          <Divider />
          <CardContent>
            {!isMobile &&
              <><StyledNameInput
                name="라인이름"
                value={values?.lineName}
                readOnly={timeSet}
                backgroundColor={timeSet ? "#cecece" : "#FFFFFF"}
                onChange={onChange} /><Box sx={{ mt: 1 }}>
                </Box>
                <StyledNameInput
                  name="설명"
                  value={values?.description}
                  readOnly={timeSet}
                  backgroundColor={timeSet ? "#cecece" : "#FFFFFF"}
                  onChange={onChange} /></>
            }
            {isMobile &&
              <><MobileNameInput
                name="라인이름"
                value={values?.lineName}
                readOnly={timeSet}
                backgroundColor={timeSet ? "#cecece" : "#FFFFFF"}
                onChange={onChange} /><Box sx={{ mt: 1 }}>
                </Box>
                <MobileNameInput
                  name="설명"
                  value={values?.description}
                  readOnly={timeSet}
                  backgroundColor={timeSet ? "#cecece" : "#FFFFFF"}
                  onChange={onChange} /></>
            }
            {isMobile &&
              <Box
                sx={{
                  //display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'

                }}
              >
                <MobileNameSelect
                  name="점멸방식"
                  object={OnOffType}
                  defaultValue={getType(values.operationMode)}
                  space={10}
                  nameWidth={110}
                  valueWidth={200}
                  backgroundColor={timeSet ? "#cecece" : "#FFFFFF"}
                  onSelect={handleOnOffType} />
                <Box sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  position: 'relative',
                  left: '50px'
                }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={values.operationMode == 'S' ? false : true}
                    onClick={onSunsetCity}
                  >
                    <Typography sx={{ fontSize: 12 }}>
                      연간스케줄 <br />설정
                    </Typography>
                  </Button>
                </Box>
              </Box>
            }
            {!isMobile &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <NameSelect
                  name="점멸 방식"
                  object={OnOffType}
                  defaultValue={getType(values.operationMode)}
                  space={10}
                  nameWidth={110}
                  valueWidth={200}
                  backgroundColor={timeSet ? "#cecece" : "#FFFFFF"}
                  onSelect={handleOnOffType} />

                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={values.operationMode == 'S' ? false : true}
                  onClick={onSunsetCity}
                >
                  <Typography sx={{ fontSize: 12 }}>
                    연간스케줄 <br />설정
                  </Typography>
                </Button>
              </Box>
            }
            <Box sx={{ mt: 1 }}> </Box>
            {values.operationMode == 'O' &&
              <>
                <TimeSelect
                  name="점등 시간"
                  space={'3rem'}
                  nameWidth={'5rem'}
                  valueWidth={'12rem'}
                  defaultHour={onHour}
                  defaultMin={onMin}
                  disabled={!onOffEnable}
                  onChangeHour={onChangeHour}
                  onChangeMin={onChangeMin}
                />
                <Box sx={{ mt: 1 }}> </Box>
                <TimeSelect
                  name="소등 시간"
                  space={'3rem'}
                  nameWidth={'5rem'}
                  valueWidth={'12rem'}
                  defaultHour={offHour}
                  defaultMin={offMin}
                  disabled={!onOffEnable}
                  onChangeHour={onChangeHour}
                  onChangeMin={onChangeMin}
                />
              </>
            }
            <Box sx={{ mt: 1 }}> </Box>
            <Box sx={{ height: 5 }} />
          </CardContent>
        </Card>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ position: 'relative', bottom: '10px' }}>
          <Button className={styles.greenButton} onClick={handleDialogClose}>
            취소
          </Button>
          <Button className={styles.primaryButton} onClick={() => {
            console.log('라인 정보 관리');
            if ((values.operationMode === 'O' || values.operationMode === 'S') && scheduleChunkId !== "-1") {
              handleSave();
            } else {
              handleSave();
            }
          }}>
            확인
          </Button>
        </Box>
      </DialogActions>

      {/* 도시선택 Dialog */}
      {/* <SunsetCitySelectDialog
        show={showSunsetCitySelectDialog}
        onShow={() => setShowSunsetCitySelectDialog(false)}
        onSunsetCity={onSunsetCity}></SunsetCitySelectDialog> */}

      {/* 연간스케줄 Dialog */}
      <SunsetSchedleUpdatePopup
        lineInfo={lineInfo}
        open={openSunsetDialog}
        selectAreaId={areaId}
        selectCityId={cityId}
        selectCityName={cityName}
        scheduleChunkId={handleScheduleChunkId}
        saveChunkId={scheduleChunkId}
        onClose={handleSunsetDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"></SunsetSchedleUpdatePopup>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Dialog>

      {/* 스케줄모드 이동 */}
      <GoScheduleModePopup
        groups={values.groupId}
        open={showGoSchedulePopup}
        lineId={values.lineId}
        operationMode={values.operationMode}
        onClose={() => handleGoScheduleClose()}
        onCloseOne={() => handleGoScheduleCloseOne()}></GoScheduleModePopup></>
  )
});

export default LineDetailUpdatePopup;