//계정관리 - 탈퇴 팝업

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import userStore from 'state/user';

export interface UserDeletePopupProps {
  userInfo: any;
  open: boolean;
  description?: string;
  onClose: (update: boolean) => void;
}
const UserDeletePopup: React.FunctionComponent<UserDeletePopupProps> = ({ userInfo, open, description, onClose }: UserDeletePopupProps) => {
  const [values, setValues] = useState(
    {
      userId: '',
      userName: '',
      hpNo: '',
      telNo: '',
      email: '',
    });
//value설정
  useEffect(() => {
    if (open) {
      console.log(`cyi UserDeletePopup useeffect userInfo${JSON.stringify(userInfo)}`)
      setValues({
        ...values,
        userId: userInfo.userId,
        userName: userInfo.userName,
        telNo: userInfo.telNo,
        email: userInfo.email,
      });
    }
  }, [open]);

  //팝업닫음
  const handleDialogClose = () => {
    console.log(`cyi handleDialog`)
    onClose(false);
  };

  //확인
  const handleApply = async () => {
    console.log(`cyi handleApply`)
    const user: any = {
      userId: values.userId,
    }
    const ret: any = await userStore.delUser(user);
    if (ret.status == 200) {
      userStore.isLoggedIn = false;
      userStore.saveToken('false');
      userStore.saveName('');

      ToastsStore.success("탙퇴 되었습니다.")
      const timer = setInterval(() => {
        onClose(true);
        clearInterval(timer);
      }, 1000)
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        탈퇴 하시겠습니까?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>
          취소
        </Button>
        <Button onClick={handleApply} autoFocus>
          확인
        </Button>
      </DialogActions>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Dialog>
  )
};

export default UserDeletePopup;