//사이트 관리 - 시설 - 기기연동
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  MenuItem,
  SelectChangeEvent,
  Input,
  CardContent,
  Divider,
  CardHeader
} from '@mui/material';
import theme from 'theme';
import { useLocation, useNavigate } from 'react-router';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import { alpha } from '@mui/material/styles';
import userStore from 'state/user';
import deviceStore from 'state/device';
import facilityStore from 'state/facility';
import { FacType } from 'model';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import styles from 'components/facilityGroup/style.module.css'

interface ILocation {
  siteId: string,
  siteName: string,
  facId: string,
  facName: string,
  facType: string,
  facDesc: string,
  devId: string,
}
const ConnectDeviceImp = observer(() => {
  const { clientStore, iotStore } = useStores();
  const [serialValue, setSerialValue] = useState(null);
  const [searchSerial, setSearchSerial] = useState('');
  const location = useLocation();
  const state = location.state as ILocation;
  const [type, setType] = useState('add');
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();

  const [values, setValues] = useState(
    {
      facId: '',
      facName: '',
      facType: '',
      facDesc: '',
      devId: '',
    });

    //device 관련 정보 받아오는것들
  useEffect(() => {
    console.log(`ConnectDeviceImp useEffect state ${JSON.stringify(state)}`)
    if (state != null) {
      setType('modify');
      console.log(`ConnectDeviceImp useEffect setValue ${JSON.stringify(state)}`)

      setValues({
        ...values,
        facId: state.facId,
        facName: state.facName,
        facType: state.facType,
        facDesc: state.facDesc,
        devId: state.devId,
      });
    }
  }, []);

  useEffect(() => {
    console.log(`serialValue serial = ${JSON.stringify(serialValue)}`);
  }, [serialValue]);

  useEffect(() => {
    setSerialValue(deviceStore.deviceList);
  }, [deviceStore.deviceList]);

  useEffect(() => {
    deviceStore.getDeviceBySiteId(userStore.siteId);
  }, []);

  useEffect(() => {
    const device = serialValue?.find((item: any) => {
      if (item.facId == values.facId)
        return true;
    })
    if (device) {
      setValues({
        ...values,
        devId: device.devId
      });
    }
  }, [serialValue]);

  //연동하기 버튼 
  const handleUpdate = async () => {
    console.log(`ConnectDeviceImp handleUpdate`)
    // console.log(`update facs ${facs}`)
    const ret: any = await facilityStore.setDevIdForFacility(values.facId, values.devId, 'reg');
    console.log(`!!!!!!!!!!!!!!!!!!!! ${JSON.stringify(ret)}`)
    if (ret) {
      ToastsStore.success("연동 성공")
      const timer = setInterval(() => {
        navigate(-1);
        clearInterval(timer);
      }, 1000)
    }
    else {
      ToastsStore.warning("연동 실패")
    }
  }

  //시리얼번호 select 함수
  const handleChangeSerial = (event: SelectChangeEvent) => {
    console.log(`ConnectDeviceImp handleChangeSerial ${event.target.value}`)
    console.log(`ConnectDeviceImp handleChangeSerial name ${event.target.name}`)
    //setDevId(event.target.value as string);
    setValues({
      ...values,
      devId: event.target.value
    });
  };

  //시리얼번호 입력(원하는 serial을 입력하세요)
  const handleSearchSerial = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`ConnectDeviceImp event = ${event}`)
    console.log(`event.name = ${event.target.name}`)
    console.log(`event.value = ${event.target.value}`)
    setSearchSerial(event.target.value)
  };

  //취소
  const BackPage = () => {
    navigate(-1);
  };

  return (
    <form
      autoComplete="off"
      noValidate
    >
      <Typography
        className={styles.pageDirection}
        gutterBottom>
        사이트 관리 {'>'} 시설 {'>'} 기기연동
      </Typography>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2%',
          right: '1%'
        }}
      >
        <Typography //타이틀 글자 조정
          className={styles.pageTitleNoBorder}
          gutterBottom >
          기기 연동
        </Typography>
      </Box>
      <Table>
        <TableRow>
          <TableCell className={styles.regTableTop}>
            <Typography
              sx={{ position: 'relative', left: 15, display: '-webkit-inline-box', lineHeight: 3.5 }}>
              시설 이름
            </Typography>
            <Input
              sx={{
                width: 200,
                height: 30,
                textAlign: 'center',
                borderRadius: "4px",
                border: "1px solid #bdbdbd",
                pl: 1,
                left: 105,
              }}
              value={values.facName}
              disableUnderline
              required
              margin="none"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={styles.regTableBottom} sx={{ left: '5%', display: '-webkit-box' }} >
            <Typography
              sx={{ position: 'relative', left: 15, display: '-webkit-inline-box', lineHeight: 3.5 }}>
              연동기기
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 120
              }}>

              {/* 시리얼번호 입력 칸 */}
              <Input
                sx={{
                  width: 215,
                  height: 30,
                  textAlign: 'center',
                  borderRadius: "4px",
                  border: "1px solid #bdbdbd",
                  pl: 1,
                  left: '51%'
                }}
                disableUnderline
                placeholder='원하는 serial을 입력 하세요'
                onChange={handleSearchSerial}
                required
                value={searchSerial}
                margin="none"
              />
              <Box sx={{ height: 5 }} />
              <FormControl sx={{ width: 215, left: '51%' }}>

                {/* 시리얼번호 select */}
                <InputLabel id="demo-simple-select-label">Serial</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-autowidth"
                  value={values.devId}
                  label="Serial"
                  onChange={handleChangeSerial}
                >
                  {
                    serialValue?.filter((item: any) => {
                      return item.serialNo.toLowerCase().includes(searchSerial.toLowerCase());
                    })
                      .map((item: any, index: number) => {
                        return (
                          <MenuItem value={item.devId}>{item.serialNo}</MenuItem>
                        )
                      })
                  }
                </Select>
              </FormControl>
            </Box>
          </TableCell>
        </TableRow>
      </Table>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2
        }}
      >
        <Button
          className={styles.greenButton}
          color="primary"
          size="medium"
          variant="contained"
          disabled={disableButton}
          onClick={() => BackPage()}
        >
          취소
        </Button>
        <Box sx={{ p: 0.5 }}></Box>
        <Button
          className={styles.primaryButton}
          color="primary"
          size="medium"
          variant="contained"
          disabled={disableButton}
          onClick={() => handleUpdate()}
        >
          연동하기
        </Button>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
      </Box>
    </form>
  );
});

export default ConnectDeviceImp;
