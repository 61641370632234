//시설운영 - 그룹 - 설정 - 그룹정보수정

import { AddCircleRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import NameInputRem from 'components/atom/NameInputRem';
import NameOverInput from 'components/atom/NameOverInput';
import { IGroupSet, ILineSet, isSiteManager } from 'model';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import styles from 'components/facilityGroup/style.module.css'
import groupStore from 'state/group';
import lineStore from 'state/line';
import userStore from 'state/user';
import { isMobile } from 'react-device-detect';
import MobileNameInput from 'components/atom/MobileNameInput';

interface StyledNameInputProps {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}
function StyledNameInput({ name, value, disabled, onChange }: StyledNameInputProps) {
  return (
    <>
      <NameInputRem
        name={name}
        value={value}
        disabled={disabled}
        space={'3rem'}
        nameWidth={'5rem'}
        valueWidth={'20rem'}
        onChange={onChange}
      />
    </>
  );
}


export interface GroupDetailUpdatePopupProps {
  groupInfo: any;
  groupId: string;
  open?: boolean;
  onClose: (update: boolean) => void;
}
const GroupDetailUpdatePopup: React.FunctionComponent<GroupDetailUpdatePopupProps> = ({ groupInfo, open, groupId, onClose }: GroupDetailUpdatePopupProps) => {
  const [values, setValues] = useState<any>(
    {
      siteId: '',
      groupId: '',
      siteName: '',
      groupName: '',
      lineId: '',
      lineName: '',
      description: '',
      users: [],
    });
  const [openUserAdd, setOpenUserAdd] = useState(false);
  useEffect(() => {
    if (open) {
      setValues({
        ...values,
      });
    }
  }, [open]);

  useEffect(() => {
    console.log(`FacilityGroupDetailImp useEffect groupInfo ${JSON.stringify(groupStore.groupInfo)}`)
    if (groupStore.groupInfo) {
      console.log(`FacilityGroupDetailImp useEffect groupInfo exist ${JSON.stringify(groupStore.groupInfo)}`)
      setValues(
        groupStore.groupInfo
      );
    }
  }, [groupStore.groupInfo]);

  //value 값 설정
  useEffect(() => {
    console.log(`FacilityGroupDetailImp useEffect groupInfo ${JSON.stringify(groupStore.groupInfo)}`)
    setValues({
      ...values,
      users: groupStore.userList
    });
  }, [groupStore.userList]);

  //그룹이름 변경
  const handleChangeGroupName = (name: string, value: string) => {
    console.log(`FacilityGroupDetailImp handleChangeGroupName = ${name}, ${value} !!!!!!!!!!!!!!!!!!`)
    setValues({
      ...values,
      groupName: value
    });
  }
  //설명 변경
  const handleChangeDescription = (name: string, value: string) => {
    console.log(`FacilityGroupDetailImp handleChangeGroupName = ${name}, ${value} !!!!!!!!!!!!!!!!!!`)
    setValues({
      ...values,
      description: value
    });
  }
//dialog닫음
  const handleDialogClose = () => {
    setValues(
      groupStore.groupInfo
    );
    console.log(`cyi handleDialog`)
    onClose(false);
  };
//담당자 추가 닫음
  const handleUserAddClose = () => {
    console.log(`handleUserAddClose`);
    setOpenUserAdd(false);
  };
  //등록
  const handleSave = async () => {
    console.log(`handleSave `)
    const group: IGroupSet = {
      siteId: userStore.siteId,
      groupId: groupId,
      groupName: values.groupName,
      description: values.description,
    }
    const ret: any = await groupStore.addGroup(group);
    console.log(`addGroup ${JSON.stringify(ret)}`)
    if (ret.status == 200) {
      ToastsStore.success("등록 성공")
      groupStore.getGroup(values.groupId)
      const timer = setInterval(() => {
        clearInterval(timer);
        onClose(true);
      }, 1000)
    }
    else if (ret.status == 400) {
      ToastsStore.success("등록 실패")
    }
  }
//담당자 삭제
  const handleUserRemoveRequest = async (userName: string) => {
    console.log(`handleUserRemoveRequest event${userName}`);
    const findUser: any = groupStore.userList.find(user => user.userName == userName);
    const ret: any = await groupStore.deleteUserGroup(values.siteId, values.groupId, findUser.userId);
    console.log(`handleUserRemoveRequest ${JSON.stringify(ret)}`)
    if (ret.status == 200) {
      ToastsStore.success("삭제 성공")
      groupStore.getUserListByGroup(values.siteId, values.groupId);
    }
    else if (ret.status == 400) {
      ToastsStore.success("삭제 실패")
    }
  };
//담당자 추가
  const handleUserAdd = async (user: any) => {
    console.log(`handleUserAdd ${user.userId}`);
    const ret: any = await groupStore.setUserToGroup(values.siteId, values.groupId, user.userId, "description");
    console.log(`addGroup ${JSON.stringify(ret)}`)
    if (ret.status == 200) {
      setValues(
        groupStore.groupInfo
      );
      ToastsStore.success("등록 성공")
      groupStore.getUserListByGroup(values.siteId, values.groupId);
    }
    else if (ret.status == 400) {
      setValues(
        groupStore.groupInfo
      );
      ToastsStore.success("등록 실패")
    }
  };
//담당자 추가 저장
  const handleUserAddRequest = async () => {
    console.log(`handleUserAddRequest`);
    const ret: any = await groupStore.getUserListByGroup(values.siteId, values.groupId);
    if (ret.status == 200) {
      setOpenUserAdd(true);
    }
    else if (ret.status == 400) {
      ToastsStore.success("사용자 리스트를 가지고 오는데 실패했습니다.")
    }
  };
  return (
    <Box>
      {/* 그룹정보수정 팝업 */}
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>

          <Typography
            sx={{
              fontSize: 23,
              fontWeight: 600,
              color: '#0C3149',
              display: 'flex',
              position: 'relative',

              padding: 1.5
            }}>
            그룹 정보 수정
          </Typography>

          <Divider />
          <CardContent>
            {isMobile &&
              <><MobileNameInput
                name="그룹명"
                value={values?.groupName}
                // value={groupName}
                onChange={handleChangeGroupName} />
                <Box sx={{ mt: 1 }}> </Box>
                <MobileNameInput
                  name="설명"
                  value={values?.description}
                  // value={groupName}
                  onChange={handleChangeDescription} /></>
            }
            {!isMobile &&
              <><StyledNameInput
                name="그룹명"
                value={values?.groupName}
                // value={groupName}
                onChange={handleChangeGroupName} />
                <Box sx={{ mt: 1 }}> </Box>
                <StyledNameInput
                  name="설명"
                  value={values?.description}
                  // value={groupName}
                  onChange={handleChangeDescription} /></>
            }
            <Box sx={{ height: 10 }} />
            <Divider sx={{ border: 1, borderColor: '#BFBFBF' }} />
            <Box sx={{ height: 10 }} />
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  position: 'relative',
                  width: '4rem',
                  left: '3.5%'
                }}
                color="textPrimary"
              >
                담당자
              </Typography>
              <Box sx={{
                width: '1rem',
              }} />
              {isSiteManager(userStore.siteLevel) &&
                <AddCircleRounded
                  onClick={() => { console.log('!!!!!!!!!!!!!!1'); handleUserAddRequest() }}
                ></AddCircleRounded>}
            </Box>
            <Box sx={{
              width: '2rem',
              backgroundColor: "#00ff00"
            }} />
            <Box sx={{ height: 5 }} />
            <Stack direction="row" spacing={1}>
              {groupStore.userList.filter(user => user.groupId == groupId).map((user) => {
                return (
                  <>
                    {isSiteManager(userStore.siteLevel)
                      ?
                      <Chip sx={{ position: 'relative,', left: '2%' }}
                        label={user.userName}
                        onDelete={() => { handleUserRemoveRequest(user.userName); }} />
                      :
                      <Chip label={user.userName} />}
                  </>
                );
              }
              )}
            </Stack>
            <Divider sx={{ position: 'relative', top: 42, borderColor: '#bdbdbd' }}></Divider>
          </CardContent>
        </DialogContent>
        <DialogActions>
          <Button className={styles.greenButton} onClick={handleDialogClose} autoFocus>
            취소
          </Button>
          <Button className={styles.primaryButton} onClick={handleSave} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>

{/* 담당자 추가 팝업 */}
      <Dialog
        open={openUserAdd}
        onClose={handleUserAddClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color: '#0C3149',
              display: 'flex',
              position: 'relative',
              padding: 1.5
            }}>
            담당자 추가
          </Typography>
          <Table size="small">
            <TableBody>
              {groupStore.userList.filter(user => user.groupId != values?.groupId).map((user, index) => (
                <TableRow
                  hover
                  key={user.userId}
                >
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {isSiteManager(userStore.siteLevel)
                        ?
                        <Chip sx={{ position: 'relative,', left: '2%' }}
                          label={user.userName} />
                        :
                        <Chip label={user.userName} />}

                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Button
                      className={styles.primaryButtonLogin} size="small" variant="outlined" color="primary"
                      onClick={() => {
                        console.log(`추가`);
                        handleUserAdd(user);
                      }}
                    >
                      추가
                    </Button>
                  </TableCell>
                  {!groupStore.userList &&
                    <TableCell>
                      <Typography>추가 할 담당자가 없습니다.</Typography>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button className={styles.greenButton} onClick={handleUserAddClose}>취소</Button>
        </DialogActions>
      </Dialog>
    </Box>

  )
};

export default GroupDetailUpdatePopup;