//대시보드 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import DashGroup from 'components/dashboard3/DashGroup';
import groupStore from 'state/group';
import userStore from 'state/user';
import { IGroupInfo } from 'model';

const Dashboard3 = observer(({}) => {

  useEffect(() => {
    console.log(`Dashboard3 grouplist : ${groupStore.groupList.length}, ${JSON.stringify(groupStore.groupList)}`)
  },[groupStore.groupList]);
  useEffect(() => {
    groupStore.getGroupListByUser(userStore.siteId, userStore.getId());
    return () => {
      groupStore.groupList = [];
    }
  },[]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pb: 3,
        }}
      >
        <Grid
          container
          spacing={3}
        >
          {groupStore.groupList.length < 1 ?
            <>
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                  sx={{
                    flex: '3'
                  }}
                >
                  등록 된 그룹이 없습니다.
                </Typography>
              </>
            :
            groupStore.groupList.map((item: IGroupInfo, index) => {
              return (
                <>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <DashGroup groupInfo={item}></DashGroup>
                  </Grid>
                </>
              )
            })
          }
        </Grid>
      </Box>
    </>
  );
});

export default Dashboard3;
