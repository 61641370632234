import { observable, computed } from 'mobx';
import moment, { Moment } from 'moment';
import { api, q } from '../api';
import { IUserDetail } from '../model/user';

// export let isLoggedIn = false;

export interface IIotStore {
	test: string;
	devOptions:any[];
	totalErrCnt:any[];
	monthErrCnt:any[];
	weekErrCnt:any[];
	weekErrStatCnt:any[];
	errStatCnt:any[];
	lastMonthOperCnt:any[];

	getDevOption:(facid:string, facType:string) => void;
	getDbSelectTotErrCnt:(id:string) => void;
	getDbSelectMonthlyErrCnt:(id:string) => void;
	getDbSelectWeeklyErrCnt:(id:string) => void;
	getDbSelectWeeklyErrStatCnt:(id:string) => void;
	getDbSelectErrStatCnt:(id:string) => void;
	getDbSelectOperStatCnt:(id:string) => void;
}
export const iotStore = observable<IIotStore>({
	test: '',
	devOptions : [],
	totalErrCnt:[],
	monthErrCnt:[],
	weekErrCnt:[],
	weekErrStatCnt:[],
	errStatCnt:[],
	lastMonthOperCnt:[],

	async getDevOption(facId:string, facType:string) {
		const ret = await api.get(`/api/iot/getDevOption${q({
			facId,
			facType
		})}`);
		console.log(`getDevOption ${JSON.stringify(ret)}`)
		this.devOptions = ret;
	},
	async getDbSelectTotErrCnt(id:string) {
		const tmp = new Date();
		const date = `${tmp.getFullYear()}/${tmp.getMonth() + 1}/${tmp.getDate()} 00:00:00`
		console.log(`date ${date}`);
		// const ret = await api.post(`/api/iot/getDbSelectTotErrCnt`,
		// 	{
		// 		id:id,
		// 		toDate:date,
		// 	});
		// console.log(`getDbSelectTotErrCnt ${JSON.stringify(ret)}`)
		// this.totalErrCnt = ret;
	},
	async getDbSelectMonthlyErrCnt(id:string) {
		const toDate = moment('00:00:00', 'HH:mm:ss')
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}`)
		const fromDate = toDate.clone().subtract(1, 'months');
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}, fromDate = ${fromDate.format('YYYY/MM/DD HH:mm:ss')}`)
		const ret = await api.post(`/api/iot/getDbSelectMonthlyErrCnt`,
		{
			id:id,
			fromDate:fromDate.format('YYYY/MM/DD HH:mm:ss'),
			toDate:toDate.format('YYYY/MM/DD HH:mm:ss'),
		});
		console.log(`getDbSelectMonthlyErrCnt ${JSON.stringify(ret)}`)
		this.monthErrCnt = ret;
	},
	async getDbSelectWeeklyErrCnt(id:string) {
		const toDate = moment('00:00:00', 'HH:mm:ss')
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}`)
		const fromDate = toDate.clone().subtract(7, 'days');
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}, fromDate = ${fromDate.format('YYYY/MM/DD HH:mm:ss')}`)
		const ret = await api.post(`/api/iot/getDbSelectMonthlyErrCnt`,
		{
			id:id,
			fromDate:fromDate.format('YYYY/MM/DD HH:mm:ss'),
			toDate:toDate.format('YYYY/MM/DD HH:mm:ss'),
		});
		console.log(`getDbSelectWeeklyErrCnt ${JSON.stringify(ret)}`)
		this.weekErrCnt = ret;
	},
	async getDbSelectWeeklyErrStatCnt(id:string) {
		const toDate = moment('00:00:00', 'HH:mm:ss')
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}`)
		const fromDate = toDate.clone().subtract(7, 'days');
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}, fromDate = ${fromDate.format('YYYY/MM/DD HH:mm:ss')}`)
		// const ret = await api.post(`/api/iot/getDbSelectErrStatCnt`,
		// {
		// 	id:id,
		// 	fromDate:fromDate.format('YYYY/MM/DD HH:mm:ss'),
		// 	toDate:toDate.format('YYYY/MM/DD HH:mm:ss'),
		// });
		// this.weekErrStatCnt = ret;
		// console.log(`getDbSelectWeeklyErrStatCnt ${JSON.stringify(ret)}`)
	},
	async getDbSelectErrStatCnt(id:string) {
		const toDate = moment('00:00:00', 'HH:mm:ss')
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}`)
		const fromDate = toDate.clone().subtract(1, 'months');
		console.log(`toDate = ${toDate.format('YYYY/MM/DD HH:mm:ss')}, fromDate = ${fromDate.format('YYYY/MM/DD HH:mm:ss')}`)
		// const ret = await api.post(`/api/iot/getDbSelectErrStatCnt`,
		// {
		// 	id:id,
		// 	fromDate:fromDate.format('YYYY/MM/DD HH:mm:ss'),
		// 	toDate:toDate.format('YYYY/MM/DD HH:mm:ss'),
		// });
		// this.errStatCnt = ret;
		// console.log(`getDbSelectErrStatCnt ${JSON.stringify(ret)}`)
	},
	async getDbSelectOperStatCnt(id:string) {
		const curDate = moment('1 00:00:00', 'DD HH:mm:ss')
		console.log(`get Oper curDate = ${curDate.format('YYYY/MM/DD HH:mm:ss')}`)
		const tmpDate = curDate.clone().subtract(1, 'months');
		const fromDate = tmpDate.subtract(30, 'minutes');
		const toDate = curDate.clone().subtract(1, 'days')
		const ret = await api.post(`/api/iot/getDbSelectOperStatCnt`,
		{
			id:id,
			fromDate:fromDate.format('YYYY/MM/DD HH:mm:ss'),
			toDate:toDate.format('YYYY/MM/DD HH:mm:ss'),
		});
		console.log(`getDbSelectOperStatCnt ${JSON.stringify(ret)}`)
		this.lastMonthOperCnt = ret;
	},
})

export default iotStore;
