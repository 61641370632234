//대시보드 전압차트 
import { BorderAll } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  styled,
  Typography
} from '@mui/material';
import { spread } from 'lodash';
import { observer } from 'mobx-react';
import { IFacilityDetailInfo } from 'model/facility';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import facilityStore from 'state/facility';
import { StringLiteral } from 'typescript';
import { Chart, ChartOptions, registerables, Tooltip } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

//import 'chartjs-adapter-date-fns';
import 'chartjs-adapter-moment';
import userStore from 'state/user';
Chart.register(zoomPlugin);
Chart.register(Tooltip);
Chart.register(annotationPlugin);
Chart.register(annotationPlugin);
Chart.register(...registerables);

type DashFacilityChartProps = {
  facId: string;
  facName: string;
  category?: string;
  vout1?: string;

  onClose?: () => void;
  open?: boolean;

};

//차트 xy
const DashFacilityChart = observer(({ open, onClose, facId, facName, category, vout1 }: DashFacilityChartProps) => {
  const chartRef = useRef(null);
  const [data, setData] = useState<any>({
    datasets: [
      {
        label: 'Dataset 1',
        data: [
          { x: '', y: '' },
          { x: '', y: '' },
          { x: '', y: '' },
          { x: '', y: '' },
        ],
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: "rgba(75,192,192,1)",
        fill: true,
      },
    ],
  });

  //과거 전압 정보 불러오는 함수
  const getFacilityCurrent = async () => {
    await facilityStore.getFacilityLampHistory(facId, '3');
  }

  // 다음날 00시 데이터 추가
  const today = new Date(); // 현재 날짜와 시간 가져오기
  today.setDate(today.getDate() + 2); // 다음 날로 이동
  today.setHours(0, 0, 0, 0); 
  
  const nextDayTimeString = today.toISOString().slice(0, 10) + ' 00:00:00';
  const nextDayData = { timeUpdate: nextDayTimeString }; // 다음 날 00시 데이터 생성
  console.log("timetime",nextDayData)
  facilityStore?.facilityCurrent?.push(nextDayData); // 배열의 맨 끝에 다음 날 00시 데이터 추가

  //차트 데이타 세팅
  const chartData = {
    labels: facilityStore?.facilityCurrent?.map((cur: { timeUpdate: any; vout1: any; }) => {
      if (moment(cur.timeUpdate).format('mm') === '00') {
        return moment(cur.timeUpdate).format('MM/DD HH:mm')
      } else {
        return moment(cur.timeUpdate).format('MM/DD HH:mm');
      }
    }),
    datasets: [
      {
        label: '전압(V)',
        data: facilityStore?.facilityCurrent?.map((cur: { timeUpdate: any; vout1: any; }) => {
          if (cur.vout1 / 10 <= 0) {
            return 1;
          }
          return cur.vout1 / 10;
        }),
        fill: false,
        backgroundColor: "#FFCF68",
        borderColor: "rgba(75,192,192,1)",
        barThickness: 3,
      },
      userStore.isAdmin() && {
        label: '전류(A)',
        data: facilityStore?.facilityCurrent?.map((cur: { timeUpdate: any; cout1: any; }) => {
          return cur.cout1;
        }),
        fill: false,
        backgroundColor: "#44B572",
        borderColor: "rgba(75,192,192,1)",
        barThickness: 3,
      },
    ].filter(Boolean),
  };

  //과거 전압 정보 불러오는 함수 실행
  useEffect(() => {
    getFacilityCurrent();
  }, []);

  // const today = moment();
  // const threeDaysAgo = moment().subtract(3, 'days');

  //차트 데이터에 마우스 올리면 뜨는 ToolTip 및 저전압 고전압 구분 선 & 줌 기능 옵션
  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem: any) {
            const cur = facilityStore?.facilityCurrent[tooltipItem[0].dataIndex];
            return moment(cur.timeUpdate).format('< MM/DD  HH:mm >')
          },
          label: function (tooltipItem: any) {
            const cur = facilityStore?.facilityCurrent[tooltipItem.dataIndex];
            const label = chartData.datasets[tooltipItem.datasetIndex].label;
            if (label == '전압(V)') {
              return `전압(V): ${cur.vout1 / 10}`;
            } else if (label == '전류(A)') {
              return `전류(A): ${cur.cout1}`;
            } else {
              return `${cur.timeUpdate}`;
            }
          },
        },
      },
      title: {
        display: true,
        text: facName,
        //label: facName,
      },
      annotation: {
        annotations: {
          line1: {
            //drawTime: "beforeDatasetsDraw",
            label: { content: '저전압', backgroundColor: '#219ebc', position: 'end' },
            yMin: 180,
            yMax: 180,
            borderColor: "#222222",
            borderWidth: 2,
            borderDash: [8, 8]
          },
          line2: {
            //drawTime: "beforeDatasetsDraw",
            label: { content: '고전압', backgroundColor: '#219ebc', position: 'end' },
            yMin: 240,
            yMax: 240,
            borderColor: "red",
            borderWidth: 2,
            borderDash: [8, 8]
          },
          // currentTime: {
          //   type: 'line',
          //   xMin:today.getTime(),
          //   xMax:today.getTime(),
          //   borderColor: "#222222",
          //   borderWidth: 2,
          //   borderDash: [8, 8]
          // }
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          drag: {
            enabled: true,
            backgroundColor: 'rgb(138,138,138,0.2)',
            threshold: 100,
          },
          mode: "x" as const,
          // speed: 100,
          //sensitivity: 0.5
        },
        pan: {
          enabled: true,
          mode: "x" as const,
          // speed: 0.5
        },
      },
    },
    scales: {
      x: {
        type: 'time' as const,
        title: {
          display: true,
        },
        time: {
          unit: 'hour',
          displayFormats: {
            hour: ' MM월 DD일 HH:mm',
            // day: 'DD/MM',
          },
          // min: threeDaysAgo.format('YYYY-MM-DD HH:mm'),
          // max: today.format('YYYY-MM-DD HH:mm'),
        },
      },
      y: {
        title: {
          display: true,
          text: 'value'
        }
      }
    },
    responsive: true,
  };

  // dialog 닫기 
  const handleDialogClose = () => {
    console.log(`handleDialog`)
    chartData.labels?.splice(0, chartData.labels.length);
    chartData.datasets?.splice(0, chartData.datasets.length);
    console.log("clear")
    onClose();
  };

  //resetZoom 버튼 기능 (줌을 리셋한다.)
  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };


  return (
    //화면 UI
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: isMobile ? '1000px' : '2000px',
          display: 'flex',
          justifyContent: 'flex-start',
          // ml: {isMobile?1:35},
          ml: 1,
          mr: 1,
          mb: 1,
        }
      }}
    >
      <DialogContent>
        <Box
          sx={{
            alignItems: 'center',
            border: 1,
            backgroundColor: '#ffffff',
            display: 'flex',
            flexDirection: 'row',
            width: isMobile ? '350px' : '1500px',
            height: isMobile ? '630px' : ''
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              border: 1,
              flex: '1 0 auto',
              display: 'flex',
              ...(isMobile && {
                width: '100%',
                height: '100%'
              })
            }}
          >
            {/* <Line options={options} data={data} width='1500vw' height='700vh' /> */}
            <Bar options={options} data={chartData} ref={chartRef} width='1500vw' height='700vh' />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResetZoom}>Reset Zoom</Button>
        <Button onClick={handleDialogClose} autoFocus>
          나가기
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DashFacilityChart