//사이드 네비게이션
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect';
import {
  Collapse,
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  ChevronDown as ChevronDownIcon,
  Map as MapIcon,
  MapPin as MapPinIcon,
  Monitor as MonitorIcon,
  Zap as ZapIcon,
  Grid as GridIcon,
  Minus as MinusIcon,
  Server as ServerIcon
} from 'react-feather';
import InputIcon from '@mui/icons-material/Input';
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';
import NavMenu from './NavMenu';
import NavDash from './NavDash';
import userStore from 'state/user';
import { borderLeft, borderLeftColor, borderRightColor } from '@mui/system';

const items = [
  {
    href: '/app/dashboard2',
    icon: BarChartIcon,
    title: 'Dashboard2'
  },
  {
    //시설지도
    href: '/app/map',
    icon: MapIcon,
    title: 'Map'
  },
  {
    href: '/app/managelamp',
    icon: MonitorIcon,
    title: 'ManageLamp'
  },
  {
    href: '/app/managegateway',
    icon: MonitorIcon,
    title: 'ManageGateway'
  },
  {
    href: '/app/registerlamp',
    icon: ZapIcon,
    title: 'RegisterLamp'
  },
  {
    href: '/app/registergateway',
    icon: ZapIcon,
    title: 'RegiterGateway'
  },
  {
    href: '/app/manageerror',
    icon: ZapIcon,
    title: 'ManageError'
  },
  {
    href: '/app/user',
    icon: UserIcon,
    title: 'User'
  },
  {
    href: '/app/facility',
    icon: UserIcon,
    title: 'Facility'
  },
  {
    href: '/app/panelboard',
    icon: BarChartIcon,
    title: 'PanelBoard'
  },
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  },
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login'
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  console.log(`cyi DashboardSidebar isMobile ${isMobile}, ${openMobile}`)
  const location = useLocation();
  const [hiddens, setHidden] = useState([]);
  const { t} = useTranslation()

  const DashItems = [
    {
      href: '/slager/dashboard',
      title: t('sidemenu.dashboard')
    },];
  const MapItems = [
      // title: t('sidemenu.map'),
      // children: [
        {
          href: '/slager/map', //지도
          title: t('sidemenu.mapstatus')
        },];
        //{
          //href: '/slager/maperror', // 장애 현황
          //title: t('sidemenu.maperror')
        //},
  const FacilityItems = [
    {
      title: t('sidemenu.facility'),
      children: [
        {
          href: '/slager/streetlamplist', // 시설
          title: t('sidemenu.streetlamplist')
        },
        {
          href: '/slager/facilitygroup',  // 그룹
          title: t('sidemenu.facilitygroup')
        },
        {
          href: '/slager/gatewaylist', // 중계기
          title: t('sidemenu.gatewaylist')
        },
        {
          href: '/slager/errorlist', // 장애관리
          title: t('sidemenu.errorlist')
        },
      ]
    },
  ];

  const UserItems = [
    {
      title: t('sidemenu.system'),
      children: [
        {
          href: '/slager/memberlist', // 사용자 관리
          title: t('sidemenu.memberlist')
        },
        {
          href: '/slager/sitelist', // 사이트관리
          title: t('sidemenu.sitelist')
        },
        {
          href: '/slager/registrationgateway', // 중계기 등록
          title: '중계기 '
        },
        {
          href: '/slager/registration', // 시설 관리
          title: t('sidemenu.registration')
        },
      ]
    },
];
const Maps = [
  {
        title: "관리자",
        children: [
          {
            href: '/admin/sitelist',
            title: t('sidemenu.sitelist')
          },
          {
            href: '/admin/memberlist',
            title: t('sidemenu.memberlist')
          },
        ]
      },
  ];
  const adminItems = [
{
      title: "관리자",
      children: [
        {
          href: '/admin/sitelist',
          title: t('sidemenu.sitelist')
        },
        {
          href: '/admin/memberlist',
          title: t('sidemenu.memberlist')
        },
      ]
    },
];
  const mobileItems = [
{
      title: "기기등록",
      children: [
        {
          href: '/slager/mregistration',
          title: "기기등록"
        },
      ]
    },
];



  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    console.log(`aaa sidebar ${location.pathname}`)
  }, [location.pathname]);

  const handleClick = (title) => {
    // let newHiddens =  hiddens.map(hidden => {
    //   hidden.state = !hidden.state;
    // })
    const state = !hiddens[title];

    setHidden((prevState) => ({
      ...prevState,
      [title]: !prevState[title]
    }));
  }

  useEffect(() => {
    console.log(`hiddens = ${JSON.stringify(hiddens)}`)
  }, [hiddens]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#E6E7E8',
      }}
    >
      <Box sx={{ p: 2  }}>
        <List sx={{right:'5px'}}>
          {DashItems.map((item) => {
            return item.children == null ? (
              //대시보드
              <NavDash
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    Icon={GridIcon}
                  />
            )
            : (
              <>
                  <NavMenu
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    Icon={GridIcon}
                  />
                </>

            )
          }
          )}
            {MapItems.map((item) => {
            return item.children == null ? (
              //시설지도
            <NavDash
              href={item.href}
              key={item.title}
              title={item.title}
              Icon={MapPinIcon}
            />
            )
            : (
              <>
                  <NavMenu
                    key={item.title}
                    title={item.title}
                    Icon={MapPinIcon}
                  />
                </>

            )
          }
          )}
          {FacilityItems.map((item) => {
            return item.children == null ? (
              //시설운영
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            )
              : (
                <>
                  <NavMenu
                    key={item.title}
                    title={item.title}
                    Icon={ServerIcon}
                    state={hiddens[item.title]}
                    onClick={() =>
                      handleClick(item.title)}
                  />
                  <Collapse
                    in={hiddens[item.title]}
                  >
                    {
                      item.children.filter((child) => {
                        return userStore.isAdmin() || child.href != '/slager/gatewaylist'
                      }).map((child) => {
                      return (
                        <NavItem
                          href={child.href}
                          key={child.title}
                          title={child.title}
                          icon={child.icon}
                        />
                      )
                    }
                    )}
                  </Collapse>
                </>

              )
          }
          )
          }
          {UserItems.map((item) => {
            return item.children == null ? (
              //사이트관리
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            )
            : (
              <>
                  <NavMenu
                    key={item.title}
                    title={item.title}
                    Icon={SettingsIcon}
                    state={hiddens[item.title]}
                    onClick={() => 
                      handleClick(item.title)}
                  />
                  <Collapse
                  in={hiddens[item.title]}
                  >
                    {
                      item.children.filter((child) => {
                        return userStore.isAdmin() || child.href != '/slager/registrationgateway'
                      }).map((child) => {
                      return (
                    <NavItem
                      href={child.href}
                      key={child.title}
                      title={child.title}
                      icon={child.icon}
                    />
                      )
                      }
                    )}
                  </Collapse>
                </>

            )
          }
          )}
          
          {userStore.isAdmin() && adminItems.map((item) => {
            return item.children == null ? (
              //관리자
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            )
            : (
              <>
                  <NavMenu
                    key={item.title}
                    title={item.title}
                    Icon={UserIcon}
                    state={hiddens[item.title]}
                    onClick={() => 
                      handleClick(item.title)}
                  />
                  <Collapse
                  in={hiddens[item.title]}
                  >
                    {item.children.map((child) => {
                      return (
                    <NavItem
                      href={child.href}
                      key={child.title}
                      title={child.title}
                      icon={child.icon}
                    />
                      )
                      }
                    )}
                  </Collapse>
                </>

            )
          }
          )}
          {isMobile && mobileItems.map((item) => {
            return item.children == null ? (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            )
            : (
              <>
                  <NavMenu
                    key={item.title}
                    title={item.title}
                    Icon={ChevronDownIcon}
                    state={hiddens[item.title]}
                    onClick={() => 
                      handleClick(item.title)}
                  />
                  <Collapse
                  in={hiddens[item.title]}
                  >
                    {item.children.map((child) => {
                      return (
                    <NavItem
                      href={child.href}
                      key={child.title}
                      title={child.title}
                      icon={child.icon}
                    />
                      )
                      }
                    )}
                  </Collapse>
                </>

            )
          }
          )}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1,
      backgroundColor: '#E6E7E8'}} />
    </Box>
  );

  return <> 

      <Hidden>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            //width: 256,
            top:48
          }
        }}
      >
        {content}
      </Drawer>
    </Hidden>

    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            //width: 256,
            top: 48
          }
        }}
      >
        {content}
      </Drawer>
    </Hidden>
    
    <Hidden lgDown>
      <Drawer
        anchor="left"
        open
        variant="persistent"
        PaperProps={{ // sidebar 사이즈 
          sx: {
            width: 236,
            top: 48, 
            height: 'calc(100% - 45px)',
            borderRight:2,
            borderRightColor:'#bfbfbf',
          }
        }}
      >
        {content}
      </Drawer>
      </Hidden>
  </>;
            // height: 'calc(100% - 64px)'
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
