//사이트 관리 - 중계기
import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import { useStores } from '../../state';
import { useLocation, useNavigate } from 'react-router';
import { IGatewayFacInfo, IRegFacInfo } from '../../model/gateway';
import { IFacilityGatewayInfo, ISetFacilityGatewayInfo } from 'model/facility';
import facilityStore from 'state/facility';
import UnRegisteredFacListResults from './UnRegisteredFacListResults';
import RegisteredFacListResults from './RegisteredFacListResults';
import UnRegisteredFacGatewayListResults from './UnRegisteredFacGatewayListResults';
import RegisteredFacGatewayListResults from './RegisteredFacGatewayListResults';

type RegistrationGatewayImpProps = {
  regFacilityGateway : IFacilityGatewayInfo[];
  unRegFacilityGateway : IFacilityGatewayInfo[];
  doit:string;
};
const RegistrationGatewayImp = ({regFacilityGateway, unRegFacilityGateway, doit}:RegistrationGatewayImpProps) => {
  const navigate = useNavigate();
  const [doCommand, setDoCommand] = useState('');

  useEffect(() => {
    if (doit.includes("registerFacilityGateway")) {
      console.log(`registerFacilityGateway`)
      navigate('/slager/registerfacilitygateway');
    }
    else if (doit.includes("registerDeviceGateway")) {
      console.log(`registerDeviceGateway`)
      navigate('/slager/registerdevicegateway');
    }
  },[doit]);

  return (
    <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <UnRegisteredFacGatewayListResults title="미연동 시설" facInfos={unRegFacilityGateway} doit={doCommand} />
              </Grid>
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <RegisteredFacGatewayListResults title="연동 시설" facInfos={regFacilityGateway} doit={doCommand} />
              </Grid>
            </Grid>
          </Box>
  );
};

export default RegistrationGatewayImp;
