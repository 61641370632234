import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import UserAdd from '../../../components/slagger/system/UserAdd';

const MemberForm = () => {
  console.log('MemberForm')
  return(
  <>
    <Helmet>
      <title>MemberForm</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <UserAdd />
      </Container>
    </Box>
  </>
);
    };

export default MemberForm;
