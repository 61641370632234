import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';
import Logo from './Logo';

const MainNavbar = (props) => (
  <AppBar
    elevation={0}
    {...props}
  >
    <Toolbar variant='dense'>
      <RouterLink to="/">
        <Logo width="30" height="30"/>
      </RouterLink>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
