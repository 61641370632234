//facility관련 api

import { observable } from "mobx";
import { IFacilityDetailInfo, IFacilityGatewayInfo, IFacilityInfo, IFacilityScheduleState, IFacilityScheduleUpdateInfo, ISetFacilityGatewayInfo, ISetFacilityInfo } from "model/facility";
import { api, q } from '../api';
import SunsetCitySelectDialog from "components/atom/SunsetCitySelectDialog";

export interface IFacilityStore {
	facilityList: IFacilityInfo[];
	inFacilityList: IFacilityInfo[];
	outFacilityList: IFacilityInfo[];
	facilityDetailInfoList: IFacilityDetailInfo[];
	facilityDetailInfo: IFacilityDetailInfo;

	facilityGatewayList: IFacilityGatewayInfo[];
	facilityGatewayInfo: IFacilityDetailInfo;

	facilityCurrent: any[];
	facilityCmdState: string;
	facilityCmdResult: string;
	facilityCmdAliveResult: string;

	facilityRenewResult: string;
	facilityUpdateResult: string;
	facilityApplyResult: string;
	facilityFixedResult: string;
	facilityUpdateCount: any;

	cmdType: string;

	facilityScheduleUpdateInfoList: IFacilityScheduleUpdateInfo[]; // 6 or 12 month
	facilityScheduleUpdateInfo: IFacilityScheduleUpdateInfo; // 6 or 12 month

	mode: string;

	getMode: () => string;
	setMode: (mode: string) => void;

	getExit: () => string;
	setExit : (exit: string) => void;

	regFacilityInfo: (facility: ISetFacilityInfo) => void;
	setFacilityInfo: (facility: ISetFacilityInfo) => void;
	getFacilityCount: () => void;
	getFacilityInfo: (facility: ISetFacilityInfo) => void;
	getFacilityInfoList: (siteId: string, groupId: string, lineId: string, facType: string) => any;
	getFacilityInfoListOutLine: (siteid: string, groupId: string, lineId: string, facType: string) => any;
	getFacilityAllInfoList: (siteId: string, groupId: string, lineId: string, facType: string) => any;
	getFacilityDetailInfoList: (siteId: string, groupId: string, lineId: string, facType: string) => any;
	delFacility: (facId: string) => void;
	setLineForFacility: (siteId: string, groupId: string, lineId: string, facId: string) => any;
	delLineForFacility: (siteId: string, groupId: string, lineId: string, facId: string) => any;
	getFacilityDetailInfo: (facId: string) => void;
	cmdFacilityLampOn: (facId: string) => any;
	cmdFacilityLampOff: (facId: string) => any;
	cmdFacilityUpdateStatus: (facId: string) => void;
	requestAS(facId: string, asInfo: string): void;
	setDevIdForFacility: (facId: string, devid: string, opt: string) => Promise<boolean>;
	getFacilityScheduleUpdateInfo: (facId: string) => any; //한줄
	getFacilityScheduleUpdateInfoList: (siteId: string, groupId: string, lineId: string, facType: string) => any; //전체

	updateFacilityScheduleRenew: (facId: string) => void;// 가져오기
	updateFacilityScheduleUpdateInfo: (facId: string, value1: any) => void;//업데이트
	updateFacilityScheduleApply: (facId: string) => void;// 모드설정
	updateFacilityFixed: (facId:string) => void; // 고정 가져오기

	setGwIdForFacilityGateway: (facId: string, gwid: string, opt: string) => Promise<boolean>;
	regFacilityGatewayInfo: (facility: ISetFacilityGatewayInfo) => void;
	getFacilityGatewayInfoList: (siteId: string, facType: string) => any;
	getFacilityGatewayInfo: (facIdId: string) => any;
	delFacilityGateway: (facId: string) => void;

	getFacilityLampHistory: (facId: string, daysAgo: string) => void;
	getCmdRunState: (facId: string, subId: string) => void;
	getScheduleUpdateRunState: (facId: string, subId: string) => void;
}
export const facilityStore = observable<IFacilityStore>({

	facilityList: [],
	inFacilityList: [],
	outFacilityList: [],
	facilityDetailInfoList: [],
	facilityDetailInfo: null,

	facilityGatewayList: [],
	facilityGatewayInfo: null,

	facilityCurrent: [],
	facilityCmdState: null,
	facilityCmdResult: null,
	facilityCmdAliveResult: null,

	facilityRenewResult: null,
	facilityUpdateResult: null,
	facilityApplyResult: null,
	facilityFixedResult:null,
	facilityUpdateCount: null,

	facilityScheduleUpdateInfoList: [],
	facilityScheduleUpdateInfo: null,

	cmdType: null,

	mode: "",

	getMode() {
		return this.mode
	},
	setMode(mode: string) {
		this.mode = mode
	},

	getExit() {
		return this.exit
	},
	setExit(exit: string) {
		this.exit = exit
	},

	async regFacilityInfo(facility: ISetFacilityInfo) {
		console.log(`regFacility ${JSON.stringify(facility)}`)
		try {
			await api.post(`/api2/facility/regFacilityInfo`, facility);
			return { status: 200 };
		} catch (error) {
			console.log(`addFac error : ${JSON.stringify(error)}`)
			return { status: error.status };
		}
	},
	async setFacilityInfo(facility: ISetFacilityInfo) {
		console.log(`setFacility`);
		try {
			await api.post(`/api2/facility/setFacilityInfo`, facility);
			return { status: 200 };
		} catch (error) {
			console.log(`addFac error : ${JSON.stringify(error)}`)
			return { status: 400 };
		}
	},
	async getFacilityCount() {
		console.log(`getFacilityCount`);
	},
	async getFacilityInfo(facility: ISetFacilityInfo) {
		// api
		console.log(`getFacility`);
		console.log(`getFacility ${JSON.stringify(facility)}`)
		try {
			await api.post(`/api2/facility/getFacilityInfo`, facility);
			return true;
		} catch (error) {
			console.log(`getFacilityInfo error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async getFacilityInfoList(siteId: string, groupId: string, lineId: string, facType: string) {
		console.log(`getFacilityInfoList ${siteId}, ${groupId}, ${lineId}, ${facType}`);
		try {
			const ret = await api.post(`/api2/facility/getFacilityInfoList`, {
				siteId,
				groupId,
				lineId,
				facType,
			}
			);
			this.inFacilityList = ret.data;
			return true;
		} catch (error) {
			console.log(`getFacilityInfoList error : ${JSON.stringify(error)}`)
			this.inFacilityList = null;
			return false;
		}
	},
	async getFacilityInfoListOutLine(siteId: string, groupId: string, lineId: string, facType: string) {
		console.log(`getFacilityInfoListOutLine ${siteId}, ${groupId}, ${lineId}`);
		try {
			const ret = await api.post(`/api2/facility/getFacilityInfoList`, {
				siteId,
				groupId,
				lineId,
				facType,
			}
			);
			this.outFacilityList = ret.data;
			return true;
		} catch (error) {
			console.log(`getFacilityInfoListOutLine error : ${JSON.stringify(error)}`)
			this.outFacilityList = null;
			return false;
		}
	},
	async getFacilityAllInfoList(siteId: string) {
		console.log(`getFacilityAllInfoList ${siteId}`);
		try {
			const ret = await api.post(`/api2/facility/getFacilityInfoList`, {
				siteId,
			}
			);
			this.facilityList = ret.data;
			return true;
		} catch (error) {
			console.log(`getFacilityAllInfoList error : ${JSON.stringify(error)}`)
			this.facilityList = null;
			return false;
		}
	},
	async getFacilityDetailInfoList(siteId: string, groupId: string, lineId: string, facType: string) {
		console.log(`getFacilityDetailInfoList ${siteId}, ${groupId}, ${lineId}, ${facType}`);
		try {
			const ret = await api.post(`/api2/facility/getFacilityDetailInfoList`, {
				siteId,
				groupId,
				lineId,
				facType,
			}
			);
			this.facilityDetailInfoList = ret.data;
			return true;
		} catch (error) {
			console.log(`getFacilityDetailInfoList error : ${JSON.stringify(error)}`)
			this.facilityDetailInfoList = null;
			return false;
		}
	},
	async delFacility(facId: string) {
		console.log(`delFacility ${facId}`);
		try {
			await api.post(`/api2/facility/removeFacilityInfo`, {
				facId
			});
			return true;
		} catch (error) {
			console.log(`delFacility error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async setLineForFacility(siteId: string, groupId: string, lineId: string, facId: string) {
		console.log(`setLineForFacility ${siteId}, ${groupId}, ${lineId}, ${facId}`);
		try {
			await api.post(`/api2/facility/setLineForFacility`, {
				siteId,
				groupId,
				lineId,
				facId
			});
			return true;
		} catch (error) {
			console.log(`setLineForFacility error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async delLineForFacility(siteId: string, groupId: string, lineId: string, facId: string) {
		console.log(`delLineForFacility ${siteId}, ${groupId}, ${lineId}, ${facId}`);
		try {
			await api.post(`/api2/facility/removeLineForFacility`, {
				siteId,
				groupId,
				lineId,
				facId
			});
			return true;
		} catch (error) {
			console.log(`delLineForFacility error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//시설 상세정보 가져옴
	async getFacilityDetailInfo(facId: string) {
		// api
		console.log(`getFacilityDetailInfo`);
		try {
			const ret = await api.post(`/api2/facility/getFacilityDetailInfo`, {
				facId
			});
			this.facilityDetailInfo = ret.data;
			return true;
		} catch (error) {
			console.log(`getFacilityDetailInfo error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//점소등 on
	async cmdFacilityLampOn(facId: string) {
		// api
		console.log(`cmdFacilityLampOn ${Date.now()}`);
		this.cmdType = "LAMP_CTRL_ON"
		try {
			const subId = Date.now()
			const ret = await api.post(`/api2/facility/cmdFacilityCtrl`, {
				facId,
				cmdType: this.cmdType,
				subId: subId
			});
			return this.facilityCmdResult = ret.result;
		} catch (error) {
			console.log(`cmdFacilityLampOn error : ${JSON.stringify(error)}`)
			return '';
		}
	},
	//점소등 off
	async cmdFacilityLampOff(facId: string) {
		// api
		console.log(`cmdFacilityLampOff`);
		this.cmdType = "LAMP_CTRL_OFF"
		try {
			const subId = Date.now()
			const ret = await api.post(`/api2/facility/cmdFacilityCtrl`, {
				facId,
				cmdType: this.cmdType,
				subId: subId
			});
			return this.facilityCmdResult = ret.result;
		} catch (error) {
			console.log(`cmdFacilityLampOn error : ${JSON.stringify(error)}`)
			return '';
		}
	},
	//alive
	async cmdFacilityUpdateStatus(facId: string) {
		// api
		console.log(`cmdFacilityUpdateStatus ${Date.now()}`);
		this.cmdType = "CL_GETLIVE"
		try {
			const subId = Date.now()
			const ret = await api.post(`/api2/facility/cmdFacilityCtrl`, {
				facId,
				cmdType: this.cmdType,
				subId: subId
			});
			return this.facilityCmdAliveResult = ret.result
		} catch (error) {
			console.log(`cmdFacilityUpdateStatus error : ${JSON.stringify(error)}`)
			return '';
		}
	},
	//AS 요청 
	async requestAS(facId: string, asInfo: string) {
		// api
		console.log(`requestAS`);
		try {
			const ret = await api.post(`/api2/facility/requestAS`, {
				facId,
				asInfo
			});
			return { status: 200 };
		} catch (error) {
			console.log(`requestAS error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async setDevIdForFacility(facId: string, devId: string, opt: string) {
		// api
		console.log(`setDevIdForFacility`);
		try {
			const ret = await api.post(`/api2/facility/setDevIdForFacility`, {
				facId,
				devId,
				opt
			});
			return true;
		} catch (error) {
			console.log(`setDevIdForFacility : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async getFacilityScheduleUpdateInfo(facId: string) {
		// api
		console.log(`getFacilityScheduleUpdateInfo`);
		try {
			const response = await api.post(`/api2/facility/getFacilityScheduleUpdateInfo`, {
				facId
			});
			return response.data; // 데이터 반환
		} catch (error) {
			console.log(`getFacilityScheduleUpdateInfo error: ${JSON.stringify(error)}`);
			return null;
		}
		// try {
		// 	const ret = await api.post(`/api2/facility/getFacilityScheduleUpdateInfo`, {
		// 		facId
		// 	});

		// console.log(`getFacilityScheduleUpdateInfo data ${JSON.stringify(ret.data)}`);
		// 	this.facilityScheduleState = ret.data;
		// console.log(`getFacilityScheduleState facilityScheduleUpdateInfo ${JSON.stringify(this.facilityScheduleState)}`);
		// 	return true;
		// } catch (error) {
		// 	console.log(`getFacilityScheduleUpdateInfo error : ${JSON.stringify(error)}`)
		// 	return false;
		// }
	},
	async getFacilityScheduleUpdateInfoList(siteId: string, groupId: string, lineId: string, facType: string) {
		// api
		console.log(`getFacilityScheduleUpdateInfoList ${siteId}, ${groupId}, ${lineId}, ${facType}`);
		try {
			const ret = await api.post(`/api2/facility/getFacilityScheduleUpdateInfoList`, {
				siteId,
				groupId,
				lineId,
				facType,
			});
			this.facilityScheduleUpdateInfoList = ret.data;
			return true;
		} catch (error) {
			console.log(`cmdFacilityUpdateStatus error : ${JSON.stringify(error)}`)
			return false;
		}
	},

	//갱신
	async updateFacilityScheduleRenew(facId: string) {
		// api
		console.log(`updateFacilityScheduleRenew`);
		this.cmdType = "CL_ONOFFTIME_GET_INFO"
		try {
			const ret = await api.post(`/api2/facility/cmdFacilityCtrl`, {
				facId,
				cmdType: this.cmdType,
				subId: Date.now(),
				value1: "",
				value2: "",
				enable: ''
			});
			return this.facilityRenewResult = ret.result;
		} catch (error) {
			console.log(`updateFacilityScheduleRenew error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//업데이트
	async updateFacilityScheduleUpdateInfo(facId: string, value1: string) {
		// api
		console.log(`updateFacilityScheduleUpdateInfo`);
		this.cmdType = "CL_ONOFFTIME_SCHEDULE_DAY365"
		try {
			const ret = await api.post(`/api2/facility/cmdFacilityCtrl`, {
				facId,
				cmdType: this.cmdType,
				subId: Date.now(),
				value1,
				value2: '',
				enable: ''
			});
			this.facilityUpdateResult = ret.result;
			return { status: 200 };
		} catch (error) {
			console.log(`updateFacilityScheduleUpdateInfo error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//적용
	async updateFacilityScheduleApply(facId: string) {
		// api
		console.log(`updateFacilityScheduleApply`);
		this.cmdType = "CL_ONOFFTIME"
		try {
			const ret = await api.post(`/api2/facility/cmdFacilityCtrl`, {
				facId,
				cmdType: this.cmdType,
				subId: Date.now(),
				value1: "",
				value2: "",
				enable: '2'
			});
			this.facilityApplyResult = ret.result;
			return { status: 200 };
		} catch (error) {
			console.log(`updateFacilityScheduleApply error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	// 고정 가져오기
	async updateFacilityFixed(facId: string) {
		// api
		console.log(`updateFacilityScheduleApply`);
		this.cmdType = "CL_ONOFFTIME_GET_INFO"
		try {
			const ret = await api.post(`/api2/facility/cmdFacilityCtrl`, {
				facId,
				cmdType: this.cmdType,
				subId: Date.now(),
				value1: "",
				value2: "",
				enable: ""
			});
			this.facilityFixedResult = ret.result;
			return { status: 200 };
		} catch (error) {
			console.log(`updateFacilityScheduleApply error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	// gateway
	async setGwIdForFacilityGateway(facId: string, gwId: string, opt: string) {
		// api
		console.log(`setGwIdForFacilityGateway`);
		try {
			const ret = await api.post(`/api2/facilityGateway/setGwIdForFacilityGateway`, {
				facId,
				gwId,
				opt
			});
			return true;
		} catch (error) {
			console.log(`setGwIdForFacilityGateway error : ${JSON.stringify(error)}`)
			return false;
		}
	},

	async regFacilityGatewayInfo(facility: ISetFacilityGatewayInfo) {
		console.log(`regFacilityGateway ${JSON.stringify(facility)}`)
		try {
			await api.post(`/api2/facilityGateway/regFacilityGatewayInfo`, facility);
			return { status: 200 };
		} catch (error) {
			console.log(`addFac error : ${JSON.stringify(error)}`)
			return { status: error.status };
		}
	},
	async getFacilityGatewayInfoList(siteId: string, facType: string) {
		console.log(`getFacilityGatewayInfoList ${siteId}, ${facType}`);
		try {
			const ret = await api.post(`/api2/facilityGateway/getFacilityGatewayInfoList`, {
				siteId,
				facType,
			}
			);
			this.facilityGatewayList = ret.data;
			return true;
		} catch (error) {
			console.log(`getFacilityGatewayInfoList error : ${JSON.stringify(error)}`)
			this.facilityGatewayList = null;
			return false;
		}
	},
	async getFacilityGatewayInfo(facId: string) {
		// api
		console.log(`getFacilityGatewayInfo`);
		try {
			const ret = await api.post(`/api2/facilityGateway/getFacilityGatewayInfo`, {
				facId
			});
			this.facilityGatewayInfo = ret.data;
		} catch (error) {
			console.log(`getFacilityGatewayInfo error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async delFacilityGateway(facId: string) {
		console.log(`delFacilityGateway ${facId}`);
		try {
			await api.post(`/api2/facilityGateway/removeFacilityGatewayInfo`, {
				facId
			});
			return true;
		} catch (error) {
			console.log(`delFacilityGateway error : ${JSON.stringify(error)}`)
			return false;
		}
	},

	async getFacilityLampHistory(facId: string, daysAgo: string) {
		console.log(`getFacilityLampHistory`);
		try {
			const ret = await api.post(`/api2/facility/getFacilityLampHistory`, {
				facId,
				daysAgo
			});
			this.facilityCurrent = ret.data;
			return true;
		} catch (error) {
			this.facilityCurrent = null;
			return false;
		}
	},
	//상태확인 (실행중인지)
	async getCmdRunState(facId: string, subId: string) {
		console.log(`getFacilityLampHistory`);
		try {
			const ret = await api.post(`/api2/facility/getCmdRunState`, {
				facId,
				cmdType: this.cmdType,
				subId
			});
			this.facilityCmdState = ret.data.state
			return true;
		} catch (error) {
			return false;
		}
	},
	async getScheduleUpdateRunState(facId: string, subId: string) {
		console.log(`getFacilityLampHistory`);
		try {
			const ret = await api.post(`/api2/facility/getScheduleUpdateRunState`, {
				facId,
				cmdType: this.cmdType,
				subId
			});
			this.facilityUpdateCount = ret.count
			return true;
		} catch (error) {
			return false;
		}
	}
})

export default facilityStore;