// 사이트 관리 - 사이트 수정 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import SiteAdd from '../../../components/slagger/system/SiteAdd';

const SiteForm = () => {
  console.log('SiteForm')
  return(
  <>
    <Helmet>
      <title>SiteForm</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <SiteAdd />
      </Container>
    </Box>
  </>
);
    };

export default SiteForm;
