//상단 네비게이션 바( 시계, 로그아웃 등 ...)

import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  Avatar,
  AppBar,
  Box,
  Hidden,
  IconButton,
  Button,
  TextField,
  Toolbar,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Divider,
  SelectChangeEvent,
  Dialog,
  Select,
  FormControl,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from './Logo';
import { useStores } from '../state';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive'
import Space from 'components/atom/Space';
import systemStore from 'state/system';
import Clock from 'react-live-clock';
import { borderRadius } from '@mui/system';
import { User as UserIcon } from 'react-feather';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import { Target as TargetIcon } from 'react-feather';
import { LogOut as LogOutIcon } from 'react-feather';
import siteStore from 'state/site';
import styles from 'components/facilityGroup/style.module.css'
import { ISiteDetail, ISiteListInUser } from 'model';
const Languages = [
  { value: 'kr', label: '한국어' },
  { value: 'en', label: 'English' },
];

interface DashboardNavbarProps {
  onMobileNavOpen: () => void;
};
const DashboardNavbar = observer(({ onMobileNavOpen }: DashboardNavbarProps) => {
  const { userStore } = useStores();
  const [notifications] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem('language'));
  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openSiteSelectDialog, setOpenSiteSelectDialog] = useState(false);
  const [openSiteSelectByAdminDialog, setOpenSiteSelectByAdminDialog] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [siteId, setSiteId] = useState('0');
  const [siteName, setSiteName] = useState('');
  const [siteLevel, setSiteLevel] = useState('');
  const [siteList, setSiteList] = useState([]);

  useEffect(() => {
    if (siteId != '0') {
      setDisableButton(false);
    }
    else {
      setDisableButton(true);
    }
  }, [siteId]);

  useEffect(() => {
    if (siteList.length <= 0) {
      return;
    }
    setSiteId(siteList[0].siteId);
    setSiteName(siteList[0].siteName);
    setSiteLevel(siteList[0].siteLevel);
  }, [siteList]);

  useEffect(() => {
    // const tmpSiteList:ISiteDetail = {siteId: "0", siteName : "선택해 주세요",description : "",
    //   userIdMaintenance : [], userIdManager : [], address : '', zipCode : '',
    //   admCode : '', admCodeType : '', enable : '', userName : '', hpNo : ''}

    setSiteList([...siteStore.siteList])
  }, [siteStore.siteList]);

  useEffect(() => {
    // const tmpSiteList:ISiteDetail = {siteId: "0", siteName : "선택해 주세요",description : "",
    //   userIdMaintenance : [], userIdManager : [], address : '', zipCode : '',
    //   admCode : '', admCodeType : '', enable : '', userName : '', hpNo : ''}
    if (siteStore.userList[0]?.siteList)
      setSiteList([...siteStore.userList[0].siteList])
  }, [siteStore.userList]);
  // const handleChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    console.log(`hangleChangeLanguage event ${event.target.value}`)
    setLanguage(event.target.value);
    localStorage.setItem('language', event.target.value);
    i18n.changeLanguage(event.target.value)
  }

  console.log(`language : ${localStorage.getItem('language')}`);
  console.log(`user : ${userStore.name}`);

  //계정관리로 이동
  const navigatemyAccount = () => {
    navigate('/slager/myaccount');
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSaveSiteInfo = async () => {
    userStore.saveSiteInfo(siteId, siteName, siteLevel, String(siteList.length));
    setOpenSiteSelectDialog(false);
    setOpenSiteSelectByAdminDialog(false);
    navigate('/slager/dashboard', { replace: true });
    location.reload();
  };

  //사이트 이동(siteId)
  const handleChangeSiteId = (event: SelectChangeEvent) => {
    const ret: ISiteListInUser = siteStore.userList[0]?.siteList?.find((item: any, index: number) => {
      return item.siteId == event.target.value;
    });
    setSiteId(ret.siteId);
    setSiteName(ret.siteName);
    setSiteLevel(ret.siteLevel);
  };

  //사이트 이동(admin)
  const handleChangeSiteIdByAdmin = (event: SelectChangeEvent) => {
    const ret: ISiteDetail = siteList.find((item: any, index: number) => {
      return item.siteId == event.target.value;
    });
    console.log('handleChange : ', JSON.stringify(ret))
    setSiteId(ret.siteId);
    setSiteName(ret.siteName);
    setSiteLevel('9200');
  };

  //사이트 변경 dialog 닫기
  const handleSiteSelectDialogClose = () => {
    console.log('handleChange : cancel')
    setOpenSiteSelectDialog(false);
  };
  //사이트 변경 dialog 닫기 (admin)
  const handleSiteSelectByAdminDialogClose = () => {
    console.log('handleChange admin: cancel')
    setOpenSiteSelectByAdminDialog(false);
  };


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (

    // <AppBar position="fixed" open={open}>
    <AppBar>
      <Toolbar variant="dense">
        <Box sx={{ width: 2 }}></Box>

        {/*} <IconButton sx = {{right:'12px'}}color="inherit" onClick={onMobileNavOpen} size="small">
            <MenuIcon />
         </IconButton>*/}

        <RouterLink to="/">
          <Logo width="25" height="25" />
        </RouterLink>

        <Box sx={{ width: 5 }}></Box>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>

        <Typography>
          Slager
        </Typography>
        {Number(userStore.siteCount) > 1 &&
          <IconButton color="inherit" onClick={
            async () => {
              if (userStore.isAdmin()) { // super user
                await siteStore.getSiteList(userStore.getId(), "10", "1");

                if (siteStore.siteList.length > 1) {
                  setOpenSiteSelectByAdminDialog(true);
                }
              }
              else {
                await siteStore.getSiteListByUser(userStore.getId());
                if (siteStore.userList[0].siteList.length > 1) {
                  setOpenSiteSelectDialog(true);
                }
              }
            }}
            size="small"
            sx={{ left: '68px' }}>
            <TargetIcon />
          </IconButton>
        }

        <Hidden lgDown>
          {userStore.siteName &&
            <Typography
              sx={{
                position: 'relative', left: 70, fontSize: 25
              }}
            >
              {userStore.siteName}
            </Typography>
          }
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ // 시계
          position: 'fixed',
          fontSize: 23,
          right: '46.3%'

        }}>
          {!isMobile &&
            <div>
              <Box sx={{ position: 'relative', fontSize: 15, right: '14%', marginBottom: '-5%' }}>
                <Clock format={'YYYY년 MM월 DD일'} ticking={true} timezone={'Asia/Seoul'} />
              </Box>
              <Box>
                <Clock format={' HH:mm:ss'} ticking={true} timezone={'Asia/Seoul'} />
              </Box>
            </div>
          }
        </Box>
        {/* <TextField 
          name="Language"
          onChange={handleChangeLanguage}
          required
          select
          SelectProps={{ 
            native: true,
            disableUnderline:true,
          }}
          value={language}
          variant="standard"
          sx={{ 
            select : { color:'white'},
            paper : { color:'black'}
          }}
        >
          {Languages.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField> */}
        {/* <IconButton color="inherit" size="large">
          <PersonIcon onClick={() => {
            console.log('click go member')
            navigate('/slager/memberform')
          }
          } />
        </IconButton> */}

        {/* <Button
            color="primary"
            size="medium"
            variant="contained"
            onClick={async () => {
              console.log('apply cache')
              await systemStore.reworkCacheMap();
              console.log('applied cache')
            }}
          >
            캐시 업데이트
          </Button> */}

        {/*<Button //계정관리 버튼
            color="primary"
            size="medium"
            variant="contained"
            onClick = {navigatemyAccount}
          ><UserIcon/> 
          </Button>

          <Typography
          sx={{fontSize:18}}
        >
          {userStore.name}
        </Typography>*/}
        {!isMobile &&
          <Button
            sx={{ color: '#FFFFFF', width: '40px', minWidth: '0' }}
            onClick={() => {
              console.log('click go logout')
              userStore.logout();
              navigate('/login')
            }}>
            <LogOutIcon style={{ width: '20px', height: '20px' }} />
          </Button>
        }
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={{ color: '#FFFFFF' }}
        >
          {userStore.name}
          <ChevronDownIcon />
        </Button>

        {/* <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        > */}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          onResize={undefined}
          onResizeCapture={undefined}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-end' ? 'left top' : 'left bottom',
              }}
            >

              <Paper sx={{ justifyContent: "center" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  // onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={navigatemyAccount}><UserIcon />&ensp;계정 관리</MenuItem>
                    {isMobile &&
                      <MenuItem onClick={() => {
                        console.log('click go logout')
                        userStore.logout();
                        navigate('/login')
                      }}>
                        <LogOutIcon />&ensp;로그아웃
                      </MenuItem>
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {/*<Typography>
          &nbsp;&nbsp;
        </Typography>
        <Space></Space>
        {userStore.siteName &&
        <Typography
        >
          ({userStore.siteName})
        </Typography>
        }*/}
        {/*<IconButton color="inherit" size="large">
          <InputIcon onClick={() => {
            console.log('click go logout')
            userStore.logout();
            navigate('/login')
          }
          } />
        </IconButton>*/}


        {/* 사이트 선택 dialog */}
        <Dialog
          open={openSiteSelectDialog}
          onClose={handleSiteSelectDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ padding: '25px 29px', pb: '10px' }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600 }}>• 사이트 선택</Typography>
            <Space></Space>
            <Box sx={{}}>
              <FormControl sx={{ minWidth: '240px' }}>
                <Select
                  displayEmpty
                  native
                  value={siteId}
                  onChange={handleChangeSiteId}
                  sx={{
                    fontSize: 17,
                    "&:hover":
                    {
                      "&& fieldset": { border: "2px solid #0C3149" }
                    }
                  }}
                >
                  {
                    siteList?.map((item: any, index: number) => {
                      return (
                        <option style={{ padding: 0 }} value={item.siteId}>{item.siteName}</option>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: '13px' }}>
            <Button className={styles.primaryButton} size="small" variant="outlined" color="error" onClick={handleSiteSelectDialogClose}>취소</Button>
            <Button className={styles.primaryButton} size="small" variant="outlined" color="primary" disabled={disableButton} onClick={handleSaveSiteInfo} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>

        {/* 사이트선택dialog Admin */}
        <Dialog
          open={openSiteSelectByAdminDialog}
          onClose={handleSiteSelectByAdminDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ padding: '25px 29px', pb: '10px' }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600 }}>• 사이트 선택</Typography>
            <Space></Space>
            <Box sx={{}}>
              <FormControl sx={{ minWidth: '240px' }}>
                <Select
                  native
                  value={siteId}
                  onChange={handleChangeSiteIdByAdmin}
                  sx={{
                    fontSize: 17,
                    "&:hover":
                    {
                      "&& fieldset": { border: "2px solid #0C3149" }
                    }
                  }}
                >{
                    siteList?.map((item: any, index: number) => {
                      return (
                        <option style={{ padding: 0 }} value={item?.siteId}>{item?.siteName}</option>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: '13px' }}>
            <Button className={styles.primaryButton} size="small" variant="outlined" color="error" onClick={handleSiteSelectByAdminDialogClose}>취소</Button>
            <Button className={styles.primaryButton} size="small" variant="outlined" color="primary" disabled={disableButton} onClick={handleSaveSiteInfo} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </AppBar>

  );
});

// DashboardNavbar.propTypes = {
//   onMobileNavOpen: PropTypes.func
// };

export default DashboardNavbar;
