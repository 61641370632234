import { observable, computed } from 'mobx';
import { api, q } from '../api';
import { IFacInfo } from '../model';
import { IAlarm } from '../model/alarm';

// export let isLoggedIn = false;

export interface IDashboardStore {
	test: string;
	facs:IFacInfo[];
	facInfo : IFacInfo;
	uplinkInfo : any;
	devInfo : any;

	getTblAlarm:(perPage:string, page:string) => any;
	getTblAlarm2:(alarm:IAlarm, perPage:string, page:string) => any;
	getDetail:(facId : string) => any;
	getListAll:(perPage:string, page:string) => any;
	getListAllWithParm:(alarm:IAlarm, perPage:string, page:string) => any;
}
export const dashboardStore = observable<IDashboardStore>({
	test: '',
	facs : [],
	facInfo: null,
	uplinkInfo: null,
	devInfo: null,

	async getTblAlarm(perPage: string, page: string) {
		const ret = await api.get(`/api/dashboard/tblAlarm${q({
			perPage,
			page,
		})}`);
		// console.log(`getTblAlarm ${JSON.stringify(ret)}`)
		this.facs = ret.data.contents;
	},
	async getTblAlarm2(alarm: IAlarm, perPage: string, page: string) {
		let facType = alarm.facType;
		let facNo = alarm.facNo;
		let locCode = alarm.locCode;
		let alarmStatus = alarm.alarmStatus;
		if (alarm.facType == '0') {
			facType = '';
		}
		if (alarm.facNo == '0') {
			facNo = '';
		}
		if (alarm.locCode == '0') {
			locCode = '';
		}
		if (alarm.alarmStatus == '0') {
			alarmStatus = '';
		}

		const ret = await api.get(`/api/dashboard/tblAlarm${q({
			facType,
			facNo,
			locCode,
			alarmStatus,
			perPage,
			page,
		})}`);
		this.facs = ret.data.contents;
	},
	async getDetail(facId: string) {
		const ret = await api.get('/api/dashboard/listDetail?facId=' + facId);
		console.log(`getDetail ${JSON.stringify(ret)}`)
		this.facInfo = ret.fac;
		this.uplinkInfo = ret.uplink;
		this.devInfo = ret.dev;
	},
	async getListAll(perPage: string, page: string) {
		const ret = await api.get(`/api/dashboard/listAll${q({
			perPage,
			page,
		})}`);
		console.log(`getlistAll ${JSON.stringify(ret)}`)
		this.facs = ret;
		console.log(`getlistAll this ${JSON.stringify(this.facs)}`)
	},
	async getListAllWithParm(alarm: IAlarm, perPage: string, page: string) {
		let facType = alarm.facType;
		let facNo = alarm.facNo;
		let locCode = alarm.locCode;
		let alarmStatus = alarm.alarmStatus;
		if (alarm.facType == '0') {
			facType = '';
		}
		if (alarm.facNo == '0') {
			facNo = '';
		}
		if (alarm.locCode == '0') {
			locCode = '';
		}
		if (alarm.alarmStatus == '0') {
			alarmStatus = '';
		}

		const ret = await api.get(`/api/dashboard/listAll${q({
			facType,
			facNo,
			locCode,
			alarmStatus,
			perPage,
			page,
		})}`);
		console.log(`getListAllWithParm ${JSON.stringify(ret)}`)
		this.facs = ret;
		console.log(`getListAlWithParm  this ${JSON.stringify(this.facs)}`)
	},
})

export default dashboardStore;
