import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as ReactLogo } from "./logo.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const fade = (primaryColor:any, secondaryColor:any) => keyframes`
0% {
fill:${primaryColor};
}
50% {
 fill:${secondaryColor};
}
100%{
  fill:${primaryColor};
}
`;

const pulse = keyframes`
0% {
  transform: scale(0);
  opacity: 1;
  transform-origin: center;
}
100% {
  transform: scale(4.5);
  opacity: 0;
  transform-origin: center;
}
`;

interface IStyledLogo {
  primaryColor:string,
  secondaryColor:string,
}
const StyledLogo = styled(ReactLogo)`
  animation: ${rotate} infinite 20s linear;
  height: 25rem;
  width: 25rem;
  display: inline-block;
  margin: auto;
  .lines {
    animation: ${(props:IStyledLogo) => fade(props.primaryColor, props.secondaryColor)}
      infinite 8s linear;
  }
  .circle {
    animation: ${pulse} infinite 4s linear;
    &:hover {
      animation-play-state: paused;
      cursor: pointer;
    }
  }
`;
const circlePulse = (colorOne:any, colorTwo:any) => keyframes`
0% {
  fill:${colorOne};
  stroke-width:4px
}
50% {
  fill:${colorTwo};
  stroke-width:2px
}
100%{
  fill:${colorOne};
  stroke-width:4px
}
`;
interface IStyledCircle {
  colorOne:string,
  colorTwo:string,
}
const StyledCircle = styled.svg`
  margin: auto;
  display: inline-block;
`;
interface IStyledInnerCircle {
  colorOne:string,
  colorTwo:string,
}
const StyledInnerCircle = styled.circle`
  animation: ${(props:IStyledInnerCircle) => circlePulse(props.colorOne, props.colorTwo)} infinite 4s
    linear;
`;

const circleOne = (
  // <StyledCircle colorOne="red" colorTwo="green" height="100" width="100">
  <StyledCircle >
    <StyledInnerCircle
      colorOne="palevioletred"
      colorTwo="mediumslateblue"
      cx="50"
      cy="50"
      r="40"
      stroke="black"
      stroke-width="3"
    />
  </StyledCircle>
);
const circleTwo = (
  // <StyledCircle colorOne="red" colorTwo="green" height="100" width="100">
  <StyledCircle >
    <StyledInnerCircle
      colorOne="tomato"
      colorTwo="hotpink"
      cx="10"
      cy="10"
      r="5"
      stroke="black"
      stroke-width="1"
    />
  </StyledCircle>
);

const FacilityLine = () => {
  console.log('FacilityLine')
  return(
  <>
    <Helmet>
      <title>FacilityLine</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
      <div
      style={{
        display: "flex",
        flexWrap: "wrap"
      }}
    >
      <div style={{ width: "200px", margin: "auto" }}>
        {circleTwo}
        {circleOne}
        {circleOne}
        {circleTwo}
      </div>
      </div>
      </Container>
    </Box>
  </>
);
    };

export default FacilityLine;
