// 연간스케줄 설정 팝업 

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    Input,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Popover,
} from '@mui/material';
import { IFacilityInfo, ILineSet, ISetSiteSunsetDetail, ISetSunsetTimeList } from 'model';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import lineStore from 'state/line';
import { useStores } from 'state';
import { useLocation } from 'react-router';
import sunsetStore from 'state/sunset';
import { getThisYear } from 'utils/util';

import { ButtonBlue, ButtonGreen, ChevronRightIcon } from 'components/admin/system/Styled';
import theme from 'theme';

import styles from 'components/facilityGroup/style.module.css'
import { HelpOutline } from '@mui/icons-material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { observer } from 'mobx-react';
import moment from 'moment';
import SunsetCitySelectDialog from 'components/atom/SunsetCitySelectDialog';

interface ICombineArr {
    chunkId: string,
    chunkSubIdx: string,
    startDate: string,
    endDate: string,
    date: string,
    ctSunriseTime: string,
    ctSunsetTime: string,
    civile: string,
    civilm: string,
    onRatioTime: any,
    offRatioTime: any,
    onTime: any,
    offTime: any,
    description: string,
    ratio: any
}

interface SunsetSchedleUpdatePopupProps {
    lineInfo?: any,
    onClose?: () => void;
    open?: boolean;
    selectCityName?: string;
    selectCityId?: any;
    selectAreaId?: any;
    scheduleChunkId?: (chunkId: any) => any;
    saveChunkId?: string;
}

const SunsetSchedleUpdatePopup = observer(({ lineInfo, onClose, open, selectCityName, selectCityId, selectAreaId, scheduleChunkId, saveChunkId }: SunsetSchedleUpdatePopupProps) => {
    const { facilityStore, userStore } = useStores();
    const location = useLocation();
    const [onHour, setOnHour] = useState<string>("");
    const [onMin, setOnMin] = useState<string>("");
    const [offHour, setOffHour] = useState<string>("");
    const [offMin, setOffMin] = useState<string>("");
    const [sunSetTimes, setSunSetTimes] = useState<any[]>([]);
    const [isTableVisible, setIsTableVisible] = useState(true);
    const [values, setValues] = useState<ILineSet>(
        {
            siteId: '',
            siteName: '',
            groupId: '',
            groupName: '',
            lineId: '',
            lineName: '',
            description: '',
            chunkId: '-1',
            chunkName: '',
            operationMode: 'N',
            offTime: '',
            onTime: '',
        });

    const [openSunsetDialog, setOpenSunsetDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSunsetCitySelectDialog, setShowSunsetCitySelectDialog] = useState(false);
    const [combineHeader, setCombineHeader] = useState<any>();
    const [combineArr, setCombineArr] = useState<ICombineArr[]>(null);
    const [currentMonthData, setCurrentMonthData] = useState([]);
    const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(0);
    const [areaId, setAreaId] = useState('1');
    const [cityId, setCityId] = useState('');
    const [cityName, setCityName] = useState('');
    const [onTimeDisabled, setOnTimeDisabled] = useState(true)//수동
    const [offTimeDisabled, setOffTimeDisabled] = useState(true)//수동
    const [onTimeDisabledAll, setOnTimeDisabledAll] = useState(true)//전체
    const [offTimeDisabledAll, setOffTimeDisabledAll] = useState(true)//전체
    const [dialogOpen, setDialogOpen] = useState(false)
    const [outFacility, setOutFacility] = useState<IFacilityInfo[]>([]);

    // 수동버튼 초기화
    useEffect(() => {
        if (openSunsetDialog) {
            setOffTimeDisabled(true);
            setOnTimeDisabled(true);
        }
    }, [openSunsetDialog]);

    // dialog닫고 combineHeader랑 combineArr 초기화
    const handleSunsetDialogClose = () => {
        onClose();
        setDialogOpen(false)
        setCombineHeader(null)
        setCombineArr(null)
    };

    // value값 설정하고 고정시간 설정에 필요한 함수들
    useEffect(() => {
        if (open) { // lineInfo의 일부만 불러오는건 안됨. 불러올때는 lineInfo 다 불러오자. 
            console.log("---- useEffect LineInfo ----", JSON.stringify(lineInfo)) //lineInfo요소를 확인할 때(콘솔을 확인해서 나오는 애들 다 넣어주면된다.)
            setValues({
                ...values,
                siteId: lineInfo.siteId,
                siteName: lineInfo.siteName,
                groupId: lineInfo.groupId,
                groupName: lineInfo.groupName,
                lineId: lineInfo.lineId,
                lineName: lineInfo.lineName,
                description: lineInfo.description,
                chunkId: lineInfo.chunkId,
                chunkName: lineInfo.chunkName,
                operationMode: lineInfo.operationMode,
                offTime: lineInfo.offTime,
                onTime: lineInfo.onTime,
            });
            const onArray = lineInfo.onTime?.split(':');
            const tmpOnHour = onArray?.[0];
            console.log("tmp tmpOnHour value =====", tmpOnHour)
            const tmpOnMin = onArray?.[1];
            console.log("tmp tmpOnMin value =====", tmpOnMin)
            setOnMin(tmpOnMin)
            setOnHour(tmpOnHour)

            const offArray = lineInfo.offTime?.split(':');
            const tmpOffHour = offArray?.[0];
            console.log("tmp OffHour value ===== ", tmpOffHour)
            const tmpOffMin = offArray?.[1];
            console.log("tmp OffMin value ===== ", tmpOffMin)
            setOffMin(tmpOffMin)
            setOffHour(tmpOffHour)
        }
    }, [open, onClose]);


    //팝업이 열리면 combineHeader, combineArr 만드는 함수 
    const doOpenSunsetDialog = async (_cityName: string, _cityId: string, _areaId: string) => {
        console.log(`doOpenSunsetDialog cityname = ${selectCityName}, id = ${selectCityId}  areaId = ${selectAreaId}`)
        console.log(`doOpenSunsetDialog cityname = ${_cityName}, id = ${_cityId}  areaId = ${_areaId}`)
        setCityId(_cityId);
        setCityName(_cityName);
        setAreaId(_areaId);
        const year = getThisYear();
        console.log("sunset11111")
        await sunsetStore.getSunsetDataList(_cityId, year.toString());
        console.log("sunset22222")
        if (sunsetStore.sunsetDataList?.length == 0) {
            console.log("sunset33333")
            setLoading(true);
            await sunsetStore.getSunsetYearFull(_cityName, _areaId, year.toString());
            await sunsetStore.getSunsetDataList(_cityId, year.toString());
            setLoading(false);
        }
        console.log("sunset44444")
        //맨 처음 설정할 때
        if (lineInfo.chunkId != '-1') {
            console.log("sunset55555")
            console.log(`doOpenSunsetDialog use already made chunk`);
            const ret: boolean = await sunsetStore.getSiteSunsetDetailList(sunsetStore.siteSunsetList[0]?.siteId, sunsetStore.siteSunsetList[0]?.chunkId, null);
            if (ret) {
                const newSunsetArrHeader: any = {
                    userId: sunsetStore.getSunsetDetail.userId,
                    siteId: sunsetStore.getSunsetDetail.siteId,
                    chunkId: sunsetStore.getSunsetDetail.chunkId,
                    areaId: sunsetStore.getSunsetDetail.areaId,
                    chunkName: sunsetStore.getSunsetDetail.chunkName,
                    chunkYear: sunsetStore.getSunsetDetail.chunkYear,
                    cityId: sunsetStore.getSunsetDetail.cityId,
                    cityName: sunsetStore.getSunsetDetail.cityName,
                    description: sunsetStore.getSunsetDetail.description,
                    timeUpdate: sunsetStore.getSunsetDetail.timeUpdate,
                    count: sunsetStore.sunsetDetailList?.length.toString(),
                    detail: []
                }
                const newSunsetArr = sunsetStore.sunsetDetailList.reduce((acc, item, index) => {
                    const newItem: ICombineArr = {
                        chunkId: item.chunkId,
                        chunkSubIdx: item.chunkSubIdx,
                        startDate: item.startDate,
                        endDate: item.endDate,

                        date: sunsetStore.sunsetDataList[index]?.date,
                        ctSunriseTime: sunsetStore.sunsetDataList[index]?.riseTime,
                        ctSunsetTime: sunsetStore.sunsetDataList[index]?.setTime,

                        onTime: item.onTime,
                        offTime: item.offTime,
                        description: item.description,
                        civile: sunsetStore.sunsetDataList[index]?.civile,
                        civilm: sunsetStore.sunsetDataList[index]?.civilm,
                        onRatioTime: item.onRatioTime,
                        offRatioTime: item.offRatioTime,
                        ratio: item.ratio
                    }
                    acc.push(newItem)
                    console.log(`doOpenSunsetDialog @@@@@@@@@@@@@ newSun item ${JSON.stringify(item)}`);
                    return acc;
                }, []);
                console.log(`sunset77777`);
                setCombineHeader(newSunsetArrHeader)
                setCombineArr(newSunsetArr)
                console.log("setcombineArr", newSunsetArr)
            }
        }
        //기존에 값이 있을 때
        else {
            console.log("sunset66666")
            console.log(`doOpenSunsetDialog create chunk`);
            const ret: boolean = await sunsetStore.getSiteSunsetDetailList(userStore.siteId, lineInfo.chunkId, null);
            if (!ret) {
                const newSunsetArrHeader: any = {
                    userId: userStore.getId(),
                    siteId: userStore.siteId,
                    chunkId: values.chunkId,
                    areaId: areaId,
                    chunkName: 'chunk-s' + userStore.siteId + 'g' + values.groupId + 'l' + values.lineId,
                    chunkYear: getThisYear().toString(),
                    cityName: _cityName,
                    cityId: _cityId,
                    description: values.description,
                    timeUpdate: '',
                    count: '',
                    //detail: []
                }
                if (!combineArr) {
                    const newSunsetArr = sunsetStore.sunsetDataList.reduce((acc, item, index) => {
                        const dateArray = item.date.split('-');
                        console.log("ddcaca", dateArray)
                        const newItem: ICombineArr = {
                            chunkId: '',
                            chunkSubIdx: '',
                            startDate: `${dateArray[0]}/${dateArray[1]}`,
                            endDate: `${dateArray[0]}/${dateArray[1]}`,
                            date: item?.date,
                            ctSunriseTime: item?.riseTime,
                            ctSunsetTime: item?.setTime,
                            onTime: item.setTime,
                            offTime: item.riseTime,
                            description: '',
                            civile: item?.civile,
                            civilm: item?.civilm,
                            onRatioTime: item.onRatioTime,
                            offRatioTime: item.offRatioTime,
                            ratio: '70'
                        }
                        acc.push(newItem)
                        console.log("sunset6666611111 index = ", index)
                        return acc;
                    }, []);

                    console.log(`doOpenSunsetDialog ~~~~~~~~ newSunsetArr ${JSON.stringify(newSunsetArr)}`);
                    console.log(`aaaaa66666`);
                    setCombineHeader(newSunsetArrHeader)
                    console.log(`doOpenSunsetDialog ~~~~~~~~ newSunsetArrHeader ${JSON.stringify(combineHeader)}`);
                    console.log("sunset6666611111")
                    setCombineArr(newSunsetArr)

                }
                else {
                    const newSunsetArrHeaderChange: any = {
                        userId: userStore.getId(),//사용자 Id
                        siteId: userStore.siteId,//사이트 Id
                        chunkId: values.chunkId,// chunkId
                        areaId: areaId,
                        chunkName: 'chunk-s' + userStore.siteId + 'g' + values.groupId + 'l' + values.lineId,
                        chunkYear: getThisYear().toString(),
                        cityName: combineHeader.cityName,//도시이름
                        cityId: combineHeader.cityId,//도시id
                        description: values.description,//설명
                        timeUpdate: '',
                        count: '',
                        detail: []
                    }
                    const newSunsetArr = sunsetStore.sunsetDataList.reduce((acc, item, index) => {
                        const newItem: ICombineArr = {
                            chunkId: combineArr[index].chunkId,
                            chunkSubIdx: '',
                            date: item.date,//날짜
                            ctSunriseTime: item.riseTime,//일출
                            ctSunsetTime: item.setTime,//일몰
                            civilm: item.civilm,//시민박명(일출)
                            civile: item.civile,//시민박명(일몰)
                            startDate: combineArr[index].startDate,//시작날짜
                            endDate: combineArr[index].endDate,//끝나는 날짜
                            onTime: item.setTime,//소등
                            offTime: item.riseTime,//점등
                            description: '',//설명
                            onRatioTime: item.onRatioTime,//일출(추천시간)
                            offRatioTime: item.offRatioTime,//일몰(추천시간)
                            ratio: item.ratio// 적용률
                        }
                        acc.push(newItem)
                        return acc;
                    }, []);

                    console.log(`doOpenSunsetDialog ~~~~~~~~ newSunsetArr ${JSON.stringify(newSunsetArr)}`);
                    console.log(`aaaaa666661111`);
                    setCombineHeader(newSunsetArrHeaderChange)
                    console.log("sunset6666622222")
                    setCombineArr(newSunsetArr)
                }
            }
        }
        setDialogOpen(true)
    }

    const handleSunsetDialogOpen = async () => {
        await sunsetStore.getSiteSunsetList(userStore.siteId, lineInfo.chunkId, null);
        if (lineInfo.chunkId != '-1' || lineInfo.chunkId == null) {
            setDialogOpen(true)
            onClose();
            doOpenSunsetDialog('', sunsetStore.siteSunsetList[0].cityId, sunsetStore.siteSunsetList[0].areaId);
        }
        else {
            console.log("도시선택 Dialog 오픈됨")
            setShowSunsetCitySelectDialog(true);
            onClose();
        }
    };
    // 팝업 열리면 handleSUnsetDialogOpen 함수 실행
    useEffect(() => {
        if (open)
            handleSunsetDialogOpen();
    }, [open]);

    const getCivilTime = (civilTime: string, curTime: string, ratio: string) => {
        if (!civilTime || !curTime) {
            return;
        }
        //시민박명
        const civilArr = civilTime.split(':');
        const civilHour = civilArr[0];
        const civilMin = civilArr[1];

        //일출일몰
        const curArr = curTime.split(':');
        const curHour = curArr[0];
        const curMin = curArr[1];

        const tmpCivil = (Number(civilHour) * 60 + Number(civilMin)); // 1
        const tmpCur = (Number(curHour) * 60 + Number(curMin)); // 2

        if (tmpCivil < tmpCur) {
            //일출
            const civilRise3 = (tmpCur - tmpCivil) // 3
            const civilRise4 = civilRise3 * (Number(ratio) / 100) //4
            const civilRise5 = tmpCivil + civilRise4 //5
            const hourRise = Math.floor(civilRise5 / 60)
            const minRise = Math.floor(civilRise5 % 60)
            const recTimeRise = `${hourRise.toString().padStart(2, '0')}:${minRise.toString().padStart(2, '0')}`;
            return recTimeRise;
        }
        else if (tmpCivil > tmpCur) {
            //일몰
            const civilSet3 = (tmpCivil - tmpCur) //3
            const civilSet4 = civilSet3 * (Number(ratio) / 100) //4
            const civilSet5 = tmpCivil - civilSet4 //5
            const hourSet = Math.floor(civilSet5 / 60)
            const minSet = Math.floor(civilSet5 % 60)
            const recTimeSet = `${hourSet.toString().padStart(2, '0')}:${minSet.toString().padStart(2, '0')}`;
            //console.log('추천 일몰시간',recTimeSet)
            return recTimeSet
        }
    }

    useEffect(() => {
        // 초기화 시 기본 비율 값으로 계산하여 설정
        const defaultRatio = '70'; // 기본 비율 값
        let initializedArr;

        if (lineInfo?.chunkId !== '-1') {
            initializedArr = combineArr?.map((item) => {
                // 만족하는 경우에만 기존 저장된 ratio로 계산식을 돌려줌
                if (item.ratio) {
                    item.onRatioTime = getCivilTime(item?.civile, item?.ctSunsetTime, item.ratio);
                    item.offRatioTime = getCivilTime(item?.civilm, item?.ctSunriseTime, item.ratio);
                } else {
                    item.ratio = defaultRatio;
                    item.onRatioTime = getCivilTime(item?.civile, item?.ctSunsetTime, item.ratio);
                    item.offRatioTime = getCivilTime(item?.civilm, item?.ctSunriseTime, item.ratio);
                }
                return item;
            });
        } else {
            initializedArr = combineArr?.map((item) => {
                //item.ratio = defaultRatio;
                item.onRatioTime = getCivilTime(item?.civile, item?.ctSunsetTime, item.ratio);
                item.offRatioTime = getCivilTime(item?.civilm, item?.ctSunriseTime, item.ratio);
                return item;
            });
        }
        // 변경이 있는 경우에만 setCombineArr 호출
        if (JSON.stringify(initializedArr) !== JSON.stringify(combineArr)) {
            setCombineArr(initializedArr);
        }
    }, [openSunsetDialog, combineArr]);

    // const handleSunsetDialogClose = () => {
    //     onClose();
    //     setDialogOpen(false)
    //     setCombineHeader(null)
    //     setCombineArr(null)
    // };

    //지역재선택 dialog 열림
    const handleSunsetTimeSelectDialogOpen = async () => {
        await sunsetStore.getSiteSunsetList(userStore.siteId, lineInfo.chunkId, null);
        setShowSunsetCitySelectDialog(true);
    };

    const handleApplySunsetTime = async () => { //일출일몰 시간 적용 
        console.log(`handleApplySunsetTime ${JSON.stringify(combineArr)}`);
        if (combineHeader == null && combineArr?.length == 0) {
            console.log(`handleApplySunsetTime sunsetDataList ${JSON.stringify(sunsetStore.sunsetDataList)}`);
            const combineHeader1: any = {
                userId: userStore.getId(),
                siteId: userStore.siteId,
                chunkId: values.chunkId,
                areaId: areaId,
                chunkName: values.chunkName,
                chunkYear: getThisYear().toString(),
                cityId: cityId,
                cityName: sunsetStore.sunsetDataList[0]?.cityName,
                description: values.description,
                timeUpdate: '',
                count: '',
                detail: []
            }
            const combineArr1 = sunsetStore.sunsetDataList.reduce((acc, item, index) => {
                const dateArray = item.date.split('-');
                console.log("ddcaca", dateArray)
                const newItem: ICombineArr = {
                    chunkId: '',
                    chunkSubIdx: '',
                    startDate: `${dateArray[0]}/${dateArray[1]}`,
                    endDate: `${dateArray[0]}/${dateArray[1]}`,
                    date: item.date,
                    ctSunriseTime: item.riseTime,
                    ctSunsetTime: item.setTime,
                    onTime: item.onRatioTime,
                    offTime: item.offRatioTime,
                    description: '',
                    civile: item.civile,
                    civilm: item.civilm,
                    onRatioTime: item.onRatioTime,
                    offRatioTime: item.offRatioTime,
                    ratio: item.ratio
                }
                acc.push(newItem)
                return acc;
            }, []);
            console.log(`aaaaa777777`);
            setCombineHeader(combineHeader1)
            setCombineArr(combineArr1);
        }
        else {
            const newSunsetArrHeader: any = {
                userId: combineHeader.userId,
                siteId: userStore.siteId,
                chunkId: values.chunkId,
                areaId: areaId,
                chunkName: 'chunk-s' + userStore.siteId + 'g' + values.groupId + 'l' + values.lineId,
                chunkYear: getThisYear().toString(),
                cityName: sunsetStore.sunsetDataList[0]?.cityName,
                cityId: cityId,
                description: values.description,
                timeUpdate: '',
                count: '',
                detail: []
            }
            const newCombineArr = combineArr.map((item: any, index: any) => {
                const dateArray = item?.date?.split('-');
                console.log("ddcaca", dateArray)
                console.log(`handleApplySunsetTime siteSunsetList=${JSON.stringify(item)} index=${index}`)
                item.chunkId = '';
                item.onTime = item?.onRatioTime;
                item.offTime = item?.offRatioTime;
                return item;
            });
            console.log(`aaaaa88888`);
            setCombineHeader(newSunsetArrHeader)
            setCombineArr(newCombineArr);
            console.log('==== combineArr SunsetApply ====', combineArr)
        }
    }
    console.log("combine", combineArr)

    const handleScheduleChunkId = (chunkId: any) => {
        scheduleChunkId(chunkId)
    }

    const handleSaveSunset = async () => { //일출일몰 시간 설정 -> 저장버튼
        console.log(`handleSaveSunset `);

        const line: ILineSet = {
            siteId: values.siteId,
            siteName: values.siteName,
            groupId: values.groupId,
            groupName: values.groupName,
            lineId: values.lineId,
            lineName: values.lineName,
            description: values.description,
            chunkId: values.chunkId == '' ? '-1' : values.chunkId,
            chunkName: values.chunkName,
            operationMode: values.operationMode == 'N' ? 'M' : values.operationMode,
            onTime: values.operationMode == 'O' ? `${onHour}:${onMin}` : '',
            offTime: values.operationMode == 'O' ? `${offHour}:${offMin}` : '',
        }
        const setSiteSunsetDetail: ISetSiteSunsetDetail = {
            siteId: userStore.siteId,
            chunkId: combineHeader.chunkId,
            chunkName: combineHeader.chunkName,
            areaId: combineHeader.areaId,
            cityId: combineHeader.cityId,
            cityName: combineHeader.cityName,
            userId: userStore.getId(),
            description: combineHeader.description,
            detail: [],
        }
        setSiteSunsetDetail.detail = combineArr.reduce((acc: any, item: any) => {
            const newItem: ISetSunsetTimeList = {
                startDate: item.date,
                endDate: item.date,
                onTime: item.onTime,
                offTime: item.offTime,
                description: '',
                ratio: item.ratio,
            }
            acc.push(newItem)
            return acc;
        }, []);

        setValues({
            ...values,
            chunkId: combineHeader.chunkId,
        });
        setCombineHeader(null)
        setCombineArr(null);
        console.log("values11", values)

        //값이 비어있거나 숫자가 하나만 입력되면 저장 안됨.
        if (combineArr.length === 0 || combineArr.some((item: any) =>
            item.offTime === ":" || !/^\d{2}:\d{2}$/.test(item.offTime) ||
            item.onTime === ":" || !/^\d{2}:\d{2}$/.test(item.onTime))
        ) {
            console.log("값을 확인해주세요");
            ToastsStore.warning("소등/점등 값을 확인해주세요.\n형식은 반드시 00:00 이여야 합니다.\n예시) 07:18(O) 7:18(X)");
            return;
        }
        const ret: any = await sunsetStore.setSiteSunsetDetailList(setSiteSunsetDetail);
        line.chunkId = ret.chunkId;
        console.log("restC", ret.chunkId)
        await lineStore.addLine(line);
        console.log(`SaveSunset setSiteSunsetDetail ${JSON.stringify(ret)}`)
        if (ret.result) {
            console.log('~~~~~~~~!!!!detail :', JSON.stringify(combineArr))
            console.log('~~~~~~~~!!!!detailSet :', JSON.stringify(setSiteSunsetDetail))
            setValues({
                ...values,
                chunkId: ret.chunkId,
            });
            ToastsStore.success("라인정보 수정에서 확인을 누르면 저장됩니다.")
            const timer = setInterval(() => {
                handleScheduleChunkId(ret.chunkId)
                console.log('ddff', ret.chunkId)
                clearInterval(timer);
                setDialogOpen(false)
                onClose();
            }, 1000)
        }
        else if (ret.status >= 400) {
            console.log(`SaveSunset ~~~~~~~~~~~~~~~~~~~~`)
            console.log('~~~~~~~~!!!!detailSet :', JSON.stringify(setSiteSunsetDetail))
            ToastsStore.warning("저장 실패")
        }
    };

    // 추천시간 계산
    const handleRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`ratio name=${event.target.name} value=${event.target.value}`);
        console.log(`ratio name=${JSON.stringify(currentMonthData)} value=${event.target.value}`);
        const newRatioTime = combineArr.map((item: any, index: any) => {
            console.log(`ratio name=${event.target.name} value1=${item.ratio}`);
            const parsedRatio = Number(event.target.value);
            item.ratio = event.target.value;
            if (!isNaN(parsedRatio) && parsedRatio >= -100 && parsedRatio <= 100) {
                item.ratio = parsedRatio.toString();
            } else {
                item.ratio = "0"; // 조건에 맞지않게 입력 될 경우 자동으로 0 으로 설정됨.
            }
            console.log(`Ratio =${item.ratio}`);
            item.onRatioTime = getCivilTime(item?.civile, item?.ctSunsetTime, item.ratio);
            item.offRatioTime = getCivilTime(item?.civilm, item?.ctSunriseTime, item.ratio);
            console.log(`onRatioTime=${item.onRatioTime}`);
            console.log(`offRatioTime=${item.offRatioTime}`);
            return item;
        });
        console.log(`handleOffCalibration newRatioTime =${JSON.stringify(newRatioTime)}`);

        console.log(`aaaaa101010`);
        setCombineArr(newRatioTime);
        console.log(`asdfaaaa nnnnnn=${JSON.stringify(newRatioTime)}`);
        console.log(`asdfaaaa ${JSON.stringify(combineArr)}`);
    };

    // 점등
    const handleOnTimeCalc = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`handleOffCalibration name=${event.target.name} value=${event.target.value}`);
        const [hourStr, minuteStr] = event.target.value.split(':');

        // 숫자 입력 최대치 설정
        if (hourStr === undefined || minuteStr === undefined) {
            console.error('Invalid input format');
            return;
        }
        const hour = parseInt(hourStr);
        const minute = parseInt(minuteStr);
        if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
            console.error('Invalid input value');
            return;
        }
        // 수동수정
        const newOnTime = combineArr.map((item: any) => {
            if (item.startDate === event.target.name) {
                return { ...item, onTime: event.target.value };
            }
            return item;
        });
        newOnTime.forEach((item: any, index: any) => {
            combineArr[index].onTime = item.onTime;
        });
        setSunSetTimes(newOnTime);
        const newSunsetDetail: any = combineArr;
        console.log("aaaaa New OFF TIME :", JSON.stringify(newOnTime))
        console.log("aaaaa New SunsetDetail :", JSON.stringify(newSunsetDetail))
        newSunsetDetail.detail = newOnTime;
        console.log("aaaaa 2222 New SunsetDetail :", JSON.stringify(newSunsetDetail))
        console.log(`aaaaa33333`);
        newSunsetDetail.description = 'aaa'
        setCombineArr(newSunsetDetail);

        // //전체수정
        if (!onTimeDisabled && !onTimeDisabledAll) {
            console.log("==== 소등 전체수정 ====")
            const updatedOnTime = newOnTime[0].onTime; // 첫 번째 수정된 offTime 가져오기
            const newSunsetDetails = {
                ...combineArr,
                detail: combineArr.map((item: any) => ({
                    ...item,
                    onTime: updatedOnTime,
                })),
            };
            console.log(`aaaaa44444`);
            const newSunsetDetailsArray = newSunsetDetails.detail;
            setCombineArr(newSunsetDetailsArray);
            console.log(`==== NEW sunsetDetail onTime !!! ==== ${JSON.stringify(newSunsetDetails)}`);
        }
        // 입력 커서 값 수정할 때 마다 뒤로 옮겨지는거 수정
        const input = event.target;
        if (!input) return;
        const { selectionStart } = input;
        queueMicrotask(() => {
            input.setSelectionRange(selectionStart, selectionStart);
        });
    };

    // 소등
    const handleOffTimeCalc = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`handleOffTimeCalc name=${event.target.name} value=${event.target.value}`);

        const [hourStr, minuteStr] = event.target.value.split(':');

        // 숫자 입력 최대치 설정
        if (hourStr === undefined || minuteStr === undefined) {
            return;
        }
        const hour = parseInt(hourStr);
        const minute = parseInt(minuteStr);
        if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
            console.error('Invalid input value');
            return;
        }
        // 수동수정
        const newOffTime = combineArr.map((item: any) => {
            if (item.startDate === event.target.name) {
                return { ...item, offTime: event.target.value };
            }
            return item;
        });
        newOffTime.forEach((item: any, index: any) => {
            combineArr[index].offTime = item.offTime;
        });

        setSunSetTimes(newOffTime);
        const newSunsetDetail: any = combineArr;
        console.log("aaaaa New OFF TIME :", JSON.stringify(newOffTime))
        console.log("aaaaa New SunsetDetail :", JSON.stringify(newSunsetDetail))
        newSunsetDetail.detail = newOffTime;
        console.log("aaaaa 2222 New SunsetDetail :", JSON.stringify(newSunsetDetail))
        console.log(`aaaaa33333`);
        newSunsetDetail.description = 'aaa'
        setCombineArr(newSunsetDetail);

        // //전체수정
        if (!offTimeDisabled && !offTimeDisabledAll) {
            console.log("==== 소등 전체수정 ====")
            const updatedOffTime = newOffTime[0].offTime; // 첫 번째 수정된 offTime 가져오기
            const newSunsetDetails = {
                ...combineArr,
                detail: combineArr.map((item: any) => ({
                    ...item,
                    offTime: updatedOffTime,
                })),
            };
            console.log(`aaaaa44444`);
            const newSunsetDetailsArray = newSunsetDetails.detail;
            setCombineArr(newSunsetDetailsArray);
            console.log(`==== NEW sunsetDetail onTime !!! ==== ${JSON.stringify(newSunsetDetails)}`);
        }
        // 입력 커서 값 수정할 때 마다 뒤로 옮겨지는거 수정
        const input = event.target;
        if (!input) return;
        const { selectionStart } = input;
        queueMicrotask(() => {
            input.setSelectionRange(selectionStart, selectionStart);
        });
    };

    const handleChangeManualOffTime = () => {
        console.log(`=============== offTime 수동 ===============`)
        setOffTimeDisabled(!offTimeDisabled);
    };

    const handleChangeManualOnTime = () => {
        console.log(`=============== onTime 수동 ===============`)
        setOnTimeDisabled(!onTimeDisabled);
    };

    const handleChangeAllOffTime = () => {
        console.log(`=============== offTime 전체 ===============`)
        setOffTimeDisabledAll(!offTimeDisabledAll);
    };

    const handleChangeAllOnTime = () => {
        console.log(`=============== onTime 전체 ===============`)
        setOnTimeDisabledAll(!onTimeDisabledAll);
    };


    //SunsetDialog 데이터 가져오는 함수들
    const newSunsetArr: any[] = [];

    for (let i = 1; i <= 12; i++) {
        const monthData = combineArr?.filter(item => {
            if (item?.date) {
                const dateArray = item.date.split('-');
                const month = parseInt(dateArray[0]);
                return month === i;
            }
            return false;
        });
        newSunsetArr.push(monthData);
    }

    const handleMonthClick = (monthIndex: number) => {
        setCurrentMonthIndex(monthIndex);
        if (monthIndex === 12) {
            // 전체 월 선택 시 모든 데이터를 보여줌
            setCurrentMonthData(combineArr);
        } else {
            // 특정 월 선택 시 해당 월에 대한 데이터만 필터링
            const selectedMonthData = newSunsetArr[monthIndex];
            let previousMonthIndex = monthIndex === 0 ? newSunsetArr?.length - 1 : monthIndex - 1;

            // 1월일 경우 이전 달을 12월로 설정하여 마지막 데이터를 가져오지 않음
            if (monthIndex === 0 || monthIndex === 12) {
                previousMonthIndex = monthIndex;
            } else {
                const lastDataOfPreviousMonth = newSunsetArr[previousMonthIndex][newSunsetArr[previousMonthIndex]?.length - 1];
                console.log("이전 달 마지막 데이터:", lastDataOfPreviousMonth);
                selectedMonthData.unshift(lastDataOfPreviousMonth);
            }

            // 12월이 아닌 경우 다음 달 첫 번째 데이터 추가
            if (monthIndex !== 11) {
                const firstDataOfNextMonth = newSunsetArr[monthIndex + 1][0];
                console.log("다음 달 첫 번째 데이터:", firstDataOfNextMonth);
                selectedMonthData.push(firstDataOfNextMonth);
            }

            setCurrentMonthData(selectedMonthData);
        }
    };

    //365x12 데이터 12개월로 쪼개줌
    useEffect(() => {
        if (combineArr?.length > 0) {
            if (currentMonthIndex === 12) {
                setCurrentMonthData(combineArr);
            } else {
                const selectedMonthData = newSunsetArr[currentMonthIndex];
                let previousMonthIndex = currentMonthIndex === 0 ? newSunsetArr?.length - 1 : currentMonthIndex - 1;

                if (currentMonthIndex === 0 || currentMonthIndex === 12) {
                    previousMonthIndex = currentMonthIndex;
                } else {
                    const lastDataOfPreviousMonth = newSunsetArr[previousMonthIndex][newSunsetArr[previousMonthIndex]?.length - 1];
                    selectedMonthData.unshift(lastDataOfPreviousMonth);
                }
                if (currentMonthIndex !== 11) {
                    const firstDataOfNextMonth = newSunsetArr[currentMonthIndex + 1][0];
                    selectedMonthData.push(firstDataOfNextMonth);
                }
                setCurrentMonthData(selectedMonthData);
            }
        }
    }, [combineArr, currentMonthIndex]);

    //SunsetDialog 오픈시 바로 1월데이터 보임.
    useEffect(() => {
        if (openSunsetDialog) {
            const selectedMonthData = newSunsetArr[0];
            setCurrentMonthData(selectedMonthData);
        }
    }, [openSunsetDialog]);

    const getFacilityAllOutofLine = async () => {
        await facilityStore.getFacilityInfoListOutLine(userStore.siteId, null, null, null);
        {
            console.log(`facilityLineFormImp getFacility facilityStore.outFacilityList ${JSON.stringify(facilityStore.outFacilityList)}`);
            setOutFacility(facilityStore.outFacilityList?.filter(facility => facility.lineId != lineInfo?.lineId));
        }
    }

    useEffect(() => {
        if (lineInfo?.lineId) {
            getFacilityAllOutofLine();
        }
    }, [onClose]);

    //doOpenSunsetDialog 오픈
    const onSunsetCity = (_cityName: string, _cityId: string, _areaId: string) => {
        doOpenSunsetDialog(_cityName, _cityId, _areaId);
    };

    return (
        <>
        {/* 연간스케줄 팝업 */}
            <SunsetCitySelectDialog
                show={showSunsetCitySelectDialog}
                onShow={() => setShowSunsetCitySelectDialog(false)}
                onSunsetCity={onSunsetCity}></SunsetCitySelectDialog>

            <Dialog
                open={dialogOpen}
                onClose={handleSunsetDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        maxWidth: 'none'
                    }
                }}>
                <Box sx={{ display: 'inline-flex' }}>
                    <Box>
                        <DialogContent sx={{ overflowY: 'visible' }}>

                            <Table
                                sx={{
                                    display: 'tableCell',
                                    [theme.breakpoints.down('sm')]: {
                                        width: window.innerWidth,
                                    },
                                }}
                                size='small'
                            >
                                <TableRow>
                                    <TableCell colSpan={7} sx={{ fontWeight: 600, borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <DialogContentText id="alert-dialog-description" sx={{ fontSize: 18, fontWeight: 700, mb: 1, mt: 1 }}>
                                                일출·일몰 기본정보
                                                <Typography sx={{ fontWeight: 600, fontSize: 18, display: 'contents' }}>
                                                    &ensp;[ <span style={{ color: '#1C75BC' }}>{sunsetStore.sunsetDataList[0]?.cityName} </span>]
                                                </Typography>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="inherit"
                                                    sx={{ fontSize: 12, color: '#1C75BC', height: 25, mb: 1, position: 'relative', left: '2%', width: 93 }}
                                                    onClick={handleSunsetTimeSelectDialogOpen}
                                                >지역 재선택</Button>
                                                <Button
                                                    variant="outlined"
                                                    size='small'
                                                    color="inherit"
                                                    sx={{ width: 120, height: 25, mb: 1, position: 'absolute', left: '465px', color: '#1C75BC' }}
                                                    onClick={handleApplySunsetTime}>
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        추천시간 적용
                                                    </Typography>
                                                    <ChevronRightIcon /></Button>

                                                <Box sx={{ display: 'flex' }}>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 0 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(0)}>
                                                        1월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 1 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(1)}>
                                                        2월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 2 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(2)}>
                                                        3월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 3 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(3)}>
                                                        4월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 4 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(4)}>
                                                        5월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 5 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(5)}>
                                                        6월
                                                    </Button>
                                                </Box>

                                                <Box sx={{ display: 'flex' }}>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 6 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(6)}>
                                                        7월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 7 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(7)}>
                                                        8월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 8 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(8)}>
                                                        9월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 9 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(9)}>
                                                        10월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 10 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(10)}>
                                                        11월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 11 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(11)}>
                                                        12월
                                                    </Button>
                                                    <Button className={styles.monthButton} style={currentMonthIndex === 12 ? { backgroundColor: 'rgb(23, 106, 173)', color: '#fff' } : {}} onClick={() => handleMonthClick(12)}>
                                                        전체
                                                    </Button>
                                                </Box>

                                            </DialogContentText>
                                        </Box>
                                    </TableCell>

                                    <TableCell colSpan={2} sx={{ borderLeft: 2, borderLeftColor: '#F7B31E', borderRight: 1, borderRightColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
                                        <DialogContentText id="alert-dialog-description" sx={{ position: 'relative', left: '15px', fontSize: 18, fontWeight: 700, mb: 1.2, mt: 1 }}>
                                            연간스케줄
                                            {/* 지역이름 */}
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
                                                    [ <span style={{ color: '#1C75BC' }}>{combineHeader?.cityName} </span> ]
                                                </Typography>
                                                {/* 연도 */}
                                                <Box sx={{ display: 'inline-table', position: 'relative', left: 10 }}>

                                                </Box>
                                            </Box>
                                        </DialogContentText>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell rowSpan={2} className={styles.tableHead} sx={{ p: 3.12 }}>
                                        날짜
                                    </TableCell>
                                    <TableCell rowSpan={2} className={styles.tableHead}>
                                        일출 <br />(시민박명)
                                    </TableCell>
                                    <TableCell rowSpan={2} className={styles.tableHead}>
                                        일출
                                    </TableCell>
                                    <TableCell rowSpan={2} className={styles.tableHead} sx={{ borderRight: 1, borderRightColor: '#E6E7E8' }}>
                                        일몰
                                    </TableCell>
                                    <TableCell rowSpan={2} className={styles.tableHead}>
                                        일몰 <br />(시민박명)
                                    </TableCell>
                                    <TableCell colSpan={2} className={styles.tableHead} sx={{ borderRight: 2, borderRightColor: '#F7B31E' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', position: 'relative', left: '10px' }}>
                                            <span>추천시간</span>
                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                {(popupState) => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <IconButton sx={{ position: 'relative', color: '#0C3149', mb: 0.2 }} {...bindTrigger(popupState)}>
                                                            <HelpOutline sx={{ width: '18px', height: '18px' }} />
                                                        </IconButton>
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                        >
                                                            <Typography sx={{ p: 2, textAlign: 'center' }}>일출·일몰시간과 시민박명의 <span style={{ color: '#70AD47' }}><sup>1</sup>/<sub>3</sub> 지점( 70 % )</span>입니다.
                                                                <br />( 0% : 어두움 / 100% : 밝음 )</Typography>
                                                        </Popover>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography sx={{ fontSize: 12, mr: '3%' }}>적용률 : </Typography>
                                            <Input
                                                sx={{
                                                    width: '2.5rem',
                                                    height: '1.1rem',
                                                    borderRadius: '4px',
                                                    border: '1px solid #bdbdbd',
                                                    fontSize: 12,
                                                    display: 'flex',
                                                }}
                                                disableUnderline
                                                inputProps={{
                                                    style: { textAlign: 'center' },
                                                }}
                                                value={currentMonthData[0]?.ratio}
                                                onChange={handleRatio}
                                                required
                                                margin="none" />
                                            <Typography sx={{ fontSize: 12, ml: '3%' }}> % </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell rowSpan={2} className={styles.tableHead}>
                                        소등
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography sx={{ fontSize: 12 }}><Checkbox size='small' onClick={handleChangeManualOffTime}></Checkbox> 수동</Typography>
                                            <Typography sx={{ fontSize: 12 }}><Checkbox size='small' onClick={handleChangeAllOffTime}></Checkbox>전체</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell rowSpan={2} className={styles.tableHeadRightEnd}>
                                        점등
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography sx={{ fontSize: 12 }}><Checkbox size='small' onClick={handleChangeManualOnTime}></Checkbox> 수동</Typography>
                                            <Typography sx={{ fontSize: 12 }}><Checkbox size='small' onClick={handleChangeAllOnTime}></Checkbox>전체</Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableHead}>
                                        일출
                                    </TableCell>
                                    <TableCell className={styles.tableHead} sx={{ borderRight: 2, borderRightColor: '#F7B31E' }}>
                                        일몰
                                    </TableCell>
                                </TableRow>

                                {isTableVisible && (
                                    <TableBody>
                                        {currentMonthData.map((value, index) => (
                                            <TableRow>
                                                <TableCell className={styles.tableBody}>
                                                    <Typography>
                                                        {moment(value.date).format('MM/DD')}

                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={styles.tableBody}>
                                                    <Typography sx={{ color: '#9D70FF' }}>
                                                        {value.civilm}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={styles.tableBody}>
                                                    <Typography sx={{ color: '#1C75BC' }}>
                                                        {value.ctSunriseTime}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell className={styles.tableBody}>
                                                    <Typography sx={{ color: '#1C75BC' }}>
                                                        {value.ctSunsetTime}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={styles.tableBody}>
                                                    <Typography sx={{ color: '#9D70FF' }}>
                                                        {value.civile}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell className={styles.tableBody}>
                                                    <Typography sx={{ color: '#70AD47' }}>
                                                        {value.offRatioTime}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={styles.tableBody} sx={{ borderRight: 2, borderRightColor: '#F7B31E' }}>
                                                    <Typography sx={{ color: '#70AD47' }}>
                                                        {value.onRatioTime}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell className={styles.tableBody}>
                                                    <Typography>
                                                        {/* 소등 */}
                                                        <Input
                                                            sx={{
                                                                width: '7.7rem',
                                                                height: '1.5rem',
                                                                borderRadius: "4px",
                                                                border: "1px solid #bdbdbd",
                                                                pl: 4.8
                                                            }}
                                                            disableUnderline
                                                            disabled={offTimeDisabled ? true : offTimeDisabledAll ? false : index === 0 ? false : true}
                                                            onChange={handleOffTimeCalc}
                                                            required
                                                            name={value.startDate}
                                                            value={value.offTime}
                                                            margin="none"
                                                            style={{ textAlign: 'center' }} />

                                                    </Typography>
                                                </TableCell>

                                                <TableCell className={styles.tableBodyRightEnd}>
                                                    <Typography>
                                                        {/* 점등 */}
                                                        <Input
                                                            sx={{
                                                                width: '7.7rem',
                                                                height: '1.5rem',
                                                                borderRadius: "4px",
                                                                border: "1px solid #bdbdbd",
                                                                pl: 4.8,
                                                                textAlign: 'center'
                                                            }}
                                                            disableUnderline

                                                            disabled={onTimeDisabled ? true : onTimeDisabledAll ? false : index === 0 ? false : true}
                                                            onChange={handleOnTimeCalc}
                                                            required
                                                            name={value.startDate}
                                                            value={value.onTime}
                                                            margin="none" />
                                                    </Typography>
                                                </TableCell>
                                                <Box sx={{ width: '1rem' }} />
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </DialogContent>
                    </Box>
                    <Box sx={{ display: 'inline-table' }}></Box>
                </Box>

                <DialogActions>
                    <ButtonGreen
                        sx={{
                            "&:hover": { backgroundColor: "#0E3B25" }
                        }}
                        onClick={handleSunsetDialogClose}>
                        취소
                    </ButtonGreen>
                    <ButtonBlue
                        sx={{
                            backgroundColor: '#0C3149',
                            "&:hover": { backgroundColor: "#02090E" }
                        }}
                        onClick={handleSaveSunset}>
                        저장
                    </ButtonBlue>
                </DialogActions>
            </Dialog>
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /></>
    )
});

export default SunsetSchedleUpdatePopup;