//페이지 이동 경로 이름 지정
import { Navigate, Outlet } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Register from './pages/Register';
import Settings from './pages/Settings';
import Maps from './pages/Maps';

import Dashboard3 from './pages/slager/Dashboard3'; //대시보드
import Map from './pages/slager/map/Map'; // 시설지도
import ExamineList from './pages/slager/fail/ExamineList';
import FailureHistory from './pages/slager/fail/FailureHistory';
import FailureList from './pages/slager/fail/FailureList';
import RepairList from './pages/slager/fail/RepairList';
import RequireList from './pages/slager/fail/RequireList';

//시설 운영
import ErrorList from 'pages/slager/facility/ErrorList'; // 장애관리
import GatewayList from './pages/slager/facility/GatewayList'; // 중계기
import SecurityLampList from './pages/slager/facility/SecurityLampList'; //보안등
import StreetLampList from './pages/slager/facility/StreetLampList'; //시설 (시설 운영)
import RegisterFacility from './pages/slager/facility/RegisterFacility'; // 시설등록
import ConfigFacility from './pages/slager/facility/ConfigFacility'; // 시설수정
import ConnectDevice from './pages/slager/facility/ConnectDevice'; // 기기연동
import RegisterDevice from './pages/slager/facility/RegisterDevice'; // 기기등록

//사이트 관리
import FacilityGroup from './pages/slager/facilityGroup/FacilityGroup'; // 그룹
import FacilityLine from './pages/slager/facilityGroup/FacilityLine';
import DepartmentForm from './pages/slager/system/DepartmentForm';
import DepartmentList from './pages/slager/system/DepartmentList';
import ManagingCompanyList from './pages/slager/system/ManagingCompanyList';
import MemberForm from './pages/slager/system/MemberForm'; //사용자 수정
import MemberList from './pages/slager/system/MemberList'; // 사용자
import SiteList from './pages/slager/system/SiteList'; // 사이트
import SiteForm from './pages/slager/system/SiteForm'; // 사이트 수정
import SiteUserForm from './pages/slager/system/SiteUserForm'; // 사이트 사용자 관리
import Registration from './pages/slager/facility/Registration'; //시설 (사이트 관리)
import RegisterFacilityGateway from './pages/slager/facility/RegisterFacilityGateway'; // 중계기 시설 등록
import RegisterDeviceGateway from './pages/slager/facility/RegisterDeviceGateway'; // 중계기 기기등록
import ConnectDeviceGateway from './pages/slager/facility/ConnectDeviceGateway'; // 중계기 기기연동
import ConfigFacilityGateway from './pages/slager/facility/ConfigFacilityGateway'; // 중계기 시설수정
import RegistrationGateway from './pages/slager/facility/RegistrationGateway';
import ResponsibleCompany from './pages/slager/system/ResponsibleCompany';
import MyAccount from './pages/slager/system/MyAccount'; // 계정관리


import MobileRegistration from './pages/slager/mobile/MobileRegistration';
import FacilityGroupForm from 'pages/slager/facilityGroup/FacilityGroupForm';
import FacilityGroupDetail from 'pages/slager/facilityGroup/FacilityGroupDetail';

// 관리자
import MapLocation from './pages/admin/test/MapLocation';

import AdminMemberList from './pages/admin/system/AdminMemberList'; // 사용자 관리
import AdminSiteForm from './pages/admin/system/AdminSiteForm'; // 사이트 수정
import AdminSiteUserForm from './pages/admin/system/AdminSiteUserForm'; // 사이트 사용자 관리
import AdminSiteList from './pages/admin/system/AdminSiteList'; // 사이트
import AdminMemberForm from './pages/admin/system/AdminMemberForm'; // 사용자 수정

const routes = (isLoggedIn) => {
  return [
{
    path: 'slager',
    element: <DashboardLayout />,
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login"/>,
    children: [
      { path: 'dashboard', element: <Dashboard3 /> },
      
      // map
      { path: 'map', element: <Map /> },

      // fail
      { path: 'failurelist', element: <FailureList /> },
      { path: 'requirelist', element: <RequireList /> },
      { path: 'repairlist', element: <RepairList /> },
      { path: 'examinelist', element: <ExamineList /> },
      { path: 'failurehistory', element: <FailureHistory /> },
      { path: 'errorlist', element: <ErrorList /> },
      // facility
      { path: 'gatewayList', element: <GatewayList /> },
      { path: 'securitylamplist', element: <SecurityLampList /> },
      { path: 'streetlamplist', element: <StreetLampList /> },
      // register facility, device
      { path: 'registerfacility', element: <RegisterFacility /> },
      { path: 'registerdevice', element: <RegisterDevice /> },
      { path: 'connectdevice', element: <ConnectDevice /> },
      { path: 'configfacility', element: <ConfigFacility /> },
      { path: 'registration', element: <Registration /> },
      { path: 'registerfacilitygateway', element: <RegisterFacilityGateway /> },
      { path: 'registerdevicegateway', element: <RegisterDeviceGateway /> },
      { path: 'registrationgateway', element: <RegistrationGateway /> },
      { path: 'connectdevicegateway', element: <ConnectDeviceGateway /> },
      { path: 'configfacilitygateway', element: <ConfigFacilityGateway /> },

      // facilityGroup
      { path: 'facilitygroup', element: <FacilityGroup /> },
      { path: 'facilityline', element: <FacilityLine /> },
      { path: 'facilitygroupdetail', element: <FacilityGroupDetail /> },
      { path: 'facilitygroupform', element: <FacilityGroupForm /> },

      // system
      { path: 'memberlist', element: <MemberList /> },
      { path: 'sitelist', element: <SiteList /> },
      { path: 'siteform', element: <SiteForm /> },
      { path: 'siteuserform', element: <SiteUserForm /> },
      { path: 'departmentlist', element: <DepartmentList /> },
      { path: 'managingcompanyList', element: <ManagingCompanyList /> },
      { path: 'dapartmentform', element: <DepartmentForm /> },
      { path: 'memberform', element: <MemberForm /> },
      { path: 'responsiblecompany', element: <ResponsibleCompany /> },
      { path: 'myaccount', element: <MyAccount /> },

      // mobile
      { path: 'mregistration', element: <MobileRegistration /> },
    ]
  },
  {
    path: 'admin',
    element: <DashboardLayout />,
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login"/>,
    children: [
      { path: 'memberlist', element: <AdminMemberList /> },
      { path: 'memberform', element: <AdminMemberForm /> },
      { path: 'sitelist', element: <AdminSiteList /> },
      { path: 'siteform', element: <AdminSiteForm /> },
      { path: 'siteuserform', element: <AdminSiteUserForm /> },
    ]
  },
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      {
        path: 'user', element: <Outlet/> ,
      },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'map', element: <Maps /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/slager/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'maplocation', element: <MapLocation /> }
    ]
  }
    // element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard"/>,
]
};

export default routes;
