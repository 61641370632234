// map에서 시설 검색 후 나오는 팝업. ( 시설 정보 보여주는 거 )

import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { IFacInfo } from '../../model';
import { styled } from '@mui/material/styles';
import { IFacilityDetailInfo, getFacTypeStr } from 'model/facility';
import facilityStore from 'state/facility';
import userStore from 'state/user';

const StyledButton = styled('button')(
  ({ theme }) => ({
    height: 30,
    color: theme.palette.primary.main
  }));

const StyledTableRow = styled(TableRow)(
  ({ theme }) => ({
    height: 30,
  }));

const StyledTableCell = styled(TableCell)(
  ({ theme }) => ({
    padding: "0px 10px"
  }));

type ErrorListResultsProps = {
  isMobile: boolean;
  facInfos: IFacilityDetailInfo[];
  onClickShowFacInfo?: (facInfo: IFacilityDetailInfo) => void;
};
const ErrorListResults = ({ isMobile, facInfos, onClickShowFacInfo }: ErrorListResultsProps) => {
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [facilityDetailInfoList, setFacilityDetailInfoList] = useState<any[]>([]);
  
  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (facInfo: IFacilityDetailInfo) => {
    onClickShowFacInfo(facInfo);
  }

  useEffect(() => {
    setPage(1);
  }, [facInfos]);

  return (
    <Card >
      <PerfectScrollbar>
        <Box sx={{
          width: isMobile ? '90vw' : '800px'
        }}>
          <Table
            size='small'
          >
            {/* <TableHead sx={{minWidth: 260}}> */}
            <TableHead >
              <StyledTableRow >
                <StyledTableCell  >
                  이름
                </StyledTableCell>
                {
                  !isMobile &&
                  <StyledTableCell  >
                    그룹
                  </StyledTableCell>
                }
                <StyledTableCell  >
                  라인
                </StyledTableCell>
                {
                  !isMobile &&
                  <StyledTableCell  >
                    시설유형
                  </StyledTableCell>
                }
                <StyledTableCell  >
                  상태
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {facInfos?.slice((page - 1) * limit, limit + (page - 1) * limit).map((facInfo, index) => (
                <StyledTableRow
                  hover
                  onClick={() => handleRowClick(facInfo)}
                  key={facInfo.facId}
                >
                  <StyledTableCell >
                    {facInfo.facName}
                  </StyledTableCell>
                  {
                    !isMobile &&
                    <StyledTableCell >
                      {facInfo.groupName}
                    </StyledTableCell>
                  }
                  <StyledTableCell >
                    {facInfo.lineName}
                  </StyledTableCell>
                  {
                    !isMobile &&
                    <StyledTableCell >
                      {getFacTypeStr(facInfo.facType)}
                    </StyledTableCell>
                  }
                  <StyledTableCell >
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!facInfos &&
                <StyledTableRow>
                  <StyledTableCell  colSpan={7} sx={{textAlign:'center'}}>
                    등록된 시설이 없습니다.
                  </StyledTableCell>
                </StyledTableRow>
              }
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Pagination
        count={Math.ceil(facInfos?.length / limit)}
        onChange={handlePageChange}
        page={page}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      />
    </Card>
  );
};

export default ErrorListResults;
