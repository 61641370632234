// 사이트 관리 - 시설 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';
import RegisterFacToolbar from 'components/facility/RegisterFacToolbar';
import { useEffect, useState } from 'react';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import RegisterationImp from 'components/facility/RegisterationImp';

const Registration = observer(({}) => {
  const { clientStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

  const [regFacility, setRegFacility] = useState<any[]>([]);
  const [unRegFacility, setUnRegFacility] = useState<any[]>([]);

  //시설 정보 받아옴.
  useEffect(() => {
    facilityStore.getFacilityAllInfoList(userStore.siteId, null, null, null);
  },[]);

  useEffect(() => {

    if (facilityStore.facilityList == null) {
      return;
    }

    const regFac= facilityStore.facilityList.filter((item: any, index: number) => {
      console.log(`facility ---> ${JSON.stringify(item)}`);
      console.log(`facility -> ${item.facId}, ${item.devId}`)
      return item.devId != -1;
    }
    );
    regFac.map((item:any, index:number) => {
      console.log(`regFacility ---> ${JSON.stringify(item)}`);
    }
    );
    setRegFacility(regFac);
    const unRegFac= facilityStore.facilityList.filter((item: any, index: number) => {
      console.log(`facility ---> ${JSON.stringify(item)}`);
      console.log(`facility -> ${item.facId}, ${item.devId}`)
      return item.devId == -1;
    }
    );
    unRegFac.map((item:any, index:number) => {
      console.log(`unRegFacility ---> ${JSON.stringify(item)}`);
    }
    );
    setUnRegFacility(unRegFac);
  },[facilityStore.facilityList]);

  const handleCommand = (command:string) => {
    console.log(`handleCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }

  return (
    <>
      <Helmet>
        <title>Registration</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <RegisterFacToolbar handleCommand={handleCommand}/>
          <RegisterationImp regFacility={regFacility} unRegFacility={unRegFacility} doit={doit}></RegisterationImp>
        </Container>
      </Box>
    </>
  );
});

export default Registration;
