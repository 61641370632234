// 사이트관리 - 중계기- 기기등록 
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useStores } from '../../state';
import { useLocation, useNavigate } from 'react-router';
// import theme from 'theme';
import { observer } from 'mobx-react';
import NameInputRem from 'components/atom/NameInputRem';
import deviceStore from 'state/device';
import { ISetSiteDevice } from 'model/device';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import styles from 'components/facilityGroup/style.module.css'

interface StyledNameInputProps {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}
function StyledNameInput({ name, value, disabled, onChange }: StyledNameInputProps) {
  return (
    <>
      <NameInputRem
        name={name}
        value={value}
        disabled={disabled}
        space={'3rem'}
        nameWidth={'5rem'}
        valueWidth={'20rem'}
        onChange={onChange}
      />
    </>
  );
}

interface ILocation {
  facId: string,
  facNo: string,
  labelNo: string,
  macAddr: string,
  lat: string,
  lng: string,
  facType: string,
  fullAddr: string,
  mtCompany: string,
  devId: string,
}
const RegisterDeviceGatewayImp = observer(() => {
  const navigate = useNavigate();
  const { userStore } = useStores();
  const location = useLocation();
  const state = location.state as ILocation;
  const [macStr, setMacStr] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeMac = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const macs = event.target.value;
    setMacStr(macs);
  }

  const isValidMacAddress = (mac: string) => {
    const regex = new RegExp("^([0-9A-Fa-f]{2}[:-])"
      + "{5}([0-9A-Fa-f]{2})$");
    return regex.test(mac);
  }

  //맥어드레스 경고 팝업 닫음
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  //저장
  const handleSave = async () => {

    const str1 = macStr.replace(/\r|\n/g, ",");
    const str = str1.replace(/\s*/g, "");

    const macArray = str.split(',');
    macArray.map(item => {
      console.log(`mac : ${item}`);
    })

    const newMac = macArray.reduce((acc, item) => {
      if (item.length != 0)
        acc.push({ serialNo: item.trim() })
      return acc;
    }, []);

    // validation check
    const isValid = newMac.find((item: any, index: number) => {
      return !isValidMacAddress(item.serialNo)
    });

    if (!isValid) {
      console.log(`mac address is valid`)
    }
    else {
      console.log(`mac address is not valid ${JSON.stringify(isValid)}`)
      ToastsStore.error(`'${isValid.serialNo}' 은 잘못된 시리얼 넘버입니다.`)

      return;
    }

    const device: ISetSiteDevice = {
      siteId: userStore.siteId,
      data: null,
    }
    device.data = newMac;

    await deviceStore.checkAvailableDeviceRegistered(device);
    const found = deviceStore.availableDeviceList.find(item => item.availableDevRegistrationCheck == 'R' || item.availableDevRegistrationCheck == 'N');
    if (found != null) {
      console.log(`cyi handleSave unavail device exist`)
      setOpenDialog(true);
      // popup message;
      return;
    }
    console.log(`cyi checkAvailableDeviceRegistered return ${JSON.stringify(deviceStore.availableDeviceList)}`)
    const ret = await deviceStore.setSiteDeviceList(device);
    console.log(`cyi setSiteDeviceList return ${ret}`)

    if (ret) {
      ToastsStore.success(`등록되었습니다.`)
      const timer = setInterval(() => {
        clearInterval(timer);
        navigate(-1);
      }, 1000)
    }
  }

  const handleSaveGateway = async () => {

    const str1 = macStr.replace(/\r|\n/g, ",");
    const str = str1.replace(/\s*/g, "");

    const macArray = str.split(',');
    macArray.map(item => {
      console.log(`mac : ${item}`);
    })

    const newMac = macArray.reduce((acc, item) => {
      if (item.length != 0)
        acc.push({ serialNo: item.trim() })
      return acc;
    }, []);

    // validation check
    const isValid = newMac.find((item: any, index: number) => {
      return !isValidMacAddress(item.serialNo)
    });

    if (!isValid) {
      console.log(`mac address is valid`)
    }
    else {
      console.log(`mac address is not valid ${JSON.stringify(isValid)}`)
      ToastsStore.error(`'${isValid.serialNo}' 은 잘못된 시리얼 넘버입니다.`)

      return;
    }

    const device: ISetSiteDevice = {
      siteId: userStore.siteId,
      data: null,
    }
    device.data = newMac;

    await deviceStore.checkAvailableDeviceGatewayRegistered(device);
    const found = deviceStore.availableDeviceList.find(item => item.availableDevRegistrationCheck == 'R' || item.availableDevRegistrationCheck == 'N');
    if (found != null) {
      console.log(`cyi handleSave unavail device exist`)
      setOpenDialog(true);
      // popup message;
      return;
    }
    console.log(`cyi checkAvailableDeviceRegistered return ${JSON.stringify(deviceStore.availableDeviceList)}`)
    const ret = await deviceStore.setSiteDeviceGatewayList(device);
    console.log(`cyi setSiteDeviceList return ${ret}`)

    if (ret) {
      ToastsStore.success(`등록되었습니다.`)
      const timer = setInterval(() => {
        clearInterval(timer);
        navigate(-1);
      }, 1000)
    }
  }

  const displayStatus = (status: string) => {

    switch (status) {
      case 'R': return '중복된 번호';
      case 'Y': return '등록 가능';
      case 'N': return '등록 불가';
    }

    return '등록가능';
  }
  const BackPage = () => {
    navigate(-1);
  };
  // add facility and get facId, 추후 수정 할 필요가 있음
  return (
    <Box>
      <Typography
        sx={{
          position: 'relative',
          height: 1,
          textAlign: 'right',
          right: '1%'
        }}
        gutterBottom>
        시스템 관리 {'>'} 중계기 {'>'} 기기 등록
      </Typography>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2%',
          right: '1%'
        }}
      >
        <Typography //타이틀 글자 조정
          sx={{
            fontSize: 30,
            fontWeight: 600,
            color: '#0C3149',
            display: 'flex',
            position: 'relative',
            left: '2.2%',
            marginBottom: '1%'
          }}
          gutterBottom >
          기기 등록
        </Typography>
      </Box>
      <Divider sx={{ border: 1, borderColor: '#bdbdbd' }} />
      <CardContent>
        <Box sx={{ mt: 1 }}> </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ ml: 1 }}> </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{ fontSize: 20 }}
            >
              시리얼 넘버를 입력 해주세요.
            </Typography>
            <TextareaAutosize
              minRows={3}
              placeholder="xx-xx-xx-xx-xx-xx, xx-xx-xx-xx-xx-xx, 여러개 입력 가능..."
              style={{ width: 500, height: 100 }}
              onChange={handleChangeMac}
              name='macAddress'
            />
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}> </Box>
      </CardContent>
      <Box sx={{ mt: 1 }}> </Box>

      <Divider sx={{ border: 1, borderColor: '#bdbdbd' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2
        }}
      >
        <Button
          className={styles.greenButton}
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => BackPage()}
        >
          취소
        </Button>
        <Button
          className={styles.primaryButton}
          color="primary"
          variant="contained"
          onClick={handleSaveGateway}
        >
          등록
        </Button>
      </Box>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            잘못 설정된 맥어드레스를 수정해주세요
          </DialogContentText>
          <Table
            size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  맥어드레스
                </TableCell>
                <TableCell>
                  상태
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceStore.availableDeviceList.map((item, index) => {
                console.log(`mac aaa ${item.serialNo}`)
                return (
                  < TableRow >
                    <TableCell>
                      {item.serialNo}
                    </TableCell>
                    <TableCell>
                      {displayStatus(item.availableDevRegistrationCheck)}
                    </TableCell>
                  </TableRow>
                )
              })}

            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>확인</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default RegisterDeviceGatewayImp;
