//사용자 관련 api
import { observable, computed } from 'mobx';
import { api, q } from '../api';
import { IUserDetail } from '../model/user';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import siteStore from './site';
import { isSiteManager } from 'model';

// export let isLoggedIn = false;

const load = (x: string) => {
	try {
		return localStorage.getItem(x).toString();
	} catch (e) {
		return null;
	}
};


export interface IUserStore {
	id: string;
	name:string;
	token:string;
	isLoggedIn:boolean;
	company:string;
	userList:IUserDetail[];
	selectedUser:any;
	siteId: string;
	siteName: string;
	siteLevel: string;
	siteCount: string;
	userInfo: any;

	getId:() => string;
	loginUser:(id: string, password: string) => Promise<boolean>;
	logout:() => void;
	addUser:(user : IUserDetail) => void;
	updateUser:(user : any) => void;
	updateUserPwd:(user : any) => void;
	isMatch:(userId: string, userPwd: string) => Promise<boolean>;
	delUser:(user : any) => void;
	getUserList:(perPage:string, page:string) => any;
	getUserListBySiteId:(siteId:string) => any;
	checkLogin:(perPage:string, page:string) => any;
	saveToken:(token:string) => void;
	saveName:(name:string) => void;
	saveSiteInfo:(siteId:string, siteName:string, siteLevel:string, siteCount:string) => void;
	getUserInfo:(userId: string) => void;
	isAdmin:()=> boolean;
	isSiteManager:()=> boolean;
}

export const userStore = observable<IUserStore>({
	id: '',
	name: load('name'),
	token: load('token'),
	siteId: load('siteId'),
	siteName: load('siteName'),
	siteLevel: load('siteLevel'),
	siteCount: load('siteCount'),
	isLoggedIn: false,
	company: '',
	userList:[],
	selectedUser:null,
	userInfo:null,

	getId():string {
		const ret = jwtDecode<JwtPayload>(this.token)
		return ret.sub;
	},
	async loginUser(id: string, pwd: string) {
		// call api
		console.log(`loginuser enter`)
		try {
			const ret = await api.post(`/api/auth/login`, {
				id,
				pwd,
			});
			console.log(`loginuser ret = ${JSON.stringify(ret)}`)
			this.isLoggedIn = true;
			this.name = ret.name;
			// this.getUserInfo(id);
			this.saveToken(ret.token);
			this.saveName(ret.name);
			return true;
		} catch (error) {
			console.log(`loginuser error`)
			this.isLoggedIn = false;
		}

		return false;
	},
	async logout() {
		this.isLoggedIn = false;
		this.saveToken('false');
		this.saveName('');
		this.saveSiteInfo("","","", "");
	},
	async addUser(user : IUserDetail) {
		console.log(`addUser ::: ${JSON.stringify(user)}`)
		try {
			const ret = await api.post(`/api2/user/add`, user);
			console.log(`addUser ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`addUser error status : ${JSON.stringify({status:error.status, resp:error.body})}`)
			return {status:error.status, resp:error.body};
		}
	},
	async updateUser(user : any) {
		console.log(`updateUser ::: ${JSON.stringify(user)}`)
		try {
			const ret = await api.post(`/api2/user/userUpdate`, user);
			console.log(`updateUser ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`updateUser error`)
			return {status:error.status};
		}
	},
	async updateUserPwd(user : any) {
		console.log(`updateUserPwd ::: ${JSON.stringify(user)}`)
		try {
			const ret = await api.post(`/api2/user/userUpdatePwd`, user);
			console.log(`updateUserPwd ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`updateUserPwd error`)
			return {status:error.status};
		}
	},
	async isMatch(userId:string, userPwd: string) {
		console.log(`isMatch ::: ${userId}, ${userPwd}`)
		try {
			const ret = await api.post(`/api2/user/isMatch`, {
				userId,
				userPwd,
			});
			console.log(`isMatch ret = ${JSON.stringify(ret)}`)

			if (ret != "") {
				return true;
			}
			//return {status:200};
		} catch (error) {
			console.log(`getUserInfo error`)
			//return {status:error.status};
		}
		return false;
	},
	async delUser(user:any) {
		console.log(`delUser ::: ${JSON.stringify(user)}`)
		try {
			// 사이트 사용자 리스트 삭제
			await siteStore.getSiteListByUser(user.userId);
			await Promise.all(siteStore.userList[0]?.siteList?.map(async (item: any, index: number) => {
				console.log(`handleDeleteUser : delete siteInfo ${JSON.stringify(item)}`)
				siteStore.deleteUser(item.siteId, user.userId);
			}));

			const ret = await api.post(`/api2/user/delete`, user);
			console.log(`delUser ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`delUser error`)
			return {status:error.status};
		}
	},
	//site에 속한 사용자는 사용자 리스트를 모두 가져옴.
	async getUserList(perPage:string, page:string) {
		console.log(`getUserList ::: `)
		try {
			const ret = await api.get(`/api/user/tblList${q({
				perPage,
				page,
			})}`);
			console.log(`getUserList ret = ${JSON.stringify(ret)}`)
			this.userList = ret.data.contents;
		} catch (error) {
			console.log(`getUserList error`)
		}
	},

	async getUserListBySiteId(siteId:string) {
		console.log(`getUserListBySiteId ::: `)
		try {
			const ret = await api.post(`/api2/user/getUserListBySiteId`, {
				siteId
			});
			console.log(`getUserListBySiteId ret = ${JSON.stringify(ret)}`)
			this.userList = ret.contents;
		} catch (error) {
			console.log(`getUserListBySiteId error`)
		}
	},
	checkLogin(perPage:string, page:string) {
		this.token = load('token');
		if (this.token == null) {
				this.isLoggedIn = false;
				return false;
		}
		const ret = jwtDecode<JwtPayload>(this.token)
		// console.log(`checkLogin ::: ret ${JSON.stringify(ret)}`)
		if (this.token) {
		    if (ret.exp > Math.floor(Date.now()/1000)) {
				api.token = this.token;
				this.name = load('name');
				console.log('checkLogin siteId : ', this.siteId)
				if (this.siteId == '') {
					this.isLoggedIn = false;
					return false;
				}

				this.isLoggedIn = true;
				return true;
			}
			else {
				this.isLoggedIn = false;
				return false;
			}
		}
		return false;
	},
	//사용자 정보 하나만 가져옴
	async getUserInfo(userId:string) {
		console.log(`getuserInfo ::: ${userId}`)
		try {
			const ret = await api.post(`/api2/user/getUserInfo`, {
				userId
			});
			console.log(`getUserInfo ret = ${JSON.stringify(ret)}`)
			this.userInfo = ret.data;
			return {status:200};
		} catch (error) {
			console.log(`getUserInfo error`)
			return {status:error.status};
		}
	},
	async saveToken(token:string) {
		this.token = token;
		api.token = token;
		if (token.includes('false')) {
			this.isLoggedIn = false;
			localStorage.removeItem('token');
		}
		else {
			localStorage.setItem('token', this.token);
		}
	},
	async saveName(name:string) {
		this.name = name;
		localStorage.setItem('name', this.name);
	},
	async saveSiteInfo(siteId:string, siteName:string, siteLevel:string, siteCount:string) {
		console.log(`saveSiteInfo : ${siteId}, ${siteName}, ${siteLevel}`)
		this.siteId = siteId;
		this.siteName = siteName;
		this.siteLevel = siteLevel;
		this.siteCount = siteCount;
		localStorage.setItem('siteId', siteId);
		localStorage.setItem('siteName', siteName);
		localStorage.setItem('siteLevel', siteLevel);
		localStorage.setItem('siteCount', siteCount);
	},
	isAdmin() {
		return this.getId() == 'super'?true:false;
	},
	isSiteManager() {
		return isSiteManager(this.siteLevel)?true:false;
	},
})

export default userStore;

