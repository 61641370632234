// 시설운영 - 장애관리 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from 'state';
import { useEffect, useState } from 'react';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import ManageErrorList from 'components/facility/ManageErrorList';
import maintenanceStore from 'state/maintenance';

const errorList = observer(() => {
  const { clientStore, iotStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);
  console.log('errorList')

  //시설 정보 받아옴.
  useEffect(() => {
    facilityStore.getFacilityInfoList(userStore.siteId, null, null, null);
  },[]);
  
 

  return (
    <>
      <Helmet>
        <title>errorList</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
            {/* <ManageErrorList lampCount={facilityStore.inFacilityList?.length.toString()} facs={facilityStore.inFacilityList} doit={doit} facId={''} /> */}
            <ManageErrorList errorCount={maintenanceStore.maintenanceInfoList?.length.toString()} maintenance={maintenanceStore.maintenanceInfoList} doit={doit} facId={''} />
        </Container>
      </Box>
    </>
  );
});

export default errorList;