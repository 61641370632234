//사이트 관리 - 연동시설 - 수정 - 기기해제

import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Typography,
  Table,
  TableCell,
  TableRow
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import userStore from 'state/user';
import deviceStore from 'state/device';
import facilityStore from 'state/facility';
import NameValueRem from 'components/atom/NameValueRem';
import { ToastsStore } from 'react-toasts';

interface StyledNameValueProps {
  name: string;
  value: string;
}
//nameValueRem 재정의
function StyledNameValue({ name, value }: StyledNameValueProps) {
  return (
    <>
      <NameValueRem
        name={name}
        value={value}
        space={'3rem'}
        nameWidth={'5rem'}
        valueWidth={'20rem'}
      />
    </>
  );
}

interface ILocation {
  siteId: string,
  siteName: string,
  facId: string,
  facName: string,
  facType: string,
  facDesc: string,
  gwId: string,
  serialNo: string,
}

const DisConnectDeviceGatewayImp = observer(() => {
  const { clientStore, iotStore } = useStores();
  const [serialValue, setSerialValue] = useState(null);
  const [serial, setSerial] = useState('');
  const location = useLocation();
  const state = location.state as ILocation;
  const navigate = useNavigate();

  const [values, setValues] = useState(
    {
      facId: '',
      facName: '',
      facType: '',
      facDesc: '',
      gwId: '',
      serialNo: '',
    });
    
//device관련 정보 받아오는 것들
  useEffect(() => {
    setSerialValue(deviceStore.deviceGatewayList);
  }, [deviceStore.deviceGatewayList]);

  useEffect(() => {
    deviceStore.getDeviceGatewayBySiteId(userStore.siteId);
  }, []);

  useEffect(() => {
    const deviceGateway = serialValue?.find((item: any) => {
      if (item.gwId == state.gwId)
        return true;
    })
    if (deviceGateway) {
      setSerial(deviceGateway.serialNo);
    }
  }, [serialValue]);

  //해제하기 함수
  const handleDisconnect = async () => {
    const ret = await facilityStore.setGwIdForFacilityGateway(state.facId, state.gwId, 'unreg');
    if (ret) {
      ToastsStore.success(`연결이 해지되었습니다.`)
      const timer = setInterval(() => {
        clearInterval(timer);
        navigate(-1);
      }, 1000)
    }
  }

  return (
    <Box>
      <Typography //타이틀 글자 조정
        sx={{
          fontSize: 25,
          fontWeight: 600,
          color: '#0C3149',
          display: 'flex',
          position: 'relative',
          left: '1.5rem',

        }}
        gutterBottom >
        기기 해제
      </Typography>
      <Table>
        <TableRow>
          <TableCell sx={{ padding: 1, borderTop: 2, borderTopColor: '#BFBFBF', borderBottom: 1, borderBottomColor: '#BFBFBF' }}>
            <StyledNameValue
              name="Serial"
              value={state.serialNo}
            />
          </TableCell>
        </TableRow>
      </Table>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2
        }}
      >
        <Button
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => handleDisconnect()}
        >
          해제하기
        </Button>
      </Box>
    </Box>

  );
});

export default DisConnectDeviceGatewayImp;
