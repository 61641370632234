import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

// Table.styled.js
import styled from 'styled-components';

const StyledTable = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
`;

export const StyledTableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.lightGray};
  }
`;

export const StyledTh = styled.div`
display: flex;
  align-items: center;
  flex: 0 0 10rem;
  width: 10rem;
  color: ${({ theme }) => theme.gray};
  background: #c3c7d926;
  font-weight: 600;
  padding: 1rem;
  word-break: keep-all;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0.8rem;
    flex: 1 1 37%;
  }
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.lightGray};
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0.8rem;
    flex: 1 1 37%;
    border-left: 1px solid ${({ theme }) => theme.lightGray};
  }
`;

export const StyledTd = styled.div`
display: flex;
  align-items: center;
  padding: 1rem;
  flex: 1;
  word-break: keep-all;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0.8rem;
    flex: 1 1 63%;
  }
  border-left: 1px solid ${({ theme }) => theme.lightGray};
  border-right: 1px solid ${({ theme }) => theme.lightGray};
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  word-break: keep-all;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0.8rem;
    flex: 1 1 63%;
  }
`;

interface TableProps {
	children?: React.ReactNode;
}
const Table = ({ children }:TableProps) => {
  return <StyledTable>{children}</StyledTable>;
};

interface TableRowProps {
	children?: React.ReactNode;
}
const TableRow = ({ children }:TableRowProps) => {
  return <StyledTableRow>{children}</StyledTableRow>;
};

interface TableRowProps {
	title: string;
	value: string;
}
const TableCell = ({ title, value }:TableRowProps) => {
  return (
    <>
      <StyledTh>{title}</StyledTh>
      <StyledTd>{value}</StyledTd>
    </>
  );
};

const DepartmentList = () => {
  console.log('DepartmentList')
  useEffect(() => {
  }, []);

  return(
  <>
    <Helmet>
      <title>DepartmentList</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            구현 중 입니다.
          </Typography>
          <Table>
              <StyledTableRow>
                <TableCell
                title='no'
                value='bbb'>
                </TableCell>
                <TableCell
                title='no1'
                value='bbb1'>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTh>
                  No
                </StyledTh>
                <StyledTd>
                  aaa
                </StyledTd>
                <StyledTh>
                  No
                </StyledTh>
                <StyledTd>
                  aaa
                </StyledTd>
              </StyledTableRow>
          </Table>
      </Container>
    </Box>
  </>
);
    };

export default DepartmentList;
