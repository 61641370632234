//사이트관리 - 사용자(테이블)
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Popover,
  Typography,
  Pagination,
  CardHeader,
  Divider,
  CardContent
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { useStores } from 'state';
import { getSiteLevelStr, isSiteManager, IUserDetail } from 'model';
import theme from 'theme';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { ButtonBlue } from 'components/admin/system/Styled';
import styles from 'components/facilityGroup/style.module.css'
import { isMobile } from 'react-device-detect';
import { fontWeight } from '@mui/system';

type UserListResultsProps = {
  userCount: string;
  users: IUserDetail[];
  doit: string;
};
const UserListResults = ({ userCount, users, doit }: UserListResultsProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { userStore } = useStores();

  //수정 페이지로 이동
  const handleModify = async (user: any) => {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!", JSON.stringify(user))
    navigate('/slager/memberform',
      {
        state: {
          userId: user.userId,
          userPwd: user.userPwd,
          userName: user.userName,
          siteLevel: user.siteLevel,
          hpNo: user.hpNo,
          telNo: user.telNo,
          email: user.email,
          company: user.company,
        }
      });
  }

  // 등록페이지와 수동페이지 이동하는 조건을 정했었지만 이 페이지에선 modify로만 이동되는듯 함.
  useEffect(() => {
    if (doit.includes("add")) {
      console.log(`add selectedUser ${JSON.stringify(selectedUsers)}`);
      navigate('/slager/memberform');
    }
    else if (doit.includes("modify")) {
      console.log(`modify ${JSON.stringify({ state: selectedUser })}`)
      console.log(`modify selectedUser count ${(selectedUsers.length)}`);
      if (selectedUsers.length == 0) {
        // popup or something
        ToastsStore.warning("선택된 사용자가 없습니다.")
        return;
      }
      userStore.selectedUser = selectedUser;

      navigate('/slager/memberform', {
        state: {
          userId: selectedUser.userId,
          userPwd: selectedUser.userPwd,
          userName: selectedUser.userName,
          siteLevel: selectedUser.siteLevel,
          hpNo: selectedUser.hpNo,
          telNo: selectedUser.telNo,
          email: selectedUser.email,
          company: selectedUser.company,
        }
      });
    }
  }, [doit]);

//하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange`)
    setPage(newPage);
  };

  return (
    <div>

      <PerfectScrollbar>
        <Box>
          {!isMobile &&
            <Table
              sx={{
                width: '100%',
              }}
              size='small'
            >
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeadEnd}>
                    No
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    아이디
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    사용자 명
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    핸드폰번호
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    연락처
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    역할
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    수정
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, limit).map((user, index) => (
                  <TableRow
                    hover
                    key={user.userId}
                    selected={selectedUserIds.indexOf(user.userId) !== -1}
                  >
                    <TableCell sx={{ textAlign: 'center' }}>
                      {(page - 1) * limit + index + 1}
                    </TableCell>
                    <TableCell className={styles.tableBody}>
                      {user.userId}
                    </TableCell>
                    <TableCell className={styles.tableBody}>
                      {user.userName}
                    </TableCell>
                    <TableCell className={styles.tableBody}>
                      {user.hpNo}
                    </TableCell>
                    <TableCell className={styles.tableBody}>
                      {user.telNo}
                    </TableCell>
                    <TableCell className={styles.tableBody}>
                      {getSiteLevelStr(user.siteLevel)}
                    </TableCell>
                    <TableCell className={styles.tableBody} sx={{ width: '10%' }}>
                      <ButtonBlue
                        size='small'
                        sx={{
                          "&:hover": { backgroundColor: "#10548A" }
                        }}
                        onClick={() => {
                          console.log(`사용자 정보 수정`);
                          console.log("This User Value is !!!!!! : ", JSON.stringify(user));
                          handleModify(user);
                        }}
                      >수정
                      </ButtonBlue>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
          {isMobile &&
            <Table
              sx={{
                width: '100%',
              }}
              size='small'
            >
              <TableBody>
                {users.slice(0, limit).map((user, index) => (
                  <TableRow
                    //hover
                    key={user.userId}
                    selected={selectedUserIds.indexOf(user.userId) !== -1}
                  >
                    <Card>
                    <CardContent>
                      <Box sx={{display:'flex',justifyContent:'space-between'}}>
                      <Typography sx={{textAlign:'center',position:'relative', top:4, fontSize:19,fontWeight:500}}>{user.userId}</Typography>
                      <Button
                      className={styles.blueButton}
                        size='small'
                        onClick={() => {
                          console.log(`사용자 정보 수정`);
                          console.log("This User Value is !!!!!! : ", JSON.stringify(user));
                          handleModify(user);
                        }}
                      >수정
                      </Button>
                      </Box>
                      <Box sx={{mt:1}}></Box>
                      <Divider/>
                    </CardContent>
                    <CardContent>
                    <Typography>
                      <span style={{fontWeight:600}}>사용자 명</span>&emsp;&emsp;&nbsp;{user.userName}
                    </Typography>
                    <Typography>
                    <span style={{fontWeight:600}}>핸드폰번호</span>&emsp;&ensp;{user.hpNo}
                    </Typography>
                    <Typography>
                    <span style={{fontWeight:600}}>연락처</span>&emsp;&emsp;&emsp;&ensp;{user.telNo}
                    </Typography>
                    <Typography>
                    <span style={{fontWeight:600}}>역할</span>&emsp;&emsp;&emsp;&emsp;&ensp;{getSiteLevelStr(user.siteLevel)}
                    </Typography>
                    </CardContent>
                    </Card>
                    <Box sx={{mt:2}}></Box>
                  </TableRow>
                ))}
              </TableBody>






            </Table>
          }
        </Box>
        <Box sx={{ display: !isMobile && 'flex', }}>
          <Typography
            className={styles.bottomCount}
          >
            전체 사용자 : {userCount} 명
          </Typography>
          <Pagination
            color="primary"
            page={page}
            count={Math.ceil(users.length / 10)}
            onChange={handlePageChange}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              height: '50px',
              position: 'relative',
              right: '7%',
              [theme.breakpoints.down('sm')]: {
                width: window.innerWidth,
              },
            }}
          />
        </Box>
      </PerfectScrollbar>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </div>
  );
};

export default UserListResults;
