//시설수정 & 시설 등록

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell
} from '@mui/material';
import theme from 'theme';
import { useStores } from '../../state';
import { useLocation, useNavigate } from 'react-router';
import { Facilitys, FacType, ISetFacilityGatewayInfo, ISetFacilityInfo, isNumber } from 'model/facility';
import facilityStore from 'state/facility';
import NameSelectRem from 'components/atom/NameSelectRem';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import NameInput from 'components/atom/NameInput';
import styles from 'components/facilityGroup/style.module.css'
import { isMobile } from 'react-device-detect';
import MobileNameInput from 'components/atom/MobileNameInput';
import MobileNameSelect from 'components/atom/MobileNameSelect';

interface StyledNameInputProps {
  name: string;
  label?: string;
  value: string;
  disabled?: boolean;
  onChange: (name: string, label: string, value: string) => void;
}
function StyledNameInput({ name, label, value, disabled, onChange }: StyledNameInputProps) {
  const handleChange = (_name: string, _value: string) => {
    onChange(_name, label, _value);
  }

  return (
    <Box
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {!isMobile &&
        <NameInput
          name={name}
          value={value}
          disabled={disabled}
          space={10}
          nameWidth={110}
          valueWidth={200}
          onChange={handleChange}
        />
      }
      {isMobile &&
        <MobileNameInput
          name={name}
          value={value}
          disabled={disabled}
          onChange={handleChange}
        />
      }
    </Box>
  );
}

interface StyledNameSelectProps {
  name: string;
  label?: string;
  defaultValue?: string;
  object: any[];
  space?: string;
  nameWidth?: string;
  valueWidth?: string;
  onSelect: (name: string, label: string, value: string) => void;
}
function StyledNameSelect({ name, label, defaultValue, object, space, nameWidth, valueWidth, onSelect }: StyledNameSelectProps) {
  const handleSelect = (_name: string, _value: string) => {
    onSelect(_name, label, _value);
  }

  return (
    <>
      {!isMobile &&
        <NameSelectRem
          name={name}
          defaultValue={defaultValue}
          object={object}
          space={space}
          nameWidth={nameWidth}
          valueWidth={valueWidth}
          onSelect={handleSelect}
        />
      }
      {isMobile &&
        <MobileNameSelect
          name={name}
          defaultValue={defaultValue}
          object={object}
          onSelect={handleSelect}
        />
      }
    </>
  );
}

interface ILocation {
  facId: string,
  facName: string,
  facType: string,
  facDesc: string,
  siteId: string,
  groupId: string,
  lineId: string,
  devId: string,
  latitude: string,
  longitude: string,
  altitude: string,
  address: string,
  telNo: string,
}
const RegisterFacImp = () => {
  const navigate = useNavigate();
  const { userStore } = useStores();
  const location = useLocation();
  const state = location.state as ILocation;
  const [disableButton, setDisableButton] = useState(true);

  const [values, setValues] = useState({
    facId: null,
    facName: null,
    facType: null, // default 가로등 
    facDesc: null,
    siteId: null,
    groupId: null,
    lineId: null,
    devId: null,
    latitude: null,
    longitude: null,
    altitude: null,
    address: null,
    telNo: null,
    enable: null,
  });

  console.log(`RegisterFac location ${JSON.stringify(location)}`)

  const getType = (value: string): string => {
    console.log(`getType ${value} `)

    if (value == null) {
      return '0';
    }

    const type = Facilitys.find((item: any) => item.value == value)
    if (type == null) {
      return '-1';
    }
    return type.value;
  }

  //value설정
  useEffect(() => {
    console.log(`useEffect ${JSON.stringify(state)}`)
    console.log(`RegisterFacImp user:id::  ${userStore.getId()}`);
    if (state != null) {
      setValues({
        ...values,
        facId: state.facId,
        facName: state.facName,
        facDesc: state.facDesc,
        facType: state.facType,
        siteId: state.siteId,
        groupId: state.groupId,
        lineId: state.lineId,
        devId: state.devId,
        latitude: state.latitude,
        longitude: state.longitude,
        altitude: state.altitude,
        address: state.address,
        telNo: state.telNo,
        enable: 'y;'
      });
    }
  }, []);

  const disableButtonFunc = () => {
    if (
      values.facName == null ||
      values.facType == null
    ) {
      console.log(`bbb `)
      setDisableButton(true);
    }
    else {
      console.log(`ccc `)
      setDisableButton(false);
    }
  }
  useEffect(() => {
    console.log(`aaa ${JSON.stringify(values)}`)
    disableButtonFunc();
  }, [values]);

  const handleChange = (name: string, label: string, value: string) => {
    setValues({
      ...values,
      [label]: value
    });
  };

  const saveFacility = async () => {
    const fac: ISetFacilityInfo = {
      facId: values.facId,
      facName: values.facName,
      facType: values.facType,
      facDesc: values.facDesc,
      siteId: userStore.siteId,
      groupId: values.groupId,
      lineId: values.lineId,
      devId: values.devId,
      latitude: values.latitude,
      longitude: values.longitude,
      altitude: values.altitude,
      address: values.address,
      telNo: values.telNo,
      enable: values.enable,
    }
    if (values.facId == null) {
      return facilityStore.regFacilityInfo(fac);
    }
    return facilityStore.setFacilityInfo(fac);
  }
  //저장 or 수정
  const handleSave = async () => {
    const ret: any = await saveFacility();
    //isNumber로 숫자인지 검사 =>  isNumber 함수는 model/facility.ts에 만들어두었음.
    const isNumberlatitude = isNumber(values.latitude);
    const isNumberlongitude = isNumber(values.longitude);
    const isNumberaltitude = isNumber(values.altitude);
    if (values.latitude || values.longitude || values.altitude) {
      if (!isNumberlatitude || !isNumberlongitude || !isNumberaltitude) {
        ToastsStore.error('위도/경도/고도 입력값은 숫자여야 합니다.'); // 숫자가 아닌 경우 에러 메시지 표시
        return;
      }
    }
    if (ret.status == 200) {
      ToastsStore.success(window.location.pathname === '/slager/configfacility' ? `수정 성공` : `등록 성공`)
      const timer = setInterval(() => {
        clearInterval(timer);
        navigate(-1); // goback
      }, 1000)

    }
    else if (ret.status == 400) {
      ToastsStore.success(window.location.pathname === '/slager/configfacility' ? `수정 실패` : `등록 실패`)
    }
  }

  //연속등록 
  const handleSaveContinus = async () => {
    console.log(`handleSaveContinus ${JSON.stringify(values)}`)
    const ret: any = await saveFacility();
    if (ret.status == 200) {
      ToastsStore.success(window.location.pathname === '/slager/configfacility' ? `수정 성공` : `등록 성공`)
      setValues(
        {
          ...values,
          "facName": null,
          "facDesc": null,
          "facType": null,
          "latitude": null,
          "longitude": null,
        }
      )
    }
    else if (ret.status == 400) {
      ToastsStore.success(values.facName != null ? `수정 실패` : `등록 실패`)
    }
  }

  //취소
  const cancel = () => {
    navigate(-1);
  };

  return (
    <form
      autoComplete="off"
      noValidate
    >
      <Box>
        <Typography
          sx={{
            position: 'relative',
            height: 1,
            textAlign: 'right',
            right: '1%'
          }}
          gutterBottom>
          {window.location.pathname === '/slager/configfacility' ? `시설 > 수정 > 시설 수정` : `시설 > 시설등록`}
        </Typography>
      </Box>
      
      <Typography //타이틀 글자 조정
        sx={{
          fontSize: 30,
          fontWeight: 600,
          color: '#0C3149',
          display: 'flex',
          position: 'relative',
          left: '1%',
          marginBottom: '1%'
        }}
        gutterBottom >
        {window.location.pathname === '/slager/configfacility'
          ? `시설 수정` : `시설 등록`}
      </Typography>

      {!isMobile &&
        <Table>
          <TableRow>
            <TableCell sx={{ padding: 1, borderTop: 2, borderTopColor: '#BFBFBF', borderBottom: '1px dotted #bdbdbd' }}>
              <StyledNameInput
                name="시설이름"
                label="facName"
                value={values.facName}
                disabled={false}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="설명"
                label="facDesc"
                value={values.facDesc}
                disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="위도"
                label="latitude"
                value={values.latitude}
                disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>
          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="경도"
                label="longitude"
                value={values.longitude}
                disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="고도"
                label="altitude"
                value={values.altitude}
                disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 2, borderBottom: 1, borderBottomColor: '#BFBFBF' }}>
              <StyledNameSelect
                name="시설종류"
                label="facType"
                object={Facilitys}
                defaultValue={getType(values.facType)}
                space={'3rem'}
                nameWidth={'7rem'}
                valueWidth={'18rem'}
                onSelect={handleChange}
              /></TableCell>
          </TableRow>
        </Table>
      }
      {isMobile &&
        <Table>
          <TableRow>
            <TableCell sx={{ padding: 1, borderTop: 2, borderTopColor: '#BFBFBF', borderBottom: '1px dotted #bdbdbd' }}>
              <StyledNameInput
                name="시설이름"
                label="facName"
                value={values.facName}
                //disabled={false}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="설명"
                label="facDesc"
                value={values.facDesc}
                //disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="위도"
                label="latitude"
                value={values.latitude}
                //disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>
          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="경도"
                label="longitude"
                value={values.longitude}
                //disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
              <StyledNameInput
                name="고도"
                label="altitude"
                value={values.altitude}
                disabled={false}
                onChange={handleChange}
              /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 2, borderBottom: 1, borderBottomColor: '#BFBFBF' }}>
              <StyledNameSelect
                name="시설종류"
                label="facType"
                object={Facilitys}
                defaultValue={getType(values.facType)}
                onSelect={handleChange}
              /></TableCell>
          </TableRow>
        </Table>
      }
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          marginBottom: 3
        }}
      >
        <Button
          className={styles.greenButton}
          color="primary"
          variant="contained"
          onClick={cancel}
        >
          취소
        </Button>
        <Button
          className={styles.primaryButton}
          color="primary"
          variant="contained"
          disabled={disableButton}
          onClick={handleSave}
        >
          {window.location.pathname !== '/slager/configfacility' ? '등록' : '수정'}
        </Button>
        {window.location.pathname !== '/slager/configfacility' &&
          <Button
            className={styles.primaryButton}
            color="primary"
            variant="contained"
            disabled={disableButton}
            onClick={handleSaveContinus}
          >
            연속 등록
          </Button>
        }
      </Box>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </form>
  );
};

export default RegisterFacImp;
