// 과거 css 대신 썼던 styled 컴포넌트 입니다.
// 현재는 사용하지않습니다.

import * as React from 'react';
import {styled, Typography, TableCell, Button}from '@mui/material';
import { ChevronsRight, ChevronsLeft } from 'react-feather';

const ButtonBlue = styled(Button)`
            color:#FFFFFF;
            margin:5px ;
            background:#1C75BC;
    `;
const ButtonGreen = styled(Button)`
            color:#FFFFFF;
            margin:5px ;
            background:#125B37;
    `;
const ChevronRightIcon = styled(ChevronsRight)`
color:#8A8A8A;
`

const ChevronLeftIcon = styled(ChevronsLeft)`
color:#8A8A8A;
`

const CellText =styled(Typography)`
color:#000000;`;

const MiddleTable = styled(TableCell)` 
color:#E6E7E8;
    `;
    export {MiddleTable , CellText , ButtonBlue, ButtonGreen, ChevronRightIcon, ChevronLeftIcon}; //export 모듈! => 더 공부해보기

//호출할땐 <CusTypo sx={{ fontSize: 25 }}  gutterBottom> 사용자 등록</CusTypo> 이렇게 하면됨.
// export는 여러개 , default는 한개

