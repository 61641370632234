import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import NameInputRem from 'components/atom/NameInputRem';
import Space from 'components/atom/Space';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

type LocationInputBarProps = {
  size?:number;
  onSearch:(lat:string, lng:string)=> void;
  onSearchDeg:(lat:string, lng:string)=> void;
};
const LocationInputBar = ({ size, onSearch, onSearchDeg }: LocationInputBarProps) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [width, setWidth] = useState(size);

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitudeDeg, setLatitudeDeg] = useState('');
  const [longitudeDeg, setLongitudeDeg] = useState('');

  useEffect(() => {
  },[]);

  useEffect(() => {
    setWidth(size);
  },[size]);
  
  console.log(`LocationInputBar size : ${size}`)

  const handleSearch = () => {
    onSearch(latitude, longitude);
  };

  const handleSearchDeg = () => {
    onSearchDeg(latitudeDeg, longitudeDeg);
  };

  const handleChangeLat = (name:string, value:string) => {
    console.log(`handleChange name:${name}, value"${value}`)
    setLatitude(value);
  };

  const handleChangeLng = (name:string, value:string) => {
    console.log(`handleChange name:${name}, value"${value}`)
    setLongitude(value);
  };

  const handleChangeLatDeg = (name:string, value:string) => {
    console.log(`handleChangeDeg name:${name}, value"${value}`)
    setLatitudeDeg(value);
  };

  const handleChangeLngDeg = (name:string, value:string) => {
    console.log(`handleChangeDeg name:${name}, value"${value}`)
    setLongitudeDeg(value);
  };

  return (
    <Card>
      <CardContentNoPadding>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p:1,
          }}
        >
          <Box
            sx={{
              p: 1,
              border: 1,
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              입력예제
            </Typography>
            <Typography
              color="textPrimary"
            >
              위도 : DM 37.229230, Deg 37.3820662
            </Typography>
            <Typography
              color="textPrimary"
            >
              경도: DM 126.489372, Deg 126.815621
            </Typography>
          </Box>
          <Box
            sx={{
              p: 1,
              border: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: { width },
              }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: '7rem'
                      }}
                    >
                      <Typography
                        color="textPrimary"
                      >
                        DM(GPS) :
                      </Typography>
                    </Box>
                    <Space width='2'></Space>
                    <NameInputRem
                      name='위도'
                      value={latitude}
                      space={'1rem'}
                      nameWidth={'2rem'}
                      valueWidth={'10rem'}
                      onChange={handleChangeLat}
                    />
                    <Space width='2'></Space>
                    <NameInputRem
                      name='경도'
                      value={longitude}
                      space={'1rem'}
                      nameWidth={'2rem'}
                      valueWidth={'10rem'}
                      onChange={handleChangeLng}
                    />
                    <IconButton
                      onClick={() => handleSearch()}
                      type="submit"
                      sx={{ p: '10px' }}
                      aria-label="search"
                      size="large">
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: { width },
              }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: '7rem'
                      }}
                    >
                      <Typography
                        color="textPrimary"
                      >
                        Deg(Google) :
                      </Typography>
                    </Box>
                    <Space width='2'></Space>
                    <NameInputRem
                      name='위도'
                      value={latitudeDeg}
                      space={'1rem'}
                      nameWidth={'2rem'}
                      valueWidth={'10rem'}
                      onChange={handleChangeLatDeg}
                    />
                    <Space width='2'></Space>
                    <NameInputRem
                      name='경도'
                      value={longitudeDeg}
                      space={'1rem'}
                      nameWidth={'2rem'}
                      valueWidth={'10rem'}
                      onChange={handleChangeLngDeg}
                    />
                    <IconButton
                      onClick={() => handleSearchDeg()}
                      type="submit"
                      sx={{ p: '10px' }}
                      aria-label="search"
                      size="large">
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </CardContentNoPadding>
    </Card >
  );
};

export default LocationInputBar;
