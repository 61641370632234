// 사이드 네비게이션 (자식 카테고리)
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, ListItem, SvgIconProps } from '@mui/material';
import { AlignLeft, ChevronDown as ChevronDownIcon, ChevronRight as ChevronRightIcon } from 'react-feather';

interface NavMenuProps {
  href: string;
  Icon : any;
  title: string;
  state:boolean;
  onClick: () => void;
};
const NavMenu = ({
  href,
  Icon,
  title,
  state,
  onClick,
  ...rest
}:NavMenuProps) => {
  const location = useLocation();

  const active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;

  console.log(`menu state ${state}`)

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        width:'215px'
      }}
      {...rest}
    >
      <Button
        onClick={onClick}
        sx={{
          color: 'text.primary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          //minWidth: 230,
          width:'215px',
          ...(active && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          }
        }}
        // to={href}
      >
        <Box sx={{
          width:'100%',
          display: 'flex',
          flexDirection: 'row',
        }} >
          <Box sx={{ flex: 1, 
            justifyContent:'flext-start',
            alignItems:'flex-start',
            position:'relative',
            top:3
          }} >
            {Icon && (
              <Icon size="20"/>
            )}
          </Box>
          <Box sx={{ flex: 3, 
            justifyContent:'flext-start',
            alignItems:'flex-end',
            textAlign:'left',
            margin:'auto'
            }} >
            {title}
          </Box>
          <Box sx={{ flex: 1, 
            justifyContent:'flext-end',
            alignItems:'flex-end',
            }} >
        {state == true ?
          <ChevronDownIcon size="20" />
          :
          <ChevronRightIcon size="20" />
        }
          </Box>
        </Box>
      </Button>
    </ListItem>
  );
};


export default NavMenu;
