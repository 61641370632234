// 사이트관리 - 중계기 - 기기등록 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import RegisterDeviceImp from 'components/facility/RegisterDeviceImp';

const RegisterDevice = () => {
  console.log('RegisterDevice')
  return(
  <>
    <Helmet>
      <title>RegisterDevice</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
            <RegisterDeviceImp />
      </Container>
    </Box>
  </>
);
    };

export default RegisterDevice;
