// 시설운영 - 그룹 - 설정 - 시설관리 (등록시설)
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	Box,
	Button,
	Card,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import theme from 'theme';
import { IFacilityInfo, ISetFacilityInfo, getFacTypeStr } from 'model/facility';
import { ChevronLeftIcon } from 'components/admin/system/Styled';
import { ArrowLeft as ArrowLeftIcon } from 'react-feather';
import { isMobile } from 'react-device-detect';

type FacilitysInLineProps = {
	facilityList?: IFacilityInfo[];
	doit?: string;
	title?: string;
	onSelected?: (facId: string) => void;
	onCommand?: (command: string) => void;
	onDelete?: (facid: string) => void;
};
const FacilitysInLine = ({ facilityList, title, doit, onSelected, onCommand, onDelete }: FacilitysInLineProps) => {
	const [selectedFacIds, setSelectedFacIds] = useState([]);
	const [limit, setLimit] = useState(8);
	const [page, setPage] = useState(1);
	const navigate = useNavigate();

	//하단 페이지 넘김
	const handlePageChange = (event: any, newPage: number) => {
		console.log(`handlePageChange ${newPage}`)
		setPage(newPage);
	};

	return (
		<>
			<PerfectScrollbar>
				<Card
				>
					<Box
						sx={{
							minWidth: !isMobile && 400,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								width: '100%',
								height: '3rem',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								backgroundColor: '#F7B31E'
							}}
						>
							<Box
								sx={{
									flex: 1,
									alignItems: 'center',
								}}>
								<Typography
									color={theme.palette.text.primary}
									variant="h3"
									align="center"
								>
									{title} {facilityList?.length !== undefined && `(${facilityList?.length}개)`}
								</Typography>
							</Box>

						</Box>

						<Table size="small">
							<TableHead sx={{ minWidth: 400 }}>
								<TableRow>
									<TableCell sx={{ width: '20%', fontWeight: 600, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
										해제
									</TableCell>
									<TableCell sx={{ fontWeight: 600, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
										시설유형
									</TableCell>
									{!isMobile &&
										<TableCell sx={{ fontWeight: 600, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
											이름
										</TableCell>
									}
									{/*<TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
										등록 시설 라인
							         </TableCell>*/}
								</TableRow>
							</TableHead>
							<TableBody>
								{/*{facilityList != null && facilityList?.slice(page * limit, limit + page * limit).map((facInfo, index) => (*/}
								{facilityList?.slice((page - 1) * limit, limit + (page - 1) * limit).map((facInfo, index) => (
									<TableRow
										hover
										key={facInfo.facId}
										selected={selectedFacIds.indexOf(facInfo.facId) !== -1}
									>
										<TableCell sx={{ textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
											<Button
												sx={{ fontSize: isMobile && 12 }}
												onClick={() => {
													console.log(`해제`)
													onDelete(facInfo.facId);
												}}
											>
												<ArrowLeftIcon style={{ color: '#8A8A8A', width: '20px', height: '20px', marginLeft: 3, marginBottom: 2.5 }} />해제
											</Button>
										</TableCell>
										{!isMobile &&
											<TableCell sx={{ fontSize: isMobile && 12, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
												{getFacTypeStr(facInfo.facType)}
											</TableCell>
										}
										<TableCell sx={{ fontSize: isMobile && 12, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
											<Button
												onClick={() => {
													console.log(`${facInfo.facName}`)
													onSelected(facInfo.facId)
												}}
											>
												{facInfo.facName}
											</Button>
										</TableCell>
										{/*<TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
											{facInfo?.lineName}
											</TableCell>*/}
									</TableRow>
								))}
								{(!facilityList) &&
									<TableRow>
										<TableCell colSpan={12} sx={{ height: '35px', textAlign: 'center', borderBottomColor: '#E6E7E8' }}>
											<Typography sx={{ fontSize: 17, fontWeight: 600 }}>시설이 없습니다.</Typography>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					</Box>
					<Pagination
						color="primary"
						page={page}
						count={Math.ceil(facilityList?.length / 8)}
						onChange={handlePageChange}
						sx={{
							height: '50px',
							width: '100%',
							//maxWidth:isMobile && '100%',
							display: 'flex',
							justifyContent: 'center',
							[theme.breakpoints.down('sm')]: {
								width: window.innerWidth,
							},
						}}
					/>
					<ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
				</Card >
			</PerfectScrollbar>
		</>
	);
};

export default FacilitysInLine;
