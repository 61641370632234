// 시설운영 - 그룹 - 설정 - 시설관리 (미등록시설)
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	Box,
	Button,
	Card,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import theme from 'theme';
import { IFacilityInfo, ISetFacilityInfo, getFacTypeStr } from 'model/facility';
import { ChevronRightIcon } from 'components/admin/system/Styled';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import { isMobile } from 'react-device-detect';
type FacilitysOutLineProps = {
	facilityList?: IFacilityInfo[];
	doit?: string;
	title?: string;
	onSelected?: (command: string) => void;
	onCommand?: (command: string) => void;
	onAdd?: (facid: string) => void;
};
const FacilitysOutLine = ({ facilityList, title, doit, onSelected, onCommand, onAdd }: FacilitysOutLineProps) => {
	const [selectedFacIds, setSelectedFacIds] = useState([]);
	const [limit, setLimit] = useState(8);
	const [page, setPage] = useState(1);
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	//하단 페이지 넘김
	const handlePageChange = (event: any, newPage: number) => {
		console.log(`handlePageChange`)
		setPage(newPage);
	};

	return (
		<>
			<Card>
				<PerfectScrollbar>
					<Box
						sx={{
							minWidth: !isMobile && 400,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								width: '100%',
								height: '3rem',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								backgroundColor: '#44B572'
							}}
						>
							<Box
								sx={{
									flex: 1,
									alignItems: 'center',
								}}>
								<Typography
									color={theme.palette.text.primary}
									variant="h3"
									align="center"
								>
									{title} {facilityList?.length !== undefined && `(${facilityList?.length}개)`}
								</Typography>
							</Box>
						</Box>
						<Table size="small">
							<TableHead sx={{ minWidth: 400 }}>
								<TableRow>
									{!isMobile &&
										<TableCell sx={{ fontWeight: 600, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
											시설유형
										</TableCell>
									}
									<TableCell sx={{ fontWeight: 600, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
										이름
									</TableCell>
									<TableCell sx={{ fontWeight: 600, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
										라인 등록 여부
									</TableCell>
									<TableCell sx={{ width: '20%', fontWeight: 600, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8', borderBottom: 2, borderBottomColor: '#1C75BC ', borderTop: 2, borderTopColor: '#A6A6A6' }}>
										등록
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{facilityList?.slice((page - 1) * limit, limit + (page - 1) * limit).map((facInfo, index) => (
									<TableRow
										hover
										key={facInfo.facId}
										selected={selectedFacIds.indexOf(facInfo.facId) !== -1}
									>
										{!isMobile &&
											<TableCell sx={{ textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
												{getFacTypeStr(facInfo.facType)}
											</TableCell>
										}
										<TableCell sx={{ fontSize: isMobile && 12, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
											{facInfo.facName}
										</TableCell>
										<TableCell sx={{ fontSize: isMobile && 12, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
											{/*facInfo.lineName이 null이 아니면 `${facInfo.lineName}에 등록됨을 출력. null이면 '미등록'출력*/}
											{facInfo.lineName != null ? <><Typography sx={{ fontSize: isMobile && 12 }}><span style={{ color: '#1C75BC', fontSize: isMobile && 12 }}>{facInfo.lineName}</span>에 등록 됨</Typography></> : `미등록`}
										</TableCell>
										<TableCell sx={{ fontSize: isMobile && 12, textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
											<Button
												sx={{ fontSize: isMobile && 12 }}
												onClick={() => {
													console.log(`등록`)
													onAdd(facInfo.facId);
												}}
											>
												{facInfo.lineName != null ? `등록변경` : `등록`}
												<ArrowRightIcon style={{ color: '#8A8A8A', width: '20px', height: '20px', marginLeft: 3, marginBottom: 2.5 }} />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Box>
				</PerfectScrollbar>
				<Pagination
					color="primary"
					page={page}
					count={Math.ceil(facilityList?.length / 8)}
					onChange={handlePageChange}
					sx={{
						height: '50px',
						width: '100%',
						//maxWidth: isMobile && '100%',
						display: 'flex',
						justifyContent: 'center',
						[theme.breakpoints.down('sm')]: {
							width: window.innerWidth,
						},
					}}
				/>
				<ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
			</Card >
		</>
	);
};

export default FacilitysOutLine;
