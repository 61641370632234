import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import MyAccountToolbar from 'components/myaccount/MyAccountToolbar';
import MyAccountProfile from 'components/myaccount/MyAccountProfile';
import userStore from 'state/user';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

const MyAccount = observer(() => {
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    userStore.getUserInfo(userStore.getId());
  }, []);

  const handleCommand = (command: string) => {
    console.log(`handleCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }


  return(
  <>
    <Helmet>
      <title>ccount | Material Kit</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <MyAccountToolbar onCommand={handleCommand}/>
          <MyAccountProfile userInfo={userStore.userInfo} doit={doit}/>
      </Container>
    </Box>
  </>
);
});

export default MyAccount;
