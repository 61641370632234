import React, { useEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import { Box } from '@mui/material';
import { useRect } from '../../utils/useRect';
import { useMediaQuery } from 'react-responsive';
import LocationInputBar from './LocationInputBar';
import LocationMarker from './LocationMarker';
import { convDMS2DegEx } from 'utils/getLocation';

type GoogleMapLocationProps = Record<string, never>;

const GoogleMapLocation = ({
}:GoogleMapLocationProps) => {
	const [position, setPosition] = useState({lat:37.323, lng:126.840});
	const [left, setLeft] = useState(0);
	const [top, setTop] = useState(0);
	const [sbWidth, setSbWidth] = React.useState(800);
	const isMobile = useMediaQuery({ maxWidth: 767 })
	const [latitude, setLatitude] = useState<number>(0);
	const [longitude, setLongitude] = useState<number>(0);


	const handleClick = (id:string, _latitude:number, _longitude:number) => {
		console.log(`cyi handleClick ${id}, ${_latitude}, ${_longitude}`)
		if (_latitude != 0) {
			setPosition({ lat: _latitude, lng: _longitude })
		}
	}

	const ref1 = useRef<HTMLDivElement>();
	const ref2 = useRef<HTMLDivElement>();
	const bbox = useRect(ref1);
  useEffect(() => {
	console.log(` width bbbbb 1 : ${JSON.stringify(bbox)}`)
	console.log(` width bbbbb 2 : ${bbox.left}`)
	if (isMobile) {
		setLeft(ref1.current.getBoundingClientRect().left);
		setTop(ref1.current.getBoundingClientRect().top);
		setSbWidth(ref1.current.getBoundingClientRect().width - 8); // just left is 8, why I don't know
	}
	else {
		setLeft(ref1.current.getBoundingClientRect().left + 10);
		setTop(ref1.current.getBoundingClientRect().top + 10);
		setSbWidth(800);
	}
	console.log(` ref2 height bbbbb : ${ref2.current.getBoundingClientRect().height}`)
  }, [bbox])

	const handleSearch = (lat:string, lng:string) => {
		console.log(`handleSearch: ${convDMS2DegEx(lat)}, ${convDMS2DegEx(lng)}`);
		if (lat == '0' || lng == '0')
			return;
		if (lat == '' || lng == '')
			return;
		setLatitude(convDMS2DegEx(lat));
		setLongitude(convDMS2DegEx(lng));
		setPosition({ lat: convDMS2DegEx(lat), lng: convDMS2DegEx(lng) })
	}

	const handleSearchDeg = (lat:string, lng:string) => {
		console.log(`handleSearchDeg: ${Number(lat)}, ${Number(lng)}`);
		if (lat == '0' || lng == '0')
			return;
		if (lat == '' || lng == '')
			return;
		setLatitude(Number(lat));
		setLongitude(Number(lng));
		setPosition({ lat: Number(lat), lng: Number(lng) })
	}

	return (
		<div ref={ref1} style={{ height: '90vh', width: '100%' }}>
			<Box
				ref={ref2}
				sx={{ zIndex: 1, position: "absolute", top: top, left: left }}>
				<LocationInputBar size={sbWidth} onSearch={handleSearch} onSearchDeg={handleSearchDeg}></LocationInputBar>
			</Box>
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyCfL4YTPX5g9tqbESwNcOmJfT9HD_ET87Q" }} // yichoi99
				center={position}
				defaultZoom={16}
			>
				{latitude > 0 &&
						<LocationMarker
						key='1'
						id='1'
						type='1'
						lat={latitude}
						lng={longitude}
						text=''
						status=''
						tooltip={`${latitude}, ${longitude}`}
						onClick={() => handleClick('aa', latitude, longitude)}
						/>
				}
			</GoogleMapReact>
		</div>
	);
};

export default GoogleMapLocation;