// 시설운영 - 그룹 - 설정 - 그룹설정(시설)

import { useCallback, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	Box,
	Button,
	Card,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Divider,
	Drawer,
	FormControlLabel,
	Pagination,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import theme from 'theme';
import { IFacilityInfo, IFacilityScheduleUpdateInfo } from 'model/facility';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import FacDetailInfoDialog from 'components/atom/FacDetailInfoDialog';
import { observer } from 'mobx-react';
import { ButtonBlue } from 'components/admin/system/Styled';
import lineStore from 'state/line';
import { Square as SquareIcon, Wifi as WifiIcon, WifiOff as WifiOffIcon, Tool as ToolIcon, Zap as VoltIcon, Calendar as CalendarIcon, AlertTriangle as AlertTriangleIcon } from 'react-feather';
import moment from 'moment';
import styles from './style.module.css'
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import { positions } from '@mui/system';

type FacilityDeviceListProps = {
	selectedOperation: string;
	selectOnTime: string;
	selectOffTime: string;
	groupId?: string;
	lineId?: string;
	facId?: string;
	title?: string;
	devTimeUpdate: string;
	handleSelected?: () => void;
	onSelected?: (command: string) => void;
	onCommand?: (command: string) => void;
	onClose?: () => void;
	mode?: string;
};
const FacilityDeviceList = observer(({ selectedOperation, selectOnTime, selectOffTime, groupId, lineId, title, onSelected, onCommand, onClose, handleSelected, mode }: FacilityDeviceListProps) => {
	const [selectedFacIds, setSelectedFacIds] = useState([]);
	const [limit, setLimit] = useState(7);
	const [page, setPage] = useState(1);
	const [showFacInfo, setShowFacInfo] = useState(false);
	const [facId, setFacId] = useState('');
	const [showFacDetailInfoDialog, setshowFacDetailInfoDialog] = useState(false);
	const [ModeChange, setModeChange] = useState('normal');
	const [lineList, setLineList] = useState<any[]>([]);
	const [lineFacilityDetailInfoList, setLineFacilityDetailInfoList] = useState<any[]>([]);
	const [facilitySchedule, setFacilitySchedule] = useState<any[]>([]);
	const [checkMonth, setCheckMonth] = useState('');
	const [changeMode, setChangeMode] = useState('');
	const [lineFacId, setLineFacId] = useState('');
	const [lineFacName, setLineFacName] = useState('');

	const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
	const [openApplyDialog, setOpenApplyDialog] = useState(false);
	const [selectedUpdateFacId, setSelectedUpdateFacId] = useState(null);
	const [selectedFacName, setSelectedFacName] = useState(null);
	const [selectedRedIndex, setSelectedRedIndex] = useState(null);
	const [lastData, setLastData] = useState<any>()
	const [renewLoading, setRenewLoading] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [applyLoading, setApplyLoading] = useState(false);
	const [fixedLoading, setFixedLoading] = useState(false);

	//facility.lineId == lineInfo.lineId가 일치하는것만 filter
	console.log("testString", facilityStore.getMode())

	//라인 정보 가져옴
	useEffect(() => {
		setLineFacilityDetailInfoList(facilityStore.facilityDetailInfoList?.filter(facility => facility?.lineId === lineId))
		console.log("lineFacilityDetailInfoList", JSON.stringify(lineFacilityDetailInfoList))
	}, [facilityStore.facilityDetailInfoList]);

	useEffect(() => {
		console.log(`FacilityDeviceList useEffect groupId=${groupId} lineId=${lineId}`)
		if (lineId != '-1') {
			facilityStore.getFacilityDetailInfoList(userStore.siteId, groupId, lineId, null);
		}
	}, [lineId]);

	useEffect(() => {
		console.log(`FacilityDeviceList useEffect groupId=${groupId} lineId=${lineId}`)
		if (lineId != '-1') {
			facilityStore.getFacilityDetailInfoList(userStore.siteId, groupId, lineId, null);
		}
	}, [facilityStore.inFacilityList]);

	useEffect(() => {
		console.log(`fffaaa ${JSON.stringify(lineList)}`)
		return () => {
			facilityStore.inFacilityList = [];
		}
	}, []);

	useEffect(() => {
		console.log(`FacilityDeviceList useEffect inFacilityList=${JSON.stringify(facilityStore.inFacilityList)}`)
	}, [facilityStore.inFacilityList]);

	//하단 페이지 넘김
	const handlePageChange = (event: any, newPage: number) => {
		console.log(`handlePageChange ${newPage}`)
		setPage(newPage);
	};

	//상세보기 open
	const handleDetailDialogOpen = async (_facId: string) => {
		setFacId(_facId)
		console.log("wowow", _facId)
		setshowFacDetailInfoDialog(true);
	};
	//상세보기 close
	const handleClose = async () => {
		console.log(`handleClose in facilitylinelist`)
		setshowFacDetailInfoDialog(false);
		await lineStore.getLineList(userStore.siteId, groupId, userStore.getId(), "10", "1");
	};

	//facilityScheduleInfo를 가져와서 배열로 만들었음.
	const getFacilityScheduleList = async () => {
		const facilityScheduleList = [];

		for (let i = 0; i < lineFacilityDetailInfoList?.length; i++) {
			const facility = lineFacilityDetailInfoList[i];
			if (facility.lineId === lineId) {
				const facilitySchedule1 = await facilityStore.getFacilityScheduleUpdateInfo(facility.facId);
				facilityScheduleList.push(facilitySchedule1);
				if (facilitySchedule1 && facilitySchedule1.length > 0) {
					const lastdata = facilitySchedule1[facilitySchedule1.length - 1];
					setLastData(lastdata)
				} else {
					console.log("데이터가 없네요.");
				}
			}
		}
		setFacilitySchedule(facilityScheduleList);
		console.log("facility Schedule", JSON.stringify(facilitySchedule));
	};

	// 모드변경시 팝업
	const getFacilityScheduleListChangeMode = async () => {
		const facilityScheduleList = [];

		for (let i = 0; i < lineFacilityDetailInfoList?.length; i++) {
			const facility = lineFacilityDetailInfoList[i];
			if (facility.lineId === lineId) {
				const facilitySchedule1 = await facilityStore.getFacilityScheduleUpdateInfo(facility.facId);
				const combineData = {
					facilitySchedule: facilitySchedule1,
					facName: facility.facName,
					facId: facility.facId
				};
				console.log('kkk', JSON.stringify(combineData))
				facilityScheduleList.push(combineData);
				if (facilitySchedule1 && facilitySchedule1.length > 0) {
					const lastdata = facilitySchedule1[facilitySchedule1.length - 1];
					setLastData(lastdata)
					if (lastdata.timeUpdate < lastdata.timeRequest) {
						ToastsStore.warning(`점멸기의 설정 정보가 서버에 업데이트 되어 있지 않습니다. \n가져오기 버튼을 통해서 최신 정보를 가지고 와주세요.`);
					}
				} else {
					console.log("데이터가 없네요.");
				}
			}
		}
		setFacilitySchedule(facilityScheduleList);
		console.log("facility Schedule", JSON.stringify(facilitySchedule));
	};
	// getFacilityScheduleList 함수실행
	useEffect(() => {
		getFacilityScheduleList();
	}, [lineId, lineFacilityDetailInfoList]);

	// 라인정보 가져옴
	useEffect(() => {
		lineStore.getLine(lineId);
	}, [lineId]);

	//가져오기
	const RenewRepeat = async (_facId: string, _facName: string) => {
		console.log(`updateOn ${facId}`);
		let count = 1;
		const maxMinute = 24; // 5초 * 24 = 120초 = 2분
		setRenewLoading(true);

		const timerOpen = setInterval(async () => {
			if (facilityStore.facilityRenewResult === "REQ") {
				ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} 의 이전 명령이 진행중입니다.`)
				clearInterval(timerOpen);
				setRenewLoading(false);
			}
		}, 100);

		const timer1 = setInterval(async () => {
			console.log(`updateOnss : ${count}`);
			if (count >= maxMinute) {
				setRenewLoading(false);
				console.log("2분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.");
				clearInterval(timer1);
			} else {
				try {
					//cmdFacilityCtrl이 REQ면 실행종료
					if (facilityStore.facilityRenewResult === 'REQ') {
						clearInterval(timer1);
					}
					//cmdFacilityCtrl이 DONE 혹은 NO_COMMAND면 getCmdRunState실행
					if (facilityStore.facilityRenewResult === "DONE" || facilityStore.facilityRenewResult === "NO_COMMAND") {
						setRenewLoading(true)
						await facilityStore.getCmdRunState(_facId, '');
						console.log(`on ? ${facId}`, JSON.stringify(facilityStore.facilityCmdState));
						//getCmdRunState가 DONE 혹은 NO_COMMAND면 getCmdRunState종료하고 getFacilityScheduleUpdateInfo실행
						if (facilityStore.facilityCmdState !== 'REQ') {
							ToastsStore.success(`${_facName}의 가져오기 명령이 전달되었습니다.`);
							setRenewLoading(false);
							clearInterval(timer1);

							const timer = setInterval(async () => {
								console.log(`updateOnOff loop : ${count}`);
								if (count >= maxMinute) {
									setRenewLoading(false);
									console.log("2분이 지났습니다.");
									ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.");
									clearInterval(timer);
								} else {
									try {
										//getCmdRunState가 DONE 혹은 NO_COMMAND면 getCmdRunState종료하고 getFacilityScheduleUpdateInfo실행
										if (facilityStore.facilityCmdState && facilityStore.facilityCmdState !== 'REQ') {
											const renewFacilitySchedule = await facilityStore.getFacilityScheduleUpdateInfo(_facId);
											const facilityScheduleList = [];
											facilityScheduleList.push(renewFacilitySchedule);
											setRenewLoading(false);
											// 마지막 배열 가져옴
											if (renewFacilitySchedule && renewFacilitySchedule.length > 0) {
												const lastdata = renewFacilitySchedule[renewFacilitySchedule.length - 1];
												setLastData(lastdata)
												// timeUpdate가 TimeRequest보다 크면 참
												if (lastdata.timeUpdate > lastdata.timeRequest) {
													ToastsStore.success(`${_facName}의 최신정보를 디바이스에서 가져왔습니다.`);
													clearInterval(timer);
												}
											} else {
												console.log("실패");
											}
										}
										//모든 조건이 실패했을 때 실패
										else {
											setRenewLoading(false);
											ToastsStore.warning(`${_facName} 갱신 실패`)
											clearInterval(timer);
										}

									} catch (error) {
										console.error("!!! 에러 !!!:", error);
										setRenewLoading(false);
										clearInterval(timer);
									}
								}
								count++;
							}, 5000);
						}
					}
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setRenewLoading(false);
				}
			}
			count++;
		}, 5000);
	}

	//업데이트
	const updateRepeat = async (_facId: string, _facName: string) => {
		console.log(`updateRepeat ${_facId}`);
		let count = 1;
		const maxMinute = 120; // 5초 * 120 = 600초 = 10분
		console.log("fac ?", facilityStore.facilityUpdateResult)
		setUpdateLoading(true);

		const timerOpen = setInterval(async () => {
			if (facilityStore.facilityUpdateResult === "REQ") {
				ToastsStore.warning(`${_facName} 의 이전 명령이 진행중입니다.`)
				clearInterval(timerOpen);
				setUpdateLoading(false);
			}
		}, 100);

		const timer = setInterval(async () => {
			console.log(`updateOnOff loop : ${count}`);
			if (count >= maxMinute) {
				setUpdateLoading(false);
				console.log("10분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
				clearInterval(timer);
			} else {

				try {
					//getCmdRunState가 DONE 혹은 NO_COMMAND면 getCmdRunState종료하고 getFacilityScheduleUpdateInfo실행
					if (facilityStore.facilityUpdateResult === 'DONE' || facilityStore.facilityUpdateResult === 'NO_COMMAND') {
						setUpdateLoading(true);
						await facilityStore.getScheduleUpdateRunState(_facId, '');
						console.log(`count ? ${_facId}`, JSON.stringify(facilityStore.facilityUpdateCount));

						//count가 0이 가져오기cmdCtrl 실행.
						if (facilityStore.facilityUpdateCount == 0) {
							clearInterval(timer);
							const timer1 = setInterval(async () => {
								await facilityStore.updateFacilityScheduleRenew(_facId)

								// cmdCrtl이 REQ가 아니면 getCmdRunState 실행
								if (facilityStore.facilityRenewResult !== 'REQ') {
									clearInterval(timer1)
									const timer2 = setInterval(async () => {
										await facilityStore.getCmdRunState(_facId, '')

										//cmdRunState가 REQ가 아니면 getFacilityScheduleUpdateInfo실행
										if (facilityStore.facilityCmdState !== 'REQ') {
											clearInterval(timer2)
											const timer3 = setInterval(async () => {
												const ret: any = await facilityStore.getFacilityScheduleUpdateInfo(_facId);

												//ret데이터가 존재하면 화면 갱신하고 업데이트 완료 alert
												if (ret) {
													setUpdateLoading(false);
													alert('업데이트 명령이 전달되었습니다!\n모드 설정 버튼을 눌러 적용해주세요.');
													getFacilityScheduleListChangeMode();
													clearInterval(timer3);
												}
											}, 5000);
										}
									}, 5000);
								}
							}, 5000);
						}
					}
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setUpdateLoading(false);
					clearInterval(timer);
				}
			}
			count++;
		}, 5000);
	};

	//모드설정
	const ApplyRepeat = async (_facId: string, _facName: string) => {
		console.log(`updateRepeat ${_facId}`);
		let count = 1;
		const maxMinute = 120; // 5초 * 120 = 600초 = 10분
		console.log("fac ?", facilityStore.facilityUpdateResult)
		setApplyLoading(true);

		const timerOpen = setInterval(async () => {
			if (facilityStore.facilityApplyResult === "REQ") {
				ToastsStore.warning(`${_facName} 의 이전 명령이 진행중입니다.`)
				clearInterval(timerOpen);
				setApplyLoading(false);
			}
		}, 100);

		const timer = setInterval(async () => {
			console.log(`updateOnOff loop : ${count}`);
			if (count >= maxMinute) {
				setApplyLoading(false);
				console.log("10분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
				clearInterval(timer);
			} else {

				try {
					if (facilityStore.facilityApplyResult === 'DONE' || facilityStore.facilityApplyResult === 'NO_COMMAND') {
						setApplyLoading(true);
						await facilityStore.getCmdRunState(_facId, '');

						if (facilityStore.facilityCmdState !== 'REQ') {
							clearInterval(timer)
							setApplyLoading(false);
							alert('모드설정 명령이 전달되었습니다!');
						}
					}
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setApplyLoading(false);
					clearInterval(timer);
				}
			}
			count++;
		}, 5000);
	};

	// 고정 가져오기
	const FixedRepeat = async (_facId: string, _facName: string) => {
		console.log(`updateOn ${facId}`);
		let count = 1;
		const maxMinute = 24; // 5초 * 24 = 120초 = 2분
		setFixedLoading(true);

		const timerOpen = setInterval(async () => {
			if (facilityStore.facilityFixedResult === "REQ") {
				ToastsStore.warning(`${_facName} 의 이전 명령이 진행중입니다.`)
				clearInterval(timerOpen);
				setFixedLoading(false);
			}
		}, 100);
		const timer1 = setInterval(async () => {
			console.log(`updateOnss : ${count}`);
			if (count >= maxMinute) {
				setFixedLoading(false);
				console.log("2분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.");
				clearInterval(timer1);
			} else {
				try {
					//cmdFacilityCtrl이 REQ면 실행종료
					if (facilityStore.facilityFixedResult === 'REQ') {
						clearInterval(timer1);
					}
					//cmdFacilityCtrl이 DONE 혹은 NO_COMMAND면 getCmdRunState실행
					if (facilityStore.facilityFixedResult === "DONE" || facilityStore.facilityFixedResult === "NO_COMMAND") {
						setFixedLoading(true)
						await facilityStore.getCmdRunState(_facId, '');
						console.log(`on ? ${facId}`, JSON.stringify(facilityStore.facilityCmdState));
						//getCmdRunState가 DONE 혹은 NO_COMMAND면 getCmdRunState종료하고 getFacilityScheduleUpdateInfo실행
						if (facilityStore.facilityCmdState !== 'REQ') {
							ToastsStore.success(`${_facName}의 가져오기 명령이 전달되었습니다.`);
							setFixedLoading(true)
							clearInterval(timer1);

							const timer = setInterval(async () => {
								console.log(`updateOnOff loop : ${count}`);
								if (count >= maxMinute) {
									setFixedLoading(false);
									console.log("2분이 지났습니다.");
									ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.");
									clearInterval(timer);
								} else {
									try {
										//getCmdRunState가 DONE 혹은 NO_COMMAND면 getCmdRunState종료하고 getFacilityScheduleUpdateInfo실행
										if (facilityStore.facilityCmdState && facilityStore.facilityCmdState !== 'REQ') {
											const fixedFacilitySchedule = await facilityStore.getFacilityScheduleUpdateInfo(_facId);
											const facilityScheduleList = [];
											facilityScheduleList.push(fixedFacilitySchedule);
											setFixedLoading(false);
											// 마지막 배열 가져옴
											if (fixedFacilitySchedule && fixedFacilitySchedule.length > 0) {
												const lastdata = fixedFacilitySchedule[fixedFacilitySchedule.length - 1];
												setLastData(lastdata)
												// timeUpdate가 TimeRequest보다 크면 참
												if (lastdata.timeUpdate > lastdata.timeRequest) {
													setFixedLoading(false);
													ToastsStore.success(`${_facName}의 최신 정보를 디바이스에서 가져왔습니다.`);
													clearInterval(timer);
												}
											} else {
												console.log("실패");
											}
										}
										//모든 조건이 실패했을 때 실패
										else {
											setFixedLoading(false);
											ToastsStore.warning(`${_facName} 갱신 실패`)
											clearInterval(timer);
										}
									} catch (error) {
										console.error("!!! 에러 !!!:", error);
										setFixedLoading(false);
										clearInterval(timer);
									}
								}
								count++;
							}, 5000);
						}
					}
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setFixedLoading(false);
				}
			}
			count++;
		}, 5000);
	}

	// 가져오기 실행
	const handleRenew = async (_facId: string, _facName: string) => {
		console.log("ww0", _facId)
		await facilityStore.updateFacilityScheduleRenew(_facId)
		RenewRepeat(_facId, _facName);
	}
	// 업데이트 실행
	const handleUpdate = async (_facId: string, value1: any) => {
		console.log("ww0", _facId)
		await facilityStore.updateFacilityScheduleUpdateInfo(_facId, value1)
		updateRepeat(_facId, selectedFacName)
		setUpdateLoading(true)
	}
	// 모드설정 실행
	const handleApply = async (_facId: string, _facName: string) => {
		console.log("ww0", _facId)
		await facilityStore.updateFacilityScheduleApply(_facId)
		ApplyRepeat(_facId, _facName);
	}
	//고정모드 - 모드변경
	const handleFixed = async (_facId: string, _facName: string) => {
		console.log("ww0", _facId)
		await facilityStore.updateFacilityFixed(_facId)
		FixedRepeat(_facId, _facName);
	}
	//모드변경
	const handleChangeMode = ({ target: { value } }: any) => {
		setModeChange(value);
		if (value == 'schedule' || value == 'fixed')
			getFacilityScheduleListChangeMode();
		console.log("??")
	};

	// status 값 가져오는데 필요한 것들
	const curDate = new Date();
	const getNow = curDate.getTime();
	console.log(`현재시간 초 ${JSON.stringify(getNow)}`);
	// ON.OFF 타임 Date로 변환 ( String -> Date )
	const newDate = new Date()

	const formDate = moment(newDate).format('YYYY-MM-DD');
	const onTime1 = new Date(`${formDate}T${selectOnTime}`);
	const offTime1 = new Date(`${formDate}T${selectOffTime}`);

	const getOnMs = onTime1.getTime();
	const getOffMs = offTime1.getTime();

	// 스케줄모드 monthCheck
	const handleCheckMonth = (index: string) => {
		let updateIndex: string[] = [];

		if (checkMonth !== '') {
			updateIndex = checkMonth.split(',');
		}

		const indexString = String(Number(index) + 1)

		const indexIndex = updateIndex.indexOf(indexString);
		if (indexIndex !== -1) {
			updateIndex.splice(indexIndex, 1);
		} else {
			updateIndex.push(indexString);
		}

		updateIndex.sort((a, b) => Number(a) - Number(b));
		const finalResult = updateIndex.join(',');
		setCheckMonth(finalResult);
		console.log("checkMonth", finalResult);

		return finalResult;
	};
	// 업데이트를 하시겠습니까? dialog닫음
	const handleUpdateDialogClose = () => {
		setOpenUpdateDialog(false);
	};
	// 업데이트 하시겠습니까? dialog열음.
	const handleOpenUpdateDialog = (_facId: any, _redIndex: any, _facName: string) => {
		setSelectedRedIndex(_redIndex)
		setSelectedFacName(_facName)
		console.log('ddcc', setSelectedFacName)
		setSelectedUpdateFacId(_facId)
		setOpenUpdateDialog(true);
		console.log("open?", openUpdateDialog)
	};
	// 업데이트를 마치셨습니까? dialog닫음
	const handleApplyDialogClose = () => {
		setOpenApplyDialog(false);
	};
	// 업데이트를 마치셨습니까? dialog열음
	const handleOpenApplyDialog = (_facId: string, _facName: string) => {
		//setSelectedRedIndex(_redIndex)
		setSelectedUpdateFacId(_facId)
		setSelectedFacName(_facName)
		setOpenApplyDialog(true);
		console.log("open?", openUpdateDialog)
	};

	// modeChange 실행
	useEffect(() => {
		if (facilityStore.getMode()) {
			setModeChange(facilityStore.getMode());
		} else {
			setModeChange('normal');
		}
	}, [facilityStore.getMode()]);

	// 기본모드는 normal로 설정됨.
	useEffect(() => {
		setModeChange('normal');
	}, [lineId]);

	return (
		<Card sx={{ backgroundColor: '#FEF5E2' }}>
			<PerfectScrollbar>
				<Box
					sx={{
						minWidth: 400,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							width: '100%',
							height: !isMobile ? '3rem' : 'auto',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							backgroundColor: '#F7B31E'
						}}
					>
						<Box
							sx={{
								flex: 1,
							}}>
						</Box>

						<Box
							sx={{
								flex: 1,
								alignItems: 'center',
							}}>

							<Typography
								color={theme.palette.text.primary}
								variant="h3"
								align="center"
							>
								{title} {lineFacilityDetailInfoList?.length !== undefined && `(${lineFacilityDetailInfoList?.length}개)`}
							</Typography>
						</Box>
						<Box sx={{
							flex: 1,
							alignItems: 'flex-end',
							display: 'flex',
							flexDirection: 'column'
						}}>
							{/* 일반,스케줄,고정모드 radioButton */}
							<RadioGroup
								aria-label="answer"
								name="answer"
								value={ModeChange}
								onChange={handleChangeMode}
								sx={{ display: 'inline-block', float: 'right' }}>
								<FormControlLabel
									value="normal"
									control={<Radio sx={{ fontSize: 10, transform: 'scale(0.9)', p: '6px' }} />}
									label={<span style={{ fontSize: 14 }}>일반</span>}
								/>

								{(lineStore.lineInfo?.operationMode === 'S') &&
									<FormControlLabel
										value="schedule"
										control={<Radio sx={{ fontSize: 10, transform: 'scale(0.9)', p: '6px' }} />}
										label={<span style={{ fontSize: 14 }}>스케줄</span>}
										disabled={ModeChange === 'fixed'}
									/>
								}
								{(lineStore.lineInfo?.operationMode === 'O') &&
									<FormControlLabel
										value="fixed"
										control={<Radio sx={{ fontSize: 10, transform: 'scale(0.9)', p: '6px' }} />}
										label={<span style={{ fontSize: 14 }}>고정</span>}
										disabled={ModeChange === 'schedule'}
									/>}
							</RadioGroup>
						</Box>
					</Box>
					{/* 일반모드 table */}
					{ModeChange == 'normal' &&
						<Table size="small">
							<TableHead sx={{ minWidth: 400 }}>
								{!isMobile &&
									<TableRow>
										<TableCell className={styles.scheduleTableHeadEnd} sx={{ width: '20%' }}>
											이름
										</TableCell>
										<TableCell className={styles.scheduleTableHead} sx={{ width: '15%' }}>
											상태
										</TableCell>
										<TableCell className={styles.scheduleTableHead} sx={{ width: '15%' }}>
											에러
										</TableCell>
										<TableCell className={styles.scheduleTableHead} sx={{ width: '13%' }}>
											센서값
										</TableCell>
										<TableCell className={styles.scheduleTableHead} sx={{ width: '20%' }}>
											최종 업데이트
										</TableCell>
										<TableCell className={styles.scheduleTableHead} sx={{ width: '6%' }}>
											기능
										</TableCell>
									</TableRow>
								}
							</TableHead>

							<TableBody>
								{lineFacilityDetailInfoList?.slice((page - 1) * limit, limit + (page - 1) * limit).map((fac, index) => {
									// on,off  정상,불량 상태확인
									const devDate = new Date(fac.devTimeUpdate)
									const getDevNow = devDate.getTime()
									let chipColor: any = "success"
									let statusChipColor: any = "success"
									let onoffState: any = ""
									let statusState = ""
									let state = ""

									if ((fac.operationMode == 'M' || fac.operationMode == 'N') && fac.vout1 > 500) {
										onoffState = "ON"
										chipColor = "info";
										console.log(`${fac.facName} ${fac.vout1 / 10}v ==== 수동 켜진 상태(정상)`)
									}
									else if ((fac.operationMode == 'M' || fac.operationMode == 'N') && fac.vout1 < 500) {
										onoffState = "OFF"
										chipColor = "default";
										console.log(`${fac.facName} ${fac.vout1 / 10}v ==== 수동 꺼진 상태(정상)`)
									}
									else if ((getDevNow > getOnMs) && (getDevNow > getOffMs) && fac.vout1 > 500) {
										onoffState = "ON"
										chipColor = "success"
										state = '정상'
										console.log(`${fac.facName} ${fac.vout1 / 10}v ==== 켜진 상태(정상)`)
									}
									else if ((getDevNow > getOnMs) && (getDevNow > getOffMs) && fac.vout1 < 500) {
										onoffState = "ON"
										chipColor = "default"
										state = '에러'
										console.log(`${fac.facName}  ${fac.vout1 / 10}v ==== 켜진상태이지만 전압이 500 이하일 때(에러)`)
									}
									else if ((getDevNow < getOnMs) && (getDevNow < getOffMs) && fac.vout1 < 500) {
										onoffState = "OFF"
										chipColor = "default"
										state = '정상'
										console.log(`${fac.facName}  ${fac.vout1 / 10}v ==== 한번 켜진 뒤 OFF 상태(정상)`)
									}
									else if ((getDevNow < getOnMs) && (getDevNow > getOffMs) && fac.vout1 > 500) {
										onoffState = "ON"
										chipColor = "default"
										state = '에러'
										console.log(`${fac.facName}  ${fac.vout1 / 10}v ==== OFF 상태이지만 전압이 500 이상(에러)`)
									}
									else if ((getDevNow < getOnMs) && (getDevNow < getOffMs) && fac.vout1 > 500) {
										onoffState = "OFF"
										chipColor = "default"
										state = '에러'
										console.log(`${fac.facName}  ${fac.vout1 / 10}v ==== 한번 켜진 뒤 OFF 상태이지만 전압이 500 이상인 상태(에러)`)
									}
									else if ((getDevNow < getOnMs) && (getDevNow > getOffMs) && fac.vout1 < 500) {
										onoffState = "OFF"
										chipColor = "default"
										state = '정상'
										console.log(`${fac.facName}  ${fac.vout1 / 10}v 켜지기 전 OFF 상태(정상) `)
									}
									else if ((!getOnMs) && (!getOffMs) && fac.vout1 > 500) {
										onoffState = "ON(M)";
										chipColor = "default";
										state = '에러';
										console.log(`${fac.facName}  ${fac.vout1 / 10}v 등록된 시간이 없어 상태를 확인할 수 없습니다.`)
									}
									else if ((!getOnMs) && (!getOffMs) && fac.vout1 < 500) {
										onoffState = "OFF(M)";
										chipColor = "default";
										state = '에러';
										console.log(`${fac.facName}  ${fac.vout1 / 10}v 등록된 시간이 없어 상태를 확인할 수 없습니다.`)
									}
									else {
										onoffState = "미이행"
										chipColor = "warning"
										state = '에러'
										console.log(`${fac.facName}  ${fac.vout1 / 10}v 등록된 시간이 없어 상태를 확인할 수 없습니다.`)
									}
									if (fac.status == "정상") {
										statusState = "정상"
										statusChipColor = "info"
									}
									else {
										statusState = "불량"
										statusChipColor = "default"
									}
									return (
										<TableRow
											sx={{ display: isMobile ? 'flex' : 'table-row', justifyContent: 'center', padding: '10px' }}
											hover
											key={fac.facId}
											selected={selectedFacIds.indexOf(fac.facId) !== -1}
										>
											{!isMobile &&
												<><TableCell className={styles.scheduleTableBodyEnd}>
													<Typography sx={{ color: '#222222', fontSize: 14 }}>{fac.facName}</Typography>
													<Typography sx={{ color: '#bdbdbd', fontSize: 12 }}>{fac.facDesc}</Typography>
												</TableCell><TableCell className={styles.scheduleTableBody} sx={{ p: 0 }}>
														<Box // 연결, 미연결

															sx={{
																p: 0,
																display: 'flex',
																flexWrap: 'wrap',
																width: '100%',
																marginLeft: 'auto',
																marginRight: 'auto',
																justifyContent: 'center'
															}}>
															<Box sx={{ ml: 0.5 }}> </Box>
															<Chip // ON , OFF

																label={onoffState}
																color={chipColor}
																size="small"
																sx={{
																	width: '30%',
																	ml: '1%',
																	mb: '3%',
																	border: state === '에러' ? '1px solid #F0474C' : 'none',
																}}
															></Chip>
															<Box sx={{ ml: 0.5 }}> </Box>
															{statusState === '불량' ? (
																<Chip
																	label={statusState}
																	color={statusChipColor}
																	//onClick={getFacilityAlive}
																	size="small"
																	sx={{
																		width: '42%',
																		ml: '1%',
																	}}
																	icon={<WifiOffIcon style={{ width: '16px', height: '16px' }} />} // 통신불량 아이콘
																></Chip>
															) : (
																<Chip
																	label={statusState}
																	color={statusChipColor}
																	//onClick={getFacilityAlive}
																	size="small"
																	sx={{
																		width: '42%',
																		ml: '1%',
																	}}
																	icon={<WifiIcon style={{ width: '16px', height: '16px' }} />} // 정상 아이콘
																></Chip>
															)}
														</Box>
													</TableCell><TableCell className={styles.scheduleTableBody}>
														{fac.reason}
													</TableCell><TableCell className={styles.scheduleTableBody}>
														{fac.vout1 && `${parseInt(fac.vout1) / 10} V / ${parseInt(fac.cur1) / 10} A`}
														{!fac.vout1 && `- V / - A`}
													</TableCell><TableCell className={styles.scheduleTableBody}>
														{fac.devTimeUpdate && `${fac.devTimeUpdate}`}
														{!fac.devTimeUpdate && `-`}
													</TableCell><TableCell className={styles.scheduleTableBody} sx={{ width: '20%' }}>
														<ButtonBlue
															size='small'
															sx={{
																color: '#222222',
																backgroundColor: '#FFB267',
																"&:hover": { backgroundColor: "#A67443" }
															}}
															onClick={() => {
																console.log(`${fac.facName}`);
																handleDetailDialogOpen(fac.facId);
															}}
														>
															상세보기
														</ButtonBlue>
													</TableCell></>
											}
											{isMobile &&
												<Box
													sx={{
														justifyContent: 'space-around',
														alignItems: 'center',
														border: 1,
														borderRadius: 3,
														borderColor: '#E6E7E8',
														boxShadow: '#bdbdbd 5px 5px 5px',
														backgroundColor: '#FFFFFF',
														textAlign: 'center',
														p: 1,
														//margin: "2%",
														width: '90%',
														height: '110px',
														//marginRight: 'auto',
													}}
												><Box sx={{ position: 'relative', top: 20 }}>
														<Box sx={{ display: 'flex', justifyContent: 'center' }}>
															<Typography sx={{ color: '#222222', fontSize: 15, fontWeight: 600 }}>⦁ {fac.facName}</Typography>
															<Box sx={{ ml: 0.5 }}> </Box>
															{/* <Typography sx={{ color: '#bdbdbd', fontSize: 12 }}>#{fac.facDesc}</Typography> */}
															<Box sx={{ ml: 0.5 }}> </Box>
															<Box // 연결, 미연결

																sx={{
																	float: 'right',
																	p: 0,
																	display: 'contents',
																	flexWrap: 'wrap',
																	//width: '100%',
																	marginLeft: '10',
																	marginRight: 'auto',
																	justifyContent: 'center'
																}}>
																<Box sx={{ ml: 0.5 }}> </Box>
																<Chip // ON , OFF

																	label={onoffState}
																	color={chipColor}
																	size="small"
																	sx={{
																		//width: '30%',
																		ml: '1%',
																		mb: '3%',
																		border: state === '에러' ? '1px solid #F0474C' : 'none',
																	}}
																></Chip>
																<Box sx={{ ml: 0.5 }}> </Box>
																{statusState === '불량' ? (
																	<Chip
																		label={statusState}
																		color={statusChipColor}
																		//onClick={getFacilityAlive}
																		size="small"
																		sx={{
																			//width: '42%',
																			ml: '1%',
																		}}
																		icon={<WifiOffIcon style={{ width: '16px', height: '16px' }} />} // 통신불량 아이콘
																	></Chip>
																) : (
																	<Chip
																		label={statusState}
																		color={statusChipColor}
																		//onClick={getFacilityAlive}
																		size="small"
																		sx={{
																			//width: '42%',
																			ml: '1%',
																		}}
																		icon={<WifiIcon style={{ width: '16px', height: '16px' }} />} // 정상 아이콘
																	></Chip>
																)}
																<Box sx={{ ml: 1.5 }}> </Box>
																<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
																	<Typography sx={{ color: "#ED1C24", fontSize: 13 }}>
																		<AlertTriangleIcon style={{ position: 'relative', display: 'inline', width: 15, height: 15, top: 3.5, right: 2, color: 'red' }} />
																		{fac.reason}
																	</Typography>
																</Box>
															</Box>
														</Box>
														<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
															<Box sx={{ margin: 'auto' }}>
																<Typography sx={{ color: "#7F7F7F", fontSize: 13 }}>
																	<VoltIcon style={{ color: '#7F7F7F', width: 13, height: 13 }} />
																	{fac.vout1 && `${parseInt(fac.vout1) / 10} V / ${parseInt(fac.cur1) / 10} A`}
																	{!fac.vout1 && `- V / - A`}
																</Typography>
															</Box>
															<Box sx={{ margin: 'auto' }}>
																<Typography sx={{ color: "#7F7F7F", fontSize: 13 }}>
																	<CalendarIcon style={{ color: '#7F7F7F', width: 13, height: 13 }} />
																	{fac.devTimeUpdate && `${fac.devTimeUpdate}`}
																	{!fac.devTimeUpdate && `-`}
																</Typography>
															</Box>
															<Box>
																<Button
																	variant='outlined'
																	color='info'
																	size='small'
																	sx={{
																		textAlign: 'right'
																	}}
																	onClick={() => {
																		console.log(`${fac.facName}`);
																		handleDetailDialogOpen(fac.facId);
																	}}
																>
																	상세보기
																</Button>
															</Box>
														</Box>
														<Box sx={{ mb: 1 }}> </Box>
														<Divider />
													</Box>
												</Box>
											}
										</TableRow>
									)
								})}
								{/* 시설이 없을경우 */}
								{!lineFacilityDetailInfoList &&
									<TableRow>
										<TableCell className={styles.scheduleTableNoFacility} colSpan={12} >
											<Typography sx={{ fontSize: 17, fontWeight: 600 }}>시설이 없습니다.</Typography>
										</TableCell>
									</TableRow>
								}
								{(lineFacilityDetailInfoList?.length == 0) &&
									<TableRow>
										<TableCell className={styles.scheduleTableNoFacility} colSpan={12} >
											<Typography sx={{ fontSize: 17, fontWeight: 600 }}>시설이 없습니다.</Typography>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					}

					{/* 스케줄모드 */}
					{(ModeChange == 'schedule') &&
						<Table size="small">
							<TableHead sx={{ minWidth: 400 }}>
								<TableRow>
									<TableCell className={styles.scheduleTableHeadEnd}>
										이름
									</TableCell>
									<TableCell className={styles.scheduleTableHead}>
										스케줄 업데이트 상태
									</TableCell>
									<TableCell className={styles.scheduleTableHead}>
										기능 &ensp;
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{facilitySchedule?.slice((page - 1) * limit, limit + (page - 1) * limit).map((fac, index) => {
									//스케줄모드 1~12부터 체크박스
									console.log('kkkk', fac?.facName)
									const scheduleColors: any[] = [];
									let allScheduleColorsNOUP = true;
									let allColorsGreen = true;

									const redIndex: string[] = [];

									for (let i = 1; i <= 12; i++) {
										const monthInfo = fac?.facilitySchedule && fac?.facilitySchedule?.find((item: any) => item.month === i.toString());
										const status = monthInfo?.status;
										console.log('wwaa', status)
										let fill = "#D6D6D6";
										let border = "#D6D6D6";

										if (status === "UP") {
											fill = "#B5E61D";
											border = "#B5E61D";
											allScheduleColorsNOUP = false;
										} else if (status === "NOUP") {
											fill = "#ED1C24";
											border = "#ED1C24";
											redIndex.push((i + 1).toString()); // 빨간색 인덱스를 배열에 추가
										}
										if (fill !== "#B5E61D") {
											allColorsGreen = false;
										}
										scheduleColors[i] = { fill, border, status: status || "기타" };
									}

									if (allScheduleColorsNOUP) {
										for (let i = 1; i <= 12; i++) {
											scheduleColors[i].fill = "#D6D6D6";
											scheduleColors[i].border = "#D6D6D6";
											scheduleColors[i].status = "기타";
										}
									}
									const redIndex1 = redIndex.join(',')
									console.log('aaaxColor', JSON.stringify(scheduleColors));

									return (
										<TableRow
											hover
											key={fac?.facId}
											selected={selectedFacIds.indexOf(fac?.facId) !== -1}
										>
											<TableCell className={styles.scheduleTableBodyEnd}>
												<Typography key={index} sx={{ color: '#222222', fontSize: 15 }}>{fac?.facName}</Typography>
											</TableCell>
											<TableCell className={styles.scheduleTableBody}>
												<Box>
													{/* <Box sx={{ position: 'relative', top: 4 }}>
														{scheduleColors?.map((color: any, indexColor: any) => (
															<SquareIcon
																key={indexColor}
																fill={color.fill}
																stroke={color.border}
																style={{ margin: '7px' }} />

														))}
													</Box> */}
													<Box>
														{scheduleColors?.map((color: any, indexColor: any) => (
															<Box sx={{ display: 'inline-block' }}>
																<Box>
																	<Typography sx={{ display: 'flex', position: 'relative', left: '6px' }}>
																		{indexColor}
																	</Typography>
																</Box>
																<Box>
																	<Checkbox
																		key={indexColor}
																		size='small'
																		onClick={() => handleCheckMonth(indexColor)}
																		sx={{
																			mr: 2,
																			border: '1px solid color.border',
																			borderRadius: 2,
																			color: color.fill,
																			backgroundColor: color.fill,
																			width: '26px',
																			height: '26px',
																			'&:hover': {
																				backgroundColor: color.fill,
																			},
																		}}
																	></Checkbox>
																</Box>
															</Box>
														))}
													</Box>
												</Box>
											</TableCell>

											<TableCell className={styles.scheduleTableBody} sx={{ width: '28%' }}>
												{/* 개별로 loading돌리려면 어떻게? */}
												<LoadingButton
													loading={renewLoading}
													className={styles.blueButton}
													disabled={updateLoading}
													variant="contained"
													size='small'
													color="inherit"
													onClick={() => {
														handleRenew(fac.facId, fac.facName)
													}}
													sx={{ color: '#FFFFFF', "&:hover": { backgroundColor: "#10548A" } }}>
													가져오기
												</LoadingButton>
												<LoadingButton
													className={styles.blueButton}
													loading={updateLoading}
													disabled={renewLoading}
													variant="contained"
													size='small'
													color="inherit"
													onClick={() => { handleOpenUpdateDialog(fac.facId, redIndex1, fac.facName) }}
													sx={{ color: '#FFFFFF', "&:hover": { backgroundColor: "#10548A" } }}>
													업데이트
												</LoadingButton>
												<LoadingButton
													className={styles.blueButton}
													loading={applyLoading}
													variant="contained"
													size='small'
													color="inherit"
													onClick={() => { handleOpenApplyDialog(fac.facId, fac.facName) }}
													sx={{ color: '#FFFFFF', "&:hover": { backgroundColor: "#10548A" } }}>
													모드설정
												</LoadingButton>
												{/* <ButtonBlue variant="contained" size='small' color="inherit" sx={{ color: '#FFFFFF', "&:hover": { backgroundColor: "#10548A" } }}>
													확인요청(일단)
												</ButtonBlue> */}
											</TableCell>
										</TableRow>
									)

								})}
								{(!lineFacilityDetailInfoList) &&
									<TableRow>
										<TableCell className={styles.scheduleTableNoFacility} colSpan={12}>
											<Typography sx={{ fontSize: 17, fontWeight: 600 }}>시설이 없습니다.</Typography>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>}
					{/* 고정모드 */}
					{(ModeChange == 'fixed') &&
						<Table size="small">
							<TableHead sx={{ minWidth: 400 }}>
								<TableRow>
									<TableCell className={styles.scheduleTableHeadEnd} sx={{ width: '20%' }}>
										이름
									</TableCell>
									<TableCell className={styles.scheduleTableHead} sx={{ width: '15%' }}>
										소등 / 점등시간( OFF / ON )
									</TableCell>
									<TableCell className={styles.scheduleTableHead} sx={{ width: '5%' }}>
										기능
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{lineFacilityDetailInfoList?.slice((page - 1) * limit, limit + (page - 1) * limit).map((fac, index) => {


									return (
										<TableRow
											hover
											key={fac.facId}
											selected={selectedFacIds.indexOf(fac.facId) !== -1}
										>
											<TableCell className={styles.scheduleTableBodyEnd}>
												<Typography sx={{ color: '#222222', fontSize: 14 }}>{fac.facName}</Typography>
												<Typography sx={{ color: '#bdbdbd', fontSize: 12 }}>{fac.facDesc}</Typography>
											</TableCell>


											<TableCell className={styles.scheduleTableBody}>
												{selectOffTime} / {selectOnTime}
											</TableCell>

											<TableCell className={styles.scheduleTableBody} sx={{ width: '20%' }}>
												<LoadingButton
													loading={fixedLoading}
													className={styles.blueButton}
													disabled={updateLoading}
													variant="contained"
													size='small'
													color="inherit"
													onClick={() => {
														handleFixed(fac.facId, fac.facName)
													}}
													sx={{ color: '#FFFFFF', "&:hover": { backgroundColor: "#10548A" } }}>
													가져오기
												</LoadingButton>
												<LoadingButton
													className={styles.blueButton}
													loading={applyLoading}
													variant="contained"
													size='small'
													color="inherit"
													onClick={() => { handleOpenApplyDialog(fac.facId, fac.facName) }}
													sx={{ color: '#FFFFFF', "&:hover": { backgroundColor: "#10548A" } }}>
													모드설정
												</LoadingButton>
											</TableCell>
										</TableRow>
									)
								})}
								{!lineFacilityDetailInfoList &&
									<TableRow>
										<TableCell className={styles.scheduleTableNoFacility} colSpan={12} >
											<Typography sx={{ fontSize: 17, fontWeight: 600 }}>시설이 없습니다.</Typography>
										</TableCell>
									</TableRow>
								}
								{(lineFacilityDetailInfoList?.length == 0) &&
									<TableRow>
										<TableCell className={styles.scheduleTableNoFacility} colSpan={12} >
											<Typography sx={{ fontSize: 17, fontWeight: 600 }}>시설이 없습니다.</Typography>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					}
				</Box>
			</PerfectScrollbar>
			{/* 하단페이지넘김 */}
			<Pagination
				className={styles.pagination}
				color="primary"
				page={page}
				count={Math.ceil(facilityStore.facilityDetailInfoList?.length / 7)}
				onChange={handlePageChange}
				sx={{
					[theme.breakpoints.down('sm')]: {
						width: window.innerWidth,
					},
				}}
			/>

			{/* 업데이트 dialog */}
			<Dialog
				open={openUpdateDialog}
				onClose={handleUpdateDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{selectedRedIndex ? (
							<>
								{checkMonth ? `${checkMonth}월이 선택되었습니다. 업데이트 하시겠습니까?` : `${selectedRedIndex}월이 업데이트 되지 않았습니다. 업데이트 하시겠습니까?`}
							</>
						) : (
							// <>
							// {checkMonth ? `${checkMonth}월은 이미 업데이트 되었습니다. 다시 업데이트 하시겠습니까?` : `1~12월 모두 업데이트를 성공한 상태입니다. 모드설정 버튼을 눌러주세요.`}
							// </>
							'1~12월 모두 업데이트를 성공한 상태입니다. 모드설정 버튼을 눌러주세요.'
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>

					<Button className={styles.greenButton} onClick={handleUpdateDialogClose}>{selectedRedIndex ? '취소' : '닫기'}</Button>
					{selectedRedIndex &&
						<Button
							className={styles.primaryButton}
							onClick={() => {
								if (checkMonth) {
									handleUpdate(selectedUpdateFacId, checkMonth);
									handleUpdateDialogClose();
								} else {
									handleUpdate(selectedUpdateFacId, selectedRedIndex);
									handleUpdateDialogClose();
								}
							}}
							autoFocus
						>
							확인
						</Button>
					}
				</DialogActions>
			</Dialog>
			{/* 적용 Dialog */}
			<Dialog
				open={openApplyDialog}
				onClose={handleApplyDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
						{selectedOperation === 'S' ? (
							<div>
								업데이트를 마치셨습니까 ? <br />
								(업데이트를 마치지 않으셨다면 아니오를 누르고 <br />
								모드설정버튼 왼쪽에 있는 업데이트 버튼을 눌러주세요.)
							</div>
						) : (
							"설정한 ON/OFF 시간으로 적용하시겠습니까?"
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button className={styles.greenButton} onClick={handleApplyDialogClose}>아니오</Button>
					<Button
						className={styles.primaryButton}
						onClick={() => {
							handleApply(selectedUpdateFacId, selectedFacName);
							handleApplyDialogClose();
						}}
						autoFocus
					>
						예
					</Button>
				</DialogActions>
			</Dialog>
			<FacDetailInfoDialog
				facId={facId}
				onClose={handleClose}
				show={showFacDetailInfoDialog}
				onShow={() => { setShowFacInfo(false) }}></FacDetailInfoDialog>
			<ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
		</Card>
	);
});

export default FacilityDeviceList;
