//on/off 점소등 버튼 (상세보기에 있는 on,off버튼)
import * as React from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  ButtonGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import facilityStore from 'state/facility';



type ManualOnOffProps = {
  name : string;
  defaultValue? : string;
  space? : string;
  onLoading?:boolean;
  offLoading?:boolean;
  onDisabled?:any;
  offDisabled?:any;
  disabled? : boolean;
  nameWidth? : string;
  valueWidth? : string;
  onEvent?:(event:string)=>void;
};
const ManualOnOff = (
    { name, defaultValue = '0', disabled = false, onLoading =false,offLoading =false,onDisabled='',offDisabled='', nameWidth = '15rem', valueWidth = '20rem', space = '2rem',
      onEvent
    }
        : ManualOnOffProps) => {
    console.log(`ManualOnOff ${name}:${defaultValue}`)
    const [hour, setHour] = useState<string>('');
    const [min, setMin] = useState<string>('');
	//const [loading, setLoading] = useState(false);

    const handleEvent = (event:string) => {
        onEvent(event);
    }

    // const updateOnOff = (onOff: string) => {
	// 	// console.log(`updateOnOff ${facId}, ${onOff}`)
	// 	setLoading(true);
	// 	let count = 0;
	// 	const timer = setInterval(() => {
	// 		console.log(`updateOnOff loop : ${count}`)
	// 		if (count >= 3) {
	// 			setLoading(false);
	// 			clearInterval(timer);
	// 		}
	// 		else {
	// 			// console.log(`updateOnOff loop : ${count} : ${dashboardStore.facInfo.devCtrlKey} ${dashboardStore.facInfo.devCtrlStatus}`)
	// 			// if (dashboardStore.facInfo.devCtrlStatus == "DONE") {
	// 			// 	setLoading(false);
	// 			// 	clearInterval(timer);
	// 			// }
	// 		}
	// 		count++;
	// 		// dashboardStore.getDetail(facId);

	// 	}, 120000)
	// 	// clientStore.updateOnOff(facId, onOff);
	// }

    const handleOnClick = () => {
        //updateOnOff('ON');
		handleEvent('ON');
	};

	const handleOffClick = () => {
        //updateOnOff('OFF');
		handleEvent('OFF');
	};

    useEffect(() => {
        if (defaultValue) {
            console.log(`ManualOnOff values ${name}:${defaultValue}`)
        }
        else {
            console.log(`ManualOnOff values else ${name}:${defaultValue}`)
        }
    }, [defaultValue]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                {/* <Typography
                    sx={{
                        width: nameWidth,
                        padding:1
                    }}
                    color="textPrimary"
                    variant="h5"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space }} /> */}
                <ButtonGroup>
                    <LoadingButton //점소등 ON/OFF버튼
                    variant='outlined'
                    color='success'
                    loading={onLoading}
                    sx={{//color:'#1C75BC ',
                         //backgroundColor:'#bdbdbd',
                         border:1,
                         height:'31px'
                         //"&:hover": {backgroundColor: "#E3E3E3"}
                        }}
                        disabled={onDisabled}
                        onClick={() => handleOnClick()}
                    >On</LoadingButton>
                    <LoadingButton
                    variant='outlined'
                    color='error'
                    loading={offLoading}
                    sx={{//color:"#1C75BC ",
                        border:1,
                        height:'31px'
                         //backgroundColor:'#0C3149',
                         //"&:hover": {backgroundColor: "#15547D"}
                        }}
                        disabled={offDisabled}
                        onClick={() => handleOffClick()}
                    >Off</LoadingButton>
                </ButtonGroup>
            </Box>
        </>
    )
};

export default ManualOnOff;
