//관리자 - 사용자 (테이블)
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  useTheme,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { useStores } from 'state';
import { IUserDetail } from 'model';
import siteStore from 'state/site';
import { borderBottomColor, borderTop } from '@mui/system';
import { MiddleTable , CellText } from './Styled';
import styles from 'components/facilityGroup/style.module.css'


type AdminUserListResultsProps = {
  users : IUserDetail[];
  doit:string;
};
const AdminUserListResults = ({ users, doit }:AdminUserListResultsProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const { userStore } = useStores();

  //계정 삭제 dialog 닫응
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
// 삭제 
  const handleDeleteUser = async () => {

    await Promise.all(selectedUsers.map(async (user) => {
      console.log(`handleDeleteUser : selecteds ${JSON.stringify(user)}`)

      const ret: any = await userStore.delUser(user);
      console.log(`handleDeleteUser : ${JSON.stringify(ret)}`)
      if (ret.status == 200) {
        console.log(`handleDeleteUser : ${user.userId} is deleted`)
      }
      else {
        ToastsStore.warning("계정 삭제에 실패 했습니다.")
      }
    }));
    const newSelectedUserIds: any = [];
    setSelectedUserIds(newSelectedUserIds);
    console.log(`handleDeleteUser : refresh`)
    userStore.getUserListBySiteId("");
    setOpenDialog(false);
  };

  // 등록, 삭제 이동
  useEffect(() => {
    if (doit.includes("add")) {
      console.log(`add selectedUser ${JSON.stringify(selectedUsers)}`);
      navigate('/admin/memberform');
    }
    else if (doit.includes("delete")) {
      console.log(`delete selectedUser ${JSON.stringify(selectedUsers)}`);
      console.log(`delete selectedUser count ${(selectedUsers.length)}`);
      if (selectedUsers.length == 0) {
        ToastsStore.warning("선택된 사용자가 없습니다.")
        return;
      }
      setOpenDialog(true);
    }
    else if (doit.includes("modify")) {
      console.log(`modify ${JSON.stringify({state:selectedUser})}`)
      console.log(`modify selectedUser count ${(selectedUsers.length)}`);
      if (selectedUsers.length == 0) {
        // popup or something
        ToastsStore.warning("선택된 사용자가 없습니다.")
        return;
      }
      userStore.selectedUser = selectedUser;

    navigate('/admin/memberform', {
      state: {
        userId:selectedUser.userId,
        userPwd:selectedUser.userPwd,
        userName:selectedUser.userName,
        siteLevel:selectedUser.siteLevel,
        hpNo:selectedUser.hpNo,
        telNo:selectedUser.telNo,
        email:selectedUser.email,
        company:selectedUser.company,
        }
        });
    }

  },[doit]);

  // 체크박스 모두선택
  const handleSelectAll = (event:any) => {
    console.log(`handleSelecAll`)
    let newSelectedUserIds:any;

    if (event.target.checked) {
      newSelectedUserIds = users.map((user) => user.userId);
    } else {
      newSelectedUserIds = [];
    }

    setSelectedUserIds(newSelectedUserIds);
  };
//체크박스 하나씩 선택
  const handleSelectOne = (event:any, id:string, user:any) => {
    console.log(`handleSelectOne  : ${id}`)
    console.log(`handleSelectOne  event: ${event}`)

    const selectedIndex = selectedUserIds.indexOf(id);
    let newSelectedUserIds:any = [];
    let newSelectedUsers:any = [];

    console.log(`handleSelectOne  selectedindex: ${selectedIndex}`)
    if (selectedIndex === -1) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id);
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, user);
    } else if (selectedIndex === 0) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1));
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUserIds.length - 1) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(0, -1));
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUserIds = newSelectedUserIds.concat(
        selectedUserIds.slice(0, selectedIndex),
        selectedUserIds.slice(selectedIndex + 1)
      );
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUserIds(newSelectedUserIds);
    setSelectedUsers(newSelectedUsers);
    setSelectedUser(newSelectedUsers[newSelectedUsers.length -1]);
  };

  const handleLimitChange = (event:any) => {
    console.log(`handleLimitChange`)
    setLimit(event.target.value);
  };
//하단 페이지 넘김
  const handlePageChange = (event:any, newPage:number) => {
    console.log(`handlePageChange`)
    setPage(newPage);
  };

  return (
    <div>

        <PerfectScrollbar>
          <Box sx={{ minWidth: '100%' }}>
          <Table 
             sx={{width:'100%',
              [theme.breakpoints.down('sm')]: {
               width: window.innerWidth,
             },
            }}
               size='small'
             >
              <TableHead>
                <TableRow>
                  <TableCell
                  sx={{ textAlign:'center',borderRight: 1, borderRightColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6'}}>
                    No
                  </TableCell>
                  <TableCell padding="checkbox" sx={{ borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6'}}>
                    <Checkbox
                      checked={selectedUserIds.length === users.length}
                      color="primary"
                      indeterminate={
                        selectedUserIds.length > 0
                        && selectedUserIds.length < users.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
                    아이디
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6'}}>
                    사용자 명
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6'}}>
                    사이트
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6'}}>
                    핸드폰번호
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6'}}>
                    연락처
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {users.slice((page-1)*limit, limit + (page -1)*limit).map((user, index) => {
               console.log(`admin ${page} ${limit} ${user.userId}`)
              return(
                  <TableRow
                    hover
                    selected={selectedUserIds.indexOf(user.userId) !== -1}
                  >
                    <TableCell sx={{ textAlign:'center',borderRight: 1, borderRightColor:'#E6E7E8'}}>
                    {(page - 1) * limit + index + 1}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUserIds.indexOf(user.userId) !== -1}
                        onChange={(event) => handleSelectOne(event, user.userId, user)}
                        value="true"
                      />
                    </TableCell>
                    
                    {/*<TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      {user.userId}
                    </TableCell>
                    <TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      {user.userName}
                    </TableCell>
                    <TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      {user.hpNo}
                    </TableCell>
                    <TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      {user.telNo}
                    </TableCell> 안묶은것*/}
                    
                    <MiddleTable sx={{ textAlign:'center', borderLeft: 1}}>
                      <CellText>
                      {user.userId}
                      </CellText>
                    </MiddleTable>

                    <MiddleTable sx={{ textAlign:'center', borderLeft: 1}}>
                    <CellText>
                      {user.userName}
                      </CellText>
                      </MiddleTable>

                    <MiddleTable sx={{ textAlign:'center', borderLeft: 1}}>
                    <CellText>
                      {user.siteName}
                      </CellText>
                      </MiddleTable>

                    <MiddleTable sx={{ textAlign:'center', borderLeft: 1}}>
                    <CellText>
                      {user.hpNo}
                      </CellText>
                    </MiddleTable>

                    <MiddleTable sx={{ textAlign:'center', borderLeft: 1}}>
                    <CellText>
                      {user.telNo}
                      </CellText>
                    </MiddleTable>

                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Pagination
          				color="primary"
          				page={page}
          				count={Math.ceil(users.length / 10)}
          				onChange={handlePageChange}
          				sx={{
            				width: '100%',
            				display: 'flex',
            				justifyContent: 'center',
            				height:'50px',
            				[theme.breakpoints.down('sm')]: {
              				width: window.innerWidth,
            					},
          					}}
        					/>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            해당 계정을 삭제 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent:'center'}}>
          <Button className={styles.greenButton} onClick={handleDialogClose}>취소</Button>
          <Button className={styles.primaryButton} onClick={handleDeleteUser} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </div>
  );
};

export default AdminUserListResults;
