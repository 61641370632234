//nameInput width,height -> Rem으로 조정한것.
import * as React from 'react';
import {
  Box,
  Typography,
  Input,
} from '@mui/material';

type NameInputProps = {
  name : string;
  value : string;
  disabled? : boolean;
  space? : string;
  nameWidth? : string;
  valueWidth? : string;
  onChange:(name:string, value:string)=>void;
};
const NameInput = (
    { name, value, disabled = false, nameWidth = '15rem', valueWidth = '20rem', space = '2rem', onChange 
    }: NameInputProps) => {
    // const [value, setValue] = useState(defaultValue);
        console.log(`NameInput ${name}:${value}`)
        console.log(`${name} : ${nameWidth}, ${valueWidth}`)

// 글자 입력 함수
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`event = ${event}`)
        console.log(`event.name = ${event.target.name}`)
        console.log(`event.value = ${event.target.value}`)
        // setValue(event.target.value);
        onChange(event.target.name, event.target.value);
    };

return (
    <>
        <Box
            sx={{
                flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',

            }}
        >
            <Typography
                sx={{
                    m:'0.6rem',
                    p:'0.4rem'
                }}
                color="textPrimary"
                variant="h5"
            >
                {name}
            </Typography>
            <Box sx={{ width: space }} />
            <Input
                sx={{
                    m:'0.4rem',
                    p:'0.7rem',
                    border:1,
                    borderRadius : '5px',
                    borderColor:'#BFBFBF',
                    backgroundColor:"#FFFFFF",
                    width: '18rem',
                    height: '2.7rem',
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",    }           
                }}
                disabled={disabled}
                disableUnderline
                name={name}
                onChange={handleChange}
                required
                value={value}
                margin="none"
            />
        </Box>
    </>
)
};

export default NameInput;
