// 사이트 관리 - 중계기 (미연동시설)
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { IFacInfo } from '../../model';
import theme from 'theme';
import { IFacilityGatewayInfo, IFacilityInfo } from 'model/facility';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import { ButtonBlue, ButtonGreen } from 'components/admin/system/Styled';
import styles from 'components/facilityGroup/style.module.css'

type UnRegisteredFacGatewayListResultsProps = {
  facInfos: IFacilityGatewayInfo[];
  doit: string;
  title?: string;
};
const UnRegisteredFacGatewayListResults = ({ facInfos, title, doit }: UnRegisteredFacGatewayListResultsProps) => {
  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [selectedFacId, setSelectedFacId] = useState<IFacilityGatewayInfo>();
  const [limit, setLimit] = useState(7);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const handleSelectAll = (event: any) => {
    console.log(`handleSelecAll`)
    let newSelectedFacIds: any;

    if (event.target.checked) {
      newSelectedFacIds = facInfos.map((fac) => fac.facId);
    } else {
      newSelectedFacIds = [];
    }

    setSelectedFacIds(newSelectedFacIds);
  };

  const handleSelectOne = (event: any, id: string) => {
    console.log(`handleSelecOne  : ${id}`)

    const selectedIndex = selectedFacIds.indexOf(id);
    let newSelectedFacIds: any = [];

    console.log(`handleSelecOne  selectedindex: ${selectedIndex}`)
    if (selectedIndex === -1) {
      newSelectedFacIds = newSelectedFacIds.concat(selectedFacIds, id);
    } else if (selectedIndex === 0) {
      newSelectedFacIds = newSelectedFacIds.concat(selectedFacIds.slice(1));
    } else if (selectedIndex === selectedFacIds.length - 1) {
      newSelectedFacIds = newSelectedFacIds.concat(selectedFacIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedFacIds = newSelectedFacIds.concat(
        selectedFacIds.slice(0, selectedIndex),
        selectedFacIds.slice(selectedIndex + 1)
      );
    }

    setSelectedFacIds(newSelectedFacIds);
  };

  const handleLimitChange = (event: any) => {
    console.log(`handleLimitChange`)
    setLimit(event.target.value);
  };

  //하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

  //시설 삭제 팝업 닫기
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  //시설 삭제
  const handleDeleteFacilityGateway = async (facInfo: any) => {
    console.log(`handleDeleteFacilityGateway`)

    console.log(`handleDeleteFacilityGateway : selecteds ${facInfo.facId}`);
    const ret: any = await facilityStore.delFacilityGateway(facInfo.facId);
    if (!ret) {
      ToastsStore.warning("시설 삭제에 실패 했습니다.")
    }


    facilityStore.getFacilityGatewayInfoList(userStore.siteId, null);
    setOpenDialog(false);
  };

  //기기연동 페이지로 이동
  const handleConnectDeviceGateway = async (facInfo: any) => { 
    console.log("!!!!!!!!!!!!!!!!!!!!!!!", JSON.stringify(facInfo))
    navigate('/slager/ConnectDeviceGateway',
      {
        state: {
          siteId: facInfo.siteId,
          siteName: facInfo.siteName,
          facId: facInfo.facId,
          facName: facInfo.facName,
          facType: facInfo.facType,
          facDesc: facInfo.facDesc,
        }
      });
  }

  //수정 페이지로 이동
  const handleUpdate = async (facInfo: any) => {
    console.log(`Device for connect ret : ${facInfo.length}, ${JSON.stringify(facInfo)}`)
    navigate('/slager/configfacility',
      {
        state: {
          siteId: facInfo.siteId,
          siteName: facInfo.siteName,
          groupId: facInfo.groupId,
          groupName: facInfo.groupName,
          lineId: facInfo.lineId,
          lineName: facInfo.lineName,
          facId: facInfo.facId,
          facName: facInfo.facName,
          facType: facInfo.facType,
          facDesc: facInfo.facDesc,
          latitude: facInfo.latitude,
          longitude: facInfo.longitude,
          altitude: facInfo.altitude,
          address: facInfo.address,
          telNo: facInfo.telNo,
          devId: facInfo.devId,
          serialNo: facInfo.devSerialNo,
        }

      });
  }

  return (
    <div>
      <Card
        sx={{
          width: '98%',
          margin: 'auto'
        }}>
        <PerfectScrollbar>
          <Box
            sx={{
              minWidth: 400,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '3rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#44B572'
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  alignItems: 'center',
                }}>
                <Typography
                  color={theme.palette.text.primary}
                  variant="h3"
                  align="center"
                >
                  {title}({facInfos.length})
                </Typography>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
              </Box>
            </Box>
            <Table >
              <TableHead sx={{ minWidth: 400 }}>
                <TableRow>
                  <TableCell className={styles.tableHeadEnd} sx={{ width: '5%' }}>
                    No
                  </TableCell>
                  <TableCell className={styles.tableHead} sx={{ width: '20%' }}>
                    시설 이름
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    설명
                  </TableCell>
                  <TableCell className={styles.tableHead} sx={{ width: '31%' }}>
                    기능
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {facInfos.slice((page - 1) * limit, limit + (page - 1) * limit).map((facInfo, index) => (
                  <TableRow
                    hover
                    key={facInfo.facId}
                    selected={selectedFacIds.indexOf(facInfo.facId) !== -1}
                  >
                    <TableCell className={styles.tableBodyEnd}>
                      {(page - 1) * limit + index + 1}
                    </TableCell>
                    <TableCell className={styles.tableBody}>
                      {facInfo.facName}
                    </TableCell>
                    <TableCell className={styles.tableBody}>
                      {facInfo.facDesc}
                    </TableCell>
                    <TableCell sx={{ padding: '1%', width: '24%', textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
                      <ButtonBlue
                        sx={{
                          "&:hover": { backgroundColor: "#02090E" }
                        }}
                        size="small"
                        onClick={() => {
                          console.log(`수정`)
                          handleUpdate(facInfo);
                        }}
                      >
                        수정
                      </ButtonBlue>
                      <ButtonBlue
                        sx={{
                          "&:hover": { backgroundColor: "#10548A" }
                        }}
                        size="small"
                        onClick={() => {
                          console.log(`기기연동`)
                          handleConnectDeviceGateway(facInfo);
                        }}
                      >
                        기기연동
                      </ButtonBlue>
                      <ButtonGreen
                        sx={{
                          "&:hover": { backgroundColor: "#0E3B25" }
                        }}
                        size="small"
                        onClick={() => {
                          console.log(`삭제`)
                          setSelectedFacId(facInfo)
                          setOpenDialog(true);
                        }}
                      >
                        시설삭제
                      </ButtonGreen>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Pagination
          color="primary"
          page={page}
          count={Math.ceil(facInfos.length / 7)}
          onChange={handlePageChange}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height: '50px',
            [theme.breakpoints.down('sm')]: {
              width: window.innerWidth,
            },
          }}
        />
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              해당 시설을 삭제 하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent:'center'}}>
            <Button className={styles.greenButton} onClick={handleDialogClose}>취소</Button>
            <Button className={styles.primaryButton} onClick={() => {
              console.log(`삭제`);
              handleDeleteFacilityGateway(selectedFacId);
            }} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
      </Card>
    </div>
  );
};

export default UnRegisteredFacGatewayListResults;
