//시설운영 - 그룹 - 그룹추가  & 관리자 - 사용자관리 - 그룹추가
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useRadioGroup
} from '@mui/material';
import { useStores } from '../../state';
import { useLocation, useNavigate } from 'react-router';
import theme from 'theme';
import NameInputRem from 'components/atom/NameInputRem';
import groupStore from 'state/group';
import siteStore from 'state/site';
import { isSiteManager } from 'model';
import NameInput from 'components/atom/NameInput';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { isMobile } from 'react-device-detect';
import MobileNameInput from 'components/atom/MobileNameInput';

interface StyledNameInputProps {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}
function StyledNameInput({ name, value, disabled, onChange }: StyledNameInputProps) {
  return (
    <>
      <NameInput
        name={name}
        value={value}
        disabled={disabled}
        space={10}
        nameWidth={110}
        valueWidth={200}
        onChange={onChange}
      />
    </>
  );
}

const GroupType = [
  { id: 'groupName', name: '시설그룹명' },
  { id: 'description', name: '설명' },
];

interface ILocation {
  siteId: string,
  groupId: string,
  groupName: string,
  description: string,
}
const FacilityGroupFormImp = () => {
  const { gatewayStore, userStore } = useStores();
  const location = useLocation();
  const state = location.state as ILocation;
  const navigate = useNavigate();

  const [values, setValues] = useState({
    siteId: '',
    groupId: '',
    groupName: '',
    description: '',
  });

  console.log(`FacilityGroupFormImp ~~~~~~~~~~~~`)
  console.log(`FacilityGroupFormImp ${JSON.stringify(location)}`)

  //value 값 set
  useEffect(() => {
    console.log(`useEffect ${JSON.stringify(state)}`)
    console.log(`RegisterGateway user:id::  ${userStore.getId()}`);
    if (state != null) {
      setValues({
        ...values,
        siteId: state.siteId,
        groupId: state.groupId,
        groupName: state.groupName,
        description: state.description,
      });
    }
  }, []);
//입력 글자 바뀌는거
  const onChange = (name: string, value: string) => {
    const found = GroupType.find(item => item.name == name);
    if (found == null) {
      return;
    }

    setValues({
      ...values,
      [found.id]: value
    });
  };

  // 저장
  const handleSave = async () => {
    console.log(`handleSave ${JSON.stringify(values)}`)
    const group: any = {
      // siteId : values.siteId,
      // groupId : values.groupId,
      // groupName : values.groupName,
      // description : values.description,
      siteId: values.siteId,
      groupId: "",
      groupName: values.groupName,
      description: values.description,
    }
    // common use for gateway, lamp
    await groupStore.addGroup(group);
    {
      await groupStore.getGroupByName(values.siteId, values.groupName);
      await siteStore.getSiteUserList(userStore.siteId);

      {
        siteStore.userList.map(async user => {
          user.siteList.map(async (site) => {
            console.log(`user add to group site: ${site.siteId}: ${site.siteLevel}`)
            if (site.siteId == values.siteId && isSiteManager(site.siteLevel)) {
              ToastsStore.success("등록 성공")
              console.log(`---> user add to group site: ${site.siteId}: ${site.siteLevel}`)
              await groupStore.setUserToGroup(values.siteId, groupStore.groupInfo.groupId, user.userId, "");
            }

          })
        })
      }
    }

    const timer = setInterval(() => {
      navigate('/slager/facilitygroup');
      clearInterval(timer);
    }, 1000)
  }

  return (
    <Box>
      <Typography
        sx={{
          position: 'relative',
          height: 1,
          textAlign: 'right',
          right: '1%'
        }}
        gutterBottom>
        시설운영 {'>'} 그룹 {'>'} 그룹 추가
      </Typography>

      <Typography
        sx={{
          fontSize: 30,
          fontWeight: 600,
          color: '#0C3149',
          display: 'flex',
          position: 'relative',
          left: '1%',
          marginBottom: '1%'
        }}
        gutterBottom >
        그룹 추가
      </Typography>

      <Box sx={{ mt: 1 }}> </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1%'
        }}
      >
      </Box>
      <form
        autoComplete="off"
        noValidate
      >
        <Table
          sx={{
            position: 'relative',
            left: '1%',
            width: '98%'
          }}>
          <TableRow>
            {/* 시설그룹명, 설명 입력 칸*/}
            <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF', borderTop: 2, borderTopColor: '#BFBFBF', }}>
              {!isMobile &&
                <StyledNameInput
                  name="시설그룹명"
                  value={values.groupName}
                  onChange={onChange}
                />
              }
              {isMobile &&
                <MobileNameInput
                  name="시설그룹명"
                  value={values.groupName}
                  onChange={onChange}
                />
              }
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ padding: 1, borderBottom: 2, borderBottomColor: '#BFBFBF' }}>
              <Box sx={{ mt: 1 }}> </Box>
              {!isMobile &&
                <StyledNameInput
                  name="설명"
                  value={values.description}
                  onChange={onChange}
                />
              }
              {isMobile &&
                <MobileNameInput
                  name="설명"
                  value={values.groupName}
                  onChange={onChange}
                />
              }
            </TableCell>
          </TableRow>
        </Table>

        <Box sx={{ mt: 1 }}> </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            등록
          </Button>
        </Box>
      </form>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Box>

  );

};

export default FacilityGroupFormImp;
