import { observable, computed } from 'mobx';
import { api, q } from '../api';
import { ICompany } from '../model';

export interface ICompanyStore {
	company:string;

	addCompany:(company : ICompany) => void;

}
export const companyStore = observable<ICompanyStore>({
	company: '',

	async addCompany(company : ICompany) {
		console.log(`addCompany ${JSON.stringify(company)}`)
	},
})

export default companyStore;
