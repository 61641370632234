// 중계기 - 기기연동
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  MenuItem,
  SelectChangeEvent,
  Input,
  CardContent,
  Divider,
  CardHeader
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import userStore from 'state/user';
import deviceStore from 'state/device';
import facilityStore from 'state/facility';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

interface ILocation {
  siteId:string,
  siteName:string,
  facId:string,
  facName:string,
  facType:string,
  facDesc:string,
  gwId:string,
}
const ConnectDeviceGatewayImp = observer(() => {
  const [serialValue, setSerialValue] = useState(null);
  const [searchSerial, setSearchSerial] = useState('');
  const location = useLocation();
  const state = location.state as ILocation;
  const [type, setType] = useState('add');
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();

  const [values, setValues] = useState(
    {
      facId: '',
      facName: '',
      facType: '',
      facDesc: '',
      gwId: '',
    });

  useEffect(() => {
    console.log(`ConnectDeviceGatewayImp useEffect state ${JSON.stringify(state)}`)
    if (state != null) {
      setType('modify');
    console.log(`ConnectDeviceGatewayImp useEffect setValue ${JSON.stringify(state)}`)

    setValues({
      ...values,
      facId:state.facId,
      facName:state.facName,
      facType:state.facType,
      facDesc:state.facDesc,
      gwId:state.gwId,
    });
    }
  },[]);

  useEffect(() => {
    setSerialValue(deviceStore.deviceGatewayList);
  },[deviceStore.deviceGatewayList]);

  useEffect(() => {
      deviceStore.getDeviceGatewayBySiteId(userStore.siteId);
  },[]);

  useEffect(() => {
      const deviceGateway = serialValue?.find((item: any) => {
        if (item.facId == values.facId)
        return true;
      })
    if (deviceGateway) {
    setValues({
      ...values,
      gwId: deviceGateway.gwId
    });
    }
  },[serialValue]);

  //연동하기
  const handleUpdate = async () => {
    const ret = await facilityStore.setGwIdForFacilityGateway(values.facId, values.gwId, 'reg');
    if (ret) {
      ToastsStore.success(`연동 성공`)
      const timer = setInterval(() => {
        clearInterval(timer);
        navigate(-1);
      }, 1000)
    }
    else {
      ToastsStore.warning(`연동 실패`)
   }
  }

  //시리얼 입력값 저장
  const handleChangeSerial = (event: SelectChangeEvent) => {
    setValues({
      ...values,
      gwId: event.target.value
    });
  };

  //시리얼 입력
  const handleSearchSerial= (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchSerial(event.target.value)
  };

  //취소
  const BackPage = () => {
    navigate(-1);
  };

  
  return (
    <form
      autoComplete="off"
      noValidate
    >
      <Typography 
         sx={{
          position:'relative',
          height:1,
          textAlign:'right',
          right:'1%'
         }}
         gutterBottom>
          시스템 관리 {'>'} 중계기 {'>'} 중계기 기기 연동
        </Typography>
        <Box
          sx={{
            display: 'flex',
            position:'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2%',
            right:'1%'
          }}
        >
      <Typography //타이틀 글자 조정
         sx={{ 
          fontSize: 30,
          fontWeight:600,
          color:'#0C3149',
          display:'flex',
          position:'relative',
          left: '2.2%',
          marginBottom:'1%'
        }}   
         gutterBottom > 
         중계기 기기 연동
         </Typography>
         </Box>
            <Table>
            <TableRow>
            <TableCell sx={{ padding: 1, borderTop : 2 , borderTopColor : '#BFBFBF', borderBottom: 1, borderBottomColor:'#BFBFBF'}}>
              <Typography
              sx= {{position:'relative',left:15, display:'-webkit-inline-box',lineHeight:3.5}}>
              시설 이름
              </Typography>
                  <Input
                    sx={{
                      width: 200,
                      height: 30,
                      textAlign: 'center',
                      borderRadius: "4px",
                      border: "1px solid #bdbdbd",
                      pl: 1,
                      left:105
                    }}
                    value={values.facName}
                    disableUnderline
                    required
                    margin="none"
                  />
                </TableCell>
            </TableRow>
            <TableRow>
            <TableCell sx={{left: '5%',display:'-webkit-box',padding: 1, borderBottom: 1, borderBottomColor:'#BFBFBF'}}>
              <Typography
              sx= {{position:'relative',left:15, display:'-webkit-inline-box',lineHeight:3.5}}>
              연동기기
              </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: 120
                    }}>
                    <Input
                      sx={{
                        width: 215,
                        height: 30,
                        textAlign: 'center',
                        borderRadius: "4px",
                        border: "1px solid #bdbdbd",
                        pl: 1,
                        left:'51%'
                      }}
                      disableUnderline
                      placeholder='원하는 serial을 입력 하세요'
                      onChange={handleSearchSerial}
                      required
                      value={searchSerial}
                      margin="none"
                    />
                    <Box sx={{ height: 5 }} />
                    <FormControl sx={{ width:215, left:'51%'}}>
                      <InputLabel id="demo-simple-select-label">Serial</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-autowidth"
                        value={values.gwId}
                        label="Serial"
                        onChange={handleChangeSerial}
                      >
                        {
                          serialValue?.filter((item:any) => {
                            return item.serialNo.toLowerCase().includes(searchSerial.toLowerCase());
                          })
                          .map((item:any, index:number) => {
                            return (
                              <MenuItem value={item.gwId}>{item.serialNo}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
              </TableCell>
            </TableRow>
        </Table>
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
        >
          <Button
            color="primary"
            size="medium"
            variant="contained"
            disabled={disableButton}
            onClick={() => BackPage()}
          >
            취소
          </Button>
          <Box sx={{p:0.5}}/>
          <Button
            color="primary"
            size="medium"
            variant="contained"
            disabled={disableButton}
            onClick={() => handleUpdate()}
          >
            연동하기
          </Button>
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
        </Box>
</form>
  );
});

export default ConnectDeviceGatewayImp;
