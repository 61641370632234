// 사이트 관리 - 시설(미연동 시설) - 기기연동 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import ConnectDeviceImpl from 'components/facility/ConnectDeviceImp';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';

interface ITags {
  tag: string;
  value: string;
};

interface ILocation {
  name:string,
  facId:string,
  labelNo:string,
  serialNo:string,
  fac?:any
}
const ConnectDevice:React.FC = () => {
  const location = useLocation();
  const state = location.state as ILocation;
  const [values, setValues] = useState(
    {
      name: '',
      facId: '',
      labelno: '',
      fac: [],
    });
  if (state != null) {
  console.log(`ConnectDevice location ${JSON.stringify(location)}`)
  console.log(`ConnectDevice state ${JSON.stringify(location.state)}`)
  console.log(`ConnectDevice --- state ${JSON.stringify(state)}`)
  console.log(`ConnectDevice name ${JSON.stringify(state.name)}`)
  console.log(`ConnectDevice facId ${JSON.stringify(state.facId)}`)
  // console.log(`ConnectDevice fac ${JSON.stringify(state.fac)}`)
  // console.log(`ConnectDevice ${JSON.stringify(location.state.id)}`)
  // console.log(`ConnectDevice ${JSON.stringify(location.state.facId)}`)
  // console.log(`ConnectDevice ${JSON.stringify(location.state.facId)}`)
  }
  return (
    <>
      <Helmet>
        <title>ConnectDevice</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
            <ConnectDeviceImpl />
        </Container>
      </Box>
    </>
  )
};

export default ConnectDevice;
