import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
} from '@mui/material';
import GoogleMap from '../components/map/GoogleMap';

export const Maps = (props) => {
	const [show, setShow] = useState(true);
  console.log(`Map ${JSON.stringify(props)}`)
  const mapStyles = {
    width: '100%',
    height: '100%',
  };
  useEffect(() => {
    console.log(`Maps ${show}`)

    return () => {
    }
  }, [])

    console.log(`handleClick in Maps`)
    console.log(`handleClick in Maps`)
    console.log(`handleClick in Maps`)
    console.log(`handleClick in Maps`)
    console.log(`handleClick in Maps`)
    console.log(`handleClick in Maps`)
  const handleClick = () => {
    console.log(`handleClick in Maps`)
    setShow(false);
  }

return(
  <>
    <Helmet>
      <title>Maps | Material Kit</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        display:'flex',
		    flexDirection: 'row',
      }}
    >
      <GoogleMap onClick={handleClick}></GoogleMap>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
      }}
    >
      {/* <GoogleMap2 onClick={handleClick}></GoogleMap2> */}
    </Box>
    </Box>
  </>
  );
};

export default Maps;
  // apiKey: "AIzaSyCfL4YTPX5g9tqbESwNcOmJfT9HD_ET87Q"
