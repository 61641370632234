//일출,일몰관련 api
import { observable, computed } from 'mobx';
import { ISetSiteSunsetDetail, IGetSiteSunsetDetail } from 'model/sunset';
import { api, q } from '../api';
import { IUserDetail } from '../model/user';

// export let isLoggedIn = false;

export interface ISunsetStore {
	test: string;
	areas: any[];
	citys: any[];
	sunsetDataList: any[];
	siteSunsetList: any[];
	sunsetDetailList: any[];
	getSunsetDetail: IGetSiteSunsetDetail;

	getSunsetAreaList:() => void;
	getSunsetCityList:(areaId:string) => void;
	getSunsetYearFull:(cityName:string, areaId:string, year:string) => void;
	getSunsetDataList:(cityId:string, year:string) => void;
	getSiteSunsetList:(siteId:string, chunkId:string, operationMode:string) => void;
	getSiteSunsetDetailList:(siteId:string, chunkId:string, operationMode:string) => Promise<boolean>;
	setSiteSunsetDetailList:(setSiteSunsetDetail:ISetSiteSunsetDetail) => void;

}
export const sunsetStore = observable<ISunsetStore>({
	test: '',
	areas: [],
	citys: [],
	sunsetDataList: [],
	siteSunsetList: [],
	sunsetDetailList: [],
	getSunsetDetail: null,
	//일출일몰의 지역 목록 가져오기
	async getSunsetAreaList() {
		try {
			const ret = await api.post(`/api2/sunset/getSunsetAreaList`, {
			});
			this.areas = ret.data;
		} catch (error) {
			console.log(`getSunsetAreaList error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//일출일몰의 지역별 도시 목록 가져오기
	async getSunsetCityList(areaId:string) {
		try {
			const ret = await api.post(`/api2/sunset/getSunsetCityList`, {
				areaId,
			});
			this.citys = ret.data;
		} catch (error) {
			console.log(`getSunsetCityList error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//도시별 일출/일몰 1년치 데이터를 OpenAPI를 이용하여 가져오기 / 참고 : 공공데이터포털(https://www.data.go.kr)
	async getSunsetYearFull(cityName:string, areaId:string, year:string) {
		try {
			const ret = await api.post(`/api2/sunset/getYearFull`, {
				cityName,
				areaId,
				year,
			});
			console.log(`getYearFull : ${JSON.stringify(ret.data)}`)

		} catch (error) {
			console.log(`getSunsetYearFull error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//도시의 일출/일몰 데이터 가져오기 ( 1, 16일 고정 )
	async getSunsetDataList(cityId:string, year:string) {
		try {
			const ret = await api.post(`/api2/sunset/getSunsetDataList`, {
				cityId,
				year,
			});
			console.log(`getSunsetDataList : ${JSON.stringify(ret.data)}`)
			this.sunsetDataList = ret.data;
		} catch (error) {
			console.log(`getSunsetDataList error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//사이트에 해당하는 일출/일몰 시간 설정의  Chunk 목록 가져오기
	async getSiteSunsetList(siteId:string, chunkId:string, operationMode:string) {
		try {
			const ret = await api.post(`/api2/sunset/getSiteSunsetList`, {
				siteId,
				chunkId,
			});
			console.log(`getSiteSunsetList : ${JSON.stringify(ret.data)}`)
			this.siteSunsetList = ret.data;
		} catch (error) {
			console.log(`getSiteSunsetList error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//사이트에 해당하는 일출/일몰 시간 설정  Chunk의 세부 내용 가져오기
	async getSiteSunsetDetailList(siteId:string, chunkId:string, operationMode:string) {
		try {
			const ret = await api.post(`/api2/sunset/getSiteSunsetDetailList`, {
				siteId,
				chunkId,
			});
			console.log(`getSiteSunsetDetailList : ${JSON.stringify(ret.data)}`)
			this.sunsetDetailList = ret.data.detail;
			this.getSunsetDetail = ret.data;
			return true;
		} catch (error) {
			console.log(`getSiteSunsetDetailList error : ${JSON.stringify(error)}`)
			this.getSunsetDetail = null;
			return false;
		}
	},
	//sunset 정보 저장
	async setSiteSunsetDetailList(setSiteSunsetDetail: ISetSiteSunsetDetail) {
		try {
			const ret = await api.post(`/api2/sunset/setSiteSunsetDetailList`, 
				setSiteSunsetDetail
			);
			console.log(`setSiteSunsetDetailList ret = ${JSON.stringify(ret)}`)
			return ret;
		} catch (error) {
			console.log(`setSiteSunsetDetailList error : ${JSON.stringify(error)}`)
			return {status:error.status};
		}
	},
})

export default sunsetStore;
