//기기 관련 api
import { observable, computed } from 'mobx';
import { IavailableDeviceList, IDeviceList, ISetSiteDevice} from 'model/device';
import { api, q } from '../api';

export interface IDeviceStore {
	availableDeviceList:IavailableDeviceList[];
	deviceList:IDeviceList[];
	deviceGatewayList:IDeviceList[];

	checkAvailableDeviceRegistered:(setSiteDevice:ISetSiteDevice) => void;
	setSiteDeviceList:(setSiteDevice:ISetSiteDevice) => any;
	addSiteDeviceAndFacilityList:(setSiteDevice:ISetSiteDevice) => any;
	getDeviceBySiteId:(siteId:string) => void;


	checkAvailableDeviceGatewayRegistered:(setSiteDevice:ISetSiteDevice) => void;
	setSiteDeviceGatewayList:(setSiteDevice:ISetSiteDevice) => any;
	getDeviceGatewayBySiteId:(siteId:string) => void;

}
export const deviceStore = observable<IDeviceStore>({
	availableDeviceList: [],
	deviceList: [],
	deviceGatewayList: [],

	async checkAvailableDeviceRegistered(setSiteDevice:ISetSiteDevice) {
		try {
			const ret = await api.post(`/api2/device/checkAvailableDeviceRegistered`, 
				setSiteDevice,
			);
			console.log(`checkAvailableDeviceRegistered : ${JSON.stringify(ret.data)}`)
			this.availableDeviceList= ret.data;
		} catch (error) {
			console.log(`checkAvailableDeviceRegistered error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async setSiteDeviceList(setSiteDevice:ISetSiteDevice) {
		try {
			const ret = await api.post(`/api2/device/addSiteDeviceList`, 
				setSiteDevice,
			);
			return true;
		} catch (error) {
			console.log(`setSiteDeviceList error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async addSiteDeviceAndFacilityList(setSiteDevice:ISetSiteDevice) {
		try {
			const ret = await api.post(`/api2/device/addSiteDeviceAndFacilityList`, 
				setSiteDevice,
			);
			return true;
		} catch (error) {
			console.log(`setSiteDeviceList error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async getDeviceBySiteId(siteId:string) {
		try {
			const ret = await api.post(`/api2/device/getDeviceBySiteId`, {
				siteId,
			});
			this.deviceList= ret.data;
		} catch (error) {
			console.log(`getSiteDeviceList error : ${JSON.stringify(error)}`)
			return false;
		}
	},

	async checkAvailableDeviceGatewayRegistered(setSiteDevice:ISetSiteDevice) {
		try {
			const ret = await api.post(`/api2/device/checkAvailableDeviceGatewayRegistered`, 
				setSiteDevice,
			);
			this.availableDeviceList= ret.data;
		} catch (error) {
			console.log(`checkAvailableDeviceGatewayRegistered error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async setSiteDeviceGatewayList(setSiteDevice:ISetSiteDevice) {
		try {
			const ret = await api.post(`/api2/device/addSiteDeviceGatewayList`, 
				setSiteDevice,
			);
			return true;
		} catch (error) {
			console.log(`setSiteDeviceGatewayList error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	async getDeviceGatewayBySiteId(siteId:string) {
		try {
			const ret = await api.post(`/api2/device/getDeviceGatewayBySiteId`, {
				siteId,
			});
			this.deviceGatewayList= ret.data;
		} catch (error) {
			console.log(`getDeviceGatewayBySiteId error : ${JSON.stringify(error)}`)
			return false;
		}
	},

})

export default deviceStore;
