//중계기 타이틀 및 borderBox 
import {
  Box,
  Button,
  Card,
  CardContent,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBlue } from 'components/admin/system/Styled';
import styles from 'components/facilityGroup/style.module.css'

type ManageGatewayProps = {
  onCommand?: (command: string) => void;
};
const ManageGatewayToolbar = ({ onCommand }: ManageGatewayProps) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation()

  //borderBox
  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };


  return (
    <Box>
      <Typography
        className={styles.pageDirection}
        gutterBottom>
        시설 운영 {'>'} 중계기
      </Typography>

      <Typography //타이틀 글자 조정
        className={styles.pageTitle}
        gutterBottom >
        중계기
      </Typography>
      <Box sx={{ mt: 1 }}> </Box>

      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
        sx={{
          borderRadius: 3,
          borderColor: '#1C75BC',
          marginBottom: '2%',
          margin: 'auto'
        }}
        {...defaultProps}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1,
            textAlign: 'left'
          }}
        >


          {/*<Box sx={{m:1}}>
          <Typography
            color="textSecondary"
            fontSize={16}
            lineHeight={3}
          >
              사용자 
          </Typography>

            <Typography
              color="textSecondary"
              fontSize={16}
            >
               통합 검색
            </Typography>
      </Box>*/}


          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              m: 1
            }}
          >
          </Box>
        </Box>
      </Box>
    </Box>

  );
};

export default ManageGatewayToolbar;
