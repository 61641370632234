import {
  Box,
  Card,
  CardContent,
  Grid,
  styled
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useEffect, useState } from 'react';
import { IAlarm } from '../../model/alarm';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import NameSelectRem from 'components/atom/NameSelectRem';
import NameInputRem from 'components/atom/NameInputRem';
import userStore from 'state/user';
import groupStore from 'state/group';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

// const Status = [
//   { value: '0', label: '선택' },
//   { value: '미연동', label: '미연동' },
//   { value: '장애', label: '장애' },
//   { value: '통신단절', label: '통신단절' },
// ];

const FacType = [
  { value: '0', label: '선택' },
  { value: '1', label: '가로등' },
  { value: '2', label: '분전반' },
];

const SearchType = [
  { id: 'facType', name: '시설유형' },
  { id: 'facNo', name: '표찰번호' },
  { id: 'locCode', name: '시/구' },
  { id: 'alarmStatus', name: '상태' },
];

type ErrorSearchProps = {
  size?:number;
  onSearch:(groupId:string, _srType:string)=> void;
};
const ErrorSearchBar = ({ size, onSearch }: ErrorSearchProps) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [width, setWidth] = useState(size);
  const [hide, setHide] = useState(true);
  const [group, setGroup] = useState(null);
  const { t} = useTranslation()

const Status = [
  { value: '0', label:  t('global.select')},
  { value: '미연동', label: '미연동' },
  { value: '장애', label: '장애' },
  { value: '통신단절', label: '통신단절' },
];

  const [values, setValues] = useState(
    {
      groupId:'',
      alarmStatus:'',
      facNo:'',
    });

  useEffect(() => {
    console.log(`ErrorSearchBar getGroupList siteId : ${userStore.siteId}`)
    groupStore.getGroupList(userStore.siteId, "10", "1");
  },[]);

  useEffect(() => {
    console.log(`ErrorSearchBar getGroupList group : ${JSON.stringify(group)}`)
  },[group]);

  useEffect(() => {
    console.log(`ErrorSearchBar groupList ${JSON.stringify(groupStore.groupList)}`);
    const aaa = groupStore.groupList.map((item:any, index:number) => {
      return {label: item.groupName, value:item.groupId}

    });
    setGroup(
      aaa
    )
  },[groupStore.groupList]);

  useEffect(() => {
    setWidth(size);
  },[size]);

  useEffect(() => {
    console.log(`iiiiiErrorSearchBar ${isMobile}`)
  },[]);

  const handleHide = (_hide:boolean) => {
  console.log(`SearchBar handleHide : ${_hide}`)
    setHide(_hide);
  };

  const handleSearch = () => {
    const alarm:IAlarm = 
    {
      facType:'',
      facNo:'',
      locCode:'',
      alarmStatus:'',
    };

    // console.log(`handleSearch factype : ${values.facType}`)
    // console.log(`handleSearch facNo : ${values.facNo}`)
    // console.log(`handleSearch locCode : ${values.locCode}`)
    // console.log(`handleSearch alarmStatus : ${values.alarmStatus}`)
    // alarm.facType = values.facType;
    // alarm.facNo = values.facNo;
    // alarm.locCode = values.locCode;
    // alarm.alarmStatus = values.alarmStatus;
    onSearch(values.groupId, 'error');
  };

  const onChange = (name:string, value:string) => {
    const found = SearchType.find(type => type.name == name)

    setValues({
      ...values,
      [found.id]: value
    });
  };

  const onChangeGroup = (name:string, value:string) => {

    setValues({
      ...values,
      groupId: value
    });
  };

  return (
    <Card>
      <CardContentNoPadding>
        {hide ?
          <IconButton
            onClick={() => handleHide(false)}
            type="submit"
            sx={{ p: '10px' }}
            aria-label="open"
            size="large">
            <SearchIcon />
          </IconButton>
          :
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                pl: 1,
                pb: '12px',
                pt: '12px',
                maxWidth: { width },
              }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                >
                  <Box>
                    <NameSelectRem
                      name={t('global.status')}
                      object={Status}
                      space={'1rem'}
                      nameWidth={'6rem'}
                      valueWidth={'20rem'}
                      onSelect={onChange}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                >
                  <Box>
                    <NameSelectRem
                      name={t('global.facGroup')}
                      object={group}
                      space={'1rem'}
                      nameWidth={'6rem'}
                      valueWidth={'20rem'}
                      onSelect={onChangeGroup}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                >
                  <Box>
                    <NameInputRem
                      name={t('global.facname')}
                      value={values.facNo}
                      space={'1rem'}
                      nameWidth={'7rem'}
                      valueWidth={'20rem'}
                      onChange={onChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => handleSearch()}
                type="submit"
                sx={{ p: '10px' }}
                aria-label="search"
                size="large">
                <SearchIcon />
              </IconButton>
              {!hide &&
                <IconButton
                  onClick={() => handleHide(true)}
                  type="submit"
                  sx={{ p: '10px' }}
                  aria-label="open"
                  size="large">
                  <CloseFullscreenIcon />
                </IconButton>
              }
            </Box>
          </Box>
        }


      </CardContentNoPadding>
    </Card>
  );
};

export default ErrorSearchBar;
