//시스템 관리 - 중계기 상단 borderBox
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Paper,
  InputBase,
  Divider,
  Typography
} from '@mui/material';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { ButtonBlue } from 'components/admin/system/Styled';

type RegisterFacProps = {
  handleCommand?:(command:string)=> void;
};
const RegisterFacToolbar = ({ handleCommand }: RegisterFacProps) => {
  const { t } = useTranslation()

  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };
  return (
    <Box>
      <Typography 
         sx={{
          position:'relative',
          height:1,
          textAlign:'right',
          right:'1%'
         }}
         gutterBottom>
          시스템 관리 {'>'} 중계기
        </Typography>
      <Box
        sx={{
          display: 'flex',
          position:'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2%',
          right:'1%'
        }}>
          <Typography //글자 조정

            sx={{
              fontSize: 30,
              fontWeight:600,
              color:'#0C3149',
              display:'flex',
              position:'relative',
              left: '2.2%',
              marginBottom:'1%'
            }}
            gutterBottom>
              중계기
          </Typography>

              {/*<Typography
              sx={{ 
                fontSize: 18,
                color:'textPrimary',
                position:'relative',
                left: '3%',
                top:'12%'
               }}
                color={theme.palette.text.primary}
                variant="h5"
              >
                중계기 등록 - 중계기 정보를 등록 / 기기등록 - 기기의 맥 어드레스를 등록 / 기기연동 - 중계기와 기기를 연동
              </Typography>*/}

      <Box sx={{ mt: 1 }}> </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <ButtonBlue
        sx= {{
          backgroundColor: '#1C75BC',
          "&:hover": {backgroundColor: "#10548A"}
        }}
          onClick={() => {
            handleCommand('registerFacilityGateway');
          }}
        >
          중계기등록
        </ButtonBlue>
        <ButtonBlue
        sx= {{
          backgroundColor: '#1C75BC',
          "&:hover": {backgroundColor: "#10548A"}
        }}
          onClick={() => {
            handleCommand('registerDeviceGateway');
          }}
        >
          {t('global.addDevice')}
        </ButtonBlue>
        
      </Box>    
    </Box>
    <Divider sx={{ border:1 ,borderColor:'#bfbfbf'}}/> 
    </Box>
  );
};

export default RegisterFacToolbar;
