import * as React from 'react';
import { ButtonProps } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonRoot = styled(MuiButton)(({ theme, size }) => ({
  // backgroundColor: theme.palette.primary.main,
  // color: theme.palette.common.white,
  // borderRadius: 100,
  // fontWeight: theme.typography.fontWeightMedium,
  // fontFamily: theme.typography.h1.fontFamily,
  // padding: theme.spacing(2, 4),
  // fontSize: theme.typography.pxToRem(14),
  // boxShadow: 'none',
  // '&:active, &:focus': {
  //   boxShadow: 'none',
  // },
  // ...(size === 'small' && {
  //   padding: theme.spacing(1, 3),
  //   fontSize: theme.typography.pxToRem(13),
  // }),
  // ...(size === 'large' && {
  //   padding: theme.spacing(2, 5),
  //   fontSize: theme.typography.pxToRem(20),
  // }),
}));

// See https://mui.com/guides/typescript/#usage-of-component-prop for why the types uses `C`.
function Button<C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }>,
) {
  return <ButtonRoot {...props} />;
}

export default Button;
