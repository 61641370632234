//현재 사용안함.
//사이트관리 - 사이트 사용자 관리 - 삭제
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { getSiteLevelStr, IUserDetail } from '../../../model';
import theme from 'theme';

type SiteUserProps = {
  type?:string;	
  userList? : any[];
  title?:string;
  onSelected?:(user:IUserDetail)=> void;
};
const SiteUser = ({ type, userList, title, onSelected }:SiteUserProps) => {
  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);

  const handleLimitChange = (event:any) => {
    console.log(`handleLimitChange`)
    setLimit(event.target.value);
  };
//하단 페이지 넘김
  const handlePageChange = (event:any, newPage:number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

	return (
		<>
			<PerfectScrollbar>
					<Box
						sx={{
							minWidth: 400,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box sx={{ mt: 1 }}> </Box>
						<Table size="small">
							<TableHead sx={{ minWidth: 400 }}>
								<TableRow>
								<TableCell sx={{ fontWeight:600, textAlign:'center', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
										사용자
									</TableCell>
									{type=='삭제' &&
									<TableCell sx={{ fontWeight:600, borderLeft: 1, borderLeftColor:'#E6E7E8', textAlign:'center', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
										레벨
									</TableCell>
									}
									<TableCell sx={{ fontWeight:600, borderLeft: 1, borderLeftColor:'#E6E7E8', textAlign:'center', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
										{type}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ userList?.slice((page-1)*limit, limit + (page -1)*limit).map((user, index) => (
									<TableRow
										hover
										key={user.userName}
										selected={selectedFacIds.indexOf(user.userName) !== -1}
									>
										<TableCell sx={{ textAlign:'center'}}>
											{user.userName}
										</TableCell>
										{type == '삭제' &&
											<TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
												{getSiteLevelStr(user.siteList[0]?.siteLevel)}
											</TableCell>
										}
										<TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
											<Button
											style={{textAlign: 'left'}}
											variant="contained"
											size="small"
												onClick={() => {
													console.log(`삭제`)
													onSelected(user);
												}}
											>
												{type}
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Box>
					<Pagination
          		color="primary"
          		page={page}
          		count={Math.ceil(userList.length / 10)}
          		onChange={handlePageChange}
          		sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height:'50px',
            [theme.breakpoints.down('sm')]: {
              width: window.innerWidth,
            },
          }}
        />
				<ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />

			</PerfectScrollbar>
		</>
	);
};

export default SiteUser;
