// 관리자 - 사용자 - 등록
// 관리자 - 사용자 - 수정
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  TableCell,
  TableRow,
  Table,
  TableBody,
  Tab,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, useNavigate } from 'react-router';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { IUserDetail, SiteLevel } from 'model';
import { useStores } from 'state';
import AlertPopup from 'components/popup/AlertPopup';
import NameInput from 'components/atom/NameInput';
import NameSelect from 'components/atom/NameSelect';
import siteStore from 'state/site';
import { observer } from 'mobx-react';
import { result } from 'lodash';
import styles from 'components/facilityGroup/style.module.css'
import NameInputPswd from 'components/atom/NameInputPswd';
import { Eye, EyeOff } from 'react-feather';

const UserType = [
  { id: 'userId', name: '아이디' },
  { id: 'userName', name: '사용자명(직책)' },
  { id: 'userPwd', name: '비밀번호' },
  { id: 'telNo', name: '연락처' },
  { id: 'hpNo', name: '핸드폰번호' },
  { id: 'email', name: '이메일' },
  { id: 'userLevel', name: '레벨' },
];

const AdminUserAdd = observer(() => {
  const { userStore } = useStores();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const state = location.state as IUserDetail;
  const navigate = useNavigate();

  const [siteValue, setSiteValue] = useState(null);
  const [type, setType] = useState('add');
  const [pwd2, setPwd2] = useState('');
  const [values, setValues] = useState(
    {
      userId: '',
      userPwd: '',
      userName: '',
      userLevel: '8001',
      hpNo: '',
      telNo: '',
      email: '',
      siteId: '',
    });
  const [disableButton, setDisableButton] = useState(true);
  const [showPswd, setShowPswd] = useState<boolean>(false);
  const [showPswdCheck, setShowPswdCheck] = useState<boolean>(false);
  const [disableButtonPwd, setDisableButtonPwd] = useState(true);
  const [disableButtonPwd2, setDisableButtonPwd2] = useState(true);
  useEffect(() => {
    console.log(`AdminUserAdd NameSel useEffect1 ${JSON.stringify(state)}`)
    if (state != null) {
      setType('modify');

      setValues({
        ...values,
        userId: state.userId,
        userPwd: state.userPwd,
        userName: state.userName,
        userLevel: state.siteLevel,
        hpNo: state.hpNo,
        telNo: state.telNo,
        email: state.email,
        siteId: state.siteId,
      });
    }
  }, []);

  //사이트 리스트 정보 받아옴.
  useEffect(() => {
    if (siteStore.userList[0]) {
      setSiteValue(siteStore.userList[0].siteList);
    }
  }, [siteStore.userList]);

  // 사이트에 속한 사용자들 정보 받아옴
  useEffect(() => {
    siteStore.getSiteListByUser(userStore.getId());
  }, []);

  //입력되는 함수
  const handleChange = (name: string, value: string) => {
    const found = UserType.find(aaa => aaa.name == name);

    setValues({
      ...values,
      [found.id]: value
    });
  };

  // 비밀번호 확인 값 설정
  const handleChangePwd2 = (name: string, value: string) => {
    setPwd2(value)
  };

  //사이트 변경 함수
  const handleChangeSite = (event: SelectChangeEvent) => {
    setValues({
      ...values,
      siteId: event.target.value
    });
  };

  // 저장
  const handleSave = async () => {
    const user: IUserDetail = {
      userId: values.userId,
      userPwd: values.userPwd,
      userName: values.userName,
      userLevel: values.userLevel,
      siteId: '',
      siteLevel: '',
      siteName: '',
      hpNo: values.hpNo,
      telNo: values.telNo,
      email: values.email,
    }
    if (type == 'add') {
      console.log(`AdminUserAdd addUser ${values.userPwd} : ${pwd2}`)
      if (values.userPwd != pwd2) {
        setOpenDialog(true);
        return;
      }
      const ret: any = await userStore.addUser(user);
      if (ret.status == 200) {
        ToastsStore.success("등록 성공")
        await siteStore.setUser(values.siteId, values.userId, values.userLevel);

        const timer = setInterval(() => {
          navigate('/admin/memberlist');
          clearInterval(timer);
        }, 1000)
      }
      else if (ret.status == 400 && ret.resp.reason == 'duplicate') {
        ToastsStore.success("이미 사용중인 ID 입니다.")
      }
      else if (ret.status == 400) {
        ToastsStore.success("등록 실패")
      }
    }
    else {
      user.userPwd = '';
      const ret: any = await userStore.updateUser(user);
      console.log(`AdminUserAdd updateuser ${JSON.stringify(ret)}`)
      if (ret.status == 200) {
        ToastsStore.success(type == 'modify' ? "수정 성공" : "등록 성공")
        const timer = setInterval(() => {
          navigate('/admin/memberlist');
          clearInterval(timer);
        }, 1000)
      }
      else if (ret.status == 400) {
        ToastsStore.success(type == 'modify' ? "수정 실패" : "등록 실패")
      }
    }
  }

  // 비밀번호 값 일치하지않음 팝업 닫기
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  //취소
  const BackPage = () => {
    navigate(-1);
  };
  //비밀번호 보여주기 숨기기
  const toggleShowPassword = () => {
    setShowPswd(true);
  }
  const toggleHidePassword = () => {
    setShowPswd(false);
  }
  //비밀번호 확인 보여주기 숨기기
  const toggleShowPasswordCheck = () => {
    setShowPswdCheck(true);
  }
  const toggleHidePasswordCheck = () => {
    setShowPswdCheck(false);
  }
  //비밀번호 보여주기 숨기기 disabled
  const disableButtonFuncPwd = () => {
    if (
      values.userPwd == '' // || values?.description == ''
    ) {
      setDisableButtonPwd(true);
    }
    else {
      setDisableButtonPwd(false);
    }
  }
  //비밀번호 확인 보여주기 숨기기 disabled
  const disableButtonFuncPwd2 = () => {
    if (pwd2 === '') {
      setDisableButtonPwd2(true);
    } else {
      setDisableButtonPwd2(false);
    }
  };
  //disabled버튼 조건 설정
  const disableButtonFunc = () => {
    if (
      values.userId == '' ||
      values.userName == '' ||
      values.userLevel == '' ||
      values.siteId == ''
    ) {
      setDisableButton(true);
    }
    else {
      setDisableButton(false);
    }
  }
//disabled 함수 실행
  useEffect(() => {
    disableButtonFunc();
    disableButtonFuncPwd();
    disableButtonFuncPwd2();
  }, [values, pwd2]);
  return (
    <Box>
      <Typography
        sx={{
          position: 'relative',
          height: 1,
          left: '87%',
        }}
        gutterBottom>
        관리자 {'>'} 사용자 관리 {'>'} {type == 'add' ? '등록' : '수정'}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2%',
          right: '1%'
        }}
      >
        <Typography //타이틀 글자 조정
          sx={{
            fontSize: 30,
            fontWeight: 600,
            color: '#0C3149',
            display: 'flex',
            position: 'relative',
            left: '2.2%',
            marginBottom: '1%'
          }}
          gutterBottom >
          사용자 {type == 'add' ? '등록' : '수정'}
        </Typography>
      </Box>


      <form
        autoComplete="off"
        noValidate
      >
        <Table
          sx={{
            position: 'relative',
            left: '1%',
            width: '98%'
          }}>
          <TableRow>
            <TableCell className={styles.regTableTop}>
              <NameInput
                name="아이디"
                value={values.userId}
                space={10}
                nameWidth={110}
                valueWidth={200}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
          {
            type == 'add' &&
            <>
              <TableRow>
                <TableCell className={styles.regTableBody}>
                  <NameInputPswd
                    type={showPswd ? "text" : "password"}
                    name="비밀번호"
                    value={values.userPwd}
                    space={10}
                    nameWidth={100}
                    valueWidth={200}
                    onChange={handleChange}
                  />
                  {showPswd === false ? (
                    <Button disabled={disableButtonPwd} onClick={toggleShowPassword}><Eye /></Button>
                  ) : (
                    <Button disabled={disableButtonPwd} onClick={toggleHidePassword}><EyeOff /></Button>
                  )}

                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={styles.regTableBody}>
                  <NameInputPswd
                    type={showPswdCheck ? "text" : "password"}
                    name="비밀번호 확인"
                    value={pwd2}
                    space={10}
                    nameWidth={100}
                    valueWidth={200}
                    onChange={handleChangePwd2}
                  />
                  {showPswdCheck === false ? (
                    <Button disabled={disableButtonPwd2} onClick={toggleShowPasswordCheck}><Eye /></Button>
                  ) : (
                    <Button disabled={disableButtonPwd2} onClick={toggleHidePasswordCheck}><EyeOff /></Button>
                  )}
                </TableCell>
              </TableRow>
            </>
          }

          <TableRow>
            <TableCell className={styles.regTableBody}>
              <NameInput
                name="사용자명(직책)"
                value={values.userName}
                space={10}
                nameWidth={110}
                valueWidth={200}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={styles.regTableBody}>
              <NameInput
                name="연락처"
                value={values.telNo}
                space={10}
                nameWidth={110}
                valueWidth={200}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>


          <TableRow>
            <TableCell className={styles.regTableBody}>
              <NameInput
                name="핸드폰번호"
                value={values.hpNo}
                space={10}
                nameWidth={110}
                valueWidth={200}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={styles.regTableBody}>
              <NameInput
                name="이메일"
                value={values.email}
                space={10}
                nameWidth={110}
                valueWidth={200}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={styles.regTableBody}>
              <NameSelect
                name="레벨"
                object={SiteLevel}
                defaultValue={values.userLevel}
                space={10}
                nameWidth={110}
                valueWidth={200}
                onSelect={handleChange}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={styles.regTableBottom}>
              <Typography
                sx={{ position: 'relative', left: 15, display: '-webkit-inline-box', lineHeight: 3.5 }}>
                사이트
              </Typography>
              <FormControl sx={{ left: 125, width: 200 }}>
                <InputLabel id="demo-simple-select-label">사이트</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-autowidth"
                  //value={siteValue}
                  label="Serial"
                  onChange={handleChangeSite}
                >
                  {
                    siteValue?.map((item: any, index: number) => {
                      return (
                        <MenuItem value={item.siteId}>{item.siteName}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
        </Table>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Button
            className={styles.greenButton}
            color="primary"
            size="medium"
            variant="contained"
            onClick={() => BackPage()}
          >
            취소
          </Button>
          <Button
            className={styles.primaryButton}
            color="primary"
            variant="contained"
            disabled={disableButton}
            onClick={handleSave}
          >
            저장
          </Button>
        </Box>
      </form>
      <AlertPopup
        open={openDialog}
        description="비밀번호와 비밀번호 확인 값이 일치하지 않습니다."
        onClose={handleDialogClose}
      >
      </AlertPopup>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Box>
  );

});

export default AdminUserAdd;
