//로고 이미지 설정
const Logo = (props) => (
  // <img
  //   alt="Logo"
  //   src="/static/logo.svg"
  //   {...props}
  // />
  <img
    alt="Logo"
    // src="/static/choeun_logo_mark.svg"
    // src="/static/logo.svg"
    src="/static/choeun_logo_mark.png"
    {...props}
  />
);

export default Logo;
