//시설 정보 dialog(상세보기, 시설상세)

import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Drawer, Grid, Typography } from "@mui/material";
import { TitleinTab } from "./DefineStyled";
import NameValue from 'components/atom/NameValue';
import { getFacTypeStr, IFacilityDetailInfo, IFacInfo } from "model";
import facilityStore from "state/facility";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ManualOnOff from "./ManualOnOff";
import { ButtonBlue } from "components/admin/system/Styled";
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import { isMobile } from "react-device-detect";
import styles from 'components/facilityGroup/style.module.css'
import ErrorRegisterPopup from "components/popup/ErrorRegisterPopup";
import maintenanceStore from "state/maintenance";
import { IMaintenanceErrorInfo } from "model/maintenance";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";
import userStore from "state/user";

interface StyledNameValueProps {
    name: string;
    value: any;
}
function StyledNameValue({ name, value }: StyledNameValueProps) {
    return (
        <>
            <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
            >
                <NameValue
                    name={name}
                    value={value}
                    space={3}
                    nameWidth={150}
                    valueWidth={400}
                />
            </Grid>
        </>
    );
}
type FacDetailInfoDialogProps = {
    show: boolean;
    facId: any;
    facInfo?: IFacInfo;
    errorInfo?: IMaintenanceErrorInfo;
    uplinkInfo?: any;
    onShow?: (show: boolean) => void;
    onClose?: () => void;
};


const FacDetailInfoDialog = observer(({ show, facId, facInfo, errorInfo, uplinkInfo, onShow, onClose }: FacDetailInfoDialogProps) => {
    // 상태저장
    const RefreshInterval = 20000;
    const [isASRequested, setIsASRequested] = useState(true);
    const [errorRegisterPopupOpen, setErrorRegisterPopupOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [onLoading, setOnLoading] = useState(false);
    const [offLoading, setOffLoading] = useState(false);
    const [aliveLoading, setAliveLoading] = useState(false);
    const [state, setState] = useState('')

    const navigate = useNavigate();

    //api실행
    useEffect(() => {
        const fetchData = () => {
            facilityStore.getFacilityDetailInfo(facId);
            maintenanceStore?.getErrorList(facId, userStore.siteId)
        };
        if (show) {
            fetchData(); // 최초 실행
            const intervalId = setInterval(fetchData, RefreshInterval);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [show, facId, facilityStore]);

    // ON 기능
    const updateOn = async () => {
        console.log(`updateOn ${facId}`);
        let count = 1;
        const maxMinute = 24; // 5초 * 24 = 120초 = 2분
        setOnLoading(true)

        const timerOpen = setInterval(async () => {
            if (facilityStore.facilityCmdResult === "REQ") {
                ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} 의 이전 명령이 진행중입니다.`)
                clearInterval(timerOpen);
                setOnLoading(false)
            }
        }, 100);

        const timer = setInterval(async () => {
            console.log(`updateOnOff loop : ${count}`);
            if (count >= maxMinute) {
                setOnLoading(false);
                console.log("2분이 지났습니다.");
                ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
                clearInterval(timer);
            } else {
                try {
                    if (facilityStore.facilityCmdResult === 'REQ') {
                        clearInterval(timer);
                    }
                    if (facilityStore.facilityCmdResult !== "REQ") {
                        setOnLoading(true);
                        await facilityStore.getCmdRunState(facId, '');
                        console.log(`on ? ${facId}`, JSON.stringify(facilityStore.facilityCmdState));

                        if (facilityStore.facilityCmdState === "DONE" || facilityStore.facilityCmdState === "NO_COMMAND") {
                            setOnLoading(false);
                            ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}의 ON 명령이 전달되었습니다.`)
                            clearInterval(timer);
                        }
                        else if (facilityStore.facilityCmdState === "DONE") {
                            setOnLoading(false);
                            ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}이 ON 되었습니다.`)
                            clearInterval(timer);
                        }
                        else if (facilityStore.facilityCmdState === "Fail") {
                            setOnLoading(false);
                            ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} ON 실패.`)
                            clearInterval(timer);
                        }
                    }
                } catch (error) {
                    console.error("!!! 에러 !!!:", error);
                    setOnLoading(false);
                    clearInterval(timer);
                }
            }
            count++;
        }, 5000);
    };
    // OFF 기능
    const updateOff = async () => {
        console.log(`updateOn ${facId}`);
        let count = 1;
        const maxMinute = 24; // 5초 * 24 = 120초 = 2분
        setOffLoading(true)

        const timerOpen = setInterval(async () => {
            if (facilityStore.facilityCmdResult === "REQ") {
                ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} 의 이전 명령이 진행중입니다.`)
                clearInterval(timerOpen);
                setOffLoading(false)
            }
        }, 100);

        const timer = setInterval(async () => {
            console.log(`updateOnOff loop : ${count}`);
            if (count >= maxMinute) {
                setOffLoading(false);
                console.log("2분이 지났습니다.");
                ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
                clearInterval(timer);
            } else {
                try {
                    //REQ면 종료
                    if (facilityStore.facilityCmdResult === 'REQ') {
                        clearInterval(timer);
                    }

                    if (facilityStore.facilityCmdResult !== "REQ") {
                        setOffLoading(true);
                        await facilityStore.getCmdRunState(facId, '');
                        console.log(`on ? ${facId}`, JSON.stringify(facilityStore.facilityCmdState));

                        if (facilityStore.facilityCmdState === "DONE" || facilityStore.facilityCmdState === "NO_COMMAND") {
                            setOffLoading(false);
                            ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}의 OFF 명령이 전달되었습니다.`)
                            clearInterval(timer);
                        }
                        else if (facilityStore.facilityCmdState === "Fail") {
                            setOffLoading(false);
                            ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} OFF 실패`)
                            clearInterval(timer);
                        }
                    }
                } catch (error) {
                    console.error("!!! 에러 !!!:", error);
                    setOffLoading(false);
                    clearInterval(timer);
                }
            }
            count++;
        }, 5000);
    };

    // ALIVE 기능
    const getFacilityAlive = async () => {
        console.log(`getFacilityAlive ${facId}`);
        let count = 1;
        const maxMinute = 24; // 5초 * 24 = 120초 = 2분
        setAliveLoading(true)

        const timerOpen = setInterval(async () => {
            if (facilityStore.facilityCmdAliveResult === "REQ") {
                ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} 의 이전 명령이 진행중입니다.`)
                setAliveLoading(false)
                clearInterval(timerOpen);
            }
        }, 100);

        const timer = setInterval(async () => {
            console.log(`getFacilityAlive loop : ${count}`);
            if (count >= maxMinute) {
                setAliveLoading(false);
                console.log("2분이 지났습니다.");
                ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
                clearInterval(timer);
            } else {
                try {
                    const devOld = facilityStore.facilityDetailInfo.devTimeUpdate
                    //REQ가 아니면 실행
                    if (facilityStore.facilityCmdAliveResult === 'DONE' || facilityStore.facilityCmdAliveResult === 'NO_COMMAND') {
                        setAliveLoading(true);
                        await facilityStore.getCmdRunState(facId, '');

                        if (facilityStore.facilityCmdState === 'DONE' || facilityStore.facilityCmdState === 'NO_COMMAND') {
                            ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}의 Alive 명령이 전달되었습니다.`)
                            clearInterval(timer);
                            setAliveLoading(false);
                            const timer1 = setInterval(async () => {
                                if (count >= maxMinute) {
                                    setAliveLoading(false);
                                    console.log("2분이 지났습니다.");
                                    ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
                                    clearInterval(timer);
                                } else {
                                    const ret: any = await facilityStore.getFacilityDetailInfo(facId)
                                    if (facilityStore.facilityDetailInfo.devTimeUpdate !== devOld) {
                                        setAliveLoading(false);
                                        ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}의 정보가 업데이트 되었습니다.`)
                                        clearInterval(timer1);
                                    }
                                    else if (ret.status == 400) {
                                        ToastsStore.success("Alive 실패")
                                        clearInterval(timer1);
                                    }
                                }
                            }, 5000);
                        }
                    }
                } catch (error) {
                    console.error("!!! 에러 !!!:", error);
                    setAliveLoading(false);
                    clearInterval(timer);
                }
            }
            count++;
        }, 5000);
    };

    //ON,OFF handle 버튼 동작 (버튼 누르고 UpdateON, UpdateOFF 기능 실행시키는거)
    const handleEvent = async (event: string) => {
        console.log(`handleEvent ${event}`);
        if (event == 'ON') {
            facilityStore.cmdFacilityLampOn(facId);
            //console.log('ffa', JSON.stringify(facilityStore.facilityCmdResult))
            updateOn();
        }
        else {
            facilityStore.cmdFacilityLampOff(facId);
            //console.log('ffa', JSON.stringify(facilityStore.facilityCmdResult))
            updateOff();
        }
    }

    //ALIVE handle 버튼 동작 (버튼 누르고 getFacilityAlive 기능 실행시키는거)
    const handleAlive = async () => {
        facilityStore.cmdFacilityUpdateStatus(facId)
        getFacilityAlive();
    }

    //Dialog 닫기 [닫기]버튼
    const handleDialogClose = () => {
        console.log(`handleDialog`)
        onClose();
    };

    // DB에 저장된 점멸 방식 operationMode를 한글로 바꿈.
    const getFacType = (operationMode: string) => {
        if (operationMode == 'S') {
            return '스케줄';
        }
        else if (operationMode == 'M') {
            return '수동';
        }
        else if (operationMode == 'O') {
            return '고정';
        }
        else if (operationMode == 'N') {
            return '미설정';
        }
    }

    // 장애 등록 Close, Open
    const handleRegisterDialogClose = async () => {
        setErrorRegisterPopupOpen(false);
    };
    const handleRegisterDialogOpen = async () => {
        setErrorRegisterPopupOpen(true);
    };

    // 장애관리 페이지로 이동
    const handleErrorList = async () => {
        navigate('/slager/errorList',
            {
                state: {
                    facId: facId,
                }
            });
    }
    return (
        //실질적으로 눈에 보이는것들 Dialog
        <Dialog
            open={show}
            onClose={onClose}
            PaperProps={{
                sx: {
                    maxWidth: 1000,
                    width: isMobile ? '100%' : '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    border: 1,
                    borderRadius: 4
                    // ml: {isMobile?1:35},
                }
            }}
        >
            <DialogContent>
                <Card >
                    <CardContent>
                        {/* isMobile이라고 되어있는 애들은 다 모바일 UI에서만 적용되는것들입니다. 
                            !isMobile이 PC버전 화면입니다.
                        */}
                        {
                            isMobile ? (
                                <Box
                                    sx={{
                                        display: 'inline',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <TitleinTab color="textPrimary" variant="h4" sx={{ textAlign: 'center' }}>
                                            시설정보
                                        </TitleinTab>
                                        {facilityStore.facilityDetailInfo?.maintenanceStateCode === '15' || facilityStore.facilityDetailInfo?.maintenanceStateCode === null ? (
                                            <ButtonBlue
                                                size="small"
                                                onClick={() => {
                                                    if (!maintenanceStore.maintenanceInfoList) {
                                                        handleRegisterDialogOpen();
                                                    } else {
                                                        handleErrorList();
                                                    }
                                                }}
                                                sx={{ borderRadius: 5, left: '15px', bottom: '7px', width: '70px', "&:hover": { backgroundColor: "#0E3B25" } }}
                                            >
                                                장애등록
                                            </ButtonBlue>
                                        ) : (
                                            <ButtonBlue
                                                size="small"
                                                onClick={() => {
                                                    if (!maintenanceStore.maintenanceInfoList) {
                                                        handleRegisterDialogOpen();
                                                    } else {
                                                        handleErrorList();
                                                    }
                                                }}
                                                sx={{ borderRadius: 5, left: '15px', bottom: '7px', width: '85px', "&:hover": { backgroundColor: "#0E3B25" } }}
                                            >
                                                장애처리중
                                            </ButtonBlue>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 1,
                                        }}
                                    ></Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <ButtonBlue
                                            size="small"
                                            onClick={getFacilityAlive}
                                            sx={{ '&:hover': { backgroundColor: '#10548A' } }}
                                        >
                                            Alive
                                        </ButtonBlue>
                                        <ManualOnOff
                                            name="점소등"
                                            disabled={facilityStore.facilityDetailInfo?.devId == '-1'}
                                            space={'0rem'}
                                            onLoading={onLoading}
                                            offLoading={offLoading}
                                            nameWidth={'3rem'}
                                            valueWidth={'5rem'}
                                            onEvent={handleEvent}
                                        />
                                    </Box>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                        }}
                                    >
                                        <TitleinTab color="textPrimary" variant="h4">
                                            시설정보
                                        </TitleinTab>
                                    </Box>
                                    {facilityStore.facilityDetailInfo?.maintenanceStateCode === '15' || facilityStore.facilityDetailInfo?.maintenanceStateCode === null ? (
                                        <ButtonBlue
                                            size="small"
                                            onClick={() => {
                                                if (!maintenanceStore.maintenanceInfoList) {
                                                    handleRegisterDialogOpen();
                                                } else {
                                                    handleErrorList();
                                                }
                                            }}
                                            sx={{ borderRadius: 5, left: '15px', bottom: '7px', width: '70px', "&:hover": { backgroundColor: "#0E3B25" } }}
                                        >
                                            장애등록
                                        </ButtonBlue>
                                    ) : (
                                        <ButtonBlue
                                            size="small"
                                            onClick={() => {
                                                if (!maintenanceStore.maintenanceInfoList) {
                                                    handleRegisterDialogOpen();
                                                } else {
                                                    handleErrorList();
                                                }
                                            }}
                                            sx={{ borderRadius: 5, left: '15px', bottom: '7px', width: '85px', "&:hover": { backgroundColor: "#0E3B25" } }}
                                        >
                                            장애처리중
                                        </ButtonBlue>
                                    )}
                                    <Box
                                        sx={{
                                            flex: 1,
                                        }}
                                    ></Box>
                                    <LoadingButton
                                        variant='outlined'
                                        color='inherit'
                                        loading={aliveLoading}
                                        size="small"
                                        onClick={handleAlive}
                                        disabled={onLoading == true || offLoading == true}
                                        sx={{
                                            border: 1,
                                            height: '31px',
                                            top: '5px', right: '10px'
                                        }}
                                    >
                                        Alive
                                    </LoadingButton>
                                    <ManualOnOff
                                        name="점소등"
                                        disabled={facilityStore.facilityDetailInfo?.devId == '-1'}
                                        space={'0rem'}
                                        onDisabled={offLoading == true || aliveLoading == true}
                                        offDisabled={onLoading == true || aliveLoading == true}
                                        onLoading={onLoading}
                                        offLoading={offLoading}
                                        nameWidth={'3rem'}
                                        valueWidth={'5rem'}
                                        onEvent={handleEvent}
                                    />
                                </Box>
                            )
                        }
                        <Box sx={{ pt: 3 }}>
                            <Grid
                                container
                                spacing={1}
                            >
                                <StyledNameValue
                                    name="이름"
                                    value={facilityStore.facilityDetailInfo?.facName}
                                />
                                <StyledNameValue
                                    name="설명"
                                    value={facilityStore.facilityDetailInfo?.facDesc}
                                />
                                <StyledNameValue
                                    name="타입"
                                    value={getFacTypeStr(facilityStore.facilityDetailInfo?.facType)}
                                />
                                <StyledNameValue
                                    name="점멸 방식"
                                    value={getFacType(facilityStore.facilityDetailInfo?.operationMode)}
                                />
                                <StyledNameValue
                                    name="좌표(위도/경도)"
                                    value={facilityStore.facilityDetailInfo?.latitude ? facilityStore.facilityDetailInfo.latitude?.substr(0, 2) + '.' + facilityStore.facilityDetailInfo.latitude?.substr(2) + '/' +
                                        facilityStore.facilityDetailInfo.longitude?.substr(0, 3) + '.' + facilityStore.facilityDetailInfo.longitude?.substr(3) : ''}
                                />
                                <StyledNameValue
                                    name="상태"
                                    value={facilityStore.facilityDetailInfo?.status == "정상" ? "정상" : facilityStore.facilityDetailInfo?.reason}
                                />
                                <StyledNameValue
                                    name="전압"
                                    value={`${parseInt(facilityStore.facilityDetailInfo?.vout1) / 10} V`}
                                />
                                <StyledNameValue
                                    name="최종 업데이트"
                                    value={facilityStore.facilityDetailInfo?.devTimeUpdate}
                                />

                                <StyledNameValue
                                    name="Mac"
                                    value={facilityStore.facilityDetailInfo?.devSerialNo}
                                />
                                <StyledNameValue
                                    name="G/W Mac"
                                    value={facilityStore.facilityDetailInfo?.gwSerialNo}
                                />
                                <StyledNameValue
                                    name="devId / facId"
                                    value={`${facilityStore.facilityDetailInfo?.devId} / ${facilityStore.facilityDetailInfo?.facId}`}
                                />
                                <StyledNameValue
                                    name="설치주소"
                                    value={facilityStore.facilityDetailInfo?.address}
                                />
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button className={styles.greenButton} onClick={handleDialogClose} sx={{ bottom: '10px' }} autoFocus>
                    닫기
                </Button>
            </DialogActions>
            <ErrorRegisterPopup
                facId={facId}
                open={errorRegisterPopupOpen}
                onClose={handleRegisterDialogClose}></ErrorRegisterPopup>
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
        </Dialog>
    )
});

export default FacDetailInfoDialog;