// 현재 사용하지않습니다.
import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import TimePicker from '@mui/lab/TimePicker';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import Button from 'components/common/Button';
import { useStores } from 'state';


export interface LampAutoOnOffDialogProps {
  open: boolean;
  enable:number
  onTime: string;
  offTime: string;
  onClose: () => void;
}

const LampAutoOnOffDialog = (props: LampAutoOnOffDialogProps) => {
  const { onClose, enable, onTime, offTime, open } = props;
  const [onOffEnable, setOnOffEnable] = useState<number>(enable);
  const [newOnTime, setOnTime] = useState<Date | null>();
  const [newOffTime, setOffTime] = useState<Date | null>();
  const { clientStore, iotStore } = useStores();

  useEffect(() => {
    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();  
    console.log(`LampAutoOnOffDialog bb ${year}-${month}-${date}T${onTime}`)

    // const forma = ${moment(date).format('YYYY-MM-DD);
    console.log(`LampAutoOnOffDialog bb01 ${moment(newDate).format('YYYY-MM-DD')}T${onTime}`)
    console.log(`LampAutoOnOffDialog cc 2022-3-13T${onTime}`)
    const formDate = moment(newDate).format('YYYY-MM-DD');
    const onTime1 = new Date(`${formDate}T${onTime}`);
    const offTime1 = new Date(`${formDate}T${offTime}`);
    // const offTime1 = new Date(`2022-04-04T${offTime}`)
    console.log(`LampAutoOnOffDialog aa enable:${enable} on:${onTime1}, off:${offTime1}`)
    setOnOffEnable(enable);
    setOnTime(onTime1);
    setOffTime(offTime1);
    console.log(`show auto onOffEnable`)
    console.log(`LampAutoOnOffDialog in useEffect onOffenable:${onOffEnable} on:${onTime}, off:${offTime}`)
  },[props]);

  const handleClose = () => {
    onClose();
  };

  const handleOnTimeChange = (newValue: Date | null) => {
    console.log(`handleOnTimeChange ${newValue}`)
    setOnTime(newValue);
  }

  const handleOffTimeChange = (newValue: Date | null) => {
    console.log(`handleOffTimeChange ${newValue}`)
    setOffTime(newValue);
  }

  const handleOnOffEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`handleonOffEnable ${event}`)
    if (event.target.checked) {
      setOnOffEnable(1);
    }
    else {
      setOnOffEnable(0);
    }
  }

  const handleSave = async () => {
    const onString = moment(newOnTime).format('HH:mm:ss');
    const offString = moment(newOffTime).format('HH:mm:ss');
    console.log(`handleSave ${onString} ~ ${offString}`)
    await clientStore.updateAutoOnOff(onOffEnable, onString, offString);
    ToastsStore.warning("설정 완료")
    onClose();
  }

  return (
    <Dialog
      // hideBackdrop={true}
                  // checked={onOffEnable == 1 ? true : false}

      onClose={handleClose} open={open}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogTitle>
          <FormGroup>
            <FormControlLabel 
            control={
                <Checkbox
                  onChange={handleOnOffEnable}
                  checked={onOffEnable == 1 ? true : false}
                />
            }
            label="자동 ON/OFF 시간 설정" />
          </FormGroup>
        </DialogTitle>
        <Divider />
        <Box
          sx={{
            p: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            {/* <Typography variant="h6" component="h2"> */}
            <Typography >
              자동 ON 시간
            </Typography>
            <Box sx={{ width: '10px' }} />
            <TimePicker
              label="Time"
              value={newOnTime}
              onChange={handleOnTimeChange}
              renderInput={(params:any) => <TextField {...params} />}
            />
          </Box>
          <Box sx={{ height: '10px' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            {/* <Typography variant="h6" component="h2"> */}
            <Typography >
              자동 OFF 시간
            </Typography>
            <Box sx={{ width: '10px' }} />
            <TimePicker
              label="Time"
              value={newOffTime}
              onChange={handleOffTimeChange}
              renderInput={(params:any) => <TextField {...params} />}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              p: 2
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleClose}
            >
              취소
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              저장
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Dialog>
  );
}
export default LampAutoOnOffDialog;
