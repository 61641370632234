//시설운영 - 그룹 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import FacilityGroupToolbar from 'components/facilityGroup/FacilityGroupToolbar';
import FacilityGroupListResults from 'components/facilityGroup/FacilityGroupListResults';
import { useStores } from 'state';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

const FacilityGroup = observer(({}) => {
  const { userStore, groupStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

// 그룹 정보 가져옴.
  useEffect(() => {
  console.log(`FacilityGroup getGroupList ${userStore.siteId}`)
    groupStore.getGroupList(userStore.siteId, "10", "1");
  },[]);

  const onCommand = (command: string) => {
    console.log(`onCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }
  return(
  <>
    <Helmet>
      <title>FacilityGroup</title>
    </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FacilityGroupToolbar groupCount={groupStore.groupList.length.toString()} onCommand={onCommand} />
          <Box sx={{ mt: 1 }}></Box>
          <Box sx={{ }}>
            <FacilityGroupListResults  groupCount={groupStore.groupList.length.toString()} groups={groupStore.groupList} doit={doit} onCommand={onCommand}/>
          </Box>
        </Container>
    </Box>
  </>
);
});

export default FacilityGroup;
