//장애 등록 팝업

import { AddCircleRounded } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import NameInputRem from 'components/atom/NameInputRem';
import NameOverInput from 'components/atom/NameOverInput';
import { IGroupSet, ILineSet, isSiteManager } from 'model';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import styles from 'components/facilityGroup/style.module.css'
import groupStore from 'state/group';
import lineStore from 'state/line';
import userStore from 'state/user';
import facilityStore from 'state/facility';
import { observer } from 'mobx-react';
import NameSelect from 'components/atom/NameSelect';
import { IMaintenanceErrorInfo, getErrorTypeStr } from 'model/maintenance';
import maintenanceStore from 'state/maintenance';
import StatusSelect from 'components/atom/StatusSelect';
interface StyledNameInputProps {
    name: string;
    value: string;
    disabled?: boolean;
    onChange: (name: string, value: string) => void;
}
function StyledNameInput({ name, value, disabled, onChange }: StyledNameInputProps) {

    return (
        <>
            <NameInputRem
                name={name}
                value={value}
                disabled={disabled}
                space={'3rem'}
                nameWidth={'5rem'}
                valueWidth={'20rem'}
                onChange={onChange}
            />
        </>
    );
}


export interface ErrorManagePopupProps {
    //groupInfo: any;
    facId: string;
    open?: boolean;
    onClose: (update: boolean) => void;
}

const ErrorManagePopup = observer(({ open, facId, onClose }: ErrorManagePopupProps) => {
    const [listItems, setListItems] = useState([]);
    const [newItem, setNewItem] = useState("");
    const [open1, setOpen] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorCategory, setErrorCategory] = useState('');
    const [values, setValues] = useState<IMaintenanceErrorInfo>(
        {
            mainId: '',
            facId: '',
            facName: '',
            category: '',
            description: '',
            stateCode: '',
            updateTime: '',
            createTime: '',
            completeTime: ''
        });
    //장애 카테고리 종류 설정
    const ErrorCategory = [
        { value: '1', label: '장애' },
        { value: '3', label: '미연동' },
        { value: '4', label: '통신단절' },
        { value: '200002', label: '전압/전류 측정 오류' },
        { value: '200006', label: 'GPS 오류' },
    ];


    //시설 정보 받아옴
    useEffect(() => {
        if (open)
            facilityStore.getFacilityDetailInfo(facId);
        console.log("fffss", JSON.stringify(facilityStore.facilityDetailInfo))
    }, [open]);

    const handleDialogClose = () => {
        console.log(`cyi handleDialog`)
        onClose(false);
    };
    //임시로 만들어놓은 동작 => 실질적 기능은 없음 
    const handle = async () => {
        console.log(`handle `)
    }

    const handleClose = () => {
        setOpen(false);
    };
    //추가 임시
    const handleAddItem = () => {
        if (newItem.trim() !== '') {
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

            const newItemElement = (
                <div>
                    <Typography>
                        <span style={{ color: '#4472C4' }}>{formattedDate}</span>  {newItem}
                    </Typography>
                </div>
            );

            const newList = [...listItems, newItemElement];
            setListItems(newList);
            setNewItem('');
        }
        handleClose();
    };
    //카테고리 바꿈 동작
    const handleChangeCategory = (name: string, value: string) => {
        console.log(`cyi handleOnOffType name:${name}, value"${value}`)
        const type = ErrorCategory.find((item: any) => item.value == value)
        setErrorCategory(type.label);
    };

    //장애 설명
    const handleChangeDesc = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const error = event.target.value;
        setErrorText(error);
    }

    //장애 등록 기능
    const handleRegError = async () => {
        if (!errorCategory) {
            ToastsStore.warning("상태를 선택해주세요.");
        } else {
            const ret: any = await maintenanceStore.regError(facId, errorCategory, errorText, '12', userStore.siteId);
            if (facilityStore.facilityDetailInfo.maintenanceStateCode == null) {
                facilityStore.facilityDetailInfo.maintenanceStateCode = '12';
            }
            await maintenanceStore.getErrorList(facId, userStore.siteId);
            if (ret.status == 200) {
                ToastsStore.success("등록 성공 !!! \n[ 등록된 장애는 장애관리에서 확인할 수 있습니다. ]");
                const timer = setInterval(() => {
                    handleDialogClose();
                    clearInterval(timer);
                }, 1000);
            } else if (ret.status == 400) {
                ToastsStore.success("등록 실패");
            }
        }
    };

    return (
        <Box>
            {/* 장애등록 팝업 */}
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>

                    <Typography
                        sx={{
                            fontSize: 23,
                            fontWeight: 600,
                            color: '#0C3149',
                            display: 'flex',
                            position: 'relative',

                            padding: 1.5
                        }}>
                        장애 등록
                    </Typography>

                    <Divider />
                    <CardContent>
                        <StyledNameInput
                            name="시설 이름"
                            value={facilityStore.facilityDetailInfo?.facName}
                            disabled={true}
                            onChange={handle} />
                        <Box sx={{ mt: 1 }}> </Box>
                        <StyledNameInput
                            name="상태"
                            value={getErrorTypeStr('12')}
                            disabled={true}
                            onChange={handle} />
                        <Box sx={{ height: 10 }} />
                        <Divider sx={{ border: 1, borderColor: '#BFBFBF' }} />

                        <Typography
                            sx={{
                                fontSize: 23,
                                fontWeight: 600,
                                color: '#0C3149',
                                display: 'flex',
                                position: 'relative',

                                padding: 1.5
                            }}>
                            장애 정보
                        </Typography>

                        <StatusSelect
                            name="장애 카테고리"
                            object={ErrorCategory}
                            defaultValue={'100'}
                            space={10}
                            nameWidth={110}
                            valueWidth={200}
                            onSelect={handleChangeCategory} />
                        <Divider />
                        <Typography
                            sx={{ fontSize: 16, position: 'relative', left: 20, mb: 2, mt: 2 }}
                        >
                            장애 내용
                        </Typography>
                        <TextField
                            id="outlined-multiline-static"
                            label="장애 상세 내용을 적어주세요."
                            multiline
                            rows={4}
                            onChange={handleChangeDesc}
                            sx={{ width: '450px' }}
                        />
                    </CardContent>
                    <Divider sx={{ border: 1, borderColor: '#BFBFBF' }} />
                </DialogContent>
                <DialogActions>
                    <Button className={styles.greenButton} onClick={handleDialogClose} autoFocus>
                        취소
                    </Button>
                    <Button className={styles.primaryButton} onClick={() => { handleRegError(); }} autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open1} onClose={handleClose}>
                <DialogTitle>Add Item</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Item"
                        value={newItem}
                        onChange={(e) => setNewItem(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddItem}>Add</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
});

export default ErrorManagePopup;