//모바일버전 nameValueRem 컴포넌트 
import * as React from 'react';
import {
  Box,
  Typography
} from '@mui/material';

type MobileNameValueRemProps = {
  name : string;
  value : string;
  space? : string;
  nameWidth? : string;
  valueWidth? : string;
};
const MobileNameValueRem = ({ name, value, nameWidth = '7rem', valueWidth = '15rem', space = '1rem' }:MobileNameValueRemProps) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Typography
                    sx={{
                        width:nameWidth,
                    }}
                    color="textPrimary"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space,
                        backgroundColor:"#00ff00"
                 }} />
                <Typography
                    sx={{
                        width:'auto',
                        height: '2rem',
                        borderRadius: "0.5rem",
                        border: "1px solid #bdbdbd",
                        pl: 1,
                        pr:1
                    }}
                    color="textPrimary"
                >
                    {value}
                </Typography>
            </Box>
        </>
    )
};

export default MobileNameValueRem;
