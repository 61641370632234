//그룹 관련 api
import { observable } from "mobx";
import { IGroupInfo, IGroupSet } from "model";
import groups from "__mocks__/groups";
import { api, q } from '../api';

export interface IGroupStore {
	groupList:IGroupInfo[];
	groupInfo:IGroupInfo;
	userList:any[];

	addGroup:(groupInfo:IGroupSet) => void;
	getGroupCount:() => void;
	getGroup:(groupId:string) => void;
	getGroupByName:(siteId:string, groupName:string) => void;
	getGroupList:(siteId:string, perPage:string, page:string) => void;
	delGroup:(siteId:string, groupId:string) => void;
	getGroupListByUser:(siteId:string, userId:string) => void;
	setUserToGroup:(siteId:string, groupId:string, userId:string, description:string) => void;
	deleteUserGroup:(siteId:string, groupId:string, userId:string) => void;
	getUserListByGroup:(siteId:string, groupId:string) => void;
}
export const groupStore = observable<IGroupStore>({
	groupList:[],
	groupInfo:null,
	userList:[],

	//그룹 추가
	async addGroup(groupInfo:IGroupSet) {
		console.log(`addGroup ::: ${JSON.stringify(groupInfo)}`)
		try {
			const ret = await api.post(`/api2/group/setGroupInfo`, groupInfo);
			console.log(`addGroup ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`addGroup error`)
			return {status:error.status};
		}
	},
	//그룹 카운트 가져오기
	async getGroupCount() {
		console.log(`getGroupCount`);
	},
	async getGroup(groupId:string) {
		const ret = await api.post('/api2/group/getGroupInfo',{
				groupId,
		});
		console.log(`getGroup ${JSON.stringify(ret)}`)
		this.groupInfo = ret.data;
	},
	async getGroupByName(siteId:string, groupName:string) {
		const ret = await api.post('/api2/group/getGroupInfo',{
				siteId,
				groupName,
		});
		console.log(`getGroup ${JSON.stringify(ret)}`)
		this.groupInfo = ret.data;
	},
	//그룹 리스트 가져오기
	async getGroupList(siteId:string, perPage:string, page:string) {
		console.log(`getGroupList ${siteId}`);
		try {
			const ret = await api.post(`/api2/group/getGroupList`,{
				siteId,
				perPage,
				page,
			});
			console.log(`getGroupList ret = ${JSON.stringify(ret)}`)
			this.groupList = ret.data;
		} catch (error) {
			console.log(`getGroupList error`)
		}
	},
	//그룹에 속한 사용자 제거, 그룹 삭제
	async delGroup(siteId:string, groupId:string){
		console.log(`delGroup`);
		try {
			const ret = await api.post(`/api2/group/removeGroupInfo`, {
				siteId,
				groupId,
			});
			console.log(`delGroup ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`delGroup error`)
			return {status:error.status};
		}
		return '';
	},
	async getGroupListByUser(siteId:string, userId:string) {
		console.log(`getGroupListByUser ${siteId}, ${userId}`)
		try {
			const ret = await api.post(`/api2/group/getUserGroupList`,{
				siteId,
				userId
			});
			console.log(`getGroupListByUser ret = ${JSON.stringify(ret)}`)
			this.groupList = ret.data;
			return {status:200};
		} catch (error) {
			console.log(`getGroupListByUser error`)
			return {status:error.status};
		}
	},
	async setUserToGroup(siteId:string, groupId:string, userId:string, description:string){
		console.log(`setUserToGroup`);
		try {
			const ret = await api.post(`/api2/user/setUserGroup`, {
				siteId,
				groupId,
				userId,
				description,
			});
			console.log(`delGroup ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`delGroup error`)
			return {status:error.status};
		}
		return '';
	},
	async deleteUserGroup(siteId:string, groupId:string, userId:string){
		console.log(`delUser`);
		try {
			const ret = await api.post(`/api2/user/deleteUserGroup`, {
				siteId,
				groupId,
				userId,
			});
			console.log(`delUserGroup ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`delGroup error`)
			return {status:error.status};
		}
		return '';
	},
	async getUserListByGroup(siteId:string, groupId:string) {
		console.log(`getUserListByGroup ::: `)
		try {
			const ret = await api.post(`/api2/user/getGroupUserList`,{
				siteId,
				groupId
			});
			console.log(`getUserListByGroup ret = ${JSON.stringify(ret)}`)
			this.userList = ret.data;
			return {status:200};
		} catch (error) {
			console.log(`getUserListByGroup error`)
			return {status:error.status};
		}
	},
})

export default groupStore;