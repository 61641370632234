//사이트 관리- 사이트 - 사이트 사용자 관리
//현재 사용 안함
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import SiteUserAdd from '../../../components/slagger/system/SiteUserAdd';

const SiteUserFom = () => {
  console.log('SiteUserFom')
  return(
  <>
    <Helmet>
      <title>SiteUserFom</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <SiteUserAdd />
      </Container>
    </Box>
  </>
);
    };

export default SiteUserFom;
