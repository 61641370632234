// 사이트 관리 - 중계기(미연동 시설) - 기기연동 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import ConnectDeviceGatewayImp from 'components/facility/ConnectDeviceGatewayImp';

interface ITags {
  tag: string;
  value: string;
};

interface ILocation {
  name:string,
  facId:string,
  labelNo:string,
  serialNo:string,
  fac?:any
}
const ConnectDeviceGateway:React.FC = () => {
  const location = useLocation();
  const state = location.state as ILocation;
  const [values, setValues] = useState(
    {
      name: '',
      facId: '',
      labelno: '',
      fac: [],
    });
  return (
    <>
      <Helmet>
        <title>ConnectDeviceGateway</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
            <ConnectDeviceGatewayImp />
        </Container>
      </Box>
    </>
  )
};

export default ConnectDeviceGateway;
