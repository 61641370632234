import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Toolbar,
  Typography
} from '@mui/material';
import { IFacInfo } from '../../model';
import { useStores } from '../../state';
import { observer } from 'mobx-react';
import { alpha } from '@mui/material/styles';
import { TitleinTab } from 'components/atom/DefineStyled';
import NameValue from 'components/atom/NameValue';

interface StyledNameValueProps {
  name: string;
  value :string;
}
function StyledNameValue({name, value}:StyledNameValueProps) {
  return (
    <>
      <NameValue
        name={name}
        value={value}
        nameWidth={90}
        valueWidth={200}
      />
      <Box sx={{
        height: 10,
      }} />
    </>
  );
}

type FacGroupProps = {
  facId : string;
  facs ?: IFacInfo[];
};
const FacGroup = observer(({ facId, facs }:FacGroupProps) => {
  const { clientStore } = useStores();
	console.log(`FacGroup :facId : ${facId}`)

  useEffect(() => {
    console.log(`FacGroup in useEffect ${facId}}`);
    clientStore.getDetail(facId);
  },[facId]);

  // useEffect(() => {
  //   console.log(`FacGroup ${facId}}`);
  //   clientStore.getDetail(facId);
  // },[]);

  useEffect(() => {
    console.log(`FacGroup ${JSON.stringify(clientStore.facInfo)}`);
  },[clientStore.facInfo]);
  useEffect(() => {
    console.log(`FacGroup ${JSON.stringify(clientStore.status)}`);
  },[clientStore.status]);

  const updateOnOff = (onOff:string) => {
    console.log(`updateOnOff ${onOff}`)
    clientStore.updateOnOff("39", onOff);
  }

  return (
    <div>
      <Box 
				sx={{
          mb: 3,
					borderColor: 'divider',
					borderBottom: 1,
          width:310
				}}
      >
        <TitleinTab variant="h4">
          시설
        </TitleinTab>
        </Box>
        <StyledNameValue
        name = "표찰번호"
        value = ""
        />
    </div>
  );
});

export default FacGroup;
