// 시설운영 - 그룹 -설정 - 라인 (바로 보입니다. 초록색 테이블)
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Drawer,
	Grid,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	useTheme
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { IFacInfo, IFacilityInfo, IGetSiteSunsetDetail, ILineInfo, ILineSet } from '../../model';
import { makeStyles, styled } from '@mui/styles';
import { observer } from 'mobx-react';
import lineStore from 'state/line';

import LineAddPopup from 'components/popup/LineAddPopup';
import LineDetailUpdatePopup from 'components/popup/LineDetailUpdatePopup';
import { ButtonBlue, ButtonGreen } from 'components/admin/system/Styled';
import FacilityLineModifyFormImp from './FacilityLineModifyFormImp';
import { useStores } from '../../state';
import styles from './style.module.css'
import ManualOnOff from 'components/atom/ManualOnOff';

// tableRow 스타일 재정의
const useStyles = makeStyles(({ }) => ({
	tableRowRoot: {
		"&$tableRowSelected, &$tableRowSelected:hover": {
			backgroundColor: "#FDEECB"
		}
	},
	tableRowSelected: {
		backgroundColor: "#ff0000"
	}
}));

type FacilityLineListProps = {
	//lineList : ILineInfo[];
	selectOnTime?: string;
	selectOffTime?: string;
	siteId: string;
	groupId: string;
	title?: string;
	lineId: any
	lineName?: string;
	//lineInfo: any,
	onSelected?: (command: string, onTime: string, offTime: string, operationMode: string, lineName: string) => void;
};

const FacilityLineList = observer(({ siteId, lineId, groupId, title, onSelected, lineName }
	: FacilityLineListProps) => {
	const [selectedFacIds, setSelectedFacIds] = useState([]);
	const { facilityStore, userStore } = useStores();
	const [inFacility, setInFacility] = useState<IFacilityInfo[]>([]);
	const [sunSetDetail, setSunSetDetail] = useState<IGetSiteSunsetDetail>(null);
	const [limit, setLimit] = useState(7);
	const [page, setPage] = useState(1);
	const [showLine, setShowLine] = useState(false);
	const [showModifyLine, setShowModifyLine] = useState(false);
	const [showLineAddPopup, setShowLineAddPopup] = useState(false);
	const [showLineDetailUpdatePopup, setShowLineDetailUpdatePopup] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedName, setSelectedName] = useState('');
	const [selectedLine, setSelectedLine] = useState(null);
	const [deleteLine, setDeleteLine] = useState(null);
	const [onLoading, setOnLoading] = useState(false);
	const [offLoading, setOffLoading] = useState(false);
	const [values, setValues] = useState<ILineSet>(
		{
			siteId: '',
			siteName: '',
			groupId: '',
			groupName: '',
			lineId: '',
			lineName: '',
			description: '',
			chunkId: '',
			chunkName: '',
			operationMode: 'N',
			offTime: '',
			onTime: '',
		});
	const theme = useTheme();
	const classes = useStyles();
	useEffect(() => {
		console.log(`facilityLineFormImp lineInfo ${JSON.stringify(values)}`);
		setValues({
			...values,
		});
	}, []);

	const navigate = useNavigate();

	// api 실행하는 함수 
	const getLine = async () => {
		await lineStore.getLineList(userStore.siteId, groupId, userStore.getId(), "10", "1");
		if (lineStore.lineList.length > 0) {
			setSelectedName(lineStore.lineList[0].lineName);
			onSelected(lineStore.lineList[0].lineId, lineStore.lineList[0]?.onTime, lineStore.lineList[0]?.offTime, lineStore.lineList[0]?.operationMode, lineStore.lineList[0]?.lineName)
		}
	}
	//getLine 함수 실행
	useEffect(() => {
		console.log(`FacilityLineList faci = ${JSON.stringify(lineStore.lineList)}`)
		console.log(`FacilityLineList siteId = ${siteId}, ${groupId}`);
		getLine();
	}, []);

	// 몇번 라인이 선택되었는지 (홈페이지 F12 콘솔에서 확인하세요.)
	useEffect(() => {
		console.log(`FacilityLineList selectedLine = ${JSON.stringify(selectedLine)}`)
	}, [selectedLine]);

	//pagination 함수(페이지 넘기는 거)
	const handlePageChange = (event: any, newPage: number) => {
		console.log(`handlePageChange ${newPage}`)
		setPage(newPage);
	};

	// 정보수정 팝업 닫기
	const handleLineDetailUpdatePopupClose = async () => {
		console.log(`handleLinerAddPopupClose in facilitylinelist`)
		setShowLineDetailUpdatePopup(false);
		await lineStore.getLineList(userStore.siteId, groupId, userStore.getId(), "10", "1");
	};
	//라인 추가 팝업 닫기
	const handleLineAddPopupClose = async () => {
		console.log(`handleLinerAddPopupClose in facilitylinelist`)
		setShowLineAddPopup(false);
		await lineStore.getLineList(userStore.siteId, groupId, userStore.getId(), "10", "1");
	};
	//시설관리 팝업 닫기
	const handleLineModifyDrawerClose = async () => {
		console.log(`handleLineModifyDrawerClose in facilitylinelist`)
		setShowModifyLine(false);
		await lineStore.getLineList(userStore.siteId, groupId, userStore.getId(), "10", "1");
	};
	//라인삭제 Dialog 닫기
	const handleDialogClose = () => {
		setOpenDialog(false);
	};
	//라인삭제 함수
	const handleDeleteLine = async () => {
		setOpenDialog(false);
		console.log(`handleDeleteLine : ${JSON.stringify(deleteLine)}`)
		const ret: any = await lineStore.delLine(siteId, groupId, deleteLine.lineId);
		console.log(`handleDeleteLine : ${JSON.stringify(ret)}`)
		if (ret.status == 200) {
			ToastsStore.success("삭제 성공")
			lineStore.getLineList(userStore.siteId, groupId, userStore.getId(), "10", "1");
		}
		else {
			ToastsStore.warning("라인 삭제에 실패 했습니다.")
		}
	};
	// 라인 ON 함수
	const lineOn = async () => {
		console.log(`updateOn ${lineId}`);
		let count = 1;
		const maxMinute = 24; // 5초 * 24 = 120초 = 2분
		setOnLoading(true)

		const timerOpen = setInterval(async () => {
			if (lineStore.lineOnOffCtrl?.result !== "true" || !lineStore.lineOnOffCtrl.subId) {
				ToastsStore.warning(`${lineName} 의 명령 전달을 실패하였습니다. 다시 시도해주세요.`)
				clearInterval(timerOpen);
				setOnLoading(false)
			}
		}, 100);

		const timer = setInterval(async () => {
			console.log(`updateOnOff loop : ${count}`);
			if (count >= maxMinute) {
				setOnLoading(false);
				console.log("2분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
				clearInterval(timer);
			} else {
				try {
					if (lineStore.lineOnOffCtrl.result === "true") {
						await lineStore.getLineOnCmdRunState(lineId, lineStore.lineOnOffCtrl.subId);
						console.log(`on ? ${lineId}`, JSON.stringify(lineStore.lineRunState.count));

						if (lineStore.lineRunState.count == '0') {
							setOnLoading(false);
							ToastsStore.success(`${lineName}의 ON 명령이 전달되었습니다.`);
							clearInterval(timer);
						}
					}
					else {
						clearInterval(timer);
						ToastsStore.warning("명령 전달을 실패하였습니다. \n 다시 시도해주세요.")
					}
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setOnLoading(false);
					clearInterval(timer);
				}
			}
			count++;
		}, 5000);
	};

	// 라인 OFF 함수
	const lineOff = async () => {
		console.log(`updateOn ${lineId}`);
		let count = 1;
		const maxMinute = 24; // 5초 * 24 = 120초 = 2분
		setOffLoading(true)

		const timerOpen = setInterval(async () => {
			if (lineStore.lineOnOffCtrl.result !== "true" || !lineStore.lineOnOffCtrl.subId) {
				ToastsStore.warning(`${lineName} 의 명령 전달을 실패하였습니다. 다시 시도해주세요.`)
				clearInterval(timerOpen);
				setOffLoading(false)
			}
		}, 100);

		const timer = setInterval(async () => {
			console.log(`updateOnOff loop : ${count}`);
			if (count >= maxMinute) {
				setOffLoading(false);
				console.log("2분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
				clearInterval(timer);
			} else {
				try {
					if (lineStore.lineOnOffCtrl.result === "true") {
						await lineStore.getLineOnCmdRunState(lineId, lineStore.lineOnOffCtrl.subId);
						console.log(`on ? ${lineId}`, JSON.stringify(lineStore.lineRunState.count));

						if (lineStore.lineRunState.count == '0') {
							setOffLoading(false);
							ToastsStore.success(`${lineName}의 ON 명령이 전달되었습니다.`);
							clearInterval(timer);
						}
					}
					else {
						clearInterval(timer);
						setOffLoading(false);
						ToastsStore.warning("명령 전달을 실패하였습니다. \n 다시 시도해주세요.")
					}
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setOffLoading(false);
					clearInterval(timer);
				}
			}
			count++;
		}, 5000);
	};

	// 라인 ON/OFF 버튼 ( lineON , lineOFF 함수 실행 )
	const handleEvent = (event: string) => {
		console.log(`handleEvent ${event}`);
		console.log(`facilityLineFormImp lineInfoaa ${JSON.stringify(values)}`);
		console.log(`handleEvent ${userStore.siteId}, ${groupId} ${lineId}`);
		if (event == 'ON') {
			lineStore.cmdLineLampOn(userStore.siteId, groupId, lineId);
			lineOn();
		}
		else {
			lineStore.cmdLineLampOff(userStore.siteId, groupId, lineId);
			lineOff();
		}
	}
	return (
		<>
			<PerfectScrollbar>
				<Card
					sx={{ width: '100%' }}
				>
					<Box
						sx={{
							minWidth: 400,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								width: '100%',
								height: '3rem',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								backgroundColor: '#44B572'
							}}
						>
							<Box
								sx={{
									flex: 1,
								}}>
							</Box>
							<Box
								sx={{
									flex: 1,
									alignItems: 'center',
								}}>
								<Typography
									color={theme.palette.text.primary}
									variant="h3"
									align="center"
								>
									{title} {lineStore.lineList.length !== undefined && `(${lineStore.lineList.length}개)`}
								</Typography>
							</Box>
							<Box
								sx={{
									flex: 1,
									alignItems: 'flex-end',
									display: 'flex',
									flexDirection: 'column',
								}}>
								<Button
									className={styles.greenButton}
									size="small"
									onClick={() => {
										console.log(`라인추가`)
										const emptyLine = {
											siteId: siteId,
											groupId: groupId,
											operationMode: 'N',
										}
										setSelectedLine(emptyLine);
										setShowLineAddPopup(true);
									}}
								>
									라인추가
								</Button>
							</Box>
						</Box>

						<Table size="small">
							<TableHead >
								<TableRow>
									<TableCell className={styles.tableHeadEnd}>
										이름
									</TableCell>
									<TableCell colSpan={2} className={styles.tableHead}>
										기능
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{lineStore.lineList.slice((page - 1) * limit, limit + (page - 1) * limit).map((line, index) => (
									<TableRow
										hover
										onClick={() => {
											console.log(`---- ${line.lineName}`)
											setSelectedName(line.lineName);
											onSelected(line.lineId, line?.onTime, line?.offTime, line?.operationMode, line?.lineName)
										}}
										key={line.lineId}
										selected={line.lineName == selectedName}
										classes={{
											root: classes.tableRowRoot,
											selected: classes.tableRowSelected,
										}}
									>
										<TableCell sx={{ textAlign: 'center' }}>
											{line.lineName}
										</TableCell>
										<TableCell className={styles.tableBody} sx={{ borderRight: 1, borderColor: '#E6E7E8' }}>
											{/* 라인ON/OFF 버튼 */}
											<ManualOnOff
												disabled={false}
												space={'0rem'}
												onLoading={onLoading}
												offLoading={offLoading}
												nameWidth={'3rem'}
												valueWidth={'5rem'}
												onEvent={handleEvent}
												name={''} />
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											<ButtonBlue
												sx={{
													height: 30,
													backgroundColor: '#3A9C62',
													"&:hover": { backgroundColor: "#235E3B" }
												}}
												size="small"
												color="primary"
												variant="contained"
												onClick={() => {
													console.log(`시설 관리`)
													setSelectedLine(line);
													setShowModifyLine(true);
												}}
											>
												<Typography sx={{ fontSize: 12 }}>시설 관리</Typography>
											</ButtonBlue>
											<ButtonBlue
												sx={{
													height: 30,
													backgroundColor: '#47BD77',
													"&:hover": { backgroundColor: "#318252" }
												}}
												size="small"
												color="primary"
												variant="contained"
												onClick={() => {
													console.log(`정보 수정`)
													setSelectedLine(line)
													setShowLineDetailUpdatePopup(true);
												}}
											>
												<Typography sx={{ fontSize: 12 }}>정보 수정</Typography>
											</ButtonBlue>
											<ButtonGreen
												sx={{
													height: 30,
													"&:hover": { backgroundColor: '#0E3B25' }
												}}
												size="small"
												color="primary"
												variant="contained"
												onClick={() => {
													console.log(`라인 삭제`)
													setDeleteLine(line);
													setOpenDialog(true);
												}}
											>
												<Typography sx={{ fontSize: 12 }}>라인 삭제</Typography>
											</ButtonGreen>
										</TableCell>
									</TableRow>
								))}
								{(lineStore.lineList.length == 0) &&
									<TableRow>
										<TableCell className={styles.tableNoLine} colSpan={12}>
											<Typography sx={{ fontSize: 17, fontWeight: 600 }}>라인이 없습니다.</Typography>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					</Box>
					<Pagination
						className={styles.pagination}
						color="primary"
						page={page}
						count={Math.ceil(lineStore.lineList.length / 7)}
						onChange={handlePageChange}
						sx={{
							[theme.breakpoints.down('sm')]: {
								width: window.innerWidth,
							},
						}}
					/>
					<ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
				</Card >
			</PerfectScrollbar>

			<Dialog
				open={showModifyLine}
				onClose={() => handleLineModifyDrawerClose()}
				PaperProps={{
					sx: {
						maxWidth: '100%',
						width: '80%',
						height: '80%',
						display: 'flex',
						justifyContent: 'flex-start',
						// ml: {isMobile?1:35},
						ml: 1,
						mr: 1,
						mb: 1,
					}
				}}
			>
				<FacilityLineModifyFormImp
					open={showModifyLine}
					lineInfo={selectedLine}
					onClose={() => handleLineModifyDrawerClose()}></FacilityLineModifyFormImp>
			</Dialog>

			<LineAddPopup
				open={showLineAddPopup}
				groupId={groupId}
				onClose={handleLineAddPopupClose}></LineAddPopup>
			<LineDetailUpdatePopup
				open={showLineDetailUpdatePopup}
				lineInfo={selectedLine}
				onClose={() => handleLineDetailUpdatePopupClose()}></LineDetailUpdatePopup>
			<Dialog
				open={openDialog}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						해당 라인을 삭제 하시겠습니까?
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button className={styles.greenButton} onClick={handleDialogClose}>취소</Button>
					<Button className={styles.primaryButton} onClick={handleDeleteLine} autoFocus>
						확인
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
});

export default FacilityLineList;

