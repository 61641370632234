// 시설상세/중계기 상세 정보칸에서 사용하는 value칸

import * as React from 'react';
import {
  Box,
  Typography
} from '@mui/material';

type NameValueProps = {
  name : string;
  value : string;
  space? : number;
  nameWidth? : number;
  valueWidth? : number;
};
const NameValue = ({ name, value, nameWidth = 60, valueWidth = 300, space = 10 }:NameValueProps) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Typography
                    sx={{
                        width:nameWidth,
                    }}
                    color="textPrimary"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space,
                        backgroundColor:"#00ff00"
                 }} />
                <Typography
                    sx={{
                        width:valueWidth,
                        height: 30,
                        border:1,
                        borderColor:"#eeee",
                        pl: 1,
                    }}
                    color="textPrimary"
                >
                    {value}
                </Typography>
            </Box>
        </>
    )
};

export default NameValue;
