import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';

const RequireList = () => {
  console.log('RequireList')
  return(
  <>
    <Helmet>
      <title>RequireList</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            구현 중 입니다.
          </Typography>
      </Container>
    </Box>
  </>
);
    };

export default RequireList;
