//시설운영 - 시설

import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Modal,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  useTheme,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate } from 'react-router';
import { IFacInfo } from 'model';
import { TitleinTab } from 'components/atom/DefineStyled';
import NameValue from 'components/atom/NameValue';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { IFacilityInfo, getFacTypeStr } from 'model/facility';
import facilityStore from 'state/facility';
import { ButtonBlue } from 'components/admin/system/Styled';
import FacInfoDialogLamp from 'components/atom/FacInfoDialogLamp';
import styles from 'components/facilityGroup/style.module.css'
import FacDetailInfoDialog from 'components/atom/FacDetailInfoDialog';
interface StyledNameValueProps {
  name: string;
  value: string;
}
function StyledNameValue({ name, value }: StyledNameValueProps) {
  return (
    <>
      <Grid
        item
        lg={6}
        sm={12}
        xl={6}
        xs={12}
      >
        <NameValue
          name={name}
          value={value}
          space={3}
          nameWidth={150}
          valueWidth={400}
        />
        {/* <Box sx={{
        height: 10,
      }} /> */}
      </Grid>
    </>
  );
}

const StyledModal = styled(Modal)(
  ({ theme }) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 400,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 4,
    // boxShadow: 24,
  }));

type ManageLampListResultsProps = {
  lampCount: string;
  facs: IFacilityInfo[];
  doit: string;
  facId: string;
};
const ManageLampListResults = observer(({ facs, doit, lampCount }: ManageLampListResultsProps) => {
  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [selectedFacId, setSelectedFacId] = useState<IFacilityInfo>();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [showFacInfo, setShowFacInfo] = useState(false);
  const [value, setValue] = React.useState<Date | null>();
  const [onTime, setOnTime] = React.useState<Date | null>();
  const [offTime, setOffTime] = React.useState<Date | null>();
  const [showFacInfoDialogLamp, setShowFacInfoDialogLamp] = useState(false);
  const [facId, setFacId] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const theme = useTheme();

  const navigate = useNavigate();

  const handleModify = async (fac: any) => {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!", JSON.stringify(fac))
    navigate('/slager/configfacility',
      {
        state: {
          siteId: fac.siteId,
          siteName: fac.siteName,
          groupId: fac.groupId,
          groupName: fac.groupName,
          lineId: fac.lineId,
          lineName: fac.lineName,
          facId: fac.facId,
          facName: fac.facName,
          facType: fac.facType,
          facDesc: fac.facDesc,
          latitude: fac.latitude,
          longitude: fac.longitude,
          altitude: fac.altitude,
          address: fac.address,
          telNo: fac.telNo,
          devId: fac.devId,
          serialNo: fac.devSerialNo,
        }
      });
  }

  //하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

  //상세 dialog 닫음
  const handleClose = async () => {
    console.log(`handleLineDrawerClose in facilitylinelist`)
    setShowFacInfoDialogLamp(false);
  };

  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };

  const handleErrorList = async (_facId: string) => {
    navigate('/slager/errorList',
      {
        state: {
          facId: _facId,
        }
      });
  }

  const handleDetailDialogOpen = async (_facId: string) => {
    setFacId(_facId)
    setShowFacInfoDialogLamp(true);
  };
  return (
    <>
      <PerfectScrollbar
      >
        <Box>
          <Typography
            className={styles.pageDirection}
            gutterBottom>
            시설 운영 {'>'} 시설
          </Typography>

          <Typography //타이틀 글자 조정
            className={styles.pageTitle}
            gutterBottom >
            시설
          </Typography>

          <Box sx={{ mt: 1 }}> </Box>
          <Box // 텍스트 박스 border
            sx={{
              borderRadius: 3,
              borderColor: '#1C75BC',
              marginBottom: '2%',
              margin: 'auto'
            }}
            {...defaultProps}
          >

            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                m: 1,
                textAlign: 'left',
                float: 'right',
                top: 15
              }}
            >

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: "center",
                  }}
                >
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box >
          <Table
            sx={{
              width: '100%',
            }}
            size='small'
          >
            <TableHead >
              <TableRow>
                {
                  !isMobile &&
                  <TableCell className={styles.tableHeadEnd} sx={{ width: '4%' }}>
                    No
                  </TableCell>
                }
                <TableCell className={!isMobile ? styles.tableHead : styles.tableHeadEnd}>
                  그룹
                </TableCell>
                <TableCell className={styles.tableHead}>
                  라인
                </TableCell>
                <TableCell className={styles.tableHead}>
                  이름
                </TableCell>
                {!isMobile &&
                  <><TableCell className={styles.tableHead}>
                    내용
                  </TableCell>
                    <TableCell className={styles.tableHead} sx={{ width: '6%' }}>
                      시설 종류
                    </TableCell></>
                }
                <TableCell className={styles.tableHead} sx={{ width: '12%' }}>
                  기능
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facs?.slice((page - 1) * limit, limit + (page - 1) * limit).map((fac, index) => (
                <TableRow
                  hover
                  key={fac.facId}
                  selected={selectedFacIds.indexOf(fac.facId) !== -1}
                >
                  {
                    !isMobile &&
                    <TableCell sx={{ textAlign: 'center', }}>
                      {(page - 1) * limit + index + 1}
                    </TableCell>
                  }
                  {/* 그룹 */}
                  <TableCell className={!isMobile ? styles.tableBody : styles.tableBodyEnd} sx={{ fontSize: isMobile && 12 }}>
                    {fac.groupName}
                  </TableCell>
                  {/* 라인 */}
                  <TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                    {fac.lineName}
                  </TableCell>
                  {/* 이름 */}
                  <TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                    {fac.facName}
                  </TableCell>
                  {!isMobile &&
                    // 설명
                    <><TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                      {fac.facDesc}
                    </TableCell>
                      {/* 시설 종류 */}
                      <TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                        {getFacTypeStr(fac.facType)}
                      </TableCell></>
                  }
                  <TableCell className={styles.tableBody} sx={{ width: '18%' }}>
                    <Button
                      sx={{ fontSize: isMobile && 12 }}
                      size="small"
                      className={styles.blueButton}
                      onClick={() => {
                        console.log("!!!!!!!!!!!!!!!!!!", JSON.stringify(fac))
                        console.log(`상세`)
                        handleErrorList(fac.facId);
                      }}
                    >
                      장애관리
                    </Button>
                    <Button
                      sx={{ fontSize: isMobile && 12 }}
                      size="small"
                      className={styles.blueButton}
                      onClick={() => {
                        console.log("!!!!!!!!!!!!!!!!!!", JSON.stringify(fac))
                        console.log(`상세`)
                        handleDetailDialogOpen(fac.facId);
                      }}
                    >
                      상세
                    </Button>
                    <Button
                      sx={{ fontSize: isMobile && 12 }}
                      size="small"
                      className={styles.blueButton}
                      onClick={() => {
                        console.log(`수정`)
                        handleModify(fac)
                      }}
                    >
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ display: !isMobile && 'flex' }}>
          <Typography className={styles.bottomCount}>
            전체 개수 : {lampCount} EA
          </Typography>
          <Pagination
            className={styles.pagination}
            color="primary"
            page={page}
            count={Math.ceil(facs?.length / 10)}
            onChange={handlePageChange}
            sx={{
              right: '7%',
              [theme.breakpoints.down('sm')]: {
                width: window.innerWidth,
              },
            }}
          />
        </Box>
      </PerfectScrollbar>
      
      {/* 시설상세 dialog */}
      <FacDetailInfoDialog
        facId={facId}
        onClose={handleClose}
        show={showFacInfoDialogLamp}
        onShow={() => {
          setShowFacInfo(false)
        }}></FacDetailInfoDialog>

      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </>
  );
});
export default ManageLampListResults;
