//사이트관리 - 사이트 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useStores } from '../../../state';
import { observer } from 'mobx-react';
import SiteListResults from '../../../components/slagger/system/SiteListResults';
import SiteToolbar from 'components/slagger/system/SiteToolbar';
import userStore from 'state/user';

const SiteList = observer(({ }) => {
  const { siteStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    siteStore.getSiteList(userStore.getId(), "10", "1");
  }, []);

  useEffect(() => {
    console.log(`SiteList siteList = ${JSON.stringify(siteStore.siteList)}`)
  }, [siteStore.siteList]);

  const onCommand = (command: string) => {
    console.log(`onCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }

  return (
    <>
      <Helmet>
        <title>SiteList</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <SiteToolbar siteCount={siteStore.siteList.filter(site => site.enable == 'Y').length.toString()} onCommand={onCommand} />
          <Box sx={{ mt: 1 }}></Box>
          <Box >
            <SiteListResults sites={siteStore.siteList} doit={doit} siteCount={siteStore.siteList.filter(site => site.enable == 'Y').length.toString()}/>
          </Box>
        </Container>
      </Box>
    </>
  );
});

export default SiteList;
