//모바일버전 nameSelect 컴포넌트 
import * as React from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { AlignCenter, AlignJustify } from 'react-feather';
import { VerticalAlignCenter } from '@mui/icons-material';
import { useEffect } from 'react';


type MobileNameSelectProps = {
    name: string;
    defaultValue?: string;
    object: any[];
    space?: number;
    nameWidth?: number;
    valueWidth?: number;
    backgroundColor? : string;
    onSelect: (name: string, value: string) => void;
};
const MobileNameSelect = ({ name, object, defaultValue = '0', nameWidth = 60, valueWidth = 300, space = 10, backgroundColor = "#FFFFFF", onSelect }: MobileNameSelectProps) => {
    console.log(`NameSelect ${name}:${defaultValue}:${JSON.stringify(object)}`)
    const [select, setSelect] = React.useState<string>('');

    useEffect(() => {
        if (defaultValue) {
            console.log(`NameSelect values ${name}:${defaultValue}`)
            setSelect(defaultValue);
        }
        else {
            console.log(`NameSelect values else ${name}:${defaultValue}`)
        }
    }, [defaultValue]);

    const handleSelect = (event: SelectChangeEvent) => {
        console.log(`cyi nameSelect ${event.target.value}`)
        setSelect(event.target.value as string);
        onSelect(name, event.target.value as string);
    };
    return (
        <>
            <Box
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                    //justifyContent: 'space-between',
                }}
            >
                <Typography
                    sx={{
                        m: '0.6rem',
                        p: '0.4rem',
                        width: nameWidth,
                    }}
                    color="textPrimary"
                    variant="h6"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space }} />
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select}
                    sx={{
                        m: '0.4rem',
                        p: '0.7rem',
                        borderColor: '#BFBFBF',
                        backgroundColor: {backgroundColor},
                        width: '13rem',
                        height: '2.7rem',
                    }}
                    onChange={handleSelect}
                    defaultValue={defaultValue}
                >
                    {object.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
};

export default MobileNameSelect;
