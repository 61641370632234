//관리자 - 사이트 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useStores } from '../../../state';
import { observer } from 'mobx-react';
import AdminSiteListResults from '../../../components/admin/system/AdminSiteListResults';
import AdminSiteToolbar from 'components/admin/system/AdminSiteToolbar';
import userStore from 'state/user';

const AdminSiteList = observer(({ }) => {
  const { siteStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    siteStore.getSiteList(userStore.getId(), "10", "1");
  }, []);

  useEffect(() => {
    console.log(`AdminSiteList siteList = ${JSON.stringify(siteStore.siteList)}`)
  }, [siteStore.siteList]);

  const onCommand = (command: string) => {
    console.log(`onCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AdminSiteToolbar siteCount={siteStore.siteList.filter(site => site.enable == 'Y').length.toString()} onCommand={onCommand} />
          <Box sx={{ mt: 1 }}></Box>
          <Box >
            <AdminSiteListResults sites={siteStore.siteList} doit={doit} />
          </Box>
        </Container>
      </Box>
    </>
  );
});

export default AdminSiteList;
