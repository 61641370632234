//사이트 관리 - 중계기(연동시설)
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { IFacInfo } from '../../model';
import theme from 'theme';
import { IFacilityGatewayInfo } from 'model/facility';
import { ButtonBlue } from 'components/admin/system/Styled';
import styles from 'components/facilityGroup/style.module.css'

type RegisteredFacGatewayListResultsProps = {
  facInfos: IFacilityGatewayInfo[];
  doit: string;
  title?: string;
};
const RegisteredFacGatewayListResults = ({ facInfos, title, doit }: RegisteredFacGatewayListResultsProps) => {
  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [limit, setLimit] = useState(7);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();


  const handleLimitChange = (event: any) => {
    console.log(`handleLimitChange`)
    setLimit(event.target.value);
  };

  //하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

  //수정 페이지로 이동
  const handleUpdate = async (facInfo: any) => {
    console.log(`Device for connect ret : ${facInfo.length}, ${JSON.stringify(facInfo)}`)
    navigate('/slager/configfacilitygateway',
      {
        state: {
          siteId: facInfo.siteId,
          siteName: facInfo.siteName,
          facId: facInfo.facId,
          facName: facInfo.facName,
          facType: facInfo.facType,
          facDesc: facInfo.facDesc,
          latitude: facInfo.latitude,
          longitude: facInfo.longitude,
          address: facInfo.address,
          telNo: facInfo.telNo,
          gwId: facInfo.gwId,
          serialNo: facInfo.devSerialNo,
        }

      });
  }
  return (
    <Card
      sx={{
        width: '98%',
        margin: 'auto'
      }}>
      <PerfectScrollbar>
        <Box
          sx={{
            minWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '3rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: '#F7B31E'
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}>
            </Box>

            <Box
              sx={{
                flex: 1,
                alignItems: 'center',
              }}>
              <Typography
                color={theme.palette.text.primary}
                variant="h3"
                align="center"
              >
                {title}({facInfos.length})
              </Typography>
            </Box>

            <Box
              sx={{
                flex: 1,
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
              }}>
            </Box>
          </Box>

          <Table >
            <TableHead className={styles.tableHeadEnd} sx={{ minWidth: 400 }}>
              <TableRow>
                <TableCell className={styles.tableHead} sx={{ width: '10%' }}>
                  No
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: '20%' }}>
                  시설이름
                </TableCell>
                <TableCell className={styles.tableHead}>
                  설명
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: '15%' }}>
                  기능
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facInfos.slice((page - 1) * limit, limit + (page - 1) * limit).map((facInfo, index) => (
                <TableRow
                  hover
                  key={facInfo.facId}
                  selected={selectedFacIds.indexOf(facInfo.facId) !== -1}
                >
                  <TableCell className={styles.tableBodyEnd} >
                    {(page - 1) * limit + index + 1}
                  </TableCell>
                  <TableCell className={styles.tableBody}>
                    {facInfo.facName}
                  </TableCell>
                  <TableCell className={styles.tableBody} >
                    {facInfo.facDesc}
                  </TableCell>
                  <TableCell className={styles.tableBody} sx={{ padding: '1%' }}>
                    <Button
                      className={styles.blueButton}
                      size="small"
                      onClick={() => {
                        console.log(`수정`)
                        handleUpdate(facInfo);
                      }}
                    >
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Pagination
        color="primary"
        page={page}
        count={Math.ceil(facInfos.length / 7)}
        onChange={handlePageChange}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          height: '50px',
          [theme.breakpoints.down('sm')]: {
            width: window.innerWidth,
          },
        }}
      />
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Card>
  );
};

export default RegisteredFacGatewayListResults;
