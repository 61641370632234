// 사이트 관리 - 시설 - 시설 등록 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import RegisterFacImp from 'components/facility/RegisterFacImp';

const RegisterFacility = () => {
  console.log('RegisterFacility')
  return(
  <>
    <Helmet>
      <title>RegisterFacility</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
            <RegisterFacImp />
      </Container>
    </Box>
  </>
);
    };

export default RegisterFacility;
