// 대시보드 시설 ( ON/OFF , 불량/정상 등등... )
import { BorderAll } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  styled,
  Typography
} from '@mui/material';
import { fontSize, getStackUtilityClass } from '@mui/system';
import Space from 'components/atom/Space';
import { observer } from 'mobx-react';
import { useState, useEffect, useRef } from 'react';
import { AlignCenter } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import DashFacilityChart from 'components/dashboard/DashFacilityChart';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import FacilityGroupDetail from 'pages/slager/facilityGroup/FacilityGroupDetail';
import { AlertTriangle as AlertTriangleIcon, Wifi as WifiIcon, WifiOff as WifiOffIcon, Tool as ToolIcon } from 'react-feather';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import FacDetailInfoDialog from 'components/atom/FacDetailInfoDialog';
import lineStore from 'state/line';
import ManualOnOff from "components/atom/ManualOnOff";
import maintenanceStore from "state/maintenance";
import { LoadingButton } from "@mui/lab";

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  $:last-child {
    padding-bottom: 0;
  }
  }`)
type DashFacilityProps = {
  category?: string;
  facInfo: any;
  onTime: string;
  offTime: string;
  curFac: any[];
  onFacilitySize?: (size: number) => void;
  onFacilityChartOpen: (on: boolean) => void;
  lineInfo: any;
};

const DashFacility = observer((
  { category, facInfo, onTime, offTime, curFac, onFacilitySize, onFacilityChartOpen, lineInfo }: DashFacilityProps) => {
  const RefreshInterval = 20000;
  const [connectColor, setConnectColor] = useState<any>('success');
  const [connectdevColor, setConnectdevColor] = useState<any>('success');
  const [connectOnColor, setConnectOnColor] = useState<any>('success');
  const [connectlabel, setConnectlabel] = useState<any>('');
  const [connectReason, setConnectReason] = useState<any>('');
  const [connectStatus, setConnectStatus] = useState<any>('');
  const [connectONOFF, setConnectONOFF] = useState<any>('');
  const [showDashFacilityChart, setShowDashFacilityChart] = useState(false);
  const [showFacDetailInfoDialog, setshowFacDetailInfoDialog] = useState(false);
  const [facId, setFacId] = useState('');
  const [showFacInfo, setShowFacInfo] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const [showTestDashFacilityChart, setTestShowDashFacilityChart] = useState(false);
  const [state, setState] = useState('')


  // 아래 연산식  = on/off 시간을 비교 계산하기위한 상태 함수들입니다.
  const curDate = new Date();
  const devDate = new Date(facInfo.devTimeUpdate)
  const onTimeDate = new Date(`T${onTime}`)
  const offTimeDate = new Date(offTime)
  console.log(`llll DashFacility name = ${facInfo.facName}`);

  //  현재시간 , devUpdateTime 밀리초로 환산  
  const getNow = curDate.getTime();
  const getDevNow = devDate.getTime();

  // ON.OFF 타임 Date로 변환 ( String -> Date )
  const newDate = new Date()
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  console.log(`LampAutoOnOffDialog bb ${year}-${month}-${date}T${onTime}`)

  const formDate = moment(newDate).format('YYYY-MM-DD');
  const onTime1 = new Date(`${formDate}T${onTime}`);
  const offTime1 = new Date(`${formDate}T${offTime}`);

  const getOnMs = onTime1.getTime();
  const getOffMs = offTime1.getTime();

  const [lastOnTime, setLastOnTime] = useState('');
  const [lastOffTime, setLastOffTime] = useState('');

  // 정상/불량 버튼에 ALIVE 기능을 넣었으나 불 필요하여 뺐습니다. 필요하시면 주석을 풀고 사용하세요.
  // const getFacilityAlive = async () => {
  //   const ret: any = await facilityStore.cmdFacilityUpdateStatus(facInfo.facId);
  //   const devOld = facilityStore.facilityDetailInfo.devTimeUpdate
  //   if (ret.status == 200) {
  //     ToastsStore.success("Alive 성공")
  //     const timer = setInterval(() => {
  //       facilityStore.getFacilityDetailInfo(facInfo.facId)
  //       console.log("10초지났음")
  //       if (facilityStore.facilityDetailInfo.devTimeUpdate !== devOld) {  // 기존값과 현재 값이 다르면
  //         ToastsStore.success(`${facilityStore.facilityDetailInfo.facName}의 업데이트 시간이 갱신 되었습니다.`)
  //         console.log("갱신을 완료했습니다..")
  //         clearInterval(timer);
  //       }
  //     }, 10000)
  //     return () => clearInterval(timer);
  //   }
  //   else if (ret.status == 400) {
  //     ToastsStore.success("Alive 실패")
  //   }
  // }

  useEffect(() => {
    //if (facInfo.vout1 > 500) {
      setLastOnTime(moment(facInfo.onTime).isValid() 
      ? moment(facInfo.onTime).format('YY-MM-DD HH:mm:ss')
      : '');
    //} else {
      setLastOffTime(moment(facInfo.offTime).isValid() 
      ? moment(facInfo.offTime).format('YY-MM-DD HH:mm:ss')
      : '');
    //}
  }, [facInfo]);

  const [onLoading, setOnLoading] = useState(false);
  const [offLoading, setOffLoading] = useState(false);

  // ON 기능
  const updateOn = async () => {
    console.log(`updateOn ${facInfo.facId}`);
    let count = 1;
    const maxMinute = 24; // 5초 * 24 = 120초 = 2분
    setOnLoading(true)

    const timerOpen = setInterval(async () => {
      if (facilityStore.facilityCmdResult === "REQ") {
        ToastsStore.warning(`${facInfo?.facName} 의 이전 명령이 진행중입니다.`)
        clearInterval(timerOpen);
        setOnLoading(false)
      }
    }, 100);

    const timer = setInterval(async () => {
      console.log(`updateOnOff loop : ${count}`);
      if (count >= maxMinute) {
        setOnLoading(false);
        console.log("2분이 지났습니다.");
        ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
        clearInterval(timer);
      } else {
        try {
          if (facilityStore.facilityCmdResult === 'REQ') {
            clearInterval(timer);
          }
          if (facilityStore.facilityCmdResult !== "REQ") {
            setOnLoading(true);
            await facilityStore.getCmdRunState(facInfo.facId, '');
            console.log(`on ? ${facInfo.facId}`, JSON.stringify(facilityStore.facilityCmdState));

            if (facilityStore.facilityCmdState === "DONE" || facilityStore.facilityCmdState === "NO_COMMAND") {
              setOnLoading(false);
              ToastsStore.success(`${facInfo?.facName}의 ON 명령이 전달되었습니다.`)
              clearInterval(timer);
            }
            else if (facilityStore.facilityCmdState === "DONE") {
              setOnLoading(false);
              ToastsStore.success(`${facInfo?.facName}이 ON 되었습니다.`)
              clearInterval(timer);
            }
            else if (facilityStore.facilityCmdState === "Fail") {
              setOnLoading(false);
              ToastsStore.warning(`${facInfo?.facName} ON 실패.`)
              clearInterval(timer);
            }
          }
        } catch (error) {
          console.error("!!! 에러 !!!:", error);
          setOnLoading(false);
          clearInterval(timer);
        }
      }
      count++;
    }, 5000);
  };

  // OFF 기능
  const updateOff = async () => {
    console.log(`updateOn ${facInfo.facId}`);
    let count = 1;
    const maxMinute = 24; // 5초 * 24 = 120초 = 2분
    setOffLoading(true)

    const timerOpen = setInterval(async () => {
      if (facilityStore.facilityCmdResult === "REQ") {
        ToastsStore.warning(`${facInfo?.facName} 의 이전 명령이 진행중입니다.`)
        clearInterval(timerOpen);
        setOffLoading(false)
      }
    }, 100);

    const timer = setInterval(async () => {
      console.log(`updateOnOff loop : ${count}`);
      if (count >= maxMinute) {
        setOffLoading(false);
        console.log("2분이 지났습니다.");
        ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
        clearInterval(timer);
      } else {
        try {
          //REQ면 종료
          if (facilityStore.facilityCmdResult === 'REQ') {
            clearInterval(timer);
          }

          if (facilityStore.facilityCmdResult !== "REQ") {
            setOffLoading(true);
            await facilityStore.getCmdRunState(facInfo.facId, '');
            console.log(`on ? ${facInfo.facId}`, JSON.stringify(facilityStore.facilityCmdState));

            if (facilityStore.facilityCmdState === "DONE" || facilityStore.facilityCmdState === "NO_COMMAND") {
              setOffLoading(false);
              ToastsStore.success(`${facInfo?.facName}의 OFF 명령이 전달되었습니다.`)
              clearInterval(timer);
            }
            else if (facilityStore.facilityCmdState === "Fail") {
              setOffLoading(false);
              ToastsStore.warning(`${facInfo?.facName} OFF 실패`)
              clearInterval(timer);
            }
          }
        } catch (error) {
          console.error("!!! 에러 !!!:", error);
          setOffLoading(false);
          clearInterval(timer);
        }
      }
      count++;
    }, 5000);
  };

  const handleEvent = async (event: string) => {
    if (event == 'ON') {
      facilityStore.cmdFacilityLampOn(facInfo.facId);
      updateOn();
    }
    else {
      facilityStore.cmdFacilityLampOff(facInfo.facId);
      updateOff();
    }
  };

  // ON/OFF 상태 비교 계산식
  // 한번 꺼졌다 켜지면 ON/OFF 타임이 다음날 기준으로 넘어가 버립니다. 참고해주세요.
  useEffect(() => {
    if ((facInfo.operationMode == 'M' || facInfo.operationMode == 'N') && facInfo.vout1 > 500) {
      setConnectONOFF("ON")
      setConnectOnColor("info");
      console.log(`${facInfo.facName} ${facInfo.vout1 / 10}v ==== 수동 켜진 상태(정상)`)
    }
    else if ((facInfo.operationMode == 'M' || facInfo.operationMode == 'N') && facInfo.vout1 < 500) {
      setConnectONOFF("OFF")
      setConnectOnColor("default");
      console.log(`${facInfo.facName} ${facInfo.vout1 / 10}v ==== 수동 꺼진 상태(정상)`)
    }
    // else if ((facInfo.operationMode == 'M' || facInfo.operationMode == 'N') && (((getDevNow > getOnMs) && (getDevNow > getOffMs)) && facInfo.vout1 < 500) || (((getDevNow < getOnMs) && (getDevNow < getOffMs)) && facInfo.vout1 > 500) ) {
    //   setConnectONOFF("OFF")
    //   setConnectOnColor("error");
    //   console.log(`${facInfo.facName} ${facInfo.vout1 / 10}v ==== 수동 켜진상태이지만 전압이 500 이하 or  한번 켜진 뒤 OFF 상태이지만 전압이 500 이상(오류)`)
    // }
    else if ((getDevNow > getOnMs) && (getDevNow > getOffMs) && facInfo.vout1 > 500) {
      setConnectONOFF("ON")
      setConnectOnColor("success");
      setState('정상')
      console.log(`${facInfo.facName} ${facInfo.vout1 / 10}v ==== 켜진 상태(정상)`)
    }
    else if ((getDevNow > getOnMs) && (getDevNow > getOffMs) && facInfo.vout1 < 500) {
      setConnectONOFF("ON")
      setConnectOnColor("default");
      setState('에러')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v ==== 켜진상태이지만 전압이 500 이하일 때(에러)`)
    }
    else if ((getDevNow < getOnMs) && (getDevNow < getOffMs) && facInfo.vout1 < 500) {
      setConnectONOFF("OFF")
      setConnectOnColor("default");
      setState('정상')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v ==== 한번 켜진 뒤 OFF 상태(정상)`)
    }
    else if ((getDevNow < getOnMs) && (getDevNow > getOffMs) && facInfo.vout1 > 500) {
      setConnectONOFF("ON");
      setConnectOnColor("default");
      setState('에러')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v ==== OFF 상태이지만 전압이 500 이상(에러)`)
    }
    else if ((getDevNow < getOnMs) && (getDevNow < getOffMs) && facInfo.vout1 > 500) {
      setConnectONOFF("ON");
      setConnectOnColor("default");
      setState('에러')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v ==== 한번 켜진 뒤 OFF 상태이지만 전압이 500 이상인 상태(에러)`)
    }
    else if ((getDevNow < getOnMs) && (getDevNow > getOffMs) && facInfo.vout1 < 500) {
      setConnectONOFF("OFF")
      setConnectOnColor("default");
      setState('정상')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v 켜지기 전 OFF 상태 `)
    }
    else if ((!getOnMs) && (!getOffMs) && facInfo.vout1 > 500) {
      setConnectONOFF("ON(M)")
      setConnectOnColor("default");
      setState('에러')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v 등록된 시간이 없어 상태를 확인할 수 없습니다.`)
    }
    else if ((!getOnMs) && (!getOffMs) && facInfo.vout1 < 500) {
      setConnectONOFF("OFF(M)")
      setConnectOnColor("default");
      setState('에러')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v 등록된 시간이 없어 상태를 확인할 수 없습니다.`)
    }
    else {
      setConnectONOFF("미이행")
      setConnectOnColor("warning");
      setState('에러')
      console.log(`${facInfo.facName}  ${facInfo.vout1 / 10}v 등록된 시간이 없어 상태를 확인할 수 없습니다.`)
    }
  }, [facInfo]);

  useEffect(() => {
    if (getNow - getDevNow < 1800000) { //시간이 30분보다 작으면 정상 (밀리초 기준 30분 = 1,800,000ms)
      setConnectStatus("연결")
      setConnectdevColor("success");
    }
    else { // 시간이 30분보다 크면 미연결
      setConnectStatus("미연결")
      setConnectdevColor("error");
    }
  }, [facInfo.devTimeUpdate]);

  useEffect(() => { //state
    if (facInfo.status == "정상") {
      setConnectlabel("정상")
      setConnectColor("info");
    }
    else {
      setConnectReason(facInfo.reason)
      setConnectlabel("불량")
      setConnectColor("default");
    }
  }, [facInfo.status]);

  // useEffect(() => { //state
  //   if (facInfo.status == "정상") {
  //     setConnectlabel("정상")
  //     setConnectColor("success");
  //   }
  //   else {
  //     setConnectReason(facInfo.reason)
  //     setConnectlabel("불량")
  //     setConnectColor("default");
  //   }
  // }, [facInfo.facilityDetailInfo.asInfo]);

  const handleDashFacilityClose = () => {
    setShowDashFacilityChart(false);
    onFacilityChartOpen(false);

  };

  const handleDetailDialogOpen = async (_facId: string) => {
    setFacId(_facId)
    setshowFacDetailInfoDialog(true);
  };

  const handleClose = async () => {
    setshowFacDetailInfoDialog(false);
    await lineStore.getLineList(userStore.siteId, null, userStore.getId(), "10", "1");
  };

  useEffect(() => {
    facilityStore.getFacilityDetailInfoList(userStore.siteId, lineInfo.groupId, lineInfo.lineId, null);
    const tmp = facilityStore.facilityDetailInfoList;
    setFacilityList(tmp);
  }, []);

  return (
    //화면 UI
    <>
      <Box
        sx={{
          justifyContent: 'space-around',
          alignItems: 'center',
          border: 1,
          borderRadius: 3,
          boxShadow: '#bdbdbd 5px 5px 5px',
          backgroundColor: '#FFFFFF',
          textAlign: 'center',
          p: 0.8,
          //margin: "2%",
          width: '95%',
          height: '200px',
          marginLeft: '-2.5%',
          marginRight: 'auto',
          mt: '-5px'
        }}
      >

        {/* 시설이름 */}
        <Typography
          color="textPrimary"
          variant="h5"
          sx={{
            textAlign: 'center',
            flex: '3',
            height: '25px',
            //fontSize:'15px'
          }}
        >
          {/* facInfo.maintenanceStateCode가 존재하고 11혹은 15가 아닐경우 ToolIcon을 띄움. */}
          {facInfo?.maintenanceStateCode && (facInfo?.maintenanceStateCode !== "11" || facInfo?.maintenanceStateCode !== "15") && (
            <>
              <ToolIcon style={{ width: '11px', height: '11px', color: 'red', position: 'relative' }} />
            </>
          )}
          {/* 시설 이름 누르면 시설 상세정보 팝업 띄움(버튼) */}
          <Button size="small" variant="text" sx={{ "&:hover": { backgroundColor: 'transparent' }, fontWeight: 500, position: 'relative', bottom: 2, height: '10px', fontSize: 15 }}
            onClick={() => {
              console.log(`${facInfo.facName}`)
              handleDetailDialogOpen(facInfo.facId);
            }}>
            {facInfo.facName.length > 12 ? `${facInfo.facName.substring(0, 12)}...` : facInfo.facName}
          </Button>
        </Typography>

        <Divider />

        {/* GPS 송신 오류 , 최초 등록 등... 통신 상태가 불량 할 시 이유를 보여줌. (시설 이름 아래 띄워짐) */}
        <Box sx={{ ml: 1 }}> </Box>
        {(facInfo.status == "정상" || connectReason == "연결불량") || //status가 정상이 아니면
          <Typography sx={{ color: '#222222', fontSize: 15, mb: 0.5 }}>
            <AlertTriangleIcon style={{ position: 'relative', display: 'inline', width: 18, height: 18, top: 3.5, right: 2, color: connectReason !== "최초등록" ? 'red' : 'blue' }} />
            {connectReason}
          </Typography>}

        {/* 통신상태가 정상이거나 연결불량 상태 일시 UI */}
        {connectlabel === '정상' || connectReason == "연결불량" ? (
          <><Box // 연결, 미연결
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '145%',
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'relative',
              top: '20px'
              //justifyContent: 'center'
            }}>

            <Box sx={{ ml: 0.5 }}> </Box>
            <Chip // ON , OFF
              label={connectONOFF}
              color={connectOnColor}
              size="small"
              sx={{
                width: '30%',
                ml: '1%',
                mb: '3%',
                border: state === '에러' ? '1px solid #F0474C' : 'none',
              }}
            ></Chip>
            <Box sx={{ ml: 0.5 }}> </Box>

            {/* 통신상태가 불량일 경우 UI */}
            {connectlabel === '불량' ? (
              <Chip
                label={connectlabel}
                color={connectColor}
                //onClick={getFacilityAlive}
                size="small"
                sx={{
                  width: '30%',
                  ml: '1%',
                  mb: '3%',
                  border: connectReason === '연결불량' ? '1px solid #F0474C' : 'none', //연결불량이면 border 빨간색
                }}
                icon={<WifiOffIcon style={{ width: '17px', height: '17px' }} />} // 통신불량 아이콘
              ></Chip>
            ) : (
              <Chip
                label={connectlabel}
                color={connectColor}
                //onClick={getFacilityAlive}
                size="small"
                sx={{
                  width: '30%',
                  ml: '1%',
                  mb: '3%',
                }}
                icon={<WifiIcon style={{ width: '17px', height: '17px' }} />} // 정상 아이콘
              ></Chip>
            )}
            {/* </Tooltip> */}
          </Box>
            <Box sx={{ ml: 0.5 }}> </Box>

            {/* 전압 보여주고 전압 누르면 전압차트 띄움 */}
            <Box sx={{ position: 'relative', display: 'flex', textAlign: 'center', height: 50, top: '25px' }}>
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
                sx={{
                  flex: '1',
                  textAlign: 'right',
                  position: 'relative',
                  top: 3
                }}
              >
                <Button
                  size="medium"
                  variant="text"
                  sx={{
                    "&:hover": { backgroundColor: 'transparent' },
                    color: '#6b778c',
                    fontWeight: 400,
                    position: 'relative',
                    bottom: 8,
                  }}
                  //disabled={onTime == "" ? true : false} //onTime 없으면 disabled
                  onClick={() => {
                    setShowDashFacilityChart(true);
                    onFacilityChartOpen(true);
                    setTestShowDashFacilityChart(true);
                  }}
                >
                  {facInfo.vout1 / 10} V
                </Button>

                {/* showDashFacilityChart가 true일 경우 전압차트 열림 */}
                {showDashFacilityChart &&
                  <DashFacilityChart
                    open={showDashFacilityChart}
                    onClose={() => handleDashFacilityClose()}
                    facName={facInfo.facName}
                    facId={facInfo.facId}></DashFacilityChart>}
              </Typography>

              {/* 전류 super계정 에서만 보임 */}
              {userStore.isAdmin() &&
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                  sx={{
                    flex: '1',
                    position: 'relative',
                    top: 3.3
                  }}
                >
                  {facInfo.cur1 ? (facInfo.cur1 !== 0 ? parseFloat(facInfo.cur1).toFixed(2) : '0') : '-'} A
                </Typography>
              }
              <Box sx={{ ml: 0.5 }}> </Box>
            </Box></>
        ) :
          (
            <><Box // 연결, 미연결
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '145%',
                marginLeft: 'auto',
                marginRight: 'auto',
                //justifyContent: 'center'
              }}>

              <Box sx={{ ml: 0.5 }}> </Box>
              <Chip // ON , OFF
                label={connectONOFF}
                color={connectOnColor}
                size="small"
                sx={{
                  width: '30%',
                  ml: '1%',
                  mb: '3%',
                  border: state === '에러' ? '1px solid #F0474C' : 'none', // 조건에 따라 border 속성을 설정
                }}
              ></Chip>
              <Box sx={{ ml: 0.5 }}> </Box>

              {/* 불량/정상 Chip  */}
              {connectlabel === '불량' ? (
                <Chip
                  label={connectlabel}
                  color={connectColor}
                  //onClick={getFacilityAlive}
                  size="small"
                  sx={{
                    width: '30%',
                    ml: '1%',
                    mb: '3%'
                  }}
                  icon={<WifiOffIcon style={{ width: '17px', height: '17px' }} />} // 통신불량 아이콘
                ></Chip>
              ) : 

              // 통신상태가 불량이거나 연결불량이 아닐때
              (
                <Chip
                  label={connectlabel}
                  color={connectColor}
                  //onClick={getFacilityAlive}
                  size="small"
                  sx={{
                    width: '30%',
                    ml: '1%',
                    mb: '3%',
                  }}
                  icon={<WifiIcon style={{ width: '17px', height: '17px' }} />} // 정상 아이콘
                ></Chip>
              )}
              {/* </Tooltip> */}
            </Box>
              <Box sx={{ ml: 0.5 }}> </Box><Box sx={{ position: 'relative', display: 'flex', textAlign: 'center', height: 24 }}>
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                  sx={{
                    flex: '1',
                    textAlign: 'right',
                    position: 'relative',
                    top: 3.3
                  }}
                >
                  <Button
                    size="medium"
                    variant="text"
                    sx={{
                      "&:hover": { backgroundColor: 'transparent' },
                      color: '#6b778c',
                      fontWeight: 400,
                      position: 'relative',
                      bottom: 8,
                    }}
                    //disabled={onTime == "" ? true : false} //onTime 없으면 disabled
                    onClick={() => {
                      console.log("gggg4444", facInfo.facName)
                      setShowDashFacilityChart(true);
                      onFacilityChartOpen(true);
                    }}
                  >
                    {facInfo.vout1 / 10} V
                  </Button>

                  {showDashFacilityChart &&
                    <DashFacilityChart
                      open={showDashFacilityChart}
                      onClose={() => handleDashFacilityClose()}
                      facName={facInfo.facName}
                      facId={facInfo.facId}></DashFacilityChart>}
                </Typography>

                {userStore.isAdmin() &&
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                    sx={{
                      flex: '1',
                      position: 'relative',
                      top: 3.3
                    }}
                  >
                    {facInfo.cur1 ? (facInfo.cur1 !== 0 ? parseFloat(facInfo.cur1).toFixed(2) : '0') : '-'} A
                  </Typography>
                }
                <Box sx={{ ml: 0.5 }}> </Box>
              </Box></>
          )}
          <Box sx={{ mt: 1, ml: 1, textAlign: 'left' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: 'black', display: 'flex' }}>
                  <Box component="span" sx={{ width: '35px' }}>ON :</Box>
                  <Box component="span">{lastOnTime}</Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: 'black', display: 'flex' }}>
                  <Box component="span" sx={{ width: '35px' }}>OFF :</Box>
                  <Box component="span">{lastOffTime}</Box>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <ManualOnOff
            name="점소등"
            disabled={facInfo.devId === '-1'}
            space={'0rem'}
            onDisabled={offLoading}
            offDisabled={onLoading}
            onLoading={onLoading}
            offLoading={offLoading}
            nameWidth={'3rem'}
            valueWidth={'5rem'}
            onEvent={handleEvent}
          />
        </Box>

        <FacDetailInfoDialog
          facId={facId}
          onClose={handleClose}
          show={showFacDetailInfoDialog}
          onShow={() => {
            setShowFacInfo(false)
          }}></FacDetailInfoDialog>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
      </Box>
    </>
  );
});

export default DashFacility
