// 시설운영 - 중계기 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import ManageGatewayToolbar from 'components/facility/ManageGatewayToolbar';
import ManageGatewayListResults from 'components/facility/ManageGatewayListResults';
import { observer } from 'mobx-react';
import { useStores } from 'state';
import { useEffect, useState } from 'react';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import { FacType } from 'model/facility';

const GatewayList = observer(({}) => {
  const { gatewayStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

  // 중계기 정보 받아옴.
  useEffect(() => {
    facilityStore.getFacilityGatewayInfoList(userStore.siteId, FacType.Gateway);
  },[]);

  const onCommand = (command: string) => {
    console.log(`onCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }

  return (
    <>
      <Helmet>
        <title>GatewayList</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ManageGatewayToolbar onCommand={onCommand}/>
          <Box sx={{ mt: 1 }}></Box>
          <Box sx={{ minWidth: 1500 }}>
            <ManageGatewayListResults gatewayCount={facilityStore.facilityGatewayList?.length.toString()} facs={facilityStore.facilityGatewayList} doit={doit}/>
          </Box>
        </Container>
      </Box>
    </>
  );
});

export default GatewayList;
