import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { useEffect } from 'react';
import { getLocation } from 'utils/getLocation';

const MobileRegistration = () => {
  console.log('Mregistration')
  useEffect(() => {
    getLocation();

  }, []);

  return(
  <>
    <Helmet>
      <title>Mobileregistration</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
      </Container>
    </Box>
  </>
);
    };

export default MobileRegistration;
