//관리자 - 사용자 관리 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useStores } from '../../../state';
import { observer } from 'mobx-react';
import AdminUserToolbar from '../../../components/admin/system/AdminUserToolbar';
import AdminUserListResults from '../../../components/admin/system/AdminUserListResults';
import siteStore from 'state/site';

const AdminMemberList = observer(({ }) => {
  const { userStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    userStore.getUserListBySiteId("");
  }, []);

  useEffect(() => {
    console.log(`AdminMemberList userList = ${JSON.stringify(userStore.userList)}`)
  }, [userStore.userList]);

  const onCommand = (command: string) => {
    console.log(`onCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    setDoit(command + '-' + count);
    setCount(count + 1);
  }

  return (
    <>
      <Helmet>
        <title>AdminMemberList</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AdminUserToolbar userCount={userStore.userList.length.toString()} onCommand={onCommand} />
          <Box sx={{ mt: 1 }}></Box>
          <Box >
            <AdminUserListResults users={userStore.userList} doit={doit} />
          </Box>
        </Container>
      </Box>
    </>
  );
});

export default AdminMemberList;
