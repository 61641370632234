import {
	Avatar,
	Box,
	Button,
	Popover,
	Tooltip,
	Typography,
} from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { useState } from 'react';

interface IErrorMarker {
	id:string;
	type:string;
	lat:number;
	lng:number;
	text?:string;
	status?:string;
	tooltip:any;
	vout:string;
	hover?:any;
	onClick:(id:string) => void;
};

const ErrorMarker = ({id, type, text, status, tooltip, lat, lng, vout, onClick}:IErrorMarker) => {
	// if (lat != null) {
	// console.log(`ErrorMarker ${id}:${type}:${text}:${status}:${lat}:${lng}`)
	// }
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const popoverId = open ? 'simple-popover' : undefined;
	const handleClick = (event: any) => {
		console.log(`You clicked on ${tooltip}`);
		// setAnchorEl(event.currentTarget);
		onClick(id);
	};

	let image;
	if (type == '501' || type == '503') {
		if (status == '정상') {
			image = "/static/images/lamp_ok.png";
		}
		else if (status == '미연동') {
			image = "/static/images/lamp_linkerror.png";
		}
		else if (status == '통신단절') {
			image = "/static/images/lamp_signalerror.png";
		}
		else {
			image = "/static/images/lamp_error.png";
		}

		console.log(`ErrorMarker vout ${vout}`);
		if (Number(vout) >= 200  ) {
			image = "/static/images/lamp_on.png";
		}
		else if (Number(vout) < 200  ) {
			image = "/static/images/lamp_off.png";
		}

	}
	else if (type == '502' /* 분전반 */) {
		if (status == '정상') {
			image = "/static/images/lamp_ok.png";
		}
		else if (status == '미연동') {
			image = "/static/images/lamp_linkerror.png";
		}
		else if (status == '통신단절') {
			image = "/static/images/lamp_signalerror.png";
		}
		else {
			image = "/static/images/lamp_error.png";
		}
	}
	else {
		if (status == '정상') {
			image = "/static/images/gateway_ok.png";
		}
		else if (status == '미연동') {
			image = "/static/images/gateway_linkerror.png";
		}
		else if (status == '통신단절') {
			image = "/static/images/gateway_signalerror.png";
		}
		else {
			image = "/static/images/gateway_error.png";
		}
	}
		// console.log(`ErrorMarker ${type}, ${status}, ${image}` );


	const handleClose = () => {
		console.log(`You clicked on close`);
		// setAnchorEl(null);
	};

	return (
		<div >
			<Tooltip title={tooltip}>
				<Box>
					<Box
					  sx={{width:25, height:30, cursor:'pointer'}}
						component="img"
						src={image}
						alt={text}
						onClick={handleClick}
					>
					</Box>
				</Box>
			</Tooltip>
			<Popover
				id={popoverId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
			</Popover>
		</div>
	);
};

export default ErrorMarker;