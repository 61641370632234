//시설운영 - 시설지도 컨테이너 레이아웃
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
} from '@mui/material';
import GoogleMap from '../../../components/map/GoogleMap';
import { useStores } from '../../../state';
import { IAlarm } from '../../../model/alarm';
import FacInfoAll from '../../../components/map/FacInfoAll';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import { useInterval } from 'utils/useInterval';

const Map2 = observer(({ }) => {
  const { dashboardStore } = useStores();
  const [facId, setFacId] = useState('');
  const [showFacInfo, setShowFacInfo] = useState(false);
  const [showSR, setShowSR] = useState(false);
  const [groupIds, setGroupIds] = useState('');

  //시설 정보 받아옴.
  useEffect(() => {
    facilityStore.getFacilityDetailInfoList(userStore.siteId, null, null, null);
  }, [])

  // 20초마다 정보 갱신
  useInterval(() => {
    console.log('ExitTrue',facilityStore.getExit())
    if(!groupIds){
    facilityStore.getFacilityDetailInfoList(userStore.siteId, null, null, null);
    }
    else {
      facilityStore.getFacilityDetailInfoList(userStore.siteId, groupIds, null, null);
    }
  }, 20000);

 // 상태가 exit 일시 groupids를 초기화
	useEffect(() => {
		if (facilityStore.getExit() === 'true') {
			setGroupIds(null);
      console.log('Exit',groupIds)
		}
	}, [facilityStore.getExit()]);


  const handleSearch = async (groupId: string) => {
    setShowSR(false);
    setGroupIds(groupId)
    await facilityStore.getFacilityDetailInfoList(userStore.siteId, groupId, null, null);
    setShowSR(true);
  }

  const handleShowFacInfo = (id: string) => {
    setShowFacInfo(false);
    setFacId(id)
    setShowFacInfo(true);
  }

  return (
    <>
      <Helmet>
        <title>Map2</title>
      </Helmet>
      <Box
        sx={{
          height: '95.1vh',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <GoogleMap _showSR = {showSR} type="info" facs={facilityStore.facilityDetailInfoList} onClick={handleShowFacInfo} onSearch={handleSearch}></GoogleMap>
        {showFacInfo &&
          <Box
            sx={{
              backgroundColor: 'background.default',
              zIndex: 2,
              
            }}
          >
            <PerfectScrollbar>
              <FacInfoAll showError={false} facId={facId} facs={facilityStore.facilityDetailInfoList} onClick={() => setShowFacInfo(false)}></FacInfoAll>
            </PerfectScrollbar>
          </Box>
        }
      </Box>
    </>
  );
});

export default Map2;
