import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Toolbar,
  Typography
} from '@mui/material';
import { IFacInfo } from '../../model';
import { useStores } from '../../state';
import { observer } from 'mobx-react';
import { alpha } from '@mui/material/styles';
import { TitleinTab } from '../atom/DefineStyled';
import { useTranslation } from 'react-i18next';
import NameValueRem from 'components/atom/NameValueRem';
import { IFacilityDetailInfo } from 'model/facility';

type FacStatusProps = {
  facId : string;
  facInfo: IFacilityDetailInfo;
  facs ?: IFacInfo[];
};
const FacStatus = observer(({ facId, facInfo, facs }:FacStatusProps) => {
  const { clientStore } = useStores();
	const { t } = useTranslation()

	console.log(`FacStatus :facId : ${facId}`)
	console.log(`FacStatus :facInfo : ${JSON.stringify(facInfo)}`)

  useEffect(() => {
    console.log(`FacStatus ${JSON.stringify(clientStore.facInfo)}`);
  },[clientStore.facInfo]);
  useEffect(() => {
    console.log(`FacStatus ${JSON.stringify(clientStore.status)}`);
  },[clientStore.status]);

  return (
    <>
      <Box 
				sx={{
          mb:3,
					borderBottom: 1,
					borderColor: 'divider',
				}}
      >
        <TitleinTab
          color="textPrimary"
          variant="h4"
        >
          {t('global.curState')}
        </TitleinTab>
      </Box>
        <NameValueRem
        name = {t('global.curState')}
        value = {clientStore.facInfo?.alarmStatus}
        />
        <Box sx={{ mb: 1 }}/>
        <NameValueRem
        name = {t('global.errorNumber')}
        value = ""
        />
        <Box sx={{ mb: 1 }}/>
        <NameValueRem
        name = {t('global.errorType')}
        value = ""
        />
        <Box sx={{ mb: 1 }}/>
        <NameValueRem
        name = {t('global.errorDetail')}
        value = ""
        />
        <Box sx={{ mb: 3 }}/>
      <Box 
				sx={{
          mb:3,
					borderBottom: 1,
					borderColor: 'divider',
				}}
      >
        <TitleinTab
          color="textPrimary"
          variant="h4"
        >
          {t('global.errorHistory')}
        </TitleinTab>
      </Box>
    </>
  );
});

export default FacStatus;
