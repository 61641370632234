//map 시설 상세 
import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	Box,
	ButtonGroup,
	Card,
	FormControlLabel,
	IconButton,
	Switch,
	Tab,
	Tabs,
	Typography,
	useTheme
} from '@mui/material';
import ExitIcon from '@mui/icons-material/Close';
import { IFacInfo } from '../../model';
import FacStatus from './FacStatus';
import { useStores } from '../../state';
import FacInfo from './FacInfo';
import FacUpperInfo from './FacUpperInfo';
import FacGroup from './FacGroup';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import Button from 'components/common/Button';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { IFacilityDetailInfo } from 'model/facility';
import facilityStore from 'state/facility';
import ManualOnOff from 'components/atom/ManualOnOff';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";

const StyledTab = styled(Tab)(
	({ theme }) => ({
		padding: "0px",
		minWidth: 80,
		width: 80,
	}));

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}
type FacInfoAllProps = {
	showError: boolean;
	facId: string;
	facs: IFacilityDetailInfo[];
	onClick: () => void;
};

const FacInfoAll = observer(({
	showError,
	facId,
	facs,
	onClick
}: FacInfoAllProps) => {
	const { clientStore } = useStores();
	const { dashboardStore } = useStores();
	const [tabvisible, setTabVisible] = useState(true);
	const [tabindex, setTabIndex] = useState(0);
	const [value, setValue] = useState(0);
	const [labelNo, setLabelNo] = useState('');
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [onLoading, setOnLoading] = useState(false);
	const [offLoading, setOffLoading] = useState(false);
	const { t } = useTranslation()

	const theme = useTheme();

	console.log(`FacInfoAll :facId : ${facId}`)

	useEffect(() => {
		setChecked(clientStore.facInfo?.devStatusValue == 'ON' ? true : false)
	}, [clientStore.facInfo]);

	useEffect(() => {
		if (showError) {
			setTabVisible(true);
			setTabIndex(4)
		}
		else {
			setTabVisible(false);
			setTabIndex(3)
		}
	}, [showError]);

	useEffect(() => {
		const facility = facs.find(fac => fac.facId.toString() == facId);
		setLabelNo(facility.facName);

		// alarm : fac, mt
		// client : fac, status
		// dashboard : fac, dev, uplink
		facilityStore.getFacilityDetailInfo(facId)
	}, [facId]);

	useEffect(() => {
		console.log(`cyi facilityStore.facilityDetailInfo = ${JSON.stringify(facilityStore.facilityDetailInfo)}`)
	}, [facilityStore.facilityDetailInfo]);

	const updateOnOff = (onOff: string) => {
		console.log(`updateOnOff ${facId}, ${onOff}`)
		setLoading(true);
		let count = 0;
		const timer = setInterval(() => {
			console.log(`updateOnOff loop : ${count}`)
			if (count >= 3) {
				setLoading(false);
				clearInterval(timer);
			}
			else {
				// console.log(`updateOnOff loop : ${count} : ${facilityStore.facilityDetailInfo.devCtrlKey} ${facilityStore.facilityDetailInfo.devCtrlStatus}`)
				// if (facilityStore.facilityDetailInfo.devCtrlStatus == "DONE") {
				// 	setLoading(false);
				// 	clearInterval(timer);
				// }
			}
			count++;
			// dashboardStore.getDetail(facId);

		}, 10000)
		if (onOff == 'ON')
			facilityStore.cmdFacilityLampOn(facId);
		else {
			facilityStore.cmdFacilityLampOff(facId);
		}
	}

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		console.log(`cyi handleTabChange ${newValue}`)
		setValue(newValue);
	};

	//on기능
	const updateOn = async () => {
		console.log(`updateOn ${facId}`);
		let count = 1;
		const maxMinute = 24; // 5초 * 24 = 120초 = 2분
		setOnLoading(true)

		const timerOpen = setInterval(async () => {
			if (facilityStore.facilityCmdResult === "REQ") {
				ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} 의 이전 명령이 진행중입니다.`)
				clearInterval(timerOpen);
				setOnLoading(false)
			}
		}, 100);

		const timer = setInterval(async () => {
			console.log(`updateOnOff loop : ${count}`);
			if (count >= maxMinute) {
				setOnLoading(false);
				console.log("2분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
				clearInterval(timer);
			} else {
				try {
					if (facilityStore.facilityCmdResult === 'REQ') {
						clearInterval(timer);
					}
					if (facilityStore.facilityCmdResult !== "REQ") {
						setOnLoading(true);
						await facilityStore.getCmdRunState(facId, '');
						console.log(`on ? ${facId}`, JSON.stringify(facilityStore.facilityCmdState));

						if (facilityStore.facilityCmdState === "DONE" || facilityStore.facilityCmdState === "NO_COMMAND") {
							setOnLoading(false);
							ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}의 ON 명령이 전달되었습니다.`)
							clearInterval(timer);
						}
						else if (facilityStore.facilityCmdState === "DONE") {
							setOnLoading(false);
							ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}이 ON 되었습니다.`)
							clearInterval(timer);
						}
						else if (facilityStore.facilityCmdState === "Fail") {
							setOnLoading(false);
							ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} ON 실패.`)
							clearInterval(timer);
						}
					}
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setOnLoading(false);
					clearInterval(timer);
				}
			}
			count++;
		}, 5000);
	};

	//off 기능
	const updateOff = async () => {
		console.log(`updateOn ${facId}`);
		let count = 1;
		const maxMinute = 24; // 5초 * 24 = 120초 = 2분
		setOffLoading(true)

		const timerOpen = setInterval(async () => {
			if (facilityStore.facilityCmdResult === "REQ") {
				ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} 의 이전 명령이 진행중입니다.`)
				clearInterval(timerOpen);
				setOffLoading(false)
			}
		}, 100);

		const timer = setInterval(async () => {
			console.log(`updateOnOff loop : ${count}`);
			if (count >= maxMinute) {
				setOffLoading(false);
				console.log("2분이 지났습니다.");
				ToastsStore.warning("시간이 초과되었습니다.\n 다시 시도해주세요.")
				clearInterval(timer);
			} else {
				try {
					//REQ면 종료
					if (facilityStore.facilityCmdResult === 'REQ') {
						clearInterval(timer);
					}

					if (facilityStore.facilityCmdResult !== "REQ") {
						setOffLoading(true);
						await facilityStore.getCmdRunState(facId, '');
						console.log(`on ? ${facId}`, JSON.stringify(facilityStore.facilityCmdState));

						if (facilityStore.facilityCmdState === "DONE" || facilityStore.facilityCmdState === "NO_COMMAND") {
							setOffLoading(false);
							ToastsStore.success(`${facilityStore.facilityDetailInfo?.facName}의 OFF 명령이 전달되었습니다.`)
							clearInterval(timer);
						}
						else if (facilityStore.facilityCmdState === "Fail") {
							setOffLoading(false);
							ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} OFF 실패`)
							clearInterval(timer);
						}
					}
					// else if (facilityStore.facilityCmdResult === "REQ") {
					//     setOffLoading(false);
					//     ToastsStore.warning(`${facilityStore.facilityDetailInfo?.facName} 의 이전 명령이 진행중입니다.`)
					//     clearInterval(timer);
					// }
				} catch (error) {
					console.error("!!! 에러 !!!:", error);
					setOffLoading(false);
					clearInterval(timer);
				}
			}
			count++;
		}, 5000);
	};

	// on/off버튼 실행
	const handleEvent = async (event: string) => {
        console.log(`handleEvent ${event}`);
        if (event == 'ON') {
            facilityStore.cmdFacilityLampOn(facId);
            console.log('ffa', JSON.stringify(facilityStore.facilityCmdResult))
            updateOn();
        }
        else {
            facilityStore.cmdFacilityLampOff(facId);
            console.log('ffa', JSON.stringify(facilityStore.facilityCmdResult))
            updateOff();
        }
    }
	
	return (
		<Card sx={{ height: '100%', width: '100%' }}>
			<PerfectScrollbar>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						borderBottom: 1,
						borderColor: 'divider',
					}}>
					<Box
						sx={{
							flex: 1,
						}}>
					</Box>
					<Box
						sx={{
							flex: 5,
							display: 'flex',
							justifyContent: "center"
						}}
					>
						<Typography
							color={theme.palette.text.primary}
							variant="h3"
						>
							{labelNo}
						</Typography>
					</Box>
					<Box
						sx={{
							flex: 1,
							display: 'flex',
							justifyContent: "flex-end"
						}}
					>
						<IconButton
							color="inherit" size="small">
							<ExitIcon
								onClick={onClick}
							/>
						</IconButton>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: "flex-end",
						alignItems: 'center',
						borderBottom: 1,
						borderColor: 'divider',
						p: "2px",
					}}>
					{
						(facilityStore.facilityDetailInfo?.facType == '501' ||
							facilityStore.facilityDetailInfo?.facType == '503') &&
						<Box
							sx={{
								display: 'flex',
								direction: 'column',
								justifyContent: "center",
								height: 25,
							}}
						>
							<ManualOnOff
								name="점소등"
								disabled={facilityStore.facilityDetailInfo?.devId == '-1'}
								space={'0rem'}
								onDisabled={offLoading == true}
								offDisabled={onLoading == true}
								onLoading={onLoading}
								offLoading={offLoading}
								nameWidth={'3rem'}
								valueWidth={'5rem'}
								onEvent={handleEvent}
							/>
						</Box>
					}
				</Box>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs variant="fullWidth" value={value} onChange={handleTabChange} aria-label="basic tabs example">
						{tabvisible == false ? (
							""
						) : (
							<StyledTab label={t('global.facState')} {...a11yProps(tabindex - 4)} style={{ paddingLeft: 0, paddingRight: 0 }} />
						)}
						<StyledTab label={t('global.facInfo')} {...a11yProps(tabindex - 3)} />
					</Tabs>
				</Box>
				{tabvisible == false ? (
					""
				) : (
					<TabPanel value={value} index={tabindex - 4}>
						<FacStatus facId={facId}
							facInfo={facilityStore.facilityDetailInfo}
						></FacStatus>
					</TabPanel>
				)}
				<TabPanel value={value} index={tabindex - 3}>
					<FacInfo
						facId={facId}
						facInfo={facilityStore.facilityDetailInfo}
					></FacInfo>
				</TabPanel>
				<TabPanel value={value} index={tabindex - 1}>
					<FacGroup facId={facId}></FacGroup>
				</TabPanel>
			</PerfectScrollbar>
			<ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
		</Card>
	);
});

export default FacInfoAll;