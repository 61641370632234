import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import kr from './locales/kr';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
      resources: {
          en: en,
          kr: kr,
      },
      lng: localStorage.getItem('language') || "kr", // if you're using a language detector, do not define the lng option
      fallbackLng: "en",

      interpolation: {
          escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
  });

export default i18n;