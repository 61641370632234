// 시설운영 - 시설 - 수정 - 기기해제
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  MenuItem,
  SelectChangeEvent,
  Input,
  CardContent,
  Divider,
  CardHeader,
  Tab
} from '@mui/material';
import theme from 'theme';
import { useLocation, useNavigate } from 'react-router';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import { alpha } from '@mui/material/styles';
import userStore from 'state/user';
import deviceStore from 'state/device';
import facilityStore from 'state/facility';
import NameValueRem from 'components/atom/NameValueRem';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { isMobile } from 'react-device-detect';
import MobileNameValueRem from 'components/atom/MobileNameValueRem';

interface StyledNameValueProps {
  name: string;
  value: string;
}
//nameValue 재정의
function StyledNameValue({ name, value }: StyledNameValueProps) {
  return (
    <>
      {!isMobile &&
        <NameValueRem
          name={name}
          value={value}
          space={'3rem'}
          nameWidth={'5rem'}
          valueWidth={'20rem'}
        />
      }
      {isMobile &&
        <MobileNameValueRem
          name={name}
          value={value}
          space={'3rem'}
          nameWidth={'5rem'}
          valueWidth={'20rem'}
        />
      }
    </>
  );
}


interface ILocation {
  siteId: string,
  siteName: string,
  facId: string,
  facName: string,
  facType: string,
  facDesc: string,
  devId: string,
  serialNo: string,
}
const DisConnectDeviceImpl = observer(() => {
  const { clientStore, iotStore } = useStores();
  const [serialValue, setSerialValue] = useState(null);
  const [serial, setSerial] = useState('');
  const location = useLocation();
  const state = location.state as ILocation;
  const navigate = useNavigate();

  const [values, setValues] = useState(
    {
      facId: '',
      facName: '',
      facType: '',
      facDesc: '',
      devId: '',
      serialNo: '',
    });

  //device관련 정보 받아오는 것들
  useEffect(() => {
    setSerialValue(deviceStore.deviceList);
  }, [deviceStore.deviceList]);

  useEffect(() => {
    deviceStore.getDeviceBySiteId(userStore.siteId);
  }, []);

  useEffect(() => {
    const device = serialValue?.find((item: any) => {
      console.log(`DisConnectDeviceImp ${item.devId}:${state.devId}`)
      if (item.devId == state.devId)
        return true;
    })
    if (device) {
      console.log(`DisConnectDeviceImp 222 ${device.serialNo}`)
      setSerial(device.serialNo);
    }
  }, [serialValue]);

//해제하기 함수
  const handleDisconnect = async () => {
    const ret = await facilityStore.setDevIdForFacility(state.facId, state.devId, 'unreg');
    if (ret) {
      ToastsStore.success(`연결이 해지되었습니다.`)
      const timer = setInterval(() => {
        clearInterval(timer);
        navigate(-1);
      }, 1000)
    }
  }
  return (
    <Box>
      <Typography //타이틀 글자 조정
        sx={{
          fontSize: 25,
          fontWeight: 600,
          color: '#0C3149',
          display: 'flex',
          position: 'relative',
          left: '1.5rem',

        }}
        gutterBottom >
        기기 해제
      </Typography>

      <Table>
        <TableRow>
          <TableCell sx={{ padding: 1, borderTop: 2, borderTopColor: '#BFBFBF', borderBottom: 1, borderBottomColor: '#BFBFBF' }}>
            <StyledNameValue
              name="Serial"
              value={state.serialNo}
            />
          </TableCell>
        </TableRow>
      </Table>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2
        }}
      >
        <Button
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => handleDisconnect()}
        >
          해제하기
        </Button>
      </Box>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Box>
  );
});

export default DisConnectDeviceImpl;
