
import { observable, computed } from 'mobx';
import { api, q } from '../api';
import { IUserDetail } from '../model/user';
import { IFacInfo } from '../model';

export interface IAlarmStore {
	test: string;
	alarmList:any[];
	usedList:any[];
	unUsedList:any[];
	facInfo : IFacInfo;

	getAlarmList:(perPage:string, page:string) => any;
	getUsedAlarm:(used: boolean) => any;
	update:(facId:string, devId:string, mtCompanyId:string, labelNo:string) => void;
	getDetail:(facId : string) => any;
	turnOnOffAlarm:(facId:string, onOff:string) => void;
	testFunc:() => void;
	testFunc1:(user : IUserDetail) => void;

}
export const alarmStore = observable<IAlarmStore>({
	test: '',
	alarmList:[],
	usedList:[],
	unUsedList:[],
	facInfo: null,

	async getAlarmList(perPage: string, page: string) {
		 const ret = await api.get(`/api/alarm/tblList${q({
			 perPage,
			 page,
		 })}`);
		console.log(`getAlarmList ${JSON.stringify(ret)}`)
		this.alarmList = ret.data.contents;
	},
	async getUsedAlarm(used: boolean) {
		let cat = 0;
		if (used) {
			cat = 1;
		}
		const ret = await api.get('/api/alarm/usedalarm?searchCategory=' + cat);
		console.log(`getUsedAlarm ${JSON.stringify(ret)}`)
		if (used) {
			this.usedList = ret.data.contents;
		}
		else {
			this.unUsedList = ret.data.contents;
		}
	},
	async getDetail(facId: string) {
		const ret = await api.get('/api/alarm/listDetail?facId=' + facId);
		console.log(`getDetail ${JSON.stringify(ret)}`)
		this.facInfo = ret.fac;
	},
	async update(facId:string, devId:string, mtCompanyId:string, labelNo:string) {
		console.log(`update ${(facId)}`)
		try {
			await api.post(`/api/alarm/update`,
				{
					facId: facId,
					devId: devId,
					mtCompanyId: mtCompanyId,
					labelNo: labelNo,
				});
		} catch (error) {
			console.log(`update error : ${JSON.stringify(error)}`)
		}
	},
	async turnOnOffAlarm(facId:string, onOff:string) {
		console.log(`turnOnOffAlarm ${(facId)}`)
	},


	async testFunc() {
		// await api.post(`/auth/api/v1/signIn${q({
		// 	id,
		// 	password,
		// })}`, {});
	},
	async testFunc1(user : IUserDetail) {
		console.log(`test 1 ${JSON.stringify(user)}`)
	},
})

export default alarmStore;
