//라인 관련 api

import { observable } from "mobx";
import { ILineInfo, ILineSet } from "model";
import lines from "__mocks__/lines";
import { api, q } from '../api';

export interface ILineStore {
	lineList:any[];
	lineInfo:ILineSet;
	aaa:ILineInfo;
	lineOnOffCtrl:any;
	lineRunState:any;

	addLine:(line:ILineSet) => void;
	getLineCount:() => void;
	getLine:(lineId:string) => void;
	getLineList:(siteId:string, groupId:string, userId:string, perPage:string, page:string) => any;
	delLine:(siteId:string, groupId:string, lineId:string) => void;
	cmdLineLampOn:(siteId:string, groudId:string, lineId:string) => void;
	cmdLineLampOff:(siteId:string, groudId:string, lineId:string) => void;
	getLineOnCmdRunState:(lineId:string,subId:string) => void;
	getLineOffCmdRunState:(lineId:string,subId:string) => void;
}

export const lineStore = observable<ILineStore>({
	lineList:[],
	lineInfo:null,
	aaa:null,
	lineOnOffCtrl:null,
	lineRunState:null,

	async addLine(line:ILineSet) {
		console.log(`addLine ::: ${JSON.stringify(this.lineInfo)}`)
		try {
			const ret = await api.post(`/api2/line/setLineInfo`, line);
			console.log(`addLine ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`addLine error`)
			return {status:error.status};
		}
	},
	async getLineCount() {
		console.log(`getLineCount`);
	},
	//라인 정보 가져오기(한개)
	async getLine(lineId:string) {
		// api
		console.log(`getLine`);
		const ret = await api.post('/api2/line/getLineInfo',{
				lineId,
		});
		console.log(`getLine ${JSON.stringify(ret)}`)
		this.lineInfo = ret.data;
	},
	//라인정보 전부 가져오기
	async getLineList(siteId:string, groupId:string, userId:string, perPage:string, page:string) {
		try {
			const ret = await api.post(`/api2/line/getUserLineList`,{
				siteId,
				groupId,
				userId,
				perPage,
				page,
			});
			console.log(`aaaaa getLineist ret = ${JSON.stringify(ret)}`)
			this.lineList = ret.data;
			return {status:200};
		} catch (error) {
			console.log(`getLineList error`)
			return {status:error.status};

		}
	},
	//라인 삭제
	async delLine(siteId:string, groupId:string, lineId:string) {
		console.log(`delLine`);
		try {
			const ret = await api.post(`/api2/line/removeLineInfo`, {
				siteId,
				groupId,
				lineId,
			});
			console.log(`delLine ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`delLine error`)
			return {status:error.status};
		}
	},
	//점소등 on
	async cmdLineLampOn(siteId:string, groupId:string, lineId:string) {
		// api
		console.log(`cmdLineLampOn`);
		try {
			const subId = Date.now()
			const ret = await api.post(`/api2/facility/cmdLineCtrl`, {
				siteId,
				groupId,
				lineId,
				cmdType:"LAMP_CTRL_ON",
				subId: subId,
				value1:"",
				value2:"",
				enable:""
			});
			this.lineOnOffCtrl = ret
		} catch (error) {
			console.log(`cmdLineLampOn error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//점소등 off
	async cmdLineLampOff(siteId:string, groupId:string, lineId:string) {
		// api
		console.log(`cmdLineLampOff`);
		const subId = Date.now()
		try {
			const ret = await api.post(`/api2/facility/cmdLineCtrl`, {
				siteId,
				groupId,
				lineId,
				cmdType:"LAMP_CTRL_OFF",
				subId: subId,
				value1:"",
				value2:"",
				enable:""
			});
			this.lineOnOffCtrl = ret
		} catch (error) {
			console.log(`cmdLineLampOn error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//라인 점소등 on
	async getLineOnCmdRunState(lineId:string, subId:string) {
		// api
		console.log(`cmdLineLampOn`);
		try {
			const ret = await api.post(`/api2/facility/getLineOnOffCmdRunState`, {
				lineId,
				cmdType:"LAMP_CTRL_ON",
				subId
			});
			this.lineRunState = ret
		} catch (error) {
			console.log(`cmdLineLampOn error : ${JSON.stringify(error)}`)
			return false;
		}
	},
	//라인 점소등 off
	async getLineOffCmdRunState(lineId:string, subId:string) {
		// api
		console.log(`cmdLineLampOff`);
		try {
			const ret = await api.post(`/api2/facility/getLineOnOffCmdRunState`, {
				lineId,
				cmdType:"LAMP_CTRL_OFF",
				subId
			});
			this.lineRunState = ret
		} catch (error) {
			console.log(`cmdLineLampOn error : ${JSON.stringify(error)}`)
			return false;
		}
	},
})

export default lineStore;
