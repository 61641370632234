//대시보드 그룹 UI
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  colors,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';

import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DashLine from './DashLine';
import { IGroupInfo } from 'model';
import lineStore from 'state/line';
import userStore from 'state/user';
import { isMobile } from 'react-device-detect';

type DashGroupProps = {
  groupInfo?: IGroupInfo
};
const DashGroup = observer(({ groupInfo }: DashGroupProps) => {
  const theme = useTheme();
  const [lineList, setLineList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLineList = async () => {
    await lineStore.getLineList(userStore.siteId, groupInfo.groupId, userStore.getId(), "10", "1");
    const orderLineList = lineStore.lineList.sort((a, b) => (Number(a.lineOrder) > Number(b.lineOrder) ? 1 : -1));
    setLineList(orderLineList);
  }
  useEffect(() => {
    if (lineList != null)
      setLoading(false);
  }, [lineList]);

  useEffect(() => {
    setLoading(true);
    getLineList();
    return () => {
      lineStore.lineList = [];
    }
  }, []);
  const getLineSizeMobile = (facilityCount: string): any => {
    let count: any = 0;
    switch (facilityCount) {
      case "0":
        count = 12;
        break;
      case "1":
        count = 12;
        break;
      case "2":
        count = 12;
        break;
      case "3":
        count = 12;
        break;
      case "4":
        count = 12;
        break;
      case "5":
        count = 12;
        break;
      case "6":
        count = 12;
        break;
      case "7":
        count = 12;
        break;
      case "8":
      default:
        count = 12;
        break;
    }
    return count;
  }

  const getLineSize = (facilityCount: string): any => {
    let count: any = 0;
    switch (facilityCount) {
      case "0":
        count = 1.5;
        break;
      case "1":
        count = 1.5;
        break;
      case "2":
        count = 3;
        break;
      case "3":
        count = 4.5;
        break;
      case "4":
        count = 6;
        break;
      case "5":
        count = 7.5;
        break;
      case "6":
        count = 9;
        break;
      case "7":
        count = 10.5;
        break;
      case "8":
      default:
        count = 12;
        break;
    }
    return count;
  }

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <Divider sx={{ border: 1, borderColor: '#bdbdbd' }} />
      <Box
        sx={{
          position: 'relative',
          width: "96%",
          //backgroundColor: "#778da9",
          //color: "white",
          color: "#0C3149",
          //border:1,
          //borderRadius:4,
          //borderColor:'#415a77',
          textShadow: '3px 3px 3px #9D9E9B',
          left: '0.5%',
          //mt:'0.7%',
        }}
      >
        {/* 그룹이름 */}
        <Typography sx={{ fontSize: 18, fontWeight: 550 }}>
          <img src='/static/images/PushPin.png' style={{ position: 'relative', top: 5, width: '30px', height: '30px' }} />
          &ensp;{groupInfo?.groupName}
        </Typography>
      </Box>

      <CardContent
        sx={{
          flex: '3',
          padding: 1,
          mt: '-15px',
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Grid
          sx={{ placeContent: 'start', mt: 1 }}
          container
          spacing={1}
        >
          {loading == true ?
            <></>
            :
            (
              lineList != null && lineList.length < 1 ?
                <Grid
                  item
                  lg={0}
                  sm={0}
                  xl={0}
                  xs={0}
                >
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                    sx={{
                      flex: '3',
                    }}
                  >
                    라인이 없습니다.
                  </Typography>
                  <Box sx={{ height: 10 }} />
                </Grid>
                :
                lineList.map((item: any, index) => {
                  return (
                    <>
                      <Grid
                        item
                        lg={isMobile ? getLineSizeMobile(item.facilityCount) : getLineSize(item.facilityCount)}
                        sm={isMobile ? getLineSizeMobile(item.facilityCount) : getLineSize(item.facilityCount)}
                        xl={isMobile ? getLineSizeMobile(item.facilityCount) : getLineSize(item.facilityCount)}
                        xs={isMobile ? getLineSizeMobile(item.facilityCount) : getLineSize(item.facilityCount)}
                      >
                        <DashLine lineInfo={item} ></DashLine>
                        {/* <DashFacility category={item.category} name={item.name} volt={item.cnt} current={item.cnt}></DashFacility> */}
                      </Grid>
                    </>
                  )
                })
            )
          }
          {/* <DashLine ></DashLine> */}
        </Grid>
      </CardContent>
    </Card>
  );
});

export default DashGroup;

