//장애 설정에 있는 내용 칸 nameInput
import * as React from 'react';
import {
  Box,
  Typography,
  Input,
} from '@mui/material';

type NameInputErrorProps = {
  name : string;
  value : string;
  disabled? : boolean;
  space? : string;
  nameWidth? : string;
  valueWidth? : string;
  onChange?:(name:string, value:string)=>void;
};
const NameInputError = (
    { name, value, disabled = false, nameWidth = '15rem', valueWidth = '20rem', space = '2rem', onChange 
    }: NameInputErrorProps) => {
        console.log(`NameInput ${name}:${value}`)
        console.log(`${name} : ${nameWidth}, ${valueWidth}`)

    // 글자 입력 함수
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`event = ${event}`)
        console.log(`event.name = ${event.target.name}`)
        console.log(`event.value = ${event.target.value}`)
        // setValue(event.target.value);
        onChange(event.target.name, event.target.value);
    };

return (
    <>
        <Box
            sx={{
                flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
            }}
        >
            <Typography
                sx={{
                    m:'0.6rem',
                    p:'0.4rem'
                }}
                color="textPrimary"
                variant="h5"
            >
                {name}
            </Typography>
            <Box sx={{ width: space }} />
            <Input
                sx={{
                    m: '0.4rem',
                    p: '0.7rem',
                    border: 1,
                    borderRadius: '5px',
                    borderColor: '#BFBFBF',
                    backgroundColor: '#FFFFFF',
                    width: '18rem',
                    height: '10rem',
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: 'black',
                    },
                    whiteSpace: 'pre-wrap', // 줄바꿈이 필요한 곳에서 자동으로 줄바꿈
                    wordWrap: 'break-word', // 단어가 중간에서 잘리지 않고 줄바꿈
                }}
                disabled={disabled}
                disableUnderline
                name={name}
                onChange={handleChange}
                required
                value={value}
                margin="none"
                multiline // 여러 줄 입력을 활성화합니다.
            />

        </Box>
    </>
)
};

export default NameInputError;
