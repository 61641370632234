// 시설 - 연동시설
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { IFacInfo } from '../../model';
import theme from 'theme';
import { IFacilityInfo } from 'model/facility';
import { ButtonBlue } from 'components/admin/system/Styled';
import styles from 'components/facilityGroup/style.module.css'

type RegisteredFacListResultsProps = {
  facInfos : IFacilityInfo[];
  doit:string;
  title?:string;
};
const RegisteredFacListResults = ({ facInfos, title, doit }:RegisteredFacListResultsProps) => {
  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [limit, setLimit] = useState(7);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();


// 하단 페이지 넘김
  const handlePageChange = (event:any, newPage:number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

  const handleUpdate = async(facInfo:any) => {
    console.log(`Device for connect ret : ${facInfo.length}, ${JSON.stringify(facInfo)}`)
      navigate('/slager/configfacility',
        {
          state: {
            siteId: facInfo.siteId,
            siteName: facInfo.siteName,
            groupId: facInfo.groupId,
            groupName: facInfo.groupName,
            lineId: facInfo.lineId,
            lineName: facInfo.lineName,
            facId: facInfo.facId,
            facName: facInfo.facName,
            facType: facInfo.facType,
            facDesc: facInfo.facDesc,
            latitude: facInfo.latitude,
            longitude: facInfo.longitude,
            altitude: facInfo.altitude,
            address: facInfo.address,
            telNo: facInfo.telNo,
            devId: facInfo.devId,
            serialNo: facInfo.devSerialNo,
          }

        });
    }

  return (
    <Card
      sx={{
        width:'98%',
        margin:'auto'
      }}> 
      <PerfectScrollbar>
        <Box 
        sx={{ 
            minWidth: 400, 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
          <Box
            sx={{
              width: '100%',
              height: '3rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: '#F7B31E'
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}>
            </Box>
            <Box
              sx={{
                flex: 1,
                alignItems: 'center',
              }}>
              <Typography
              sx={{color:"#000000"}}
                variant="h3"
                align="center"
              >
                {title}({facInfos.length})
              </Typography>
            </Box>

            <Box
              sx={{
                flex: 1,
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
              }}>

            </Box>
          </Box>

          <Table>
            <TableHead sx={{minWidth: 400}}>
              <TableRow>
                <TableCell className={styles.tableHeadEnd} sx={{ width:'10%' }}>
                  No
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width:'20%' }}>
                  시설 이름
                </TableCell>
                <TableCell className={styles.tableHead}>
                  설명
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width:'15%' }}>
                  기능
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
            {facInfos.slice((page-1)*limit, limit + (page -1)*limit).map((facInfo, index) => (
                <TableRow
                  hover
                  key={facInfo.facId}
                  selected={selectedFacIds.indexOf(facInfo.facId) !== -1}
                >
                  {/* No */}
                  <TableCell className={styles.tableBodyEnd} sx={{ width:'10%' }}>
                  {(page - 1) * limit + index + 1}
                  </TableCell>
                  {/* 시설이름 */}
                  <TableCell className={styles.tableBody} sx={{ width:'25%'}}>
                    {facInfo.facName}
                  </TableCell>
                   {/* 설명 */}
                  <TableCell className={styles.tableBody}>
                    {facInfo.facDesc}
                  </TableCell>
                  {/* 기능 */}
                  <TableCell className={styles.tableBody} sx={{ width:'15%',padding:'1%'}}>
                  <ButtonBlue
                      className={styles.blueButton}
                      size="small"
                      onClick={() => {
                          console.log(`수정`)
                          handleUpdate(facInfo);
                         }}
                         >
                          수정
              </ButtonBlue>
              </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* 하단 페이지넘김 */}
      <Pagination
          color="primary"
          page={page}
          count={Math.ceil(facInfos.length / 7)}
          onChange={handlePageChange}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height:'50px',
            [theme.breakpoints.down('sm')]: {
              width: window.innerWidth,
            },
          }}
        />
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Card>
  );
};

export default RegisteredFacListResults;
