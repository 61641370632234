//사이트관리 - 중계기 - 등록/수정
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  Table,
  TableCell,
  TableRow
} from '@mui/material';
import theme from 'theme';
import { useStores } from '../../state';
import { useLocation, useNavigate } from 'react-router';
import { Facilitys, FacType, ISetFacilityGatewayInfo, ISetFacilityInfo } from 'model/facility';
import facilityStore from 'state/facility';
import NameSelectRem from 'components/atom/NameSelectRem';
import NameInputRem from 'components/atom/NameInputRem';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import NameInput from 'components/atom/NameInput';


interface StyledNameInputProps {
  name: string;
  label?: string;
  value :string;
  disabled? : boolean;
  onChange:(name:string, label:string, value:string)=>void;
}
function StyledNameInput({name, label, value, disabled, onChange}:StyledNameInputProps) {
  const handleChange = (_name:string, _value:string) => {
    onChange(_name, label, _value);
  }

  return (
    <>
      <NameInput
        name={name}
        value={value}
        disabled={disabled}
        space={10}
        nameWidth={110}
        valueWidth={200}
        onChange={handleChange}
      />
    </>
  );
}
interface StyledNameSelectProps {
  name : string;
  label?: string;
  defaultValue? : string;
  object : any[];
  space? : string;
  nameWidth? : string;
  valueWidth? : string;
  onSelect:(name:string, label:string, value:string)=>void;
}
function StyledNameSelect({name, label, defaultValue, object, space, nameWidth, valueWidth, onSelect}:StyledNameSelectProps) {
  const handleSelect = (_name:string, _value:string) => {
    onSelect(_name, label, _value);
  }

  return (
    <>
      <NameSelectRem
        name={name}
        defaultValue={defaultValue}
        object={object}
        space={space}
        nameWidth={nameWidth}
        valueWidth={valueWidth}
        onSelect={handleSelect}
      />
    </>
  );
}

interface ILocation {
  facId:string,
  facName:string,
  facType:string,
  facDesc:string,
  latitude:string,
  longitude:string,
  altitude:string,
  address:string,
  telNo:string,
}
const RegisterFacGatewayImp = () => {
  const navigate = useNavigate();
  const { gatewayStore, userStore } = useStores();
  const location = useLocation();
  const state = location.state as ILocation;

  const [values, setValues] = useState({
    siteId: '',
    facId: null,
    facName: null,
    facType: null, // default 가로등 
    facDesc: null,
    latitude: null,
    longitude: null,
    altitude: null,
    address: null,
    telNo: null,
  });

  console.log(`RegisterFac location ${JSON.stringify(location)}`)

  const getType = (value: string): string => {
    console.log(`getType ${value} `)

    if (value == null) {
      return '0';
    }

    const type = Facilitys.find((item: any) => item.value == value)
    if (type == null) {
      return '-1';
    }
    return type.value;
  }

  //value값 설정
  useEffect(() => {
    console.log(`useEffect ${JSON.stringify(state)}`)
  console.log(`RegisterFacGatewayImp user:id::  ${userStore.getId()}`);
  if (state != null) {
    setValues({
      ...values,
      facId: state.facId, 
      facName: state.facName, 
      facDesc: state.facDesc, 
      facType: state.facType,
      latitude: state.latitude,
      longitude: state.longitude,
      altitude: state.altitude,
      address: state.address,
      telNo: state.telNo,
    });
  }
  },[]);
  useEffect(() => {
    console.log('useEffect')
  },[]);

  //정보 입력
  const handleChange = (name:string, label:string, value:string) => {
    console.log(`handleChange ${name}, ${label}, ${value}`)
    setValues({
      ...values,
      [label]: value
    });
  };

  //정보 저장
  const saveFacility = async () => {
    // gateway
    const facGateway: ISetFacilityGatewayInfo = {
      siteId: userStore.siteId,
      facId: null,
      facName: values.facName,
      facType: values.facType,
      facDesc: values.facDesc,
      latitude: values.latitude,
      longitude: values.longitude,
      address: values.address,
      telNo: values.telNo,
    }
    return facilityStore.regFacilityGatewayInfo(facGateway);
  }
  const handleSave = async () => {
    console.log(`handleSave ${JSON.stringify(values)}`)
    // common use for gateway, lamp
    const ret: any = await saveFacility();
    if (ret.status == 200) {
        ToastsStore.success("추가 되었습니다.")
        const timer = setInterval(() => {
          clearInterval(timer);
          navigate(-1); // goback
        }, 1000)

      }
      else if (ret.status == 400) {
        ToastsStore.success("추가 실패")
      }
  }

  const handleSaveContinus = async () => {
    console.log(`handleSaveContinus ${JSON.stringify(values)}`)
    saveFacility();
    // common use for gateway, lamp
    const ret: any = await saveFacility();
    if (ret.status == 200) {
        ToastsStore.success("추가 되었습니다.")
        const timer = setInterval(() => {
          clearInterval(timer);
          setValues ({
            siteId: '',
            facId: '',
            facName: '',
            facType: '',
            facDesc: '',
            latitude: '',
            longitude: '',
            altitude: '',
            address: '',
            telNo: '',
          })
          setValues ({
            siteId: '',
            facId: null,
            facName: null,
            facType: null,
            facDesc: null,
            latitude: null,
            longitude: null,
            altitude: null,
            address: null,
            telNo: null,
          })
        }, 1000)

      }
      else if (ret.status == 400) {
        ToastsStore.success("추가 실패")
      }
  }
  return (
<Box>
      <Typography 
         sx={{
          position:'relative',
          height:1,
          textAlign:'right',
          right:'1%'
         }}
         gutterBottom>
          {values.facName != null ?`시스템 관리 >  중계기 > 중계기 수정`: `시스템 관리 > 중계기 > 중계기 등록`}	
        </Typography>
        <Box
          sx={{
            display: 'flex',
            position:'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2%',
            right:'1%'
          }}
        >
            <Typography //타이틀 글자 조정
              sx={{ 
                fontSize: 30,
                fontWeight:600,
                color:'#0C3149',
                display:'flex',
                position:'relative',
                left: '2.2%',
                marginBottom:'1%'
               }}    
                gutterBottom > 
                {values.facName != null ?`중계기 수정`: `중계기 등록`}	
                </Typography>
</Box>
<Divider sx={{border:1 , borderColor:'#bfbfbf'}}/>
                <form
                 autoComplete="off"
                 noValidate
      >
            <Table
              sx= {{
                position:'relative',
                left:'1%',
                width:'98%'
            }}>
            <TableRow>
            <TableCell sx={{ padding: 1,  borderBottom:'1px dotted #bdbdbd', borderBottomColor:'#BFBFBF'}}>
          <StyledNameInput
            name="시설이름"
            label="facName"
            value={values.facName}
            disabled={false}
            onChange={handleChange}
          /></TableCell>
          </TableRow>


          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1,  borderBottom:'1px dotted #bdbdbd', borderBottomColor:'#BFBFBF'}}>
          <StyledNameInput
            name="설명"
            label="facDesc"
            value={values.facDesc}
            disabled={false}
            onChange={handleChange}
          /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1,  borderBottom:'1px dotted #bdbdbd', borderBottomColor:'#BFBFBF'}}>
          <StyledNameInput
            name="위도"
            label="latitude"
            value={values.latitude}
            disabled={false}
            onChange={handleChange}
          /></TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell sx={{ padding: 1,  borderBottom:2, borderBottomColor:'#BFBFBF'}}>
          <StyledNameInput
            name="경도"
            label="longitude"
            value={values.longitude}
            disabled={false}
            onChange={handleChange}
          /></TableCell>
          </TableRow>
</Table>
          <Box sx={{ height: 5 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            저장
          </Button>
        </Box>
        </form>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
      </Box>
      
  );
};

export default RegisterFacGatewayImp;
