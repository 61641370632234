// 사이트관리 - 시설 - 미연동 시설
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { IFacInfo } from '../../model';
import theme from 'theme';
import { IFacilityInfo } from 'model/facility';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import { ButtonBlue, ButtonGreen } from 'components/admin/system/Styled';
import styles from 'components/facilityGroup/style.module.css'

type UnRegisteredFacListResultsProps = {
  facInfos: IFacilityInfo[];
  doit: string;
  title?: string;

};
const UnRegisteredFacListResults = ({ facInfos, title, doit }: UnRegisteredFacListResultsProps) => {

  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [selectedFacId, setSelectedFacId] = useState<IFacilityInfo>();
  const [limit, setLimit] = useState(7);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);


  const handleConnectDevice = async (facInfo: any) => { //시설연동
    console.log("!!!!!!!!!!!!!!!!!!!!!!!", JSON.stringify(facInfo))
    navigate('/slager/connectdevice',
      {
        state: {
          siteId: facInfo.siteId,
          siteName: facInfo.siteName,
          groupId: facInfo.groupId,
          groupName: facInfo.groupName,
          lineId: facInfo.lineId,
          lineName: facInfo.lineName,
          facId: facInfo.facId,
          facName: facInfo.facName,
          facType: facInfo.facType,
          facDesc: facInfo.facDesc,
        }
      });
  }

  // 하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

  // 시설삭제 dialog 닫음
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteFacility = async (facInfo: any) => { //시설삭제
    console.log(`handleDeleteFacility`)

    console.log(`handleDeleteFacility : selecteds ${facInfo.facId}`);
    const ret: any = await facilityStore.delFacility(facInfo.facId);
    if (ret) {
      console.log(`handleDeleteFacility : ${facInfo.facId} is deleted`)
    }
    else {
      ToastsStore.warning("시설 삭제에 실패 했습니다.")
    }

    facilityStore.getFacilityAllInfoList(userStore.siteId, null, null, null);
    setOpenDialog(false);
  };

  //수정
  const handleUpdate = async (facInfo: any) => {
    console.log(`Device for connect ret : ${facInfo.length}, ${JSON.stringify(facInfo)}`)
    navigate('/slager/configfacility',
      {
        state: {
          siteId: facInfo.siteId,
          siteName: facInfo.siteName,
          groupId: facInfo.groupId,
          groupName: facInfo.groupName,
          lineId: facInfo.lineId,
          lineName: facInfo.lineName,
          facId: facInfo.facId,
          facName: facInfo.facName,
          facType: facInfo.facType,
          facDesc: facInfo.facDesc,
          latitude: facInfo.latitude,
          longitude: facInfo.longitude,
          altitude: facInfo.altitude,
          address: facInfo.address,
          telNo: facInfo.telNo,
          devId: facInfo.devId,
          serialNo: facInfo.devSerialNo,
        }

      });
  }


  return (
    <div>
      <Card
        sx={{
          width: '98%',
          margin: 'auto'
        }}>
        <PerfectScrollbar>
          <Box
            sx={{
              minWidth: 400,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '3rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#44B572'
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  alignItems: 'center',
                }}>
                <Typography
                  sx={{ color: "#000000" }}
                  variant="h3"
                  align="center"
                >
                  {title}({facInfos.length})
                </Typography>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
              </Box>
            </Box>
            <Table >
              <TableHead sx={{ minWidth: 400 }}>
                <TableRow>
                  <TableCell className={styles.tableHeadEnd} sx={{ width: '10%' }} >
                    No
                  </TableCell>
                  <TableCell className={styles.tableHead} sx={{ width: '20%' }}>
                    시설 이름
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    설명
                  </TableCell>
                  <TableCell className={styles.tableHead} sx={{ width: '31%' }}>
                    기능
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {facInfos.slice((page - 1) * limit, limit + (page - 1) * limit).map((facInfo, index) => (
                  <TableRow
                    hover
                    key={facInfo.facId}
                    selected={selectedFacIds.indexOf(facInfo.facId) !== -1}
                  >
                    {/* NO */}
                    <TableCell className={styles.tableBodyEnd} sx={{ width: '10%' }}>
                      {(page - 1) * limit + index + 1}
                    </TableCell>
                    {/* 이름 */}
                    <TableCell className={styles.tableBody} sx={{ width: '25%' }}>
                      {facInfo.facName}
                    </TableCell>
                    {/* 설명 */}
                    <TableCell className={styles.tableBody}>
                      {facInfo.facDesc}
                    </TableCell>
                    <TableCell className={styles.tableBody} sx={{ padding: '1%' }}>
                      <Button
                        className={styles.blueButton}
                        size="small"
                        onClick={() => {
                          console.log(`수정`)
                          handleUpdate(facInfo);
                        }}
                      >
                        수정
                      </Button>
                      <Button
                        className={styles.blueButton}
                        size="small"
                        onClick={() => {
                          console.log(`기기연동`)
                          handleConnectDevice(facInfo);
                        }}
                      >
                        기기연동
                      </Button>
                      <Button
                        className={styles.greenButton}
                        size="small"
                        onClick={() => {
                          console.log(`삭제`)
                          setSelectedFacId(facInfo);
                          setOpenDialog(true);
                        }}
                      >
                        시설삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>

        <Pagination
          color="primary"
          page={page}
          count={Math.ceil(facInfos.length / 7)}
          onChange={handlePageChange}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height: '50px',
            [theme.breakpoints.down('sm')]: {
              width: window.innerWidth,
            },
          }}
        />
        {/* 시설삭제 dialog */}
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              해당 시설을 삭제 하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button className={styles.greenButton} onClick={handleDialogClose}>취소</Button>
            <Button className={styles.primaryButton} onClick={() => {
              console.log(`삭제`);
              handleDeleteFacility(selectedFacId);
            }} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
      </Card>
    </div>
  );
};

export default UnRegisteredFacListResults;
