//관리자 - 사이트 관리 - 사용자 관리
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
  Input,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import theme from 'theme';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { ISiteDetail, isSiteManager, IUserDetail, SiteLevel } from 'model';
import { useStores } from 'state';
import userStore from 'state/user';
import { observer } from 'mobx-react';
import NameValueRem from 'components/atom/NameValueRem';
import SiteUser from './AdminSiteUser';
import Space from 'components/atom/Space';
import styles from 'components/facilityGroup/style.module.css'
import { Spa } from '@mui/icons-material';

interface StyledNameValueProps {
  name: string;
  value :string;
}
function StyledNameValue({name, value }:StyledNameValueProps) {
  return (
    <>
      <NameValueRem
        name={name}
        value={value}
        space={'3rem'}
        nameWidth={'5rem'}
        valueWidth={'20rem'}
      />
    </>
  );
}


interface AdminSiteUserAddProps {
  onClose?:()=> void;
}
const AdminSiteUserAdd = observer(({onClose}:AdminSiteUserAddProps) => {
  const { siteStore } = useStores();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const state = location.state as ISiteDetail;
  const [values, setValues] = useState<any>(
    {
    siteId: '',
    siteName: '',
    siteLevel: '',
    });

  const [inUser, setInUser] = useState<any[]>([]);
  const [outUser, setOutUser] = useState<any[]>([]);

  const [user, setUser] = useState(null);
  const [siteLevel, setSiteLevel] = useState('');
  const [searchId, setSearchId] = useState('');
  const [searchUserName, setSearchUserName] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    console.log(`AdminSiteUserAdd useEffect ${JSON.stringify(state)}`)
    if (state != null) {
      setValues(
        state
      );
    }
  },[]);

  useEffect(() => {
    console.log(`AdminSiteUserAdd useEffect siteId = ${values.siteId}, usestore site id = ${userStore.siteId}`);
    if (values.siteId != '') {
    console.log(`AdminSiteUserAdd useEffect get siteId = ${values.siteId}, usestore site id = ${userStore.siteId}`);
      siteStore.getSiteUserList(values.siteId);
    }
  },[values]);

  useEffect(() => {
      console.log(`---inusers--- ${userStore.siteId}`);
    const inusers = siteStore.userList.filter((item: any, index: number) => {
      console.log(`users ---> ${JSON.stringify(item)}`);
      console.log(`users -> ${item.userId}, ${item.siteList.length}`)
      return item.siteList.length >= 1;
    }
    );
    setInUser(inusers);
      console.log(`---outusers---`);
    const outusers = siteStore.userList.filter((item: any, index: number) => {
      console.log(`users ---> ${JSON.stringify(item)}`);
      console.log(`users -> ${item.userId}, ${item.userName}`)
      return item.siteList.length < 1;
    }
    );
    setOutUser(outusers);
  }, [siteStore.userList]);

  // 사이트 추가 팝업 닫기
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
//사이트 삭제 팝업 닫기
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  //추가 버튼 기능
  const handleAddUser = async () => {
    setOpenDialog(false);
    const ret: any = await siteStore.setUser(values.siteId, userId, siteLevel);
    if (ret.status == 200) {
        ToastsStore.success("추가 되었습니다.")
     console.log(`cyi handleAddUser ~~~~~~~~~~~~~~~ siteId:${values.siteId}`)
      siteStore.getSiteUserList(values.siteId);
      }
      else if (ret.status == 400) {
        ToastsStore.success("추가 실패")
      }
  };
// 삭제 
  const handleDeleteUser = async () => {
    setOpenDeleteDialog(false);
    const ret: any = await siteStore.deleteUser(values.siteId, user.userId);
    if (ret.status == 200) {
        ToastsStore.success("삭제 되었습니다.")
     console.log(`cyi handleDeleteUser ~~~~~~~~~~~~~~~ siteId:${values.siteId}`)
      siteStore.getSiteUserList(values.siteId);
      }
      else if (ret.status == 400) {
        ToastsStore.success("삭제 실패")
      }
  };
// 추가 팝업 열림
  const onAdd = () => {
    // console.log(`cyi onAdd user name:${JSON.stringify(_user)}`)
    // setUser(_user);
    userStore.userInfo = null;
    setOpenDialog(true);
  };
// 삭제 팝업 열림
  const handleDelete = (_user:IUserDetail) => {
    console.log(`cyi ondelete user name:${JSON.stringify(_user)}`)
    setUser(_user);
    setOpenDeleteDialog(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`!!!!event = ${event}`)
    console.log(`!!!!event.name = ${event.target.name}`)
    console.log(`!!!!event.value = ${event.target.value}`)
    console.log(`!!!!value = ${JSON.stringify(values)}`)
    setSearchId(event.target.value);
  };

  //사용자 id select
  const handleChangeUserId = (event: SelectChangeEvent) => {
    console.log(`handleChangeUserId ${event.target.value}`)
    console.log(`handleChangeUserId name ${event.target.name}`)
    setUserId(event.target.value);
  };
// 사용자 id 검색
  const handleSearchUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`handleSearchUserId event = ${event}`)
    console.log(`event.name = ${event.target.name}`)
    console.log(`event.value = ${event.target.value}`)
    setSearchUserName(event.target.value)
  };

  const handleSearch = async () => {
    await userStore.getUserInfo(searchId)
    if (userStore.userInfo == null) {
      ToastsStore.success("없는 사용자 ID 입니다.")
    }
    else {
      ToastsStore.success(`${searchId}가 검색되었습니다.`)
    }
  };

  //사이트 레벨 select
  const handleChangeSiteLevel = (event: SelectChangeEvent) => {
    setSiteLevel(event.target.value as string);
  };
  return (
    <>
      <PerfectScrollbar>
      <Typography 
         sx={{
          position:'relative',
          height:1,
          left:'83.7%',
         }}
         gutterBottom>
          관리자 {'>'} 사이트 관리 {'>'} 사용자 관리
        </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography
              sx={{
                 fontSize: 30,
                 fontWeight:600,
                 color:'#0C3149',
                 display:'flex',
                 position:'relative',
                 left: '1%'
              }}
              >
                사이트 사용자 관리
              </Typography>
            </Box>
            <Box sx={{ mt: 1 }}> </Box>
            <Box sx={{ mt: 2 }}> </Box>
            <Divider />
            <Box sx={{ mt: 2 }}> </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            >
              <StyledNameValue
                name="사이트"
                value={values?.siteName}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: "center",
                }}
              >
                {userStore.isAdmin() &&
                  <Button
                    className={styles.primaryButton}
                    onClick={() => {
                      console.log(`추가`)
                      onAdd();
                    }}
                  >
                    추가
                  </Button>
                }
              </Box>
            </Box>
            <Box sx={{ mt: 1 }}> </Box>
            <Box sx={{ mt: 1 }}> </Box>

            <Grid
              container
              spacing={1}
            >
              {/* <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <SiteUser
                  type ='추가'
                  title="미등록 사용자"
                  userList={outUser}
                  onSelected={onAdd}
                />
              </Grid> */}
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <SiteUser
                  type = '삭제'
                  title="등록 사용자"
                  userList={inUser}
                  onSelected={handleDelete}
                />
              </Grid>
            </Grid>

            <Divider />

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color: '#0C3149',
              display: 'flex',
              position: 'relative',
              padding: 1.5
            }}>
            담당자 추가
          </Typography>
          <Divider/>
            </DialogContentText>
            <Space />
            <Space />
            <Space />
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            >
            <Input
                sx={{
                  width: 215,
                  height: 30,
                  textAlign: 'center',
                  borderRadius: "4px",
                  border: "1px solid #bdbdbd",
                  pl: 1,
                }}
                disableUnderline
                placeholder='사용자를 입력 하세요(검색)'
                onChange={handleSearchUserId}
                required
                value={searchUserName}
                margin="none"
              />
            <Space />
            <FormControl sx={{ width: 215 }}>
                <InputLabel id="demo-simple-select-label">사용자 ID</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-autowidth"
                  value={userId}
                  label="사용자 ID"
                  onChange={handleChangeUserId}
                >
                  {
                    outUser?.filter((item: any) => {
                      return item.userName.includes(searchUserName);
                    })
                    .map((item: any, index: number) => {
                        return (
                          <MenuItem value={item.userId}>{item.userName}</MenuItem>
                        )
                      })
                  }
                </Select>
              </FormControl>
            </Box>
            <Space />
            <Box sx={{}}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">사이트레벨</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-autowidth"
                  value={siteLevel}
                  label="User"
                  onChange={handleChangeSiteLevel}
                >
                  {
                    SiteLevel.map((item: any, index: number) => {
                      return (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>
            <Space/>
            <Divider/>
          </DialogContent>
          <DialogActions sx={{justifyContent:'center', position:'relative',bottom:12}}>
            <Button variant='outlined' color='error' onClick={handleDialogClose}>취소</Button>
            <Button variant='outlined' color='success' disabled={!userId || !siteLevel} onClick={handleAddUser} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              해당 계정을 삭제 하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent:'center'}}>
            <Button className={styles.greenButton} onClick={handleDeleteDialogClose}>취소</Button>
            <Button className={styles.primaryButton}onClick={handleDeleteUser} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </PerfectScrollbar>
    </>
  );

});

export default AdminSiteUserAdd;
