//구글맵스
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import ErrorMarker from './ErrorMarker'
import { Box } from '@mui/material';
import { useRect } from '../../utils/useRect';
import SearchBar from './SearchBar';
import SearchResults from './SearchResults';
import ErrorSearchBar from './ErrorSearchBar';
import { useMediaQuery } from 'react-responsive';
import { IFacilityDetailInfo } from 'model/facility';
import { convDMS2DegEx } from 'utils/getLocation';
import facilityStore from 'state/facility';

type GoogleMapProps = {
	_showSR?:boolean;
	type?:string;
	facs?:IFacilityDetailInfo[];
	onClick:(id:string) => void;
	onSearch:(groupId:string) => void;
};

const GoogleMap = ({
	_showSR,
	type,
	facs,
	onClick,
	onSearch
}:GoogleMapProps) => {
	const [position, setPosition] = useState({lat:37.323, lng:126.840});
	const [left, setLeft] = useState(0);
	const [top, setTop] = useState(0);
	const [topFSR, setTopForSR] = useState(0);
	const [showSR, setSR] = React.useState(false);
	const [srType, setSrType] = React.useState('error');
	const [sbWidth, setSbWidth] = React.useState(800);
	const isMobile = useMediaQuery({ maxWidth: 767 })

	useEffect(() => {
		if (_showSR) {
			setSR(true);
		}
	}, [_showSR]);
	useEffect(() => {
		if (facs && facs?.length != 0) {
			let isFirst = 0;
			const tmpFacs = facs?.filter(({ latitude, longitude }) => {
				if (latitude != null) {
					if (isFirst > 10) {
						return false;
					}
					isFirst++;
					return true;
				}
			});
			if (tmpFacs.length > 0) {
				// console.log(`tmpFacs ${convDMS2DegEx(tmpFacs[tmpFacs.length - 1].latitude)}, ${convDMS2DegEx(tmpFacs[tmpFacs.length - 1].longitude)}`)
				setPosition({ lat: convDMS2DegEx(tmpFacs[0].latitude), lng: convDMS2DegEx(tmpFacs[0].longitude) })
			}
		}
	}, [facs]);

	const handleClick = (id:string, latitude:number, longitude:number) => {
		if (latitude != 0) {
			setPosition({ lat: latitude, lng: longitude })
		}
		onClick(id);
	}


	const ref1 = useRef<HTMLDivElement>();
	const ref2 = useRef<HTMLDivElement>();
	const ref3 = useRef<HTMLDivElement>();
	const bbox = useRect(ref1);

	useEffect(() => {
		if (isMobile) {
			setLeft(ref1.current.getBoundingClientRect().left);
			setTop(ref1.current.getBoundingClientRect().top);
			setSbWidth(ref1.current.getBoundingClientRect().width - 8); // just left is 8, why I don't know
		}
		else {
			setLeft(ref1.current.getBoundingClientRect().left + 10);
			setTop(ref1.current.getBoundingClientRect().top + 5);
			setSbWidth(800);
		}
	}, [bbox])

	useEffect(() => {
		if (showSR) {
			setTopForSR(ref1.current.getBoundingClientRect().bottom - ref3?.current?.getBoundingClientRect().height - 3);
		}
	}, [showSR])

	const handleSearch = (groupId:string, _srType:string) => {
		onSearch(groupId);
		setSrType(_srType)
	}

	const centerMoved = (map:any) => {
		setPosition({ lat: map.center[0], lng: map.center[1]});

	}

	return (
		<div ref={ref1} style={{ height: '100%', width: '100%' }}>
		{/* <div ref={ref1} style={{ height: '100%', width: '100%' }}> */}
			<Box
				ref={ref2}
				sx={{ zIndex: 1, position: "absolute", top: top, left: left }}>
					{
						type=="info"?
						<SearchBar size={sbWidth} onSearch={handleSearch}></SearchBar>
						:
						<ErrorSearchBar size={sbWidth} onSearch={handleSearch}></ErrorSearchBar>
					}
			</Box>
			{showSR &&
				<Box
				ref={ref3}
					sx={{ zIndex: 1, position: "absolute", top: topFSR, left: left }}>
					<SearchResults type={srType} facs={facs} 
					onClick={function (): void {
						facilityStore.setExit('true')
						setSR(false);
					}} 
					onClickShowFacInfo={function (facInfo:IFacilityDetailInfo): void {
						handleClick(facInfo.facId.toString(), convDMS2DegEx(facInfo.latitude), convDMS2DegEx(facInfo.longitude))
					}} 
					></SearchResults>
				</Box>
			}
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyCfL4YTPX5g9tqbESwNcOmJfT9HD_ET87Q" }} // yichoi99
				center={position}
				defaultZoom={16}
				onDragEnd={centerMoved}
			>
				{facs?.filter(({latitude, longitude}) => {
					if (latitude != null)
						return true;
					}).map(({latitude, longitude, facId, facType, status, facName, vout1}) => {
						console.log(`GoogleMap ${facId}: ${convDMS2DegEx(latitude)} ${convDMS2DegEx(longitude)}`)
					return (
						<ErrorMarker
						key={facId}
						id={facId.toString()}
						type={facType}
						lat={convDMS2DegEx(latitude)}
						lng={convDMS2DegEx(longitude)}
						text=''
						status={status}
						tooltip={facName}
						vout={vout1}

						onClick={() => handleClick(facId.toString(), convDMS2DegEx(latitude), convDMS2DegEx(longitude))}
						/>
					)
				})}
			</GoogleMapReact>
		</div>
	);
};

export default GoogleMap;