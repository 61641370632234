// 시설운영 - 시설 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import { observer } from 'mobx-react';
import ManageLampToolbar from 'components/facility/ManageLampToolbar';
import ManageLampListResults from 'components/facility/ManageLampListResults';
import { useStores } from 'state';
import { useEffect, useState } from 'react';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import { FacType } from 'model';

const StreetLampList = observer(() => {
  const { clientStore, iotStore } = useStores();
  const [doit, setDoit] = useState('');
  const [count, setCount] = useState(0);
  console.log('StreetLampList')

  useEffect(() => {
    // clientStore.getLampList("10","1");
    facilityStore.getFacilityInfoList(userStore.siteId, null, null, null);
  },[]);
  
  useEffect(() => {
    console.log(`managelamp clientlist ${JSON.stringify(facilityStore.inFacilityList)}`);
  },[facilityStore.inFacilityList]);

  const onCommand = (command: string, name?:string, value?:string) => {
    console.log(`onCommand = ${command} !!!!!!!!!!!!!!!!!!`)
    if (command=='search') {
      if (name && name != '0' && value) {
        const param: any = {};
        param[name] = value;
        param.perPage = "10";
        param.page = "1";
        clientStore.getLampListWithParm(param);
      }
      else {
        clientStore.getLampList("10", "1");
      }
    }
    else {
      setDoit(command + '-' + count);
      setCount(count + 1);
    }
  }

  return (
    <>
      <Helmet>
        <title>StreetLampList</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {/* <ManageLampToolbar lampCount={facilityStore.inFacilityList?.length.toString()} onCommand={onCommand} />
          <Box sx={{ mt: 1 }}></Box> */}
            <ManageLampListResults lampCount={facilityStore.inFacilityList?.length.toString()} facs={facilityStore.inFacilityList} doit={doit} facId={''} />
        </Container>
      </Box>
    </>
  );
});

export default StreetLampList;
