// 대시보드 그룹이름과 ON/OFF시간 보여주는 것.
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DashFacility from './DashFacility';
import facilityStore from 'state/facility';
import userStore from 'state/user';
import { isMobile } from 'react-device-detect';
import styles from 'components/facilityGroup/style.module.css'
import { ButtonBlue } from 'components/admin/system/Styled';
import LineDetailUpdatePopup from 'components/popup/LineDetailUpdatePopup';
import { useInterval } from 'utils/useInterval';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Sun } from 'react-feather';

type DashLineProps = {
  lineInfo?: any;
  showChart?: () => void;
};
const DashLine = observer(({ lineInfo, showChart }: DashLineProps) => {
  const theme = useTheme();
  const [facilityList, setFacilityList] = useState([]);
  const [facilitys, setFacilitys] = useState(4);
  const [facilitysMobile, setFacilitysMobile] = useState(4);
  const [showDashFacilityChart, setShowDashFacilityChart] = useState(false);
	const [selectedLine, setSelectedLine] = useState(null);
	const [showLineDetailUpdatePopup, setShowLineDetailUpdatePopup] = useState(false);

  const getFacilityList = async () => {
    await facilityStore.getFacilityDetailInfoList(userStore.siteId, lineInfo.groupId, lineInfo.lineId, null);
    const tmp = facilityStore.facilityDetailInfoList;
    setFacilityList(tmp);
  }

  // 모바일이면 (만드는중)
  useEffect(() => {
    // grid 최대 12까지 화면을 분할 한다.
    // setFacilitys 는 시설이 라인에서 차지 하는 비중을 의미 한다
    // onLineSize 는 라인에 그룹에서 차지하는 비중을 의미 한다.
    if (isMobile && facilityList) {
      switch (facilityList.length) {
        case 1:
          setFacilitysMobile(8)
          break;
        case 2:
          setFacilitysMobile(6);
          break;
        case 3:
          setFacilitysMobile(6);
          break;
        case 4:
          setFacilitysMobile(6);
          break;
        case 5:
          setFacilitysMobile(6);
          break;
        case 6:
          setFacilitysMobile(6);
          break;
        case 7:
          setFacilitysMobile(6);
          break;
        case 8:
        default:
          setFacilitysMobile(6); //1.5 = 12 /8
          break;
      }
    }
  }, [facilityList]);


  useEffect(() => {
    if (facilityList == null) {
      setFacilitys(0)
      return;
    }
    // grid 최대 12까지 화면을 분할 한다.
    // setFacilitys 는 시설이 라인에서 차지 하는 비중을 의미 한다
    // onLineSize 는 라인에 그룹에서 차지하는 비중을 의미 한다.
    switch (facilityList.length) {
      case 1:
        setFacilitys(12)
        break;
      case 2:
        setFacilitys(6);
        break;
      case 3:
        setFacilitys(4);
        break;
      case 4:
        setFacilitys(3);
        break;
      case 5:
        setFacilitys(2.4);
        break;
      case 6:
        setFacilitys(2);
        break;
      case 7:
        setFacilitys(1.77);
        break;
      case 8:
      default:
        setFacilitys(1.5); //1.5 = 12 /8
        break;
    }
  }, [facilityList]);

  useEffect(() => {
    getFacilityList();
    return () => {
      facilityStore.facilityDetailInfoList = [];
    }
  }, []);

  //20초마다 대시보드 정보 새로고침.
  useInterval(() => {
    if (showDashFacilityChart === false) {
      getFacilityList();
    }
  }, 20000);

  //facility 정보 불러오는 함수
  useEffect(() => {
    getFacilityList();
  }, [facilityStore.facilityDetailInfo?.maintenanceStateCode]);

  const handleShowDashFacilityChart = (on: boolean) => {
    setShowDashFacilityChart(on);
  }

	// 정보수정 팝업 닫기
	const handleLineDetailUpdatePopupClose = async () => {
		console.log(`handleLinerAddPopupClose in facilitylinelist`)
		setShowLineDetailUpdatePopup(false);
	};

  return (
    <Card> 
      {/* 아래는 라인 UI 조건문 해당 조건에 따라 UI가 바뀝니다. */}
      {/* 시설이 하나도 없을 때 */}
      {lineInfo.lineName && facilityList == null &&
        <Box className={styles.dashBoardLineNameBoxJustify} sx={{ alignItems: "center" }}>
          <Typography sx={{ color: "#FFFFFF", fontSize: '14px' }}> {lineInfo?.lineName.length > 12 ? `${lineInfo.lineName.substring(0, 12)}...` : lineInfo?.lineName} </Typography>
          <Box sx={{ height: 40 }}></Box>
        </Box>}

      {/* 시설이 여러개 존재할 때 display:'flex' */}
      {lineInfo.onTime && facilityList && facilityList.length > 1 &&
        <Box className={styles.dashBoardLineNameBoxJustify} sx={{ alignItems: "center" }}>
          <Typography sx={{ color: "#FFFFFF", fontSize: '14px' }}>{lineInfo.lineName}</Typography>

          <ButtonBlue
            sx={{
              height: 30,
              backgroundColor: '#47BD77',
              marginLeft: 'auto',
              "&:hover": { backgroundColor: "#318252" }
            }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              console.log(`정보 수정`)
              setSelectedLine(lineInfo)
              setShowLineDetailUpdatePopup(true);
            }}
          >
            <Typography sx={{ fontSize: 12 }}>ON/OFF 시간 설정</Typography>
          </ButtonBlue>

          <Hidden lgDown>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => ( // ON/OFF 시간 보여줌. => 해 모양 아이콘
                <Box>
                  <IconButton sx={{ position: 'relative', color: '#FFFFFF' }}{...bindTrigger(popupState)}>
                    <Sun style={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Typography sx={{ p: 2 }}> ON/OFF : {lineInfo.onTime} / {lineInfo.offTime} </Typography>
                  </Popover>
                </Box>
              )}
            </PopupState>
          </Hidden>
        </Box>}

      {/* 시설이 하나만 존재할 때 display:'block' */}
      {lineInfo.onTime && facilityList && facilityList.length <= 1 &&
        <Box className={styles.dashBoardLineNameBoxJustify} sx={{ alignItems: "center" }}>
          <Typography sx={{ color: "#FFFFFF", fontSize: '14px' }}>{lineInfo?.lineName.length > 12 ? `${lineInfo.lineName.substring(0, 12)}...` : lineInfo?.lineName}</Typography>

          <ButtonBlue
            sx={{
              height: 30,
              backgroundColor: '#47BD77',
              marginLeft: 'auto',
              "&:hover": { backgroundColor: "#318252" }
            }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              console.log(`정보 수정`)
              setSelectedLine(lineInfo)
              setShowLineDetailUpdatePopup(true);
            }}
          >
            <Typography sx={{ fontSize: 12 }}>ON/OFF 시간 설정</Typography>
          </ButtonBlue>

          {/* 시설이 존재하고 OnTime이 있는 라인만 ON/OFF Time이 보임. */}
          <Hidden lgDown>
            {lineInfo.onTime && facilityList &&
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => ( // ON/OFF 시간 보여줌. => 해 모양 아이콘
                  <Box>
                    <IconButton sx={{ position: 'relative', color: '#FFFFFF' }}{...bindTrigger(popupState)}>
                      <Sun style={{ width: '20px', height: '20px' }} />
                    </IconButton>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Typography sx={{ p: 2 }}> ON/OFF : {lineInfo.onTime} / {lineInfo.offTime} </Typography>
                    </Popover>
                  </Box>
                )}
              </PopupState>
            }
          </Hidden>
        </Box>}
        
      {/* 시설이 하나만 존재하고 onTime설정이 안되어있을 때  */}
      {!lineInfo.onTime && facilityList && facilityList.length <= 1 &&
        <Box className={styles.dashBoardLineNameBoxJustify} sx={{ alignItems: "center" }}>
          <Typography sx={{ color: "#FFFFFF", fontSize: '14px' }}>{lineInfo?.lineName.length > 12 ? `${lineInfo.lineName.substring(0, 12)}...` : lineInfo?.lineName}</Typography>

          <ButtonBlue
            sx={{
              height: 30,
              backgroundColor: '#47BD77',
              marginLeft: 'auto',
              "&:hover": { backgroundColor: "#318252" }
            }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              console.log(`정보 수정`)
              setSelectedLine(lineInfo)
              setShowLineDetailUpdatePopup(true);
            }}
          >
            <Typography sx={{ fontSize: 12 }}>ON/OFF 시간 설정</Typography>
          </ButtonBlue>

          {/* 시설이 존재하고 OnTime이 있는 라인만 ON/OFF Time이 보임. */}
          <Hidden lgDown>
            {lineInfo.onTime && facilityList && <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => ( // ON/OFF 시간 보여줌. => 해 모양 아이콘
                <Box>
                  <IconButton sx={{ position: 'relative', color: '#FFFFFF' }}{...bindTrigger(popupState)}>
                    <Sun style={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Typography sx={{ p: 2 }}> ON/OFF : {lineInfo.onTime} / {lineInfo.offTime} </Typography>
                  </Popover>
                </Box>
              )}
            </PopupState>}
          </Hidden>
        </Box>}
      {/* 시설이 여러개 존재하지만 onTime 정보가 없을 때*/}
      {facilityList && facilityList.length > 1 && !lineInfo.onTime &&
        <Box className={styles.dashBoardLineNameBoxJustify} sx={{ alignItems: "center" }}>
          <Typography sx={{ color: "#FFFFFF", fontSize: '14px' }}>{lineInfo.lineName}</Typography>

          <ButtonBlue
            sx={{
              height: 30,
              backgroundColor: '#47BD77',
              marginLeft: 'auto',
              "&:hover": { backgroundColor: "#318252" }
            }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              console.log(`정보 수정`)
              setSelectedLine(lineInfo)
              setShowLineDetailUpdatePopup(true);
            }}
          >
            <Typography sx={{ fontSize: 12 }}>ON/OFF 시간 설정</Typography>
          </ButtonBlue>
        </Box>}
      <Divider />

      <CardContent
        sx={{
          backgroundColor: "#E0E1DD",
          color: "white",
          height: facilitys ? '100%' : '210px',
          pb: 0,
          mt: '-5px',
          mb: '-12px'
        }}
      >
        <Grid
          container
          spacing={2}
        >
          {facilityList?.length >= 1 ?
            facilityList?.map((item: any, index) => {
              return (
                <>
                  <Grid
                    item
                    lg={facilitys}
                    sm={facilitys}
                    xl={facilitys}
                    xs={isMobile ? facilitysMobile : facilitys}
                    sx={{ margin: isMobile ? 'auto' : 'none' }}
                  >
                    <DashFacility
                      onTime={lineInfo.onTime}
                      offTime={lineInfo.offTime}
                      facInfo={item}
                      curFac={facilityStore.facilityCurrent}
                      lineInfo={lineInfo}
                      onFacilityChartOpen={handleShowDashFacilityChart}
                    ></DashFacility>
                  </Grid>
                </>
              )
            })
            :
            <Grid
              item
              lg={facilitys}
              sm={facilitys}
              xl={facilitys}
              xs={isMobile ? facilitysMobile : facilitys}
              sx={{ margin: isMobile ? 'auto' : 'none' }}
            >
              <Box
                sx={{ position: 'relative', top: 85, left: 34 }}
              >
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                  sx={{
                    flex: '3',
                  }}
                >
                  시설이 없습니다.
                </Typography>
              </Box>
            </Grid>
          }
        </Grid>
      </CardContent>
			<LineDetailUpdatePopup
				open={showLineDetailUpdatePopup}
				lineInfo={selectedLine}
				onClose={() => handleLineDetailUpdatePopupClose()}
        timeSet={true}></LineDetailUpdatePopup>
    </Card>
  );
});

export default DashLine;
