import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
} from '@mui/material';
import { IFacInfo } from '../../model';
import { useStores } from '../../state';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import NameValue from '../atom/NameValue';
import { TitleinTab } from '../atom/DefineStyled';

interface StyledNameValueProps {
  name: string;
  value :string;
}
function StyledNameValue({name, value}:StyledNameValueProps) {
  return (
    <>
      <NameValue
        name={name}
        value={value}
        nameWidth={90}
        valueWidth={200}
      />
      <Box sx={{
        height: 10,
      }} />
    </>
  );
}

type FacInfoProps = {
  facId : string;
  facInfo: any;
  uplinkInfo?: any;
  facs ?: IFacInfo[];
};
const FacInfo = observer(({ facId, facInfo, uplinkInfo, facs }: FacInfoProps) => {
  const { t } = useTranslation()

  console.log(`FacInfo :facId : ${facId}`)
  console.log(`FacInfo :facInfo : ${JSON.stringify(facInfo)}`)

  return (
    <div>
      <Box
        sx={{
          mb: 3,
          borderBottom: 1,
          borderColor: 'divider',
          width: 310
        }}
      >
        <TitleinTab variant="h4">
          시설
        </TitleinTab>
      </Box>
      <StyledNameValue
        name={t('global.status')}
        value={facInfo?.status}
      />
      {facInfo ?
        <StyledNameValue
          name={t('global.latitude')}
          value={facInfo.latitude != null ? facInfo.latitude?.substr(0, 2) + '.' + facInfo.latitude?.substr(2) : ''}
        />
        :
        <StyledNameValue
          name={t('global.latitude')}
          value={''}
        />
      }
      {facInfo ?
        <StyledNameValue
          name={t('global.longtude')}
          value={facInfo.latitude != null ? facInfo.longitude?.substr(0, 3) + '.' + facInfo.longitude?.substr(3) : ''}
        />
        :
        <StyledNameValue
          name={t('global.longtude')}
          value={''}
        />
      }
      <StyledNameValue
        name={t('global.address')}
        value={facInfo?.fullAddr}
      />
      <Box sx={{ mb: 3 }} />
      <Box
        sx={{
          mb: 3,
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <TitleinTab variant="h4">
          {t('global.device')}
        </TitleinTab>
      </Box>
      <StyledNameValue
        name={t('global.gwMac')}

        value={facInfo?.gwSerialNo}
      />

      <StyledNameValue
        name={t('global.receiveTime')}
        value={facInfo?.devTimeUpdate}
      />
      <StyledNameValue
        name={t('global.inputVolt')}
        value={facInfo?.locCode}
      />

    </div>
  );
});

export default FacInfo;
