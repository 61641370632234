// 계정 관리
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@mui/material';
import NameOverValueRem from 'components/atom/NameOverValue';
import UserUpdatePopup from 'components/popup/UserUpdatePopup';
import UserUpdatePwdPopup from 'components/popup/UserUpdatePwdPopup';
import userStore from 'state/user';
import UserDeletePopup from 'components/popup/UserDeletePopup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import theme from 'theme';

type MyAccountProfileProps = {
  userInfo: any;
  doit?: string;
};
const MyAccountProfile = ({ userInfo, doit }: MyAccountProfileProps) => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    state: '',
    country: ''
  });
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdatePwdDialog, setOpenUpdatePwdDialog] = useState(false);

  console.log(`MyAccountProfile userInfo = ${JSON.stringify(userInfo)}`)

  // 수정, 암호변경, 삭제 팝업 오픈
  useEffect(() => {
    console.log(`MyAccountProfile userInfo = ${JSON.stringify(userInfo)}`)
    if (doit.includes("modify")) {
      setOpenUpdateDialog(true);
    }
    else if (doit.includes("changePW")) {
      setOpenUpdatePwdDialog(true);
    }
    else if (doit.includes("leave")) {
      setOpenDeleteDialog(true);
    }
  }, [doit]);

  //수정 팝업 닫음
  const handleUpdateDialogClose = (update: boolean) => {
    if (update) {
      userStore.getUserInfo(userStore.getId());
    }
    setOpenUpdateDialog(false);
  };
  //삭제 팝업 닫음
  const handleDeleteDialogClose = (update: boolean) => {
    setOpenDeleteDialog(false);
  };
  //암호변경 팝업 닫음
  const handleUpdatePwdDialogClose = () => {
    setOpenUpdatePwdDialog(false);
  };

  if (userInfo == null)
    return null;

  return (
    <form
      autoComplete="off"
      noValidate
    >
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableRow>
              <TableCell sx={{ padding: 1, borderTop: 2, borderTopColor: '#BFBFBF', borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
                <NameOverValueRem
                  name='아이디'
                  value={userInfo.userId}
                ></NameOverValueRem>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
                <NameOverValueRem
                  name='이름&nbsp;&nbsp;&nbsp;&nbsp;' //&nbsp; = 공백문자
                  value={userInfo.userName}
                ></NameOverValueRem>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ padding: 1, borderBottom: '1px dotted #bdbdbd', borderBottomColor: '#BFBFBF' }}>
                <NameOverValueRem
                  name='연락처'
                  value={userInfo.telNo}
                ></NameOverValueRem>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ padding: 1, borderBottom: 2, borderBottomColor: '#BFBFBF' }}>
                <NameOverValueRem
                  name='이메일'
                  value={userInfo.email}
                ></NameOverValueRem>
              </TableCell>
            </TableRow>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Divider />
      {/* 수정 */}
      <UserUpdatePopup
        userInfo={userInfo}
        open={openUpdateDialog}
        description="hello"
        onClose={handleUpdateDialogClose}
      >
      </UserUpdatePopup>
      {/* 암호변경 */}
      <UserUpdatePwdPopup
        userInfo={userInfo}
        open={openUpdatePwdDialog}
        description="hello"
        onClose={handleUpdatePwdDialogClose}
      >
      </UserUpdatePwdPopup>
      {/* 삭제  */}
      <UserDeletePopup
        userInfo={userInfo}
        open={openDeleteDialog}
        description="hello"
        onClose={handleDeleteDialogClose}
      >
      </UserDeletePopup>
    </form>
  );
};

export default MyAccountProfile;
