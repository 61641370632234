//시설운영 - 그룹 (타이틀 및 borderBox)

import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStores } from 'state';
import LampAutoOnOffDialog from 'components/atom/LampAutoOnOffDialog';
import { observer } from 'mobx-react';
import userStore from 'state/user';
import styles from 'components/facilityGroup/style.module.css'

const ONOFF = [
  'OFF',
  'ON',
];

type FacilityGroupProps = {
  groupCount: string;
  onCommand?: (command: string, name?: string, value?: string) => void;
};
const FacilityGroupToolbar = observer(({ groupCount, onCommand }: FacilityGroupProps) => {
  const { clientStore } = useStores();
  const [onTime, setOnTime] = React.useState<string>("");
  const [offTime, setOffTime] = React.useState<string>("");
  const [onOffEnable, setOnOffEnable] = useState(1);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const theme = useTheme();

  const { t } = useTranslation()

  useEffect(() => {
    if (clientStore.autoOnOff) {
      console.log(`autoOnOff : ${JSON.stringify(clientStore.autoOnOff)}`)
      setOnTime(clientStore.autoOnOff.onoffOntime);
      setOffTime(clientStore.autoOnOff.onoffOfftime);
      setOnOffEnable(clientStore.autoOnOff.onoffEnable);
    }

  }, [clientStore.autoOnOff]);


  const handleTimeClose = () => {
    console.log(`handleTimeClose`)
    clientStore.getAutoOnOff();
    setShowTimePicker(false);
  }

  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };

  return (
    <><Box>
      <Typography
        className={styles.pageDirection}
        gutterBottom>
        시설 운영 {'>'} 그룹
      </Typography>
      <Typography //타이틀 글자 조정
        className={styles.pageTitle}
        gutterBottom>
        그룹
      </Typography>

      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
        sx={{
          borderRadius: 3,
          borderColor: '#1C75BC',
          marginBottom: '2%',
          margin: 'auto'
        }}
        {...defaultProps}
      >

        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1,
            textAlign: 'left',
            float: 'right',
            top: 15
          }}
        >

          <Box
            sx={{
              display: 'flex',
              alignItems: "center",
              margin: 'auto',
              position: 'relative',
              right: '10px'
            }}
          >
            {userStore.siteLevel !== '9300' &&
              <Button
                className={styles.blueButton}
                onClick={() => {
                  console.log(`추가`);
                  onCommand('add');
                }}
              >
                그룹추가
              </Button>
            }
          </Box>
        </Box>
      </Box>
    </Box>
      <LampAutoOnOffDialog
        enable={onOffEnable}
        onTime={onTime}
        offTime={offTime}
        open={showTimePicker}
        onClose={handleTimeClose} /></>

  );
});

export default FacilityGroupToolbar;
