// 사이트 관리 - 사이트(테이블)

import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { useStores } from 'state';
import { ISiteDetail } from 'model';
import styles from 'components/facilityGroup/style.module.css'
import { isMobile } from 'react-device-detect';

type SiteListResultsProps = {
  siteCount: string;
  sites: ISiteDetail[];
  doit: string;
};
const SiteListResults = ({ siteCount, sites, doit }: SiteListResultsProps) => {
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { userStore } = useStores();
  const theme = useTheme();

  const getMyCurSites = (_sites: ISiteDetail[]) => {
    const tmpSites: ISiteDetail[] = _sites.filter(site => site.enable == 'Y');
    const myCurSite: ISiteDetail[] = _sites.filter(site => site.enable == 'Y' && site.siteId == userStore.siteId);
    return myCurSite;
  }


  const selectedSite = getMyCurSites(sites)[0];

  useEffect(() => {
    if (doit.includes("manageUser")) {

      const selectedSiteUser = selectedSite.userIdManager.find(elem => elem.userId == userStore.getId());
      console.log(`manageUser selectedSite ${JSON.stringify(selectedSite)}`);
      console.log(`manageUser ${JSON.stringify(
        {
          state:
          {
            siteId: selectedSite.siteId,
            siteName: selectedSite.siteName,
            siteLevel: selectedSiteUser?.siteLevel,
          }
        })}`)
      navigate('/slager/siteuserform', {
        state:
        {
          siteId: selectedSite.siteId,
          siteName: selectedSite.siteName,
          siteLevel: selectedSiteUser?.siteLevel,
        }
      });
    }
    else if (doit.includes("modify")) {

      navigate('/slager/siteform', {
        state:
        {
          siteId: selectedSite.siteId,
          siteName: selectedSite.siteName,
          description: selectedSite.description,
        }
      });
    }

  }, [doit]);

  //하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange`)
    setPage(newPage);
  };

  return (
    <div>
      <PerfectScrollbar>
        <Table
          sx={{
            width: '100%',
          }}
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeadEnd}>
                이름
              </TableCell>
              <TableCell className={styles.tableHead}>
                설명
              </TableCell>
              <TableCell className={styles.tableHead}>
                사용자
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {sites.filter(site => site.enable=='Y').slice((page-1)*limit, limit + (page -1)*limit).map((site, index) => { */}
            {getMyCurSites(sites).slice((page - 1) * limit, limit + (page - 1) * limit).map((site, index) => {
              return (
                <TableRow
                  hover
                  key={site.siteId}
                  selected={selectedSiteIds.indexOf(site.siteId) !== -1}
                >
                  <TableCell className={styles.tableBodyEnd} sx={{fontSize: isMobile && 12}}>
                    {site.siteName}
                  </TableCell>
                  <TableCell className={styles.tableBody} sx={{fontSize: isMobile && 12}}>
                    {site.description}
                  </TableCell>
                  <TableCell className={styles.tableBody}>
                    {!isMobile &&
                      <Stack direction="row" spacing={1}>
                        {site.userIdManager.map((user, index1) => {
                          return (
                            <Chip label={user.userName}
                              style={{ fontSize: "0.7rem" }}
                            />
                          )
                        })
                        }
                      </Stack>
                    }
                    {isMobile &&
                      <Stack spacing={1}>
                        {site.userIdManager.map((user, index1) => {
                          return (
                            <Chip label={user.userName}
                              style={{ fontSize: "0.7rem" }}
                            />
                          )
                        })
                        }
                      </Stack>
                    }
                  </TableCell>
                  {/*<TableCell>
                      {site.hpNo}
                        </TableCell>*/}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Box sx={{ display: !isMobile && 'flex', }}>
          <Typography className={styles.bottomCount}>
            전체 개수 : {1} EA
          </Typography>

          <Pagination
            color="primary"
            page={page}
            count={Math.ceil(sites.filter(site => site.enable == 'Y').length / 10)}
            onChange={handlePageChange}
            sx={{
              width: '100%',
              display: 'flex',
              height: '50px',
              justifyContent: 'center',
              position: 'relative',
              right: '7%',
              [theme.breakpoints.down('sm')]: {
                width: window.innerWidth,
              },
            }}
          />
        </Box>
      </PerfectScrollbar>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </div>
  );
};

export default SiteListResults;
