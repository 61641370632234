//NameOverValue -> rem으로 조정한것.
import * as React from 'react';
import {
  Box,
  Typography
} from '@mui/material';

type NameOverValueRemProps = {
  name : string;
  value : string;
  space? : string;
  nameWidth? : string;
  valueWidth? : string;
};
const NameOverValueRem = ({ name, value, nameWidth = '7rem', valueWidth = '15rem', space = '2rem' }:NameOverValueRemProps) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                    
                }}
            >
                <Typography
                    sx={{
                        m:'0.6rem',
                        p:'0.4rem',
                    }}
                    color="textPrimary"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space, backgroundColor:"#00ff00",
                 }} />
                <Typography
                    sx={{
                        m:'0.4rem',
                        p:'0.3rem',
                        pl:'0.5rem',
                        border:1 ,
                        borderRadius:1.5,
                        borderColor:'#BFBFBF',
                        backgroundColor:"#FFFFFF",
                        width: '20rem',
                        height: '2rem',
                        lineHeight:'2rem'
                    }}
                    color="textPrimary"
                >
                    {value}
                </Typography>
            </Box>
        </>
    )
};

export default NameOverValueRem;
