import { observable, computed } from 'mobx';
import { api, q } from '../api';
import { IUserDetail } from '../model/user';
import { IAutoOnOff, ILampFacInfo } from '../model/client';
import { IFacInfo } from '../model';

export interface IClientStore {
	test: string;
	clientList:any[];
	usedList:any[];
	unUsedList:any[];
	facInfo : IFacInfo;
	status : any[];
	autoOnOff : IAutoOnOff;

	addFac:(fac : ILampFacInfo) => void;
	getLampList:(perPage:string, page:string) => any;
	// getLampListWithParm:(perPage:string, page:string, facNo?string) => any;
	getLampListWithParm:(param:any) => any;
	getUsedLamp:(used: boolean) => any;
	update:(facId:string, devId:string, mtCompanyId:string, labelNo:string, lat?:string, lnt?:string) => void;
	updateOnOff:(facId:string, onOff:string) => void;
	getDetail:(facId : string) => any;
	getDevOption:() => any;
	getAutoOnOff:() => any;
	updateAutoOnOff:(onOffEnable:number, onTime:string, offTime:string) => any;
}
export const clientStore = observable<IClientStore>({
	test: '',
	clientList:[],
	usedList:[],
	unUsedList:[],
	facInfo: null,
	status: [],
	autoOnOff: null,

	async addFac(fac : ILampFacInfo) {
		console.log(`addFac ${JSON.stringify(fac)}`)
		// await api.post(`/api/lamp/regFacInfo${q(fac)}`, {});
		// await api.post(`/api/lamp/regFacInfo`, fac);
	},
	async getLampList(perPage:string, page:string) {
		 const ret = await api.get(`/api/lamp/tblList${q({
			 perPage,
			 page,
		 })}`);
		console.log(`getLampList ${JSON.stringify(ret)}`)
		this.clientList = ret.data.contents;
	},
	// async getLampListWithParm(perPage:string, page:string, facNo?:string) {
	async getLampListWithParm(param) {
		const ret = await api.get(`/api/lamp/tblList${q(param)}`);
		console.log(`getLampList ${JSON.stringify(ret)}`)
		this.clientList = ret.data.contents;
	},
	async getUsedLamp(used: boolean) {
		let cat = 0;
		if (used) {
			cat = 1;
		}
		const ret = await api.get('/api/lamp/usedlamp?searchCategory=' + cat);
		console.log(`getUsedLamp ${JSON.stringify(ret)}`)
		if (used) {
			this.usedList = ret.data.contents;
		}
		else {
			this.unUsedList = ret.data.contents;
		}
	},
	async getDetail(facId: string) {
		const ret = await api.get('/api/lamp/listDetail?facId=' + facId);
		console.log(`getDetail ${JSON.stringify(ret)}`)
		this.facInfo = ret.fac;
		this.status = ret.status;
	},
	async update(facId:string, devId:string, mtCompanyId:string, labelNo:string, lat?:string, lng?: string) {
		console.log(`update ${(facId)}`)
		try {
			await api.post(`/api/lamp/update`,
				{
					facId: facId,
					devId: devId,
					mtCompanyId: mtCompanyId,
					labelNo: labelNo,
					lat:lat,
					lng:lng,
				});
		} catch (error) {
			console.log(`update error : ${JSON.stringify(error)}`)
		}
	},
	async updateOnOff(facId:string, onOff:string) {
		console.log(`updateonOff ${(facId)}`)
		try {
			await api.post(`/api/lamp/updateOnOff`,
				{
					facId: facId,
					devStatusValue: onOff
				});
		} catch (error) {
			console.log(`updateonOff error : ${JSON.stringify(error)}`)
		}
	},
	async getDevOption() {
		try {
			const ret = await api.get('/ajaxGetDevOption.do');
			console.log(`getDevOption ${JSON.stringify(ret)}`)
		} catch (error) {
			console.log(`getDevOption error : ${JSON.stringify(error)}`)
		}
	},
	async getAutoOnOff() {
		try {
			const ret = await api.get('/api/lamp/auto-onoff-info');
			console.log(`getAutoOnOff ${JSON.stringify(ret)}`)
			this.autoOnOff = ret.data;
		} catch (error) {
			console.log(`getAutoOnOff error : ${JSON.stringify(error)}`)
		}
	},
	async updateAutoOnOff(onOffEnable:number, onTime:string, offTime:string) {
		console.log(`updateAutoOnOff `)
		try {
			await api.post(`/api/lamp/auto-onoff-info`,
				{
					onoff: onOffEnable,
					offTime: offTime,
					onTime: onTime,
				});
		} catch (error) {
			console.log(`update error : ${JSON.stringify(error)}`)
		}
	},
})

export default clientStore;
