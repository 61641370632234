import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  defaults
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  Button,
  useTheme
} from '@mui/material';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PhoneIcon from '@mui/icons-material/Phone';
import TabletIcon from '@mui/icons-material/Tablet';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

ChartJS.register(ArcElement);
const TrafficByDevice = (props) => {
  const theme = useTheme();
//  const [data, setData] = useState([]);
  const [desk, setDesk] = useState(0);
  const [tablet, setTablet] = useState(0);
  const [mobile, setMobile] = useState(0);

  const initData = () => {
    setData(
      {
        datasets: [
          {
            data: [65, 15, 22],
            backgroundColor: [
              colors.indigo[500],
              colors.red[600],
              colors.orange[600]
            ],
            borderWidth: 8,
            borderColor: colors.common.white,
            hoverBorderColor: colors.common.white
          }
        ],
        labels: ['Desktop', 'Tablet', 'Mobile']
      }
    )
  }

  //useEffect(() => {
  //  initData();
  //  
  //},[]);

   const data = {
     datasets: [
       {
         data: [63, 15, 22],
         backgroundColor: [
           colors.indigo[500],
           colors.red[600],
           colors.orange[600]
         ],
         borderWidth: 8,
         borderColor: colors.common.white,
         hoverBorderColor: colors.common.white
       }
     ],
     labels: ['Desktop', 'Tablet', 'Mobile']
   };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Desktop',
      value: desk,
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'Tablet',
      value: tablet,
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: 'Mobile',
      value: mobile,
      icon: PhoneIcon,
      color: colors.orange[600]
    }
  ];

  //   datasets: [
  //     {
  //       data: [63, 15, 22],
  //       backgroundColor: [
  //         colors.indigo[500],
  //         colors.red[600],
  //         colors.orange[600]
  //       ],
  //       borderWidth: 8,
  //       borderColor: colors.common.white,
  //       hoverBorderColor: colors.common.white
  //     }
  //   ],
  //   labels: ['Desktop', 'Tablet', 'Mobile']
  // };
  const handleDataSet = () => {
    console.log(`handleDataSet`)
    console.log(`handleDataSet`)
    console.log(`handleDataSet`)
    console.log(`handleDataSet`)
    console.log(`handleDataSet`)
    console.log(`handleDataSet`)

    const min = 1;
    const max = 70;
    const rand1 = Math.ceil(min + Math.random() * (max - min));
    const rand2 = Math.ceil(min + Math.random() * (max - min));
    const rand3 = 100 + (rand1 + rand2)
    setDesk(rand1);
    setTablet(rand2);
    setMobile(rand3);
    console.log(`CYI ${rand1},${rand2}, ${rand3}`)
    console.log(`CYI data = ${JSON.stringify(data)}`);
    console.log(`CYI data = ${data.datasets[0].data}`);
    // setData(
    //   {
    //     ...data, datasets:[{data:[rand1, rand2, rand3]}],
    //   }
    // )
  }

  return (
    <Card {...props}>
      <CardHeader title="Traffic by Device" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            height: 10,
            position: 'relative'
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="contained"
            onClick={handleDataSet}
          >
            change value
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;
