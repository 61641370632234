//map 검색 바
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import ExitIcon from '@mui/icons-material/Close';
import { useMediaQuery } from 'react-responsive';
import { IFacInfo } from '../../model';
import ErrorListResults from './ErrorListResults';
import { useTranslation } from 'react-i18next';
import { IFacilityDetailInfo } from 'model/facility';

type SearchResultsProps = {
	type:string;
	facs:IFacilityDetailInfo[];
	onClick:() => void;
	onClickShowFacInfo?:(facInfo:IFacilityDetailInfo) => void;
};

const SearchResults = ({
	type,
	facs,
	onClick,
	onClickShowFacInfo,
}:SearchResultsProps) => {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	const theme = useTheme();
	const { t } = useTranslation()

	console.log(`SearchResults : type ${type}`)
	console.log(`SearchResults :facs length ${facs?.length}`)

  useEffect(() => {
  },[]);
	return (
		<Box
			sx={{
				display: 'flex',
				width: isMobile ? '95vw' : '800px',
				pl:1,
				pr:3,
				mr:3,
			}}
		>
			<Card >
				<CardContent>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					borderBottom: 1,
					borderColor: 'divider'
				}}>
				<Box
					sx={{
						flex: 1,
					}}>
				</Box>
				<Box
					sx={{
						flex: 5,
						display: 'flex',
						justifyContent: "center"
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						variant="h4"
					>
						{type=='error'?t('global.failList'):t('global.searchList')}
						({facs?.length})
					</Typography>
				</Box>
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						justifyContent: "flex-end"
					}}
				>
					<IconButton
						color="inherit" size="small">
						<ExitIcon
							onClick={onClick}
						/>
					</IconButton>
				</Box>
			</Box>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
						}}
					>
						<ErrorListResults isMobile={isMobile} facInfos={facs} onClickShowFacInfo={onClickShowFacInfo}></ErrorListResults>
					</Box>
				</CardContent>
			</Card>
		</Box>
		
		);
};

export default SearchResults;