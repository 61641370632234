// 시설 운영 - 그룹설정 - 시설 관리
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import ExitIcon from '@mui/icons-material/Close';
import { useStores } from '../../state';
import theme from 'theme';
import FacilitysInLine from './FacilitysInLine';
import FacilitysOutLine from './FacilitysOutLine';
import { ILineSet } from 'model';
import ManualOnOff from 'components/atom/ManualOnOff';
import lineStore from 'state/line';
import { IFacilityInfo } from 'model/facility';
import dashboardStore from 'state/dashboard';
import { IGetSiteSunsetDetail } from 'model/sunset';

import { observer } from 'mobx-react';
import FacDetailInfoDialog from 'components/atom/FacDetailInfoDialog';
import styles from 'components/facilityGroup/style.module.css'
import { isMobile } from 'react-device-detect';

const OnOffType = [
  { value: '0', mode: 'N', label: '선택' },
  { value: '1', mode: 'S', label: '일출일몰시간' },
  { value: '2', mode: 'O', label: '직접입력' },
  { value: '3', mode: 'M', label: '사용안함' },
];

interface FacilityLineModifyFormImpProps {
  lineInfo: any,
  lId?: string,
  onClose?: () => void;
  open?: boolean;
}
const FacilityLineModifyFormImp = observer(({ lId, lineInfo, onClose, open }: FacilityLineModifyFormImpProps) => {
  const { facilityStore, userStore } = useStores();
  const [inFacility, setInFacility] = useState<IFacilityInfo[]>([]);
  const [outFacility, setOutFacility] = useState<IFacilityInfo[]>([]);
  const [facId, setFacId] = useState('');
  const [values, setValues] = useState<ILineSet>(
    {
      siteId: '',
      siteName: '',
      groupId: '',
      groupName: '',
      lineId: '',
      lineName: '',
      description: '',
      chunkId: '',
      chunkName: '',
      operationMode: 'N',
      offTime: '',
      onTime: '',
    });

  const [showFacDetailInfoDialog, setshowFacDetailInfoDialog] = useState(false);

  //facility정보 가져옴
  const getFacility = async () => {
    await facilityStore.getFacilityInfoList(userStore.siteId, lineInfo.groupId, lineInfo.lineId, null);
    {
      console.log(`facilityLineFormImp getFacility facilityStore.inFacilityList ${JSON.stringify(facilityStore.inFacilityList)}`);
      setInFacility(facilityStore.inFacilityList);
    }
  }

  const getFacilityAllOutofLine = async () => {
    await facilityStore.getFacilityInfoListOutLine(userStore.siteId, null, null, null);
    {
      console.log(`facilityLineFormImp getFacility facilityStore.outFacilityList ${JSON.stringify(facilityStore.outFacilityList)}`);
      setOutFacility(facilityStore.outFacilityList?.filter(facility => facility.lineId != lineInfo.lineId));
    }
  }


  useEffect(() => {
    console.log(`facilityLineFormImp inFacility ${JSON.stringify(inFacility)}`);
  }, [inFacility]);

  useEffect(() => {
    console.log(`facilityLineFormImp outFacility ${JSON.stringify(outFacility)}`);
  }, [outFacility]);

  useEffect(() => {
    console.log(`facilityLineFormImp useEffect values ${JSON.stringify(values)}`);
  }, [values]);

  useEffect(() => {
    console.log(`facilityLineFormImp lineInfo ${JSON.stringify(lineInfo)}`);
    setValues(
      lineInfo
    );

    // modify line
    if (lineInfo.lineId) {
      getFacility();
    }

    return () => {
    }
  }, []);

  useEffect(() => {
    if (lineInfo.lineId) {
      getFacilityAllOutofLine();
    }
  }, []);
  //등록
  const onAdd = async (_facId: string) => {
    await facilityStore.setLineForFacility(
      userStore.siteId,
      lineInfo.groupId,
      lineInfo.lineId,
      _facId);
    getFacility();
    getFacilityAllOutofLine();
  };

  //해제
  const handleDelete = async (_facId: string) => {
    console.log(`handleDelete aaaa `)
    await facilityStore.delLineForFacility(
      userStore.siteId,
      lineInfo.groupId,
      lineInfo.lineId,
      _facId);
    getFacility();
    getFacilityAllOutofLine();
  };
//미등록시설 hoevr
  const handleSelected = async (_facId: string) => {
    console.log(`handleSelected `)
    setFacId(_facId)
  };
  //시설상세 팝업 열림.
  const handleDetailDialogOpen = async (_facId: string) => {
    setFacId(_facId)
    setshowFacDetailInfoDialog(true);
  };
  // 시설상세 팝업 닫음.
  const handleClose = async () => {
    setshowFacDetailInfoDialog(false);
    await lineStore.getLineList(userStore.siteId, facilityStore.facilityDetailInfo.groupId, userStore.getId(), "10", "1");
  };

  return (
    <>
      <PerfectScrollbar>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >

              <Typography
                sx={{
                  fontSize: 23,
                  fontWeight: 600,
                  color: '#0C3149',
                  display: 'flex',
                  position: 'relative',
                  padding: 1.5
                }}
              >
                {!isMobile ? (<span>시설 관리 ({values.lineName})</span>) : (<span>시설 관리 <br /> ({values.lineName})</span>)}
              </Typography>


              <Box
                sx={{
                  flex: 1,
                }}>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <Box sx={{ ml: 2 }}> </Box>
                <Box sx={{ ml: 2 }}> </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
            </Box>
            <Divider />
            <Box sx={{ mt: 1 }}> </Box>

            <Box sx={{ mt: 1 }}> </Box>


            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <FacilitysOutLine
                  title="미등록 시설"
                  facilityList={outFacility}
                  onAdd={onAdd}
                  onSelected={handleSelected}
                />
              </Grid>
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <FacilitysInLine
                  title="등록 시설"
                  facilityList={inFacility}
                  onDelete={handleDelete}
                  onSelected={handleDetailDialogOpen}
                />
              </Grid>
            </Grid>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                className={styles.Button}
                color="primary"
                variant="contained"
                onClick={onClose}
              >
                닫기
              </Button>
            </DialogActions>
          </CardContent>
        </Card>
        <FacDetailInfoDialog
          facId={facId}
          onClose={handleClose}
          show={showFacDetailInfoDialog}
        ></FacDetailInfoDialog>
      </PerfectScrollbar>
    </>
  );

});

export default FacilityLineModifyFormImp;
