// 사이트 입력 처리상태 등 상태 select
import * as React from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { AlignCenter, AlignJustify } from 'react-feather';
import { VerticalAlignCenter } from '@mui/icons-material';
import { useEffect } from 'react';


type NameSelectProps = {
  name : string;
  defaultValue? : string;
  object : any[];
  space? : number;
  nameWidth? : number;
  valueWidth? : number;
  backgroundColor? : string;
  onSelect:(name:string, value:string)=>void;
};
const NameSelect = ({ name, object, defaultValue = '0', nameWidth = 60, valueWidth = 300, space = 10, backgroundColor = "#FFFFFF", onSelect }:NameSelectProps) => {
        console.log(`NameSelect ${name}:${defaultValue}:${JSON.stringify(object)}`)
  const [select, setSelect] = React.useState<string>('');

  useEffect(() => {
      if (defaultValue) {
          console.log(`NameSelect values ${name}:${defaultValue}`)
          setSelect(defaultValue);
      }
      else {
          console.log(`NameSelect values else ${name}:${defaultValue}`)
      }
  },[defaultValue]);

  const handleSelect = (event: SelectChangeEvent) => {
      console.log(`cyi nameSelect ${event.target.value}`)
    setSelect(event.target.value as string);
    onSelect(name, event.target.value as string);
  };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Typography
                    sx={{
                        width: nameWidth,
                        m:'1rem',
                        p:'0.4rem'
                    }}
                    color="textPrimary"
                    variant="h5"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space }} />
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select}
                    sx={{
                        m:'0.4rem',
                        p:'0.3rem',
                        borderColor:'#BFBFBF',
                        backgroundColor:{backgroundColor},
                        width: '15rem',
                        height: '3rem'
                    }}
                    onChange={handleSelect}
                    defaultValue={defaultValue}
                >
                    {object.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
};

export default NameSelect;
