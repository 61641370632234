// 시설운영 -그룹 -그룹추가 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import FacilityGroupFormImp from 'components/facilityGroup/FacilityGroupFormImp';

const FacilityGroupForm = () => {
  console.log('FacilityGroupForm')
  return(
  <>
    <Helmet>
      <title>FacilityGroupForm</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <FacilityGroupFormImp></FacilityGroupFormImp>
      </Container>
    </Box>
  </>
);
    };

export default FacilityGroupForm;
