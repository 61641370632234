//시설운영 - 중계기 (테이블)
import * as React from 'react';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Pagination,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import getInitials from '../../utils/getInitials';
import { useNavigate } from 'react-router';
import { IFacInfo } from '../../model';
import { styled } from '@mui/material/styles';
import { ScreenSearchDesktopTwoTone, StayPrimaryLandscapeSharp } from '@mui/icons-material';
import { TitleinTab } from 'components/atom/DefineStyled';
import NameValue from 'components/atom/NameValue';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { IFacilityDetailInfo, IFacilityGatewayInfo, IFacilityInfo } from 'model/facility';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';
import { ButtonBlue } from 'components/admin/system/Styled';
import FacGatewayInfoDialog from 'components/atom/FacGatewayInfoDialog';
import styles from 'components/facilityGroup/style.module.css'

type ManageGatewayListResultsProps = {
  gatewayCount: string;
  facs: IFacilityGatewayInfo[];
  doit: string;
};
const ManageGatewayListResults = ({ gatewayCount, facs, doit }: ManageGatewayListResultsProps) => {
  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [selectedFacId, setSelectedFacId] = useState<IFacilityGatewayInfo>();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [showFacInfo, setShowFacInfo] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [facId, setFacId] = useState('');

  //수정
  const handleModify = async (fac: any) => {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!", JSON.stringify(fac))
    navigate('/slager/configfacilitygateway',
      {
        state: {
          siteId: fac.siteId,
          siteName: fac.siteName,
          facId: fac.facId,
          facName: fac.facName,
          facType: fac.facType,
          facDesc: fac.facDesc,
          latitude: fac.latitude,
          longitude: fac.longitude,
          address: fac.address,
          telNo: fac.telNo,
          gwId: fac.gwId,
          serialNo: fac.devSerialNo,
        }
      });
  }

  useEffect(() => {
    if (doit.includes("detail")) {
      console.log(`toggle detail `)
      if (selectedFacIds.length == 0) {
        ToastsStore.warning("시설을 선택해 주세요")
        return;
      }
      console.log(`toggle detail ${selectedFacIds[0]}`)
      setShowFacInfo(true);
    }
    else if (doit.includes("modify")) {
      if (selectedFacIds.length == 0) {
        ToastsStore.warning("시설을 선택해 주세요")
        return;
      }
      const ret = facs.filter(facInfo => {
        if (facInfo.facId.toString() == selectedFacIds[0]) {
          console.log(`in facs filter find ~~~~~~~~~~~~: ${JSON.stringify(facInfo)}`);
        }
        return facInfo.facId.toString() == selectedFacIds[0];
      })
      console.log(`registerGateway for register ret : ${ret.length}, ${ret[0]}`)
      navigate('/slager/configfacilitygateway',
        {
          state: {
            siteId: ret[0].siteId,
            siteName: ret[0].siteName,
            facId: ret[0].facId,
            facName: ret[0].facName,
            facType: ret[0].facType,
            facDesc: ret[0].facDesc,
            latitude: ret[0].latitude,
            longitude: ret[0].longitude,
            address: ret[0].address,
            telNo: ret[0].telNo,
            gwId: ret[0].gwId,
            serialNo: ret[0].devSerialNo,
          }

        });
    }
  }, [doit]);

  //상세 팝업 오픈
  const handleDialogOpen = async (_facId: string) => {
    setFacId(_facId)
    setShowFacInfo(true);
  };
  //상세 팝업 닫기
  const handleDialogClose = async () => {
    console.log(`handleLineDrawerClose in facilitylinelist`)
    setShowFacInfo(false);
  };
  //하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table
            size='small'
          >
            <TableHead>
              <TableRow>
                {
                  !isMobile &&
                  <TableCell className={styles.tableHeadEnd} sx={{width:'4%'}}>
                    No
                  </TableCell>
                }
                <TableCell className={styles.tableHead} >
                  이름
                </TableCell>
                <TableCell className={styles.tableHead}>
                  내용
                </TableCell>
                {
                  !isMobile &&
                  <TableCell className={styles.tableHead}>
                    주소
                  </TableCell>
                }
                {
                  !isMobile &&
                  <TableCell className={styles.tableHead}>
                    설치날짜
                  </TableCell>
                }
                <TableCell className={styles.tableHead} sx={{ width: '12%' }}>
                  기능
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facs?.slice((page - 1) * limit, limit + (page - 1) * limit).map((fac, index) => (
                <TableRow
                  hover
                  key={fac.facId}
                  selected={selectedFacIds.indexOf(fac.facId) !== -1}
                >
                  <TableCell sx={{ textAlign: 'center' }}>
                    {(page - 1) * limit + index + 1}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
                    {fac.facName}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
                    {fac.facDesc}
                  </TableCell>
                  {
                    !isMobile &&
                    <TableCell sx={{ textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
                      {fac.address}
                    </TableCell>
                  }
                  {
                    !isMobile &&
                    <TableCell sx={{ textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
                      {fac.facTimeUpdate}
                    </TableCell>
                  }
                  <TableCell sx={{ textAlign: 'center', borderLeft: 1, borderLeftColor: '#E6E7E8' }}>
                    <ButtonBlue
                      size="small"
                      sx={{
                        "&:hover": { backgroundColor: "#10548A" }
                      }}
                      onClick={() => {
                        console.log("!!!!!!!!!!!!!!!!!!", JSON.stringify(fac))
                        console.log(`상세`)
                        handleDialogOpen(fac.facId);
                        setShowFacInfo(true);
                      }}
                    >
                      상세
                    </ButtonBlue>
                    <ButtonBlue
                      size="small"
                      sx={{
                        "&:hover": { backgroundColor: "#10548A" }
                      }}
                      onClick={() => {
                        console.log(`수정`)
                        handleModify(fac)
                      }}
                    >
                      수정
                    </ButtonBlue>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ display: 'flex', }}>
          <Typography
            className={styles.bottomCount}
          >
            전체 개수 : {gatewayCount} EA
          </Typography>
          <Pagination
            color="primary"
            page={page}
            count={Math.ceil(facs?.length / 10)}
            onChange={handlePageChange}
            size="small"
            sx={{
              width: '100%',
              display: 'flex',
              height: '50px',
              justifyContent: 'center',
              position: 'relative',
              right: '7%',
              [theme.breakpoints.down('sm')]: {
                width: window.innerWidth,
              },
            }}
          />
        </Box>
      </PerfectScrollbar>
      {/* 중계기 상세정보 팝업 */}
      <FacGatewayInfoDialog
        facId={facId}
        show={showFacInfo}
        onShow={() => setShowFacInfo(false)}
        onClose={handleDialogClose}
      ></FacGatewayInfoDialog>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </div>
  );
};

export default ManageGatewayListResults;
