// 시설 모델
export enum FacType { 
	Gateway = '101',
	StreetLamp = '501',
	DistributionBox = '502',
	SecurityLamp = '503',
	Sewage = '504',
	Unknown = '-1',
}

export const Facilitys = [
  { value: FacType.Gateway, label: '게이트웨이' },
  { value: FacType.StreetLamp, label: '가로등' },
  { value: FacType.DistributionBox, label: '분전함' },
  { value: FacType.SecurityLamp, label: '보안등' },
  { value: FacType.Sewage, label: '오수확인' },
  { value: FacType.Unknown, label: '미확인 시설' },
];

export const getFacTypeStr = (value: string) => {
  const ret: any = Facilitys.find(fac => fac.value == value);
  return ret?.label;
}

// 숫자 검사 함수
export const isNumber = (value:any) => {
	return !isNaN(value) && !isNaN(parseFloat(value));
  };

export interface ISetFacilityInfo {
	facId : string;
	facName : string;
	facType : string;
	facDesc : string;
	siteId : string;
	groupId : string;
	lineId : string;
	devId : string;
	latitude: string;
	longitude: string;
	altitude: string;
	address : string;
	telNo : string;
	enable : string;
};

export interface IFacilityInfo {
	siteId : string;
	siteName : string;
	facId : string;
	groupId : string;
	groupName : string;
	lineId : string;
	lineName : string;
	devId : string;
	facName : string;
	facType : string;
	facDesc : string;
	facEnable : string;
	latitude: string;
	longitude: string;
	altitude: string;
	address : string;
	telNo : string;
	facTimeUpdate : string;
	devSerialNo : string;

	vout1 : string;
	cur1 : string;
	vout2 : string;
	cur2 : string;
};

export interface IFacilityDetailInfo {
	siteId : string; //사이트 id
	groupId : string; // 그룹 id
	groupName : string; // 그룹 이름
	lineId : string; // 라인 id
	lineName : string; // 라인 이름
	facId : string; // 시설id
	facName : string; // 시설 이름
	facType : string; // 시설 종류
	facDesc : string; // 시설 설명
	devId : string; // 기기 id
	latitude: string; // 위도
	longitude: string; // 경도
	altitude: string; // 고도
	facEnable : string;
	address : string; // 주소
	telNo : string; // 연락처
	facTimeUpdate : string; // 시설 업데이트 시간

	serialNo : string; // 시리얼번호
	devSerialNo : string; // 기기 시리얼 번호
	devType : string; // 기기 타입
	protoVer : string;
	loraFreq : string;
	loraPanId : string;
	linkDevId : string;
	linkGwId : string;
	ipAddr : string;
	portNo : string; // 포트번호
	devEnable : string;
	devTimeUpdate : string;
	timeInstalled : string;
	timeuninstalled : string;
	gwSerialNo : string; // 게이트웨이 시리얼번호

	operationMode:string; // 점멸방식
	status : string; // 상태
	reason : string; // 장애 이유

	vout1 : string;// 전압
	cur1 : string; // 전류
	vout2 : string;
	cur2 : string;

	maintenanceStateCode:string;
	
};

export interface IUpdateFacilityInfo {
	facId : string;
	devId : string;
	facNo : string;
	labelNo : string;
	lat: string;
	lng: string;
	facType: string; // 1 : 가로등, 2: 게이트웨이
	alt: string;
	mtUserId: string;
};



export interface ISetFacilityGatewayInfo {
	siteId : string;
	facId : string;
	facName : string;
	facType : string;
	facDesc : string;
	latitude: string;
	longitude: string;
	address : string;
	telNo : string;
};

export interface IFacilityGatewayInfo {
	siteId : string;
	siteName : string;
	facId : string;
	devId : string;
	gwId : string;
	facName : string;
	facType : string;
	facDesc : string;
	facEnable : string;
	latitude: string;
	longitude: string;
	address : string;
	telNo : string;
	facTimeUpdate : string;
	devSerialNo : string;
};

export interface IFacilityGatewayDetailInfo {
	siteId : string;
	siteName : string;
	facId : string;
	devId : string;
	gwId : string;
	facName : string;
	facType : string;
	facDesc : string;
	facEnable : string;
	latitude: string;
	longitude: string;
	address : string;
	telNo : string;
	facTimeUpdate : string;
	devSerialNo : string;
};

export interface IFacilityScheduleState {
	status : string;
};
export interface IFacilityScheduleUpdateInfo {
	facId : string;
	facName :string;
	update : IFacilityScheduleState[];
};

