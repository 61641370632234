//비밀번호 입력 / 비밀번호 재입력 input
import * as React from 'react';
import {
  Box,
  Typography,
  Input,
} from '@mui/material';
import { Password } from '@mui/icons-material';

type NameInputProps = {
  type:string;
  name : string;
  value : string;
  disabled? : boolean;
  space? : number;
  nameWidth? : number;
  valueWidth? : number;
  onChange:(name:string, value:string)=>void;
};
const NameInputPswd = (
    { type,name, value, disabled = false, nameWidth = 60, valueWidth = 300, space = 10, onChange 
    }: NameInputProps) => {
        console.log(`${name} : ${nameWidth}, ${valueWidth}`)
        
// 글자 입력 함수
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.name, event.target.value);
    };

return (
    <>
        <Box
            sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width:'29rem'
                  
            }}
        >
            <Typography
                sx={{
                   // width: nameWidth,
                   m:'0.6rem',
                   p:'0.4rem'
                }}
                color="textPrimary"
                variant="h5"
            >
                {name}
            </Typography>
            <Box sx={{ width: space , backgroundColor:"#00ff00" }} />
            <Input
                sx={{
                        m:'0.4rem',
                        p:'0.7rem',
                        border:1,
                        borderRadius : '5px',
                        borderColor:'#BFBFBF',
                        backgroundColor:"#FFFFFF",
                        width: '18rem',
                        height: '2.7rem'

                    /*width: valueWidth,
                    height: 30,
                    textAlign:'center',
                    borderRadius:"4px",
                    border: "1px solid #bdbdbd",
                    pl: 1,*/
                }}
                autoComplete = 'new-password'
                type={type}
                disabled={disabled}
                disableUnderline
                name={name}
                onChange={handleChange}
                required
                value={value}
                margin="none"
            />
            {/* <TextField
                sx={{
                    width: 200,
                    backgroundColor:"#ffff00",
                    textAlign:'center',
                }}
                hiddenLabel
                name={name}
                onChange={handleChange}
                required
                value={value}
                margin="none"
                size="small"
                variant="filled"
                InputLabelProps={{
                    shrink: true
                  }}
            /> */}
        </Box>
    </>
)
};

export default NameInputPswd;