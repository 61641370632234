export default {
	translation: {
		global: {
			modify: "수정",
			delete: "삭제",
			add: "등록",
			addFacility: "시설등록",
			addDevice: "기기등록",
			addUser: "사용자추가",
			setUser: "사용자관리",
			select:"선택",
			status:"상태",
			facnumber:"표찰번호",
			factype:"타입유형",
			state:"시/구",
			facname:"시설이름",
			facState:"시설현상태",
			facInfo:"시설정보",
			upperFacInfo:"상위시설정보",
			facGroup:"시설그룹",
			curState:"현재상태",

			curState:"현재상태",
			errorNumber:"장애넘버",
			errorType:"장애유형",
			errorDetail:"장애상세",

			errorHistory:"장애처리이력",

			upperFacility:"상위시설",
			mtCompany:"관리업체`",
			latitude:"위도",
			longtude:"경도",
			atatude:"고도",
			address:"설치주소",
			device:"Device",
			gwMac:"G/W Mac",
			macAddress:"Mac Addr",
			installDay:"설치 날자",
			receiveTime:"수신 시간",
			inputVolt:"입력 전압",

			failList:"장애 발생관리 목록",
			searchList:"검색 결과 목록",
		},
		navbar: {
			welcome: "Welcome",
		},
		sidemenu: {
			dashboard: "dashboard",

			map: "map",
			mapstatus: "facility group",
			maperror: "error",

			fail: "fail",
			failurelist: "failurelist",
		},
		signin: {
			title: "로그인",
			description: "가로등 관제 시스템",
			id: "아이디를 입력하세요",
			pw: "비밀번호를 입력하세요",
			signin: "로그인",
		},
		navbar: {
			welcome: "환영합니다.",
		},
		sidemenu: {
			dashboard: "대쉬보드",

			//map: "지도",
			//mapstatus: "시설 그룹별",
			mapstatus:"시설 지도",
			//maperror: "장애 현황",

			fail: "장애발생 관리",
			failurelist: "장애발생 관리",
			requirelist: "작업요청 관리",
			repairlist: "작업중 관리",
			examinelist: "작업완료 검수",
			failurehistory: "장애 이력",

			facility: "시설 운영",
			streetlamplist: "시설",
			facilitygroup: "그룹",
			gatewaylist: "중계기",
			errorlist:"장애관리",
            //securitylamplist: "보안등",

			facilityline: "시설 라인 관리",

			system: "사이트 관리",
			memberlist: "사용자",
			sitelist: "사이트",
			registration: "시설",
			myaccount: "계정 관리",
			departmentlist: "부서 관리",
			managingcompanylist: "관리업체 관리",
			gatewayform: "중계기 등록",
			departmentform: "departmentform",
		},
		facility: {
			facnumber: "표찰번호",
		},
		callinfo: {
			title:"전화-연락처",
			subtitle1:"가장 적절하게,",
			subtitle2:"가장 단순하게,",
			description: "장소에 구애받지 않고 모바일 및 PC에서 VoIP(인터넷 전화기)의 기업용 서비스를 편리하게 사용할 수 있습니다.  또한 중요한 통화내용을 녹음할 수 있습니다.",
		}
	}
}