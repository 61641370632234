import axios from "axios";
import userStore from "../state/user";

axios.defaults.withCredentials = true;
class Api {
  public token = '';

  constructor(private endpoint: string) {
  }

  async _fetch(url: string, options: RequestInit) {
    if (!url.startsWith('http'))
      url = `${this.endpoint}${url}`;
    return fetch(url, options);
  }

async get1(path:string) {
    console.log(`GET ${path}`);
    const token = this.token;
    const resp = await this._fetch(`${path}`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        ['Content-Type']: 'text/html;charset=UTF-8',
          'jwt':token,
      },
    });
    const text = await resp.text();
    console.log('GET', text);
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400)
        throw json;
      return json;
    } catch(e) {
      if (resp.status >= 400) {
        let error = new Error();
        error = Object.assign(error, {message: 'Error', status: resp.status, body: text})
        if (resp.status == 401) {
          userStore.saveToken('false') 
        }
        // throw 받은 후 한곳에서 처리 할 수 있다면..
        throw error;
      }
      return text;
    }
  }

  async get(path:string) {
    console.log(`GET ${path}`);
    const token = this.token;
    const resp = await this._fetch(`${path}`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        ...this.getCommonHeaders(),
          // 'jwt': 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdXBlciIsImV4cCI6MTYzOTI2ODgxMywiaWF0IjoxNjM5MjMyODEzfQ.37U5gwO4V85_jlhSYulhDJ0yvthxNVfycnDAXZqdF4g',
          'jwt':token,
      },
    });
    const text = await resp.text();
    console.log('GET', text);
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400)
        throw json;
      return json;
    } catch(e) {
      if (resp.status >= 400) {
        let error = new Error();
        error = Object.assign(error, {message: 'Error', status: resp.status, body: text})
        if (resp.status == 401) {
          userStore.saveToken('false') 
        }
        // throw 받은 후 한곳에서 처리 할 수 있다면..
        throw error;
      }
      return text;
    }
  }

  async post(path:string, param:any) {
    console.log(`POST ${path}`, param);
    const resp = await this._fetch(`${path}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.getCommonHeaders(),
          'jwt':this.token,
      },
      body: JSON.stringify(param),
    });
    const text = await resp.text();
    console.log(`POST path : ${path} : ${text}`);
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400)
        throw json;
      return json;
    } catch(e) {
      console.log(`POST error text : `, text);
      if (resp.status >= 400) {
        let error = new Error();
        error = Object.assign(error, {message: 'Error', status: resp.status, body: JSON.parse(text)})
        throw error;
      }
      return text;
    }
  }

async postForm(path:string, params:any = {}) {
    console.log(`POSTFORM ${path}`, params);
    const data = new FormData();
    for (const p of Object.keys(params)) {
      const v = params[p];
    console.log(`POSTFORM v = ${v}, p = ${p}`);
      data.append(p, v);
    }

    const resp = await this._fetch(`${path}`, {
      method: 'POST',
      headers: {
        ['Content-Disposition']: 'attachment',
        ['Content-Type']: 'application/json',
        ['jwt']: `${this.token}`,
      },
      body: data,
    });
    const json = await resp.json();
    console.log('POSTFORM', json);
    return json;
  }

  async postFile(path:string, params:any = {}) {
    console.log(`POSTFILE ${path}`, params);
    const data = new FormData();
    for (const p of Object.keys(params)) {
      const v = params[p];
      if (Array.isArray(v) && p !== 'files')
        data.append(p, v.join(','));
      else if (p === 'files') {
        v.map((x:any) => {
          console.log('append ', x);
          data.append('files[]', x);
        });
      }
      else
        data.append(p, v);
    }

    const resp = await this._fetch(`${path}`, {
      method: 'POST',
      headers: {
        ['Content-Disposition']: 'attachment',
        ['Content-Type']: 'multipart/form-data',
        ['jwt']: `${this.token}`,
      },
      body: data,
    });
    const json = await resp.json();
    console.log('POSTFILE', json);
    return json;
  }

  async delete(path:string) {
    console.log(`DELETE ${path}`);

    const resp = await this._fetch(`${path}`, {
      method: 'DELETE',
      headers: {
        ...this.getCommonHeaders(),
      },
    });
    const text = await resp.text();
    console.log(`DELETE ${path}`, text);
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400)
        throw json;
      return json;
    } catch(e) {
      if (resp.status >= 400) {
        let error = new Error();
        error = Object.assign(error, {message: 'Error', status: resp.status, body: text})
        throw error;
      }
      return text;
    }
  }

  async patch(path:string, param:string) {
    console.log(`PATCH ${path}`, param);
    const resp = await this._fetch(`${path}`, {
      method: 'PATCH',
      headers: this.getCommonHeaders(),
      body: JSON.stringify(param),
    });
    const json = await resp.json();
    console.log('PATCH', json);
    return json;
  }

  async put(path:any, param:string) {
    console.log(`PUT ${path}`, param);
    const resp = await this._fetch(`${path}`, {
      method: 'PUT',
      headers: {
        ...this.getCommonHeaders(),
      },
      body: JSON.stringify(param),
    });
    const text = await resp.text();
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400)
        throw new json;
      return json;
    } catch(e) {
      if (resp.status >= 400) {
        let error = new Error();
        error = Object.assign(error, {message: 'Error', status: resp.status, body: text})
        throw error;
      }
      return text;
    }
  }

  getCommonHeaders() {
    return {
      ['Content-Type']: 'application/json',
      ['Authorization']: this.token ? `Bearer his.token}` : null,
    };

  }
}

export const q = (x: any) => `?${Object.keys(x).map(k => `${k}=${encodeURIComponent(x[k])}`).join('&')}`;
// export const Endpoint = 'http://goodeng.iptime.org:8080';
export const Endpoint = 'https://goodeng.systemcontrol.center';
// export const Endpoint = 'http://127.0.0.1:80';
//export const Endpoint = 'https://10.10.10.6';
//export const Endpoint = 'http://localhost:8080';
export const api = new Api(Endpoint);
