// 시설- 장애 관리

import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Modal,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  useTheme,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useLocation, useNavigate } from 'react-router';
import { IFacInfo } from 'model';
import { TitleinTab } from 'components/atom/DefineStyled';
import NameValue from 'components/atom/NameValue';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { IFacilityInfo, getFacTypeStr } from 'model/facility';
import facilityStore from 'state/facility';
import { ButtonBlue } from 'components/admin/system/Styled';
import FacInfoDialogLamp from 'components/atom/FacInfoDialogLamp';
import ErrorManagePopup from 'components/popup/ErrorManagePopup';
import styles from 'components/facilityGroup/style.module.css'
import maintenanceStore from 'state/maintenance';
import { IMaintenanceErrorInfo, getErrorTypeStr } from 'model/maintenance';
import { BugReportTwoTone } from '@mui/icons-material';
import ErrorRegisterPopup from 'components/popup/ErrorRegisterPopup';
import userStore from 'state/user';

type ManageErrorListProps = {
  errorCount: string;
  facs?: IFacilityInfo[];
  maintenance: IMaintenanceErrorInfo[];
  doit: string;
  facId: string;
};
const ManageErrorList = observer(({ facs, doit, errorCount }: ManageErrorListProps) => {
  const [selectedFacIds, setSelectedFacIds] = useState([]);
  const [selectedFacId, setSelectedFacId] = useState<IFacilityInfo>();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [showFacInfo, setShowFacInfo] = useState(false);
  const [value, setValue] = React.useState<Date | null>();
  const [onTime, setOnTime] = React.useState<Date | null>();
  const [offTime, setOffTime] = React.useState<Date | null>();
  const [showFacInfoDialogLamp, setShowFacInfoDialogLamp] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [facId, setFacId] = useState('');
  const [mainId, setMainId] = useState('');
  const [createTime, setCreateTime] = useState('');
  const [errorRegisterPopupOpen, setErrorRegisterPopupOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const theme = useTheme();

  const location = useLocation();
  const state = location.state as IMaintenanceErrorInfo;

  //하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

  // 장애설정 dialog 오픈.
  const handleManageErrorOpen = async (_facId: string, _mainId: string, _createTime: string) => {
    setShowFacInfoDialogLamp(true)
    setCreateTime(_createTime)
    setFacId(_facId)
    setMainId(_mainId)
  };

  // mainId 세팅
  useEffect(() => {
    const fetchData = async () => {
      setMainId(mainId);
    };

    fetchData();
  }, [mainId]);
  //장애설정 dialog 닫기
  const handleClose = async () => {
    console.log(`handleLineDrawerClose in facilitylinelist`)
    setShowFacInfoDialogLamp(false);
  };

  //타이틀 하단 borderBox
  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };

  // 네비게이션(왼쪽 메뉴)에서 장애관리로 올시 모든 시설의 장애를 보여줌.
  // 대시보드 [장애처리중] 버튼을 누르고 올시 해당 시설의 장애만 보여줌.
  useEffect(() => {
    console.log(`FacilityGroupDetailImp useEffect state ${JSON.stringify(state)}`)
    if (state?.facId) {
      maintenanceStore.getErrorList(state?.facId, userStore.siteId);
    }
    else {
      maintenanceStore.getErrorList(facId, userStore.siteId);
    }
  }, []);

  //장애 등록 팝업 오픈
  const handleRegisterDialogClose = async () => {
    setErrorRegisterPopupOpen(false);
  };


  return (
    <>
      <PerfectScrollbar
      >
        <Box>
          <Typography
            className={styles.pageDirection}
            gutterBottom>
            시설 운영 {'>'} 장애관리
          </Typography>


          <Typography //타이틀 글자 조정
            className={styles.pageTitle}
            gutterBottom >
            장애 관리
          </Typography>

          <Box sx={{ mt: 1 }}> </Box>
          <Box // 텍스트 박스 border
            sx={{
              borderRadius: 3,
              borderColor: '#1C75BC',
              marginBottom: '2%',
              margin: 'auto'
            }}
            {...defaultProps}
          >
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                m: 1,
                textAlign: 'left',
                float: 'right',
                top: 15
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: "center",
                    margin: 'auto',
                    position: 'relative',
                    right: '10px'
                  }}
                >
                  {state?.facId &&
                    <Button
                      className={styles.blueButton}
                      size='small'
                      sx={{
                        "&:hover": { backgroundColor: "#10548A" }
                      }}
                      onClick={() => {
                        setErrorRegisterPopupOpen(true)
                      }}
                    >
                      장애등록
                    </Button>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box >
          <Table
            sx={{
              width: '100%',
            }}
            size='small'
          >
            <TableHead >
              <TableRow>
                {
                  !isMobile &&
                  <TableCell className={styles.tableHeadEnd} sx={{ width: !isMobile ? '4%' : 'auto' }}>
                    No
                  </TableCell>
                }
                <TableCell className={!isMobile ? styles.tableHead : styles.tableHeadEnd}>
                  이름
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: !isMobile ? '10%' : 'auto' }}>
                  처리상태
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: !isMobile ? '10%' : 'auto' }}>
                  카테고리
                </TableCell>
                {!isMobile &&
                  <><TableCell className={styles.tableHead}>
                    내용
                  </TableCell>
                    <TableCell className={styles.tableHead} sx={{ width: !isMobile ? '15%' : 'auto' }}>
                      발생시각
                    </TableCell></>
                }
                <TableCell className={styles.tableHead} sx={{ width: !isMobile ? '7%' : 'auto' }}>
                  기능
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {maintenanceStore.maintenanceInfoList?.slice((page - 1) * limit, limit + (page - 1) * limit).map((main, index) => (
                <TableRow
                  hover
                  key={main.facId}
                  selected={selectedFacIds.indexOf(main.facId) !== -1}
                >
                  {
                    !isMobile &&
                    <TableCell sx={{ textAlign: 'center', }}>
                      {(page - 1) * limit + index + 1}
                    </TableCell>
                  }
                  {/* 시설이름 */}
                  <TableCell className={!isMobile ? styles.tableBody : styles.tableBodyEnd} sx={{ fontSize: isMobile && 12 }}>
                    {main.facName}
                  </TableCell>
                  {/* 처리상태 */}
                  <TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                    {getErrorTypeStr(main.stateCode)}
                  </TableCell>
                  {/* 카테고리 */}
                  <TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                    {main.category}
                  </TableCell>
                  {!isMobile &&
                    // 설명
                    <><TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                      {main.description}
                    </TableCell>
                      {/* 발생시각 */}
                      <TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                        {main.createTime}
                      </TableCell></>
                  }
                  <TableCell className={styles.tableBody}>
                    <Button
                      size="small"
                      sx={{ fontSize: isMobile && 12 }}
                      className={styles.blueButton}
                      onClick={() => {
                        console.log("!!!!!!!!!!!!!!!!!!", JSON.stringify(main))
                        console.log(`장애설정`)
                        handleManageErrorOpen(main.facId, main.mainId, main.createTime);
                      }}
                    >
                      장애설정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {/* 등록된 장애가 없을경우 등록된 장애가 없다고 띄웁니다. */}
              {!maintenanceStore.maintenanceInfoList &&
                <TableRow>
                  <TableCell colSpan={7} className={styles.tableNoLine}>
                    등록된 장애가 없습니다.
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ display: !isMobile && 'flex' }}>
          <Typography className={styles.bottomCount}>
            전체 개수 : {errorCount} EA
          </Typography>
          <Pagination
            className={styles.pagination}
            color="primary"
            page={page}
            count={Math.ceil(maintenanceStore.maintenanceInfoList?.length / 10)}
            onChange={handlePageChange}
            sx={{
              right: '7%',
              [theme.breakpoints.down('sm')]: {
                width: window.innerWidth,
              },
            }}
          />
        </Box>
      </PerfectScrollbar>
      <ErrorRegisterPopup
        facId={state?.facId}
        open={errorRegisterPopupOpen}
        onClose={handleRegisterDialogClose}></ErrorRegisterPopup>
      <ErrorManagePopup
        facId={facId}
        mainId={mainId}
        createTime={createTime}
        open={showFacInfoDialogLamp}
        onShow={() => setShowFacInfoDialogLamp(false)}
        onClose={handleClose}
      ></ErrorManagePopup>

      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </>
  );
});
export default ManageErrorList;