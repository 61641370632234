//관리자 - 사이트
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router';
import { useStores } from 'state';
import { ISiteDetail } from 'model';
import styles from 'components/facilityGroup/style.module.css'

type AdminSiteListResultsProps = {
  sites : ISiteDetail[];
  doit:string;
};
const AdminSiteListResults = ({ sites, doit }:AdminSiteListResultsProps) => {
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState<ISiteDetail>(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const { siteStore, userStore } = useStores();
	const theme = useTheme();
  const [state, setState] = useState(null);

// 사이트 삭제 팝업
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

// 사이트 삭제
  const handleDeleteSite = async () => {

    await Promise.all(selectedSites.map(async (site) => {
    console.log(`handleDeleteSite : selecteds ${JSON.stringify(site)}`)
      const ret: any = await siteStore.delSite(userStore.getId(), site.siteId, "N");
      console.log(`handleDeleteSite : ${JSON.stringify(ret)}`)
      if (ret.status == 200) {
        console.log(`handleDeleteSite : ${site.siteId} is deleted`)
      }
      else {
        ToastsStore.warning("계정 삭제에 실패 했습니다.")
      }
    }));
    const newSelectedSiteIds: any = [];
    setSelectedSiteIds(newSelectedSiteIds);
    console.log(`handleDeleteSite : refresh`)
    setOpenDialog(false);
    siteStore.getSiteList(userStore.getId(), "10", "1");
  };

  //선택 된 사이트가 없을 때 
  useEffect(() => {
    if (doit.includes("addUser")) {
      if (selectedSites.length == 0) {
        // popup or something
        ToastsStore.warning("선택된 사이트가 없습니다.")
        return;
      }

      console.log(`adduser info ${JSON.stringify(
        {
          state:
          {
            siteId: selectedSite.siteId,
            siteName: selectedSite.siteName,
          }
        })}`)
      navigate('/admin/siteuserform', {
        state:
        {
          siteId: selectedSite.siteId,
          siteName: selectedSite.siteName,
        }
      });
    }
    else if (doit.includes("add")) {
      console.log(`add selectedSite ${JSON.stringify(selectedSites)}`);
      navigate('/admin/siteform');
    }
    else if (doit.includes("delete")) {
      console.log(`delete selectedSite ${JSON.stringify(selectedSites)}`);
      console.log(`delete selectedSite count ${(selectedSites.length)}`);
      if (selectedSites.length == 0) {
        ToastsStore.warning("선택된 사이트가 없습니다.")
        return;
      }
      setOpenDialog(true);
    }
    else if (doit.includes("modify")) {
      if (selectedSites.length == 0) {
        // popup or something
        ToastsStore.warning("선택된 사이트가 없습니다.")
        return;
      }

      console.log(`modify aa ${JSON.stringify({selectedSite})}`)
      console.log(`modify bb ${JSON.stringify({state:selectedSite})}`)
      console.log(`modify selectedSite count ${(selectedSites.length)}`);
      console.log(`modify cc ${JSON.stringify(
        {state:
          {
            siteId:selectedSite.siteId,
          siteName: selectedSite.siteName,
          description: selectedSite.description,
          }
        })}`)
    navigate('/admin/siteform', {
        state:
        {
          siteId: selectedSite.siteId,
          siteName: selectedSite.siteName,
          description: selectedSite.description,
        }
    // navigate('/admin/siteform', {
    //   state:{...
    //     selectedSite}
        });
    }

  },[doit]);

  //모두 체크
  const handleSelectAll = (event:any) => {
    console.log(`handleSelecAll`)
    let newSelectedSiteIds:any;

    if (event.target.checked) {
      newSelectedSiteIds = sites.map((site) => site.siteId);
    } else {
      newSelectedSiteIds = [];
    }

    setSelectedSiteIds(newSelectedSiteIds);
  };

  //체크박스 하나 누르는거
  const handleSelectOne = (event:any, id:string, site:any) => {
    console.log(`handleSelectOne  : id ${id}`)

    const selectedIndex = selectedSiteIds.indexOf(id);
    let newSelectedSiteIds:any = [];
    let newSelectedSites:any = [];

    console.log(`handleSelectOne  selectedindex: ${selectedIndex}`)
    if (selectedIndex === -1) {
      newSelectedSiteIds = newSelectedSiteIds.concat(selectedSiteIds, id);
      newSelectedSites = newSelectedSites.concat(selectedSites, site);
    } else if (selectedIndex === 0) {
      newSelectedSiteIds = newSelectedSiteIds.concat(selectedSiteIds.slice(1));
      newSelectedSites = newSelectedSites.concat(selectedSites.slice(1));
    } else if (selectedIndex === selectedSiteIds.length - 1) {
      newSelectedSiteIds = newSelectedSiteIds.concat(selectedSiteIds.slice(0, -1));
      newSelectedSites = newSelectedSites.concat(selectedSites.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedSiteIds = newSelectedSiteIds.concat(
        selectedSiteIds.slice(0, selectedIndex),
        selectedSiteIds.slice(selectedIndex + 1)
      );
      newSelectedSites = newSelectedSites.concat(
        selectedSites.slice(0, selectedIndex),
        selectedSites.slice(selectedIndex + 1)
      );
    }

    setSelectedSiteIds(newSelectedSiteIds);
    setSelectedSites(newSelectedSites);
    setSelectedSite(newSelectedSites[newSelectedSites.length -1]);
  };
//하단 페이지 넘김
  const handlePageChange = (event:any, newPage:number) => {
    console.log(`handlePageChange`)
    setPage(newPage);
  };

  const getMySites = (_sites:ISiteDetail[]) => {
    if (userStore.getId() == 'super') {
      const tmpSites: ISiteDetail[] = _sites.filter(site => site.enable == 'Y');
      return tmpSites;
    }

    // const aaa = sites.filter(site => site.enable == 'Y').userIdManager.find(elem => elem.userId ==userStore.getId());
    const tmpSites:ISiteDetail[] =  _sites.filter(site => site.enable == 'Y');
    tmpSites.filter(site => site.userIdManager.find(elem => elem.userId ==userStore.getId()));
    const mySites = tmpSites.filter(site => site.userIdManager.find(elem => elem.userId ==userStore.getId()));
    return mySites;
  }

  return (
    <div>

        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
          <Table 
             sx={{width:'100%',
              [theme.breakpoints.down('sm')]: {
               width: window.innerWidth,
             },
            }}
               size='small'
             >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
                    No
                  </TableCell>
                  <TableCell padding="checkbox" sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
                    <Checkbox
                      checked={selectedSiteIds.length === sites.length}
                      color="primary"
                      indeterminate={
                        selectedSiteIds.length > 0
                        && selectedSiteIds.length < sites.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
                    이름
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
                    설명
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
                    사용자 
                  </TableCell>
                  <TableCell sx={{ fontWeight:600, textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8', borderBottom: 2 ,borderBottomColor:'#1C75BC ', borderTop:2, borderTopColor:'#A6A6A6' }}>
                    사용자 번호
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {sites.filter(site => site.enable=='Y').slice((page-1)*limit, limit + (page -1)*limit).map((site, index) => { */}
                {getMySites(sites).slice((page-1)*limit, limit + (page -1)*limit).map((site, index) => {
                  return (
                  <TableRow
                    hover
                    key={site.siteId}
                    selected={selectedSiteIds.indexOf(site.siteId) !== -1}
                  >
                    <TableCell sx={{ textAlign:'center'}}>
                    {(page - 1) * limit + index + 1}
                    </TableCell>
                    <TableCell padding="checkbox" sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      <Checkbox
                        checked={selectedSiteIds.indexOf(site.siteId) !== -1}
                        onChange={(event) => handleSelectOne(event, site.siteId, site)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      {site.siteName}
                    </TableCell>
                    <TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      {site.description}
                    </TableCell>
                    <TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                        <Stack direction="row" spacing={1}>
                          {site.userIdManager.map((user, index1) => {
                            return (
                                <Chip label={user.userName}
                                  style={{ fontSize: "0.7%" }}
                                  />
                            )
                          })
                          }
                        </Stack>
                    </TableCell>
                    <TableCell sx={{ textAlign:'center', borderLeft: 1, borderLeftColor:'#E6E7E8'}}>
                      {site.hpNo}
                    </TableCell>
                  </TableRow>
                )
                  })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Pagination
          color="primary"
          page={page}
          count={Math.ceil(sites.filter(site => site.enable=='Y').length / 10)}
          onChange={handlePageChange}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height:'50px',
            [theme.breakpoints.down('sm')]: {
              width: window.innerWidth,
            },
          }}
        />

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            해당 사이트를 삭제 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent:'center'}}>
          <Button className={styles.greenButton} onClick={handleDialogClose}>취소</Button>
          <Button className={styles.primaryButton} onClick={handleDeleteSite} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </div>
  );
};

export default AdminSiteListResults;
