//시설운영 - 그룹

import * as React from 'react';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Modal,
  Divider,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  useTheme,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Chip,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { IGroupInfo } from 'model';
import NameValue from 'components/atom/NameValue';
import { styled } from '@mui/material/styles';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import lineStore from 'state/line';
import NameInputRem from 'components/atom/NameInputRem';
import { ButtonBlue, ButtonGreen } from 'components/admin/system/Styled';
import facilityStore from 'state/facility';
import { ConstructionOutlined } from '@mui/icons-material';
import { StylesContext } from '@mui/styles';
import styles from 'components/facilityGroup/style.module.css'

interface StyledNameValueProps {
  name: string;
  value: string;
}
function StyledNameValue({ name, value }: StyledNameValueProps) {
  return (
    <>
      <Grid
        item
        lg={6}
        sm={12}
        xl={6}
        xs={12}
      >
        <NameValue
          name={name}
          value={value}
          space={3}
          nameWidth={150}
          valueWidth={400}
        />
        {/* <Box sx={{
        height: 10,
      }} /> */}
      </Grid>
    </>
  );
}

const StyledModal = styled(Modal)(
  ({ theme }) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 400,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 4,
    // boxShadow: 24,
  }));

interface StyledNameInputProps {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}
function StyledNameInput({ name, value, disabled, onChange }: StyledNameInputProps) {
  return (
    <>
      <NameInputRem
        name={name}
        value={value}
        disabled={disabled}
        space={'1rem'}
        nameWidth={'3rem'}
        valueWidth={'10rem'}
        onChange={onChange}
      />
    </>
  );
}
type FacilityGroupListResultsProps = {
  groupCount: string;
  groups: IGroupInfo[];
  doit: string;
  onCommand?: (command: string, name?: string, value?: string) => void;
};
const FacilityGroupListResults = observer(({ groupCount, groups, doit, onCommand }: FacilityGroupListResultsProps) => {
  const [selectedGroups, setSelectedGroups] = useState<IGroupInfo[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<IGroupInfo>();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { groupStore } = useStores();
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const theme = useTheme();
  const [OpenFacilityGroupDetail, setOpenFacilityGroupDetail] = useState(false);
  const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);
  const { userStore } = useStores();
  const [lineList, setLineList] = useState([]);
  const [values, setValues] = useState<IGroupInfo>(
    {
      siteId: '',
      siteName: '',
      groupId: '',
      groupName: '',
      lineId: '',
      lineName: '',
      description: '',
      users: [],
    });
  const [lineCount, setLineCount] = useState([]); // 라인 수
  const [facCount, setFacCount] = useState([]); // 시설물 수


  // 라인 수
  const getLineList = async (_groupId: string): Promise<any> => {
    await lineStore.getLineList(userStore.siteId, _groupId, userStore.getId(), "10", "1");

    let tmp;
    if (lineStore.lineList == null) { //라인이 NULL이면
      console.log("!!!! line is NULL !!!!");
      tmp = 0;
    }
    else { // 라인이 0개가 아니면 
      tmp = lineStore.lineList.length;
    }
    return tmp;
  }

  const getLineCountValue = async () => {
    if (groups.length > 0) { //그룹이 0개보다 많으면
      //const i:any = 0;
      const tmpLinesArr = await Promise.all(
        groups.map(async (group) => {
          console.log("eeeee group !!!!!!!!!!!", JSON.stringify(group))

          const getLine = await getLineList(group.groupId);
          return getLine;
        })
      )
      console.log("Lines Array JSON !!!!!!!!!!!", JSON.stringify(tmpLinesArr));
      setLineCount(tmpLinesArr);
    }
  }
  useEffect(() => {
    console.log("eeeee line length", JSON.stringify(lineCount))
  }, [lineCount]);

  useEffect(() => {
    getLineCountValue();
  }, [groupStore.groupList]);


  // reduce 시설물 수
  const getFacList = async (_groupId: string): Promise<any> => {
    await lineStore.getLineList(userStore.siteId, _groupId, userStore.getId(), "10", "1");

    let tmpFac;
    if (lineStore.lineList == null || lineStore.lineList.length == 0) { //라인이 NULL이거나 0개면
      console.log("!!!! line is NULL !!!!");
      tmpFac = 0;
    }
    else { // 라인이 0개가 아니면 
      console.log(" line Count", JSON.stringify(lineStore.lineList)); // 라인 갯수
      const result = lineStore.lineList.reduce((sum, item) => {

        return Number(item.facilityCount) + sum;
      }, 0)
      tmpFac = result
    }
    return tmpFac;
  }

  const getFacCountValue = async () => {
    if (groups.length > 0) { //그룹이 0개보다 많으면
      //const i:any = 0;
      const tmpFacArr = await Promise.all(
        groups.map(async (group) => {

          const getFac = await getFacList(group.groupId);
          return getFac;
        })
      )
      setFacCount(tmpFacArr);

    }
  }
  //시설물수 count
  useEffect(() => {
    console.log("FacilityCount !!!!!!!!", JSON.stringify(facCount))
  }, [facCount]);

  useEffect(() => {
    getFacCountValue();
  }, [groupStore.groupList]);


  const navigate = useNavigate();


  //그룹설정으로 이동
  const handleModify = async (group: any) => {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!", JSON.stringify(group))
    navigate('/slager/facilitygroupdetail',
      {
        state: {
          groupId: group.groupId,
        }
      });
  }
  //그룹추가로 이동
  useEffect(() => {
    console.log(`FacilityGroupListResults doit ${doit}`)

    if (doit.includes("add")) {
      navigate('/slager/facilitygroupform',
        {
          state: {
            siteId: userStore.siteId,
          }
        });
    }
  }, [doit]);

  //하단 페이지 넘김
  const handlePageChange = (event: any, newPage: number) => {
    console.log(`handlePageChange ${newPage}`)
    setPage(newPage);
  };

  //삭제 dialog닫음
  const handleDialogClose = () => {
    setOpenDeleteGroupDialog(false);

  };
  //삭제
  const handleDeleteGroup = async (group: any) => {

    let ret: any;

    // 그룹에 속하는 라인 제거
    ret = await lineStore.getLineList(group.siteId, group.groupId, userStore.getId(), "10", "1");
    if (ret.status == 400) {
      ToastsStore.success("라인 리스트를 가지고 오는데 실패했습니다.")
      return;
    }
    console.log(`handleDeletegroup : lineList list ${JSON.stringify(lineStore.lineList)}`)
    await Promise.all(lineStore.lineList.map(async line => {
      if (line.groupId == group.groupId) {
        console.log(`handleDeletegroup : line in map ${JSON.stringify(line)}`)
        await lineStore.delLine(group.siteId, group.groupId, line.lineId);
      }
    }));

    // 그룹에 속하는 사용자 제거
    ret = await groupStore.getUserListByGroup(group.siteId, group.groupId);
    if (ret.status == 400) {
      ToastsStore.success("사용자 리스트를 가지고 오는데 실패했습니다.")
      return;
    }
    console.log(`handleDeletegroup : user list ${JSON.stringify(groupStore.userList)}`)
    await Promise.all(groupStore.userList.map(async user => {
      if (user.groupId == group.groupId) {
        console.log(`handleDeletegroup : delete user ${JSON.stringify(user)}`)
        await groupStore.deleteUserGroup(group.siteId, group.groupId, user.userId);
      }
    }));

    // 그룹 제거
    ret = await groupStore.delGroup(group.siteId, group.groupId);
    console.log(`handleDeleteGroup : ${JSON.stringify(ret)}`)
    if (ret.status == 200) {
      console.log(`handleDeleteGroup : ${group.groupId} is deleted`)
      ToastsStore.success("삭제 성공")
    }
    else {
      ToastsStore.warning("그룹 삭제에 실패 했습니다.")
    }
    groupStore.getGroupList(group.siteId, group.perPage, group.Page);
    setOpenDeleteGroupDialog(false);
  };


  return (
    <>

      <PerfectScrollbar>
        <Box>
          <Table
            size='small'>
            <TableHead>
              <TableRow>
                {
                  !isMobile &&
                  <TableCell className={styles.tableHeadEnd} sx={{ width: '4%' }}>
                    No
                  </TableCell>
                }
                <TableCell className={!isMobile ? styles.tableHead : styles.tableHeadEnd}>
                  시설그룹
                </TableCell>
                {
                  !isMobile &&
                  <><TableCell className={styles.tableHead}>
                    설명
                  </TableCell>
                    <TableCell className={styles.tableHead}>
                      사용자
                    </TableCell>
                    <TableCell className={styles.tableHead}>
                      라인 수
                    </TableCell>
                    <TableCell className={styles.tableHead}>
                      시설물 수
                    </TableCell></>
                }
                <TableCell className={styles.tableHead}>
                  시설 기능
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.slice((page - 1) * limit, limit + (page - 1) * limit).map((group, index) => (
                <TableRow
                  hover
                  key={group.groupId}
                >
                  {
                    !isMobile &&
                    <TableCell sx={{ textAlign: 'center' }}>
                      {(page - 1) * limit + index + 1}
                    </TableCell>
                  }
                  <TableCell className={!isMobile ? styles.tableBody : styles.tableBodyEnd} sx={{ fontSize: isMobile && 12 }}>
                    {group.groupName}
                  </TableCell>
                  {
                    !isMobile &&
                    <><TableCell className={styles.tableBody}>
                      {group.description}
                    </TableCell>
                      {/* 사용자 */}
                      <TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                        <Stack direction="row" spacing={1}>
                          {group?.users?.map((user) => {
                            return (
                              <Chip label={user.userName}
                                style={{ fontSize: "0.7rem" }} />
                            );
                          }
                          )}
                        </Stack>

                        {/* {group.users[0]?.userName},
        {group.users[1]?.userName} */}
                      </TableCell><TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                        {lineCount[index]}
                      </TableCell><TableCell className={styles.tableBody} sx={{ fontSize: isMobile && 12 }}>
                        {facCount[index]}
                      </TableCell>
                    </>
                  }
                  <TableCell className={styles.tableBody} sx={{ width: isMobile ? '35%' : '12%' }} >
                    <Button
                      className={styles.blueButton}
                      sx={{ fontSize: isMobile && 12 }}
                      size='small'
                      onClick={() => {
                        console.log(`설정`);
                        console.log("This Group Value is !!!!!! : ", JSON.stringify(group));
                        handleModify(group);
                      }}
                    >  설정
                    </Button>
                    {userStore.siteLevel !== '9300' &&
                      <ButtonGreen
                        className={styles.greenButton}
                        sx={{ fontSize: isMobile && 12 }}
                        size='small'
                        disabled={userStore.siteLevel == '9300' ? true : false}
                        onClick={() => {
                          console.log(`삭제`);
                          console.log("This Group Value is !!!!!! : ", JSON.stringify(group));
                          setSelectedGroup(group);
                          setOpenDeleteGroupDialog(true); //Dialog열기 
                        }}
                      >
                        삭제
                      </ButtonGreen>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ display: !isMobile && 'flex' }}>
          <Typography
            className={styles.bottomCount}
          >
            전체 그룹 : {groupCount} EA
          </Typography>
          <Pagination
            color="primary"
            page={page}
            count={Math.ceil(groups.length / 10)}
            onChange={handlePageChange}
            sx={{
              width: '100%',
              display: 'flex',
              height: '50px',
              justifyContent: 'center',
              position: 'relative',
              right: '7%',
              [theme.breakpoints.down('sm')]: {
                width: window.innerWidth,
              },
            }}
          />
        </Box>
      </PerfectScrollbar>

      {/* 그룹삭제 dialog */}
      <Dialog
        open={openDeleteGroupDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            해당 그룹을 삭제 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button className={styles.greenButton} onClick={handleDialogClose}>취소</Button>
          <Button className={styles.primaryButton} onClick={() => {
            console.log(`삭제`);
            handleDeleteGroup(selectedGroup); //selectedGroup
          }} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </>
  );
});

export default FacilityGroupListResults;
