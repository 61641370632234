// 시설운영 - 그룹 - 설정 - 그룹 설정
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useRadioGroup
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import NameInputRem from 'components/atom/NameInputRem';
import { IFacilityScheduleUpdateInfo, IGroupInfo, IGroupSet, isSiteManager } from 'model';
import userStore from 'state/user';
import { ToastsStore } from 'react-toasts';
import FacilityLineList from './FacilityLineList';
import FacilityDeviceList from './FacilityDeviceList';
import { observer } from 'mobx-react';
import groupStore from 'state/group';
import { ButtonBlue } from 'components/admin/system/Styled';
import lineStore from 'state/line';
import GroupDetailUpdatePopup from 'components/popup/GroupDetailUpdatePopup';
import facilityStore from 'state/facility';
import { isMobile } from 'react-device-detect';
import MobileNameInput from 'components/atom/MobileNameInput';
import styles from 'components/facilityGroup/style.module.css'

interface StyledNameInputProps {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}
//NameInput 사용자 설정 style
function StyledNameInput({ name, value, disabled, onChange }: StyledNameInputProps) {
  return (
    <>
      <NameInputRem
        name={name}
        value={value}
        disabled={disabled}
        space={'1rem'}
        nameWidth={'6rem'}
        valueWidth={'20rem'}
        onChange={onChange}
      />
    </>
  );
}

interface ILocation {
  groupId: string,
}
const FacilityGroupDetailImp = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as ILocation;
  const [values, setValues] = useState<any>(
    {
      siteId: '',
      groupId: '',
      siteName: '',
      groupName: '',
      lineId: '',
      lineName: '',
      description: '',
      users: [],
    });
  const [openUserAdd, setOpenUserAdd] = useState(false);
  const [selectedLineId, setselectedLineId] = useState('-1');
  const [selectedOnTime, setselectedOnTime] = useState<string>("");
  const [selectedOffTime, setselectedOffTime] = useState<string>("");
  const [selectedOperation, setSelectedOperation] = useState<string>("");
  const [showGroupDetailUpdatePopupPopup, setShowGroupDetailUpdatePopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [selectedLine, setSelectedLine] = useState(null);
  const [selectedDevTime, setSelectedDevTime] = useState(null);
  const [selectedLineName, setSelectedLineName] = useState(null);
  const [facilitySchedule, setFacilitySchedule] = useState<string>("");
  const [modeChange, setModeChange] = useState('')

  useEffect(() => {
    console.log(`FacilityGroupDetailImp useEffect groupInfo ${JSON.stringify(groupStore.groupInfo)}`)
    if (groupStore.groupInfo) {
      console.log(`FacilityGroupDetailImp useEffect groupInfo exist ${JSON.stringify(groupStore.groupInfo)}`)
      setValues(
        groupStore.groupInfo
      );
    }
  }, [groupStore.groupInfo]);

  //그룹정보수정 팝업 닫음.
  const handleGroupDetailUpdatePopupClose = async () => {
    console.log(`handleLinerAddPopupClose in facilitylinelist`)
    setShowGroupDetailUpdatePopup(false);
    //await lineStore.getLineList(userStore.siteId, userStore.siteId, userStore.getId(), "10", "1");
  };

  //value설정
  useEffect(() => {
    console.log(`FacilityGroupDetailImp useEffect groupInfo ${JSON.stringify(groupStore.groupInfo)}`)
    setValues({
      ...values,
      users: groupStore.userList
    });
  }, [groupStore.userList]);

  useEffect(() => {
    console.log(`FacilityGroupDetailImp useEffect state ${JSON.stringify(state)}`)
    groupStore.getGroup(state.groupId);
    groupStore.getUserListByGroup(userStore.siteId, state.groupId);
  }, []);

  useEffect(() => {
    console.log(`FacilityGroupDetailImp useEffect values=${JSON.stringify(values)}`)
  }, [values]);


  const handleUserAdd = async (user: any) => {
    console.log(`handleUserAdd ${user.userId}`);
    const ret: any = await groupStore.setUserToGroup(values.siteId, values.groupId, user.userId, "description");
    console.log(`addGroup ${JSON.stringify(ret)}`)
    if (ret.status == 200) {
      ToastsStore.success("등록 성공")
      groupStore.getUserListByGroup(values.siteId, values.groupId);
    }
    else if (ret.status == 400) {
      ToastsStore.success("등록 실패")
    }
  };

  const handleUserAddClose = () => {
    console.log(`handleUserAddClose`);
    setOpenUserAdd(false);
  };


  const handleChangeGroupName = (name: string, value: string) => {
    console.log(`FacilityGroupDetailImp handleChangeGroupName = ${name}, ${value} !!!!!!!!!!!!!!!!!!`)
    setValues({
      ...values,
      groupName: value
    });
  }
  const handleChangeDescription = (name: string, value: string) => {
    console.log(`FacilityGroupDetailImp handleChangeGroupName = ${name}, ${value} !!!!!!!!!!!!!!!!!!`)
    setValues({
      ...values,
      description: value
    });
  }
  useEffect(() => {
    console.log(`FacilityLineList selectedLine = ${JSON.stringify(selectedLine)}`)
  }, [selectedLine]);

  useEffect(() => {
    console.log("=======a=======")
    setValues(
      groupStore.groupInfo
    );
  }, [groupStore.groupInfo]);

  const handleSelected = async (lineId: string, onTime: string, offTime: string, operationMode: string, lineName: string) => {
    console.log(`handleSelected = ${lineId} !!!!!!!!!!!!!!!!!!`)
    setselectedLineId(lineId);
    setselectedOnTime(onTime);
    setselectedOffTime(offTime);
    setSelectedOperation(operationMode);
    setSelectedLineName(lineName)

    await facilityStore.getFacilityDetailInfoList(userStore.siteId, state.groupId, lineId, null);
  }

  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '8.5rem' },
  };


  return (
    <Box>
      <Typography
        className={styles.pageDirection}
        sx={{
          fontSize: isMobile && 13
        }}
        gutterBottom>
        시설 관리 {'>'} {values?.groupName} 설정 {'>'} 그룹 설정
      </Typography>

      <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
        <Typography //타이틀 글자 조정
          className={styles.pageTitle}
          gutterBottom >
          그룹 설정
        </Typography>
        {isMobile &&
          <Button
            className={styles.blueButton}
            sx={{ float: 'right' }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              console.log(`그룹 정보 수정`);
              const emptyLine = {};
              setSelectedLine(emptyLine);
              setShowGroupDetailUpdatePopup(true);
            }}
          >
            그룹 정보 수정
          </Button>
        }
      </Box>
      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
        sx={{
          borderRadius: 3,
          borderColor: '#1C75BC',
          marginBottom: '2%',
          margin: 'auto'
        }}
        {...defaultProps}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1
          }}
        >
          <Box sx={{ mt: 1, display: 'flex' }}>
            {isMobile &&
              <>
                <Box sx={{ position: 'relative', top: '26px' }}>
                  <MobileNameInput
                    name="그룹명"
                    value={values?.groupName}
                    disabled={true}
                    onChange={handleChangeGroupName} /></Box></>
            }
            {!isMobile &&
              <><StyledNameInput
                name="그룹명"
                value={values?.groupName}
                disabled={true}
                // value={groupName}
                onChange={handleChangeGroupName} />
                <StyledNameInput
                  name="설명"
                  value={values?.description}
                  disabled={true}
                  // value={groupName}
                  onChange={handleChangeDescription} /></>
            }
          </Box>
          {!isMobile &&
            <ButtonBlue
              sx={{
                position: 'relative',
                right: '1%',
                "&:hover": { backgroundColor: "#10548A" }
              }}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                console.log(`그룹 정보 수정`)
                const emptyLine = {
                }
                setSelectedLine(emptyLine);
                setShowGroupDetailUpdatePopup(true);
              }}
            >
              그룹 정보 수정
            </ButtonBlue>
          }
        </Box>
        {!isMobile &&
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{
                position: 'relative',
                width: '4rem',
                left: '1.6%'
              }}
              color="textPrimary"
            >
              담당자
            </Typography>
            <Box sx={{
              width: '2rem',
              backgroundColor: "#00ff00"
            }} />
            <Stack direction="row" spacing={1} sx={{ position: 'relative', left: '0.8%' }}>
              {groupStore.userList.filter(user => user.groupId == state.groupId).map((user, index) => {
                return (
                  <>
                    {isSiteManager(userStore.siteLevel)
                      ?
                      <Chip label={user.userName}
                      //onDelete={() => handleUserRemoveRequest(user.userName) }
                      />
                      :
                      <Chip label={user.userName}
                      />
                    }
                  </>
                )
              }
              )}
            </Stack>
            <Box sx={{
              width: '1rem',
            }} />

            {/*{isSiteManager(userStore.siteLevel) &&
                <AddCircleRounded
                  onClick={() => {handleUserAddRequest()}}
                ></AddCircleRounded>
              }*/}

          </Box>
        }
      </Box>
      <Box sx={{ pt: 1 }}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={!isMobile ? 4.5 : undefined}
            sm={!isMobile ? 12 : undefined}
            xl={!isMobile ? 4.5 : undefined}
            xs={!isMobile ? 12 : undefined}
          >

            <FacilityLineList
              siteId={userStore?.siteId}
              groupId={state.groupId}
              lineId={selectedLineId}
              lineName={selectedLineName}
              title="라인"
              onSelected={handleSelected}
            />
          </Grid>
          <Grid
            item
            lg={!isMobile ? 7.5 : undefined}
            sm={!isMobile ? 12 : undefined}
            xl={!isMobile ? 7.5 : undefined}
            xs={!isMobile ? 12 : undefined}
          >
            <FacilityDeviceList
              groupId={state.groupId}
              lineId={selectedLineId}
              selectedOperation={selectedOperation}
              selectOnTime={selectedOnTime}
              selectOffTime={selectedOffTime}
              devTimeUpdate={selectedDevTime}
              title="시설"
              mode={modeChange}

            />
          </Grid>
        </Grid>
      </Box>
      
      {/* 담당자 추가 팝업 */}
      <GroupDetailUpdatePopup
        open={showGroupDetailUpdatePopupPopup}
        groupId={state.groupId}
        onClose={handleGroupDetailUpdatePopupClose} groupInfo={undefined}></GroupDetailUpdatePopup>

      <Dialog
        open={openUserAdd}
        onClose={handleUserAddClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            사용자 추가
          </DialogContentText>
          <Table size="small">
            <TableBody>
              {groupStore.userList.filter(user => user.groupId != values?.groupId).map((user, index) => (
                <TableRow
                  hover
                  key={user.userId}
                >
                  <TableCell>
                    {user.userName}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        console.log(`추가`)
                        handleUserAdd(user);
                      }}
                    >
                      추가
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserAddClose}>취소</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default FacilityGroupDetailImp;