// 연간스케줄에서 -> 스케줄모드 이동 팝업
// 고정시간에서 -> 고정모드 이동 팝업
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from '@mui/material';
import { textAlign } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import LineDetailUpdatePopup from './LineDetailUpdatePopup';
import facilityStore from 'state/facility';
import lineStore from 'state/line';

export interface GoScheduleModePopupProps {
    open?: boolean;
    groups?: any;
    lineId?: string;
    operationMode: string;
    onClose?: () => void;
    onCloseOne?: () => void;
}
const GoScheduleModePopup: React.FunctionComponent<GoScheduleModePopupProps> = ({ open, groups, lineId, onClose, onCloseOne, operationMode }: GoScheduleModePopupProps) => {

    const navigate = useNavigate();

    const handleDialogClose = () => {
        onCloseOne();
    };

    //스케줄모드로 이동
    const handleGoScheduleMode = () => {
        lineStore.getLine(lineId);
        facilityStore.setMode('schedule')
        onClose();
        onCloseOne();
        navigate('/slager/facilitygroupdetail',
            {
                state: {
                    groupId: groups,
                }
            });
    };

    //고정모드로 이동
    const handleGoFiexedMode = () => {
        lineStore.getLine(lineId);
        facilityStore.setMode('fixed')
        onClose();
        onCloseOne();
        navigate('/slager/facilitygroupdetail',
            {
                state: {
                    groupId: groups,
                }
            });
    };
    return (
        //적용하시겠습니까? 팝업
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Card sx={{ display: 'flex' }}>
                    {operationMode === 'S' &&
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 23,
                                    fontWeight: 600,
                                    color: '#0C3149',
                                    display: 'flex',
                                    position: 'relative',
                                    padding: 1.5,
                                    textAlign: 'center'
                                }}
                            >
                                <span>연간 스케줄 변경 시 점멸기에 적용해야 합니다.<br />지금 적용하시겠습니까?<br /></span>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 17,
                                    fontWeight: 600,
                                    color: '#0C3149',
                                    position: 'relative',
                                    padding: 1.5,
                                    textAlign: 'center',
                                    display: 'block'
                                }}
                            >
                                <span>(지금 적용을 원하시면 바로가기 버튼을 누른 후<br /> 업데이트 버튼을 눌러주세요.)</span>
                            </Typography>
                        </Box>
                    }
                    {operationMode === 'O' &&
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 23,
                                    fontWeight: 600,
                                    color: '#0C3149',
                                    display: 'flex',
                                    position: 'relative',
                                    padding: 1.5,
                                    textAlign: 'center'
                                }}
                            >
                                <span>고정시간 변경 시 점멸기에 적용해야 합니다.<br />지금 적용하시겠습니까?<br /></span>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 17,
                                    fontWeight: 600,
                                    color: '#0C3149',
                                    position: 'relative',
                                    padding: 1.5,
                                    textAlign: 'center',
                                    display: 'block'
                                }}
                            >
                                <span>(지금 적용을 원하시면 바로가기 버튼을 누른 후<br /> [모드 설정] 버튼을 눌러주세요.)</span>
                            </Typography>
                        </Box>
                    }

                </Card>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Box sx={{ justifyContent: 'center' }}>
                    <Button onClick={operationMode == 'O' ? handleGoFiexedMode : handleGoScheduleMode} autoFocus>
                        바로가기
                    </Button>
                    <Button onClick={handleDialogClose} autoFocus>
                        취소
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
};

export default GoScheduleModePopup;