import { Box} from '@mui/material';

interface SpaceProps {
	width?:string;
}
const Space = ({ width}: SpaceProps): JSX.Element => {
  // const ratio = 100 - (current / total) * 100;
	return (
		<>
			{width ?
				<Box sx={{ ml: Number(width) }} > </Box >
				: <Box sx={{ mt: 1 }}> </Box>
			}
		</>
	);
};

export default Space;