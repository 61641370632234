// 시설운영 - 정보수정 - 고정시간 설정시 뜨는 시간 선택 select 칸

import * as React from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Clear, ClearAll } from '@mui/icons-material';

//시간,분 설정
const Hours = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
];
// const Mins = [
//   { value: '00', label: '00' },
//   { value: '10', label: '10' },
//   { value: '20', label: '20' },
//   { value: '30', label: '30' },
//   { value: '40', label: '40' },
//   { value: '50', label: '50' },
// ];
let Mins: any[] = [];
//let Hours: any[] = [];



type TimeSelectProps = {
    name: string;
    defaultValue?: string;
    space?: string;
    disabled?: boolean;
    nameWidth?: string;
    valueWidth?: string;
    defaultHour?: string;
    defaultMin?: string;
    onChangeHour: (name: string, value: string) => void;
    onChangeMin: (name: string, value: string) => void;
};
const TimeSelect = (
    { name, defaultValue = '0', defaultHour = '0', defaultMin = '0', disabled = false, nameWidth = '15rem', valueWidth = '20rem', space = '2rem', onChangeHour, onChangeMin }
        : TimeSelectProps) => {
    console.log(`TimeSelect ${name}:${defaultValue}`)
    const [hour, setHour] = useState<string>('');
    const [min, setMin] = useState<string>('');

    // 시간 입력 조건 설정 
    useEffect(() => {
        if (Mins.length < 60) {
            for (let i = 0; i < 60; i++) {
                const value = i.toString().padStart(2, '0'); // Add leading zeros
                Mins.push({ value, label: value });
                console.log(`TimeSelect useEffect MinsLength !!!!!!!!!!!!!!!!!${JSON.stringify(Mins.length)}`);
            }
        }
        return () => {
            Mins = [];
            console.log(`TimeSelect useEffect MinsLength !!!!!!!!!!!!!!!!!${JSON.stringify(Mins.length)}`);
        };
    }, []);

    // useEffect(() => {
    //     if(Hours.length < 24){
    //     for (let i = 0; i < 24; i++) {
    //         Hours.push({value:i.toString(), label:i.toString()})
    //         console.log(`TimeSelect useeffect MinsLength !!!!!!!!!!!!!!!!!${JSON.stringify(Hours.length)}`)
    //     }
    //     }
    //     return () => { Hours =[]; console.log(`TimeSelect useeffect MinsLength !!!!!!!!!!!!!!!!!${JSON.stringify(Hours.length)}`)}
    // }, []);

    //시간과 분 값 설정
    useEffect(() => {
        if (defaultHour) {
            setHour(defaultHour)
            console.log(`TimeSelect Hour ${name}:${defaultHour}`)
        }
        else {
            setHour(defaultHour)
            console.log(`TimeSelect Hour else ${name}:${defaultHour}`)
        }
    }, [defaultHour]);

    useEffect(() => {
        if (defaultMin) {
            setMin(defaultMin)
            console.log(`TimeSelect Min ${name}:${defaultMin}`)
        }
        else {
            console.log(`TimeSelect Min else ${name}:${defaultMin}`)
        }
    }, [defaultMin]);

    // 시 바꾸는 함수
    const handleHour = (event: SelectChangeEvent) => {
        console.log(`nameSelect ${event.target.value}`)
        setHour(event.target.value as string);
        onChangeHour(name, event.target.value as string);
    };
    // 분 바꾸는 함수
    const handleMin = (event: SelectChangeEvent) => {
        console.log(`nameSelect ${event.target.value}`)
        setMin(event.target.value as string);
        onChangeMin(name, event.target.value as string);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Typography
                    sx={{
                        width: nameWidth,
                    }}
                    color="textPrimary"
                    variant="h5"
                >
                    {name}
                </Typography>
                <Box sx={{ width: space }} />
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={hour}
                    sx={{
                        height: '2rem',
                        width: valueWidth,
                        textAlign: 'center',
                        borderRadius: "0.5rem",
                    }}
                    disabled={disabled}
                    onChange={handleHour}
                >
                    {Hours.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
                <Box sx={{ width: '1rem' }} />
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={min}
                    sx={{
                        height: '2rem',
                        width: valueWidth,
                        textAlign: 'center',
                        borderRadius: "0.5rem",
                    }}
                    disabled={disabled}
                    onChange={handleMin}
                >
                    {Mins.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
};

export default TimeSelect;
