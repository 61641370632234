// import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';
import routes from './routes';
import { useStores } from './state';
import { observer } from 'mobx-react';
import { useEffect } from 'react';


declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const App = observer(({}) => {
  const { userStore } = useStores();

  // TODO : 로그인 정보를 위해서 
  // isLoggedIn, token 어느것을 사용하는게 좋을까?
  const content = useRoutes(routes(userStore.checkLogin("1", "1")));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
