//사이트 관련 api
import { StringMap } from 'i18next';
import { observable, computed } from 'mobx';
import { api, q } from '../api';
import { ISiteDetail, ISiteSet, ISiteUser } from '../model/site';
export interface ISiteStore {
	siteList:ISiteDetail[];
	siteInfo:ISiteDetail;
	userList:ISiteUser[];

	getSiteCount:(userId:string) => void;
	getSiteList:(userId:string, perPage:string, page:string) => void;
	getSiteInfo:(userId:string) => void;
	setSite:(siteInfo:ISiteSet) => void;
	delSite:(userId:string, siteId:string, enable:string) => any;
	setUser:(siteId:string, userId:string, siteLevel:string) => void;
	setSiteInfoToMaintenance:(siteId:string, userIdMaintenance:string) => void;
	deleteUser:(siteId:string, userId:string) => void;
	getSiteUserList:(siteId:string ) => void;
	getSiteListByUser:(userId:string ) => void;
}
export const siteStore = observable<ISiteStore>({
	siteList:[],
	siteInfo:null,
	userList:[],

	//사이트 카운트
	async getSiteCount(userId:string) {
		// console.log(`getSiteCount ::: `)
		// try {
		// 	const ret = await api.post(`/api2/site/siteList`,{
		// 	});
		// 	console.log(`getSiteList ret = ${JSON.stringify(ret)}`)
		// 	this.siteList = ret.data.contents;
		// } catch (error) {
		// 	console.log(`getUserList error`)
		// }
		// return '';
	},
	//사이트 리스트 가져옴
	async getSiteList(userId:string, perPage:string, page:string) {
		console.log(`getSiteList ::: `)
		try {
			const ret = await api.post(`/api2/site/getSiteList`,{
				perPage,
				page,
			});
			console.log(`getSiteList ret = ${JSON.stringify(ret.data)}`)
			// this.siteList = ret.data.map((arr: string | any[]) => {
			// 	return arr.slice
			// });
			this.siteList =ret.data;
			// this.siteList = JSON.parse(JSON.stringify(ret.data));
			// this.siteList = ret.data.slice();

		} catch (error) {
			console.log(`getSiteList error (${error})`)
		}
	},
	//사이트 정보 가져옴(한개)
	async getSiteInfo(userId:string) {
		const ret = await api.post('/api2/site/getSiteInfo',{
				userId,

		});
		console.log(`getDetail ${JSON.stringify(ret)}`)
		this.siteInfo = ret.data;
	},
//사이트 정보 수정
	async setSite(siteInfo:ISiteSet) {
		console.log(`setSite ::: ${JSON.stringify(siteInfo)}`)
		try {
			const ret = await api.post(`/api2/site/setSiteInfo`, siteInfo);
			console.log(`setSite ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`setSite error`)
			return {status:error.status};
		}
	},
	//사이트 삭제
	async delSite(userId:string, siteId:string, enable:string) {
		console.log(`delSite ::: ${JSON.stringify(siteId)}`)
		try {
			const ret = await api.post(`/api2/site/setSiteInfoEnable`, {
				siteId,
				enable,
			});
			console.log(`delSite ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`delSite error`)
			return {status:error.status};
		}
		return '';
	},
	//사용자 정보 수정
	async setUser(siteId:string, userId:string, siteLevel:string) {
		console.log(`setUser ::: ${JSON.stringify(siteId)}`)
		try {
			const ret = await api.post(`/api2/site/setSiteInfoToUser`, {
				siteId,
				userId,
				siteLevel,
			});
			console.log(`setUser ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`setsetUser error`)
			return {status:error.status};
		}
	},
	async setSiteInfoToMaintenance(siteId:string, userIdMaintenance:string) {
		console.log(`setSiteInfoToMaintenance ::: ${JSON.stringify(siteId)}`)
		try {
			const ret = await api.post(`/api2/site/setSiteInfoToMaintenance`, {
				siteId,
				userIdMaintenance,
			});
			console.log(`setSiteInfoTOMaaintenance ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`setSiteInfoTOMaaintenance error`)
			return {status:error.status};
		}
	},
	//사용자 삭제
	async deleteUser(siteId:string, userId:string) {
		console.log(`deleteUser ::: ${JSON.stringify(siteId)}`)
		try {
			const ret = await api.post(`/api2/site/deleteSiteInfoToUser`, {
				siteId,
				userId,
			});
			console.log(`deleteSiteInfoToUser ret = ${JSON.stringify(ret)}`)
			return {status:200};
		} catch (error) {
			console.log(`deleteSiteInfoToUser error`)
			return {status:error.status};
		}
	},
	//사이트에 속한 사용자 리스트 가져옴
	async getSiteUserList(siteId:string) {
		console.log(`getSiteUserList ::: `)
		try {
			const ret = await api.post(`/api2/site/getSiteUserList`,{
				siteId
			});
			console.log(`getSiteUserList ret = ${JSON.stringify(ret)}`)
			this.userList = ret.data;
		} catch (error) {
			console.log(`getSiteUserList error`)
		}
	},async getSiteListByUser(userId:string) {
		console.log(`getSiteListByUser ::: `)
		try {
			const ret = await api.post(`/api2/site/getSiteUserList`,{
				userId
			});
			console.log(`getSiteListByUser ret = ${JSON.stringify(ret)}`)
			this.userList = ret.data;
			console.log(`getSiteListByUser this.userList = ${JSON.stringify(this.userList)}`)
		} catch (error) {
			console.log(`getSiteListByUser error`)
		}
	},
})

export default siteStore;

