import {
	Box,
	Popover,
	Tooltip,
	Typography,
} from '@mui/material';
import { useState } from 'react';

interface ILocationMarker {
	id:string;
	type:string;
	lat:number;
	lng:number;
	text?:string;
	status?:string;
	tooltip:any;
	hover?:any;
	onClick:(id:string) => void;
};

const LocationMarker = ({id, type, text, status, tooltip, lat, lng, onClick}:ILocationMarker) => {
	if (lat != null) {
	console.log(`LocationMarker ${id}:${type}:${text}:${status}:${lat}:${lng}`)
	}
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const popoverId = open ? 'simple-popover' : undefined;
	const handleClick = (event: any) => {
		console.log(`You clicked on ${tooltip}`);
		// setAnchorEl(event.currentTarget);
		onClick(id);
	};

	const image = "/static/images/lamp_ok.png";

	const handleClose = () => {
		console.log(`You clicked on close`);
		// setAnchorEl(null);
	};

	return (
		<div >
			<Tooltip title={tooltip}>
				<Box>
					<Box
					  sx={{width:25, height:30, cursor:'pointer'}}
						component="img"
						src={image}
						alt={text}
						onClick={handleClick}
					>
					</Box>
				</Box>
			</Tooltip>
			<Popover
				id={popoverId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
			</Popover>
		</div>
	);
};

export default LocationMarker;