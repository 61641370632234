// 장애 모델
export enum Errorype { 
	NoError = '11',
	ErrorApply = '12',
	ErrorFixing = '13',
	ErrorFixEnd = '14',
	ErrorFixEndCheck = '15',
}

export const ErrorStatus = [
  { value: Errorype.NoError, label: '장애 없음' },
  { value: Errorype.ErrorApply, label: '장애 처리 요청' },
  { value: Errorype.ErrorFixing, label: '장애 처리 중' },
  { value: Errorype.ErrorFixEnd, label: '장애 처리 완료' },
  { value: Errorype.ErrorFixEndCheck, label: '장애 완료 확인' },
];

export const getErrorTypeStr = (value: string) => {
  const ret: any = ErrorStatus.find(status => status.value == value);
  return ret?.label;
}

export interface IMaintenanceErrorInfo {
    mainId: string; // 장애 mainId
    facId: string; // 시설 id
    facName: string; // 시설 이름
    category: string; // 장애 카테고리
    description: string; // 설명
    stateCode: string; // 상태코드
    updateTime: string; // 업데이트 시간
    createTime: string; // 장애가 등록된 시간
    completeTime: string; // 장애 완료 시간
};

export interface ICommentInfo {
    mainId: string;
    subId: string; // 장애 subId(코멘트 id)
    comment: string; // 댓글
    updateTime: string; // 업데이트 된 시간
    createTime: string; // 장애가 등록된 시간
};