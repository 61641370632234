// 시설운영 -그룹 - 설정 - 라인추가
// 계정관리 - 수정, 암호변경
// 위 두개에서 사용하는 input
import * as React from 'react';
import {
    Box,
    Input,
    Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';

type NameOverValueProps = {
    name: string;
    value: string;
    type?: string;
    space?: string;
    disabled?: boolean;
    nameWidth?: string;
    valueWidth?: string;
    onChange: (name: string, value: string) => void;
};
const NameOverValue = ({ name, value, type = 'text', disabled = false, onChange, space = '1rem' }: NameOverValueProps) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`event = ${event}`)
        console.log(`event.name = ${event.target.name}`)
        console.log(`event.value = ${event.target.value}`)
        onChange(event.target.name, event.target.value);
    };
    return (
        <>
            {!isMobile &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    <Typography
                        sx={{
                            m: '0.1rem',
                            p: '0.3rem',
                        }}
                        color="textPrimary"
                    >
                        {name}
                    </Typography>
                    <Box sx={{
                        width: space,
                        backgroundColor: "#00ff00"
                    }} />
                    <Input
                        sx={{
                            boder: 1,
                            borderRadius: '5px',
                            m: '0.4rem',
                            p: '0.6rem',
                            border: 1,
                            borderColor: '#BFBFBF',
                            backgroundColor: "#FFFFFF",
                            width: '18rem',
                            height: '2.7rem'
                        }}
                        disabled={disabled}
                        disableUnderline
                        name={name}
                        type={type}
                        onChange={handleChange}
                        required
                        value={value}
                        margin="none"
                    />

                </Box>
            }
            {isMobile &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    <Typography
                        sx={{
                            m: '0.1rem',
                            p: '0.3rem',
                        }}
                        color="textPrimary"
                    >
                        {name}
                    </Typography>
                    <Box sx={{
                        width: space,
                        backgroundColor: "#00ff00"
                    }} />
                    <Input
                        sx={{
                            boder: 1,
                            borderRadius: '5px',
                            m: '0.4rem',
                            p: '0.6rem',
                            border: 1,
                            borderColor: '#BFBFBF',
                            backgroundColor: "#FFFFFF",
                            width: '17rem',
                            height: '2.7rem'
                        }}
                        disabled={disabled}
                        disableUnderline
                        name={name}
                        type={type}
                        onChange={handleChange}
                        required
                        value={value}
                        margin="none"
                    />

                </Box>
            }
        </>
    )
};

export default NameOverValue;
