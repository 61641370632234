// 사이트관리 - 사이트 - 수정

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Table,
  TableRow,
  TableCell
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { ISiteDetail, ISiteSet, SiteLevelType } from 'model';
import { useStores } from 'state';
import userStore from 'state/user';
import NameInput from 'components/atom/NameInput';
import styles from 'components/facilityGroup/style.module.css'
import { isMobile } from 'react-device-detect';
import MobileNameInput from 'components/atom/MobileNameInput';

const SiteType = [
  { id: 'siteName', name: '사이트이름' },
  { id: 'description', name: '설명' },
  { id: 'userIdManager', name: '사용자아이디' },
  { id: 'userIdMaintenance', name: '관리자아이디' },
];

const SiteAdd = () => {
  const { siteStore } = useStores();
  const location = useLocation();
  const state = location.state as ISiteDetail;
  const navigate = useNavigate();

  const [type, setType] = useState('add');
  const [values, setValues] = useState<ISiteDetail>(
    {
      siteId: '',
      siteName: '',
      description: '',
      userIdManager: null,
      userIdMaintenance: null,
      address: '',
      zipCode: '',
      admCode: '',
      admCodeType: '803',
      enable: 'Y',
      userName: '',
      hpNo: '',
    });
  const [disableButton, setDisableButton] = useState(true);

  //value값 설정
  useEffect(() => {
    console.log(`SiteAdd useEffect ${JSON.stringify(state)}`)
    if (state != null) {
      setType('modify');

      setValues({
        ...values,
        siteId: state.siteId,
        siteName: state.siteName,
        description: state.description
      });
    }
  }, []);

  //disabled 조건 지정
  const disableButtonFunc = () => {
    if (
      values?.siteName == '' ||
      values?.description == ''
    ) {
      setDisableButton(true);
    }
    else {
      setDisableButton(false);
    }
  }

  //disabled함수 실행
  useEffect(() => {
    disableButtonFunc();
  }, [values]);

  //입력 기능
  const onChange = (name: string, value: string) => {
    const found = SiteType.find(item => item.name == name);
    if (found == null) {
      return;
    }

    setValues({
      ...values,
      [found.id]: value
    });

  };

  //저장 기능
  const handleSave = async () => {
    if (type == 'add') {
      const site: ISiteSet = {
        siteId: '',
        siteName: values.siteName,
        description: values.description,
        address: values.address,
        zipCode: values.zipCode,
        admCode: values.admCode,
        admCodeType: values.admCodeType,
        userIdManager: null,
        userIdMaintenance: values.userIdMaintenance,
        enable: values.enable,
        smtpEnable: 'N',
        smtpSslEnable: 'N',
        smtpServer: '',
        smtpPort: '465',
        smtpId: '',
        smtpPw: '',
        smtpEncryptPw: '',
        smtpFromName: '',
      }
      //성공실패 구분
      const ret: any = await siteStore.setSite(site);
      console.log(`SiteAdd setSite ${JSON.stringify(ret)}`)
      if (ret.status == 200) {
        ToastsStore.success("등록 성공")
        await siteStore.getSiteList(userStore.getId(), "10", "1");
        const siteInfo = siteStore.siteList.find(elem => {
          return elem.siteName == site.siteName;
        })
        await siteStore.setUser(siteInfo.siteId, userStore.getId(), SiteLevelType.Manager);
        const timer = setInterval(() => {
          navigate('/slager/sitelist');
          clearInterval(timer);
        }, 1000)
      }
      else if (ret.status == 400) {
        ToastsStore.success("등록 실패")
      }
    }
    else if (type == 'modify') {
      const site: ISiteSet = {
        siteId: values.siteId,
        siteName: values.siteName,
        description: values.description,
        address: values.address,
        zipCode: values.zipCode,
        admCode: values.admCode,
        admCodeType: values.admCodeType,
        userIdManager: null,
        userIdMaintenance: values.userIdMaintenance,
        enable: values.enable,
        smtpEnable: 'N',
        smtpSslEnable: 'N',
        smtpServer: '',
        smtpPort: '465',
        smtpId: '',
        smtpPw: '',
        smtpEncryptPw: '',
        smtpFromName: '',
      }
      const ret: any = await siteStore.setSite(site);
      console.log(`SiteAdd setSite ${JSON.stringify(ret)}`)
      if (ret.status == 200) {
        ToastsStore.success("등록 성공")
        const timer = setInterval(() => {
          navigate('/slager/sitelist');
          clearInterval(timer);
        }, 1000)
      }
      else if (ret.status == 400) {
        ToastsStore.success("등록 실패")
      }
    }
  }
  
  //취소
  const BackPage = () => {
    navigate(-1);
  };
  return (
    <Box>
      <Typography
        className={styles.pageDirection}
        gutterBottom>
        사이트 관리 {'>'} 사이트 {'>'} 사이트 수정
      </Typography>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2%',
          right: '1%'
        }}
      >
        <Typography //타이틀 글자 조정
          className={styles.pageTitleNoBorder}
          gutterBottom >
          사이트 수정
        </Typography>
      </Box>
      <form
        autoComplete="off"
        noValidate
      >
        <Table>
          <TableRow>
            <TableCell className={styles.regTableTop}>
              {!isMobile &&
                <NameInput
                  name="사이트이름"
                  value={values?.siteName}
                  space={10}
                  nameWidth={110}
                  valueWidth={200}
                  onChange={onChange}
                />}
              {isMobile &&
                <MobileNameInput
                  name="사이트이름"
                  value={values?.siteName}
                  onChange={onChange}
                />}
            </TableCell>
          </TableRow>

          <Box sx={{ height: 5 }} />
          <TableRow>
            <TableCell className={styles.regTableBottom}>
              {!isMobile &&
                <NameInput
                  name="설명"
                  value={values?.description}
                  space={10}
                  nameWidth={110}
                  valueWidth={200}
                  onChange={onChange}
                />}
              {isMobile &&
                <MobileNameInput
                  name="설명"
                  value={values?.description}
                  onChange={onChange}
                />}
            </TableCell>
          </TableRow>
        </Table>
        <Box sx={{ height: 5 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Button
            className={styles.greenButton}
            color="primary"
            size="medium"
            variant="contained"
            disabled={disableButton}
            onClick={() => BackPage()}
          >
            취소
          </Button>
          <Button
            className={styles.primaryButton}
            color="primary"
            variant="contained"
            disabled={disableButton}
            onClick={handleSave}
          >
            저장
          </Button>
        </Box>

      </form>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
    </Box>
  );
};

export default SiteAdd;
