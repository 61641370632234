//사이트 관리 - 사이트 (타이틀 및 borderBox..)

import {
  Box,
  Card,
  CardContent,
  Typography,
  Popover,
  IconButton,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonBlue, ButtonGreen } from 'components/admin/system/Styled';
import styles from 'components/facilityGroup/style.module.css'

type SiteToolbarProps = {
  siteCount: string;
  onCommand?: (command: string) => void;
};
const SiteToolbar = ({ siteCount, onCommand }: SiteToolbarProps) => {
  const { t } = useTranslation()

  //borderBox설정
  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    style: { width: '100%', height: '6rem' },
  };
  return (
    <Box>
      <Typography
         className={styles.pageDirection}
        gutterBottom>
        사이트 관리 {'>'} 사이트
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '-0.5%'
        }}>
        <Typography //글자 조정
          className={styles.pageTitle}
          gutterBottom >
          사이트
        </Typography>

        {/* <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => ( // 도움말
            <div>
              <IconButton sx={{ position: 'relative', left: '35%', marginBottom: '35%', color: '#0C3149' }}{...bindTrigger(popupState)}>
                <HelpOutline />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Typography sx={{ p: 2 }}> 이미 등록 된 사용자를 사이트에 등록 혹은 삭제하고 사이트 정보를 수정할 수 있다. </Typography>
              </Popover>
            </div>
          )}
        </PopupState> */}
      </Box>

      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
        sx={{
          borderRadius: 3,
          borderColor: '#1C75BC',
          marginBottom: '2%',
          margin: 'auto'
        }}
        {...defaultProps}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1,
            textAlign: 'left',
            float: 'right',
            top: 15
          }}
        >
          <Box sx={{ m: 1 }}>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 'auto',
              position: 'relative',
              right: '10px'
            }}
          >
            <Button
              className={styles.blueButton}
              onClick={() => {
                console.log(`modify`)
                onCommand('modify');
              }}
            >
              {t('global.modify')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteToolbar;
