// 장애설정
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import NameInputRem from 'components/atom/NameInputRem';
import NameOverInput from 'components/atom/NameOverInput';
import { IGroupSet, ILineSet, getFacTypeStr, isSiteManager } from 'model';
import React, { useEffect, useState } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import styles from 'components/facilityGroup/style.module.css'
import groupStore from 'state/group';
import lineStore from 'state/line';
import userStore from 'state/user';
import facilityStore from 'state/facility';
import { observer } from 'mobx-react';
import NameSelect from 'components/atom/NameSelect';
import { XCircle as XCircleIcon } from 'react-feather';
import { IMaintenanceErrorInfo, getErrorTypeStr } from 'model/maintenance';
import maintenanceStore from 'state/maintenance';
import StatusSelect from 'components/atom/StatusSelect';
import NameInputError from 'components/atom/NameInputError';
interface StyledNameInputProps {
    name: string;
    value: string;
    disabled?: boolean;
    defaultValue?: string;
    onChange?: (name: string, value: string) => void;
}
function StyledNameInput({ name, value, disabled, onChange }: StyledNameInputProps) {

    return (
        <>
            <NameInputRem
                name={name}
                value={value}
                disabled={disabled}
                space={'3rem'}
                nameWidth={'5rem'}
                valueWidth={'20rem'}
                onChange={onChange}
            />
        </>
    );
}


export interface ErrorManagePopupProps {
    //groupInfo: any;
    facId: string;
    mainId: string;
    createTime: string;
    open: boolean;
    onShow?: (show: boolean) => void;
    onClose: (update: boolean) => void;
}
const ErrorManagePopup = observer(({ open, facId, mainId, createTime, onClose, onShow }: ErrorManagePopupProps) => {
    //const [listItems, setListItems] = useState([]);
    const [newItem, setNewItem] = useState("");
    const [open1, setOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);
    const [stateSelectOpen, setStateSelectOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [error, setError] = useState([]);
    const [errorStatus, setErrorStatus] = useState('');
    const [comments, setComments] = useState([]);
    const [errorStatusValue, setErrorStatusValue] = useState('');
    const [values, setValues] = useState<IMaintenanceErrorInfo>(
        {
            mainId: '',
            facId: '',
            facName: '',
            category: '',
            description: '',
            stateCode: '',
            updateTime: '',
            createTime: '',
            completeTime: ''
        });

    const ErrorStatus = [
        { value: '12', label: '장애 처리 요청' },
        { value: '13', label: '장애 처리 중' },
        { value: '14', label: '장애 처리 완료' },
        { value: '15', label: '장애 완료 확인' },
        //{ value: '3', label: '보류' },
    ];
//팝업 열리면 장애등록됨 comment 추가
    useEffect(() => {
        const addComment = async () => {
          if (mainId) {
            await maintenanceStore.getCommentList(mainId);
            if (maintenanceStore.commentList === undefined || !maintenanceStore.commentList || maintenanceStore.commentList.length === 0) {
                maintenanceStore.addComment(mainId, '', '장애 등록 됨');
              }
          }
        };
        addComment();
        setComments(maintenanceStore.commentList)
      }, [mainId]);

      //에러 목록 가져옴
    useEffect(() => {
        if (open)
            maintenanceStore.getError(mainId);
    }, [open]);

    //장애설정 닫기
    const handleDialogClose = () => {
        console.log(`cyi handleDialog`)
        onClose(false);
    };
    //코멘트 추가 Open,Close
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setNewItem(null);
        setOpen(false);
    };
    //장애 처리 완료 경고 팝업
    const handleEndOpen = () => {
        setEndOpen(true);
    };
    const handleEndClose = () => {
        setEndOpen(false);
    };
    //장애 설정 확인
    const handleSave = async () => {
        if (!values.stateCode) {
            ToastsStore.warning("처리 상태를 선택해주세요.");
        }
        else {
            if (values.stateCode == '13') {
                maintenanceStore.addComment(mainId, '', '장애 처리 중');
            }
            else if (values.stateCode == '14') {
                maintenanceStore.addComment(mainId, '', '장애 처리 완료');
            }
            else if (values.stateCode == '15') {
                maintenanceStore.addComment(mainId, '', '장애 완료 확인');
            }
            else if (values.stateCode == '보류') {
                maintenanceStore.addComment(mainId, '', '보류');
            }
            maintenanceStore.getCommentList(mainId);
            const ret: any = await maintenanceStore.updateError(
                facId,
                mainId,
                maintenanceStore.maintenanceInfo?.category,
                maintenanceStore.maintenanceInfo?.description,
                values.stateCode
            )

            maintenanceStore.getErrorList('', userStore.siteId);
            if (ret.status == 200) {
                ToastsStore.success("설정 성공")
                const timer = setInterval(() => {
                    onClose(false);
                    setEndOpen(false);
                    clearInterval(timer);
                }, 1000)
            }
            else if (ret.status == 400) {
                ToastsStore.success("설정 실패")
            }
        }
    };
    //상태 변경
    const handleChangeStatus = (name: string, value: string) => {
        const type = ErrorStatus.find((item: any) => item.value == value)

        setValues({
            ...values,
            stateCode: type.value,
        });
        maintenanceStore.getError(mainId);
    };

    //추가
    const handleAddItem = async () => {
        await maintenanceStore.addComment(mainId, '', newItem);
        maintenanceStore.getCommentList(mainId)
        handleClose();
    };
    //삭제
    const handleDeleteItem = async (subId: string) => {
        console.log("aaff", subId)
        await maintenanceStore.delComment(subId);
        maintenanceStore.getCommentList(mainId)
    };

    return (
        <Box>
            {/* 장애설정 dialog */}
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                fontSize: 23,
                                fontWeight: 600,
                                color: '#0C3149',
                                display: 'flex',
                                position: 'relative',

                                padding: 1.5
                            }}>
                            장애 설정
                        </Typography>
                        {maintenanceStore.maintenanceInfo?.stateCode == '15' &&
                            <Typography
                                sx={{
                                    fontSize: 21,
                                    fontWeight: 600,
                                    color: '#0C3149',
                                    display: 'flex',
                                    position: 'relative',

                                    padding: 1.5
                                }}>
                                ( 완료 됨 )
                            </Typography>}
                    </Box>
                    <Divider />
                    <CardContent>
                        <Box>
                            <StyledNameInput
                                name="시설 이름"
                                value={maintenanceStore.maintenanceInfo?.facName}
                                // value={groupName}
                                disabled={true} />
                            <Box sx={{ mt: 1 }}> </Box>
                            <NameInputError
                                name="내용"
                                //defaultValue={values?.status}
                                value={maintenanceStore.maintenanceInfo?.description}
                                disabled={true} />
                            <Box sx={{ mt: 1 }}> </Box>
                            <StatusSelect
                                name="처리 상태"
                                object={ErrorStatus}
                                defaultValue={maintenanceStore.maintenanceInfo?.stateCode}
                                space={10}
                                nameWidth={110}
                                valueWidth={200}
                                onSelect={handleChangeStatus}
                                disabled={maintenanceStore.maintenanceInfo?.stateCode == '15' ? true : false} />
                            <Box sx={{ mt: 1 }}> </Box>
                        </Box>
                        <Box sx={{ height: 10 }} />
                        <Divider sx={{ border: 1, borderColor: '#BFBFBF' }} />
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                sx={{
                                    fontSize: 23,
                                    fontWeight: 600,
                                    color: '#0C3149',
                                    display: 'flex',
                                    position: 'relative',

                                    padding: 1.5
                                }}>
                                작업 내용
                            </Typography>
                            <Box>
                                <Button
                                    variant='outlined'
                                    className={styles.errorCommentAddButton}
                                    size={'small'}
                                    onClick={handleOpen}
                                    disabled={maintenanceStore.maintenanceInfo?.stateCode == '15' ? true : false}>
                                    추가</Button>
                            </Box>
                        </Box>
                        <Box sx={{ height: 10 }} />
                        <Box sx={{ display: 'flex' }}>
                        </Box>
                        <Box sx={{
                            width: '2rem',
                            backgroundColor: "#00ff00"
                        }} />

                        <Card>
                            <List component="nav" aria-label="mailbox folders">
                                {maintenanceStore.commentList?.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <Typography>
                                                            {item.comment === '장애 등록 됨' ? (
                                                                <>
                                                                    <span style={{ color: '#4472C4' }}>{maintenanceStore.maintenanceInfo?.createTime}</span>
                                                                    <br />
                                                                    {item.comment}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span style={{ color: '#4472C4' }}>{item.createTime}</span>
                                                                    <br />
                                                                    {item.comment}
                                                                </>
                                                            )}
                                                        </Typography>
                                                    }
                                                />
                                                <Button variant="outlined" size='small' onClick={() => handleDeleteItem(item.subId)}
                                                    disabled={maintenanceStore.maintenanceInfo?.stateCode == '15' ? true : false}>
                                                    삭제
                                                </Button>
                                            </ListItem>
                                            {index < maintenanceStore.commentList.length - 1 && <Divider />}
                                        </div>
                                    );
                                })}
                            </List>
                        </Card>
                        <Box sx={{ height: 5 }} />

                        <Divider sx={{ position: 'relative', top: 42, borderColor: '#bdbdbd' }}></Divider>
                    </CardContent>
                </DialogContent>
                <DialogActions>
                    <Button className={styles.greenButton} onClick={handleDialogClose} autoFocus>
                        {maintenanceStore.maintenanceInfo?.stateCode !== '15' ? '취소' : '닫기'}
                    </Button>
                    {maintenanceStore.maintenanceInfo?.stateCode !== '15' && (
                        <Button className={styles.primaryButton} onClick={() => {
                            if (values?.stateCode === '15') {
                                handleEndOpen();
                            } else {
                                handleSave();
                            }
                        }}>
                            확인
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* 코멘트 추가 Dialog */}
            <Dialog open={open1} onClose={handleClose} >
                <DialogContent>
                    <Typography
                        sx={{
                            fontSize: 21,
                            fontWeight: 600,
                            color: '#0C3149',
                            display: 'flex',
                            position: 'relative',

                            padding: 1.5
                        }}>
                        코멘트 추가
                    </Typography>
                    <Divider />
                    <Box sx={{ mt: 2 }}> </Box>
                    <TextField
                        id="outlined-multiline-static"
                        label="코멘트를 입력하세요."
                        multiline
                        rows={4}
                        value={newItem}
                        onChange={(e) => setNewItem(e.target.value)}
                        sx={{ width: '400px' }}
                    />
                    <Box sx={{ mt: 2 }}> </Box>
                    <Divider />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button className={styles.greenButton} onClick={handleClose}>취소</Button>
                    <Button className={styles.blueButton} onClick={handleAddItem}>추가</Button>
                </DialogActions>
            </Dialog>
            {/* 상태 저장 경고 팝업 */}
            <Dialog open={endOpen} onClose={handleEndClose}>
                <DialogContent>
                    <Card>
                        <Typography
                            sx={{
                                fontSize: 21,
                                fontWeight: 600,
                                color: '#0C3149',
                                display: 'flex',
                                position: 'relative',
                                textAlign: 'center',
                                padding: 1.5
                            }}>
                            [ 장애 완료 확인 ]으로 상태를 변경하면 <br />장애 처리가 종료되며 상태를 변경할 수 없습니다.<br />
                            장애를 처리 완료하시겠습니까?
                        </Typography>
                    </Card>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button className={styles.greenButton} onClick={handleEndClose}>취소</Button>
                    <Button className={styles.blueButton} onClick={handleSave}>확인</Button>
                </DialogActions>
            </Dialog>
            {/* 상태변경 dialog */}
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
        </Box>

    )
});

export default ErrorManagePopup;