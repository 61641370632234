// 시설운영 - 그룹 - 설정 (그룹설정) 컨테이너 레이아웃
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@mui/material';
import FacilityGroupDetailImp from 'components/facilityGroup/FacilityGroupDetailImp';
import { useLocation } from 'react-router';
import { IGroupInfo } from 'model';

const FacilityGroupDetail = () => {
  const location = useLocation();
  const state = location.state as IGroupInfo;
  console.log('FacilityGroupDetail')

  console.log(`FacilityGroupDetail state ${JSON.stringify(state)}`)

  return(
  <>
    <Helmet>
      <title>FacilityGroupDetail</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <FacilityGroupDetailImp></FacilityGroupDetailImp>
      </Container>
    </Box>
  </>
);
    };

export default FacilityGroupDetail;
