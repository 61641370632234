// 관리자 - 사이트 (타이틀 및 버튼들)
import {
  Box,
  Button,
  Card,
  CardContent,
  Input,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonBlue, ButtonGreen } from './Styled';
import styles from 'components/facilityGroup/style.module.css'

type AdminSiteToolbarProps = {
  siteCount: string;
  onCommand?: (command: string) => void;
};

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1,
  border: 1,
  style: { width: '100%', height: '6rem' },
};
const AdminSiteToolbar = ({ siteCount, onCommand }: AdminSiteToolbarProps) => {
  const { t } = useTranslation()

  return (
    <Box >
      <Typography
        className={styles.pageDirection}
        gutterBottom>
        관리자 {'>'} 사이트
      </Typography>

      <Typography //타이틀 글자 조정
        className={styles.pageTitle}
        gutterBottom >
        사이트 관리
      </Typography>

      <Box sx={{ mt: 1 }}> </Box>
      <Box // 텍스트 박스 border
        sx={{
          borderRadius: 3,
          borderColor: '#1C75BC',
          marginBottom: '2%',
          margin: 'auto'
        }}
        {...defaultProps}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1,
            textAlign: 'left',
            float: 'right',
            top: 15
          }}
        >
          <Box sx={{ m: 1 }}>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 'auto',
              right: '10px'
            }}
          >
            <ButtonBlue
              sx={{
                "&:hover": { backgroundColor: "#10548A" }
              }}
              onClick={() => {
                onCommand('addUser');
              }}
            >
              {t('global.setUser')}
            </ButtonBlue>
            <Button
              className={styles.blueButton}
              onClick={() => {
                onCommand('add');
              }}
            >
              {t('global.add')}
            </Button>
            <Button
              className={styles.blueButton}
              onClick={() => {
                onCommand('modify');
              }}
            >
              {t('global.modify')}
            </Button>
            <Button
              className={styles.greenButton}
              onClick={() => {
                onCommand('delete');
              }}
            >
              {t('global.delete')}
            </Button>
          </Box>

        </Box>
      </Box>
    </Box>

  );
};

export default AdminSiteToolbar;
