// 연간스케줄 초기 설정시 뜨는 지역선택 dialog
// 지역 재선택 dialog

import { Box, Card, CardContent, Drawer, Grid } from "@mui/material";
import { TitleinTab } from "./DefineStyled";
import { useStores } from 'state';
import NameValue from 'components/atom/NameValue';
import { IFacInfo } from "model";
import facilityStore from "state/facility";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import NameSelectRem from "./NameSelectRem";
import sunsetStore from "state/sunset";
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";

interface StyledNameValueProps {
	name: string;
	value :string;
  }
  function StyledNameValue({name, value}:StyledNameValueProps) {
	return (
	  <>
		<Grid
		  item
		  lg={6}
		  sm={12}
		  xl={6}
		  xs={12}
		>
		<NameValue
		  name={name}
		  value={value}
		  space={3}
		  nameWidth={150}
		  valueWidth={400}
		/>
		{/* <Box sx={{
		  height: 10,
		}} /> */}
		</Grid>
	  </>
	);
  }
type SunsetCitySelectDialogProps = {
	show: boolean;
	onShow?: (show:boolean) => void;
	onSunsetCity?: (cityName:string, cityId:string, areaId:string) => void;
};
const SunsetCitySelectDialog = observer(({ show, onShow, onSunsetCity }:SunsetCitySelectDialogProps) => {

	const [cityss, setCityss] = useState(null);
	const [areas, setAreas] = useState(null);
	const [areaId, setAreaId] = useState('0');
	const [cityId, setCityId] = useState('');
	const [cityName, setCityName] = useState('');

  // 지역 받아옴.
	const getArea = async () => {

    await sunsetStore.getSunsetAreaList();
    const newAreas = sunsetStore.areas.reduce((acc, item) => {
      console.log(`getArea = area = ${JSON.stringify(item)}`)
      acc.push({value:item.areaId - 1 , label :item.areaName})
      return acc;
    }, []);
    newAreas.map((item:any, index:number) => {
      console.log(`getArea newarea = ${JSON.stringify(item)}`)
    });
    setAreas(newAreas);
  }

  // 도시 받아옴
  const getCity = async (_areaId:string) => {
    await sunsetStore.getSunsetCityList(_areaId);
    let indexNum = 0;
    const newCitys = sunsetStore.citys.reduce((acc, item) => {
      console.log(`getCity = citys = ${JSON.stringify(item)}`)
      acc.push({value:indexNum, label : item.cityName, cityId:item.cityId, cityName :item.cityName})
      indexNum++;
      return acc;
    }, []);
    newCitys.map((item:any, index:number) => {
      console.log(`getCity newcity = ${JSON.stringify(item)}`)
    });
    setCityss(newCitys);
  }

	useEffect(() => {
		console.log(`getCity useEffect(areaId) ${areaId}`)
		if (Number(areaId) > 0)
			getCity(areaId);
	}, [areaId]);

	useEffect(() => {
		if (show)
			getArea();
      console.log('aaaaaaaaaaaaad',areaId)
	}, [show]);

  // 지역 바꿈
	const onChangeArea = (name: string, value: string) => {
		console.log(`cyi onChangeArea name:${name}, value:${value}`)
		setAreaId(String(value + 1));
	};

  // 도시 바꿈
	const onChangeCity = (name: string, value: string) => {
		console.log(`cyi onChangeCity name:${name}, value:${value}`)
		const city = cityss.find((item: any) => item.value == value)
		console.log(`SunsetCitySelectDialog onChangeCity ${JSON.stringify(city)}`)
		setCityId(city.cityId);
		setCityName(city.cityName);
	};

  // 팝업 닫음
	const handleSelectDialogClose = () => {
		onShow(false);
	};

  // 선택 정보 저장
	const handleSaveSunsetCity = () => {
		console.log(`SunsetCitySelectDialog handleSaveSunsetCity ${cityName}, ${cityId}`)
    console.log("aaffaa",areaId)
    if(Number(areaId) == 0|| Number(cityId) == 0){
    ToastsStore.warning("지역을 선택해주세요.")
    }
    else{
		onSunsetCity(cityName, cityId, areaId)
		onShow(false);
    }
	};

	return (
		<Dialog
          open={show}
          onClose={handleSelectDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx:{
            width:'2400px'
          }
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              지역 설정
            </DialogContentText>
            {
              areas &&
            <NameSelectRem
              name='광역'
              object={areas}
              space={'3rem'}
              nameWidth={'5rem'}
              valueWidth={'20rem'}
              onSelect={onChangeArea}
            />
            }
            {
              cityss &&
            <NameSelectRem
              name='도시'
              object={cityss}
              space={'3rem'}
              nameWidth={'5rem'}
              valueWidth={'20rem'}
              onSelect={onChangeCity}
            />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSelectDialogClose}>취소</Button>
            <Button onClick={handleSaveSunsetCity}>확인</Button>
          </DialogActions>
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
        </Dialog>
	)
});
		
export default SunsetCitySelectDialog;